import React from "react";

const IconCamionS = () => {
    return (
        <svg width="85" height="55" viewBox="0 0 85 55" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_14330_74212)">
                <path d="M16.831 50.7467C17.9552 50.7467 18.8704 49.8315 18.8704 48.7073C18.8704 47.5831 17.9552 46.668 16.831 46.668C15.7068 46.668 14.7916 47.5831 14.7916 48.7073C14.7916 49.8315 15.7068 50.7467 16.831 50.7467Z" fill="#231F20" />
                <path d="M16.831 55.0006C20.3071 55.0006 23.1242 52.1835 23.1242 48.7073C23.1242 45.2312 20.3071 42.4141 16.831 42.4141C13.3548 42.4141 10.5377 45.2312 10.5377 48.7073C10.5377 52.1835 13.3567 55.0006 16.831 55.0006ZM16.831 44.9299C18.9513 44.9299 20.6725 46.6209 20.6725 48.7073C20.6725 50.7938 18.9532 52.4848 16.831 52.4848C14.7087 52.4848 12.9914 50.7938 12.9914 48.7073C12.9914 46.6209 14.7106 44.9299 16.831 44.9299Z" fill="#231F20" />
                <path d="M62.0551 48.7073C62.0551 49.8315 62.9702 50.7467 64.0944 50.7467C65.2186 50.7467 66.1338 49.8315 66.1338 48.7073C66.1338 47.5831 65.2186 46.668 64.0944 46.668C62.9702 46.668 62.0551 47.5831 62.0551 48.7073Z" fill="#231F20" />
                <path d="M57.8012 48.7073C57.8012 52.1835 60.6202 55.0006 64.0945 55.0006C67.5687 55.0006 70.3877 52.1835 70.3877 48.7073C70.3877 45.2312 67.5706 42.4141 64.0945 42.4141C60.6183 42.4141 57.8012 45.2312 57.8012 48.7073ZM67.9341 48.7073C67.9341 50.7938 66.2148 52.4848 64.0945 52.4848C61.9741 52.4848 60.2549 50.7938 60.2549 48.7073C60.2549 46.6209 61.9741 44.9299 64.0945 44.9299C66.2148 44.9299 67.9341 46.6209 67.9341 48.7073Z" fill="#231F20" />
                <path d="M77.8409 46.6641C76.7167 46.6641 75.8016 47.5792 75.8016 48.7034C75.8016 49.8276 76.7167 50.7428 77.8409 50.7428C78.9651 50.7428 79.8803 49.8276 79.8803 48.7034C79.8803 47.5792 78.9651 46.6641 77.8409 46.6641Z" fill="#231F20" />
                <path d="M77.8409 42.4141C74.3666 42.4141 71.5477 45.2312 71.5477 48.7073C71.5477 52.1835 74.3666 55.0006 77.8409 55.0006C81.3152 55.0006 84.1342 52.1835 84.1342 48.7073C84.1342 45.2312 81.3171 42.4141 77.8409 42.4141ZM77.8409 52.4848C75.7206 52.4848 74.0013 50.7938 74.0013 48.7073C74.0013 46.6209 75.7206 44.9299 77.8409 44.9299C79.9613 44.9299 81.6805 46.6209 81.6805 48.7073C81.6805 50.7938 79.9613 52.4848 77.8409 52.4848Z" fill="#231F20" />
                <path d="M82.1852 41.2064C83.831 41.2064 85.1718 39.8337 85.1718 38.1483V13.0581C85.1718 11.3728 83.8329 10 82.1852 10H31.1009C29.4551 10 28.1144 11.3709 28.1144 13.0581V38.1502C28.1144 39.8355 29.4533 41.2083 31.1009 41.2083H82.1833L82.1852 41.2064Z" fill="url(#paint0_linear_14330_74212)" />
                <path d="M34.476 31.864H37.612C37.696 32.228 37.864 32.494 38.088 32.648C38.312 32.816 38.648 32.886 39.096 32.886C39.488 32.886 39.782 32.83 39.992 32.69C40.216 32.564 40.314 32.382 40.314 32.158C40.314 31.794 39.866 31.5 38.956 31.262C38.774 31.22 38.634 31.192 38.536 31.164C37.794 30.968 37.206 30.8 36.758 30.646C36.324 30.492 36.002 30.338 35.806 30.198C35.414 29.918 35.134 29.596 34.938 29.204C34.742 28.812 34.644 28.378 34.644 27.874C34.644 26.88 35.022 26.096 35.778 25.522C36.534 24.962 37.556 24.668 38.872 24.668C40.174 24.668 41.196 24.948 41.938 25.494C42.68 26.04 43.1 26.824 43.184 27.832H40.118C40.076 27.538 39.95 27.314 39.74 27.174C39.516 27.034 39.222 26.964 38.83 26.964C38.48 26.964 38.228 27.02 38.06 27.132C37.892 27.244 37.808 27.412 37.808 27.65C37.808 27.986 38.242 28.266 39.138 28.49L39.306 28.532C39.432 28.56 39.628 28.602 39.894 28.672C41.056 28.966 41.826 29.232 42.19 29.47C42.638 29.778 42.96 30.114 43.17 30.492C43.366 30.87 43.478 31.318 43.478 31.822C43.478 32.942 43.086 33.796 42.302 34.384C41.518 34.986 40.412 35.28 38.97 35.28C37.626 35.28 36.576 34.986 35.792 34.384C35.008 33.796 34.56 32.956 34.476 31.864Z" fill="black" />
                <path d="M12.3869 15.2255C12.3869 15.3178 12.5752 15.3761 12.9556 15.3761H23.4199C24.1449 15.3761 24.738 14.783 24.738 14.058V11.3256C24.738 10.5216 24.1825 10.096 23.5065 10.3822L12.8709 14.8583C12.53 15.0014 12.37 15.1332 12.3888 15.2274L12.3869 15.2255Z" fill="#231F20" />
                <path d="M57.1647 44.6889C57.1741 44.171 56.8145 43.7492 56.2947 43.7492H25.5911C25.0714 43.753 24.6496 43.1824 24.6496 42.5704V18.0584C24.6496 17.4464 24.2277 16.9492 23.708 16.9492H10.4323C9.91256 16.9492 9.26478 17.3673 8.98796 17.887L8.71115 18.4011C8.94842 18.6647 9.04069 18.9679 8.92017 19.192L3.99027 28.3287C3.87163 28.5471 3.57787 28.6337 3.23703 28.5885L1.8944 31.0874C1.61758 31.6052 1.39161 32.5223 1.39161 33.1343V38.1038C1.69291 38.2054 1.90193 38.4182 1.90193 38.6687V41.273C1.90193 41.5234 1.69291 41.7362 1.39161 41.8379V42.4367C1.39161 43.3858 0.630848 43.6494 0.271179 44.2953C-0.195825 45.1371 0.0320276 47.2311 0.257997 47.7207C0.463253 48.1669 0.659094 48.6942 2.52711 48.6942L8.39479 48.7074C8.91452 48.7074 9.36835 47.5493 9.5849 46.9844C10.4624 44.6945 12.1233 41.4462 16.8291 41.4462C22.0528 41.4462 25.2917 46.3404 23.8323 51.6469C23.4124 53.1722 24.256 52.2739 24.7117 49.7619C24.819 49.1631 25.1523 48.7074 25.6721 48.7074H55.9577C56.4774 48.7074 57.1628 48.4362 57.1628 47.9165C57.1628 47.9165 57.1421 45.7736 57.1628 44.6927L57.1647 44.6889ZM21.8569 34.9873C21.8569 35.3545 21.5481 35.652 21.1659 35.652H19.2056C18.8233 35.652 18.5145 35.3545 18.5145 34.9873V34.3112C18.5145 33.944 18.8233 33.6465 19.2056 33.6465H21.1659C21.5481 33.6465 21.8569 33.944 21.8569 34.3112V34.9873ZM23.3013 28.7128C23.3013 29.4529 22.8399 30.0536 22.2712 30.0536L13.4151 30.0423C12.8464 30.0423 12.3229 30.3888 12.0423 30.9838L9.56042 35.7348C9.14614 36.3638 8.54544 36.36 7.97675 36.36H6.07484C5.50615 36.36 5.09752 35.9194 5.09752 35.1793V30.0799C5.09752 29.338 5.17661 28.7467 5.4779 28.1215L10.0011 19.6477C10.3024 19.0206 11.0085 18.5122 11.5753 18.5122H22.2712C22.8399 18.5122 23.3013 19.1129 23.3013 19.8529V28.7091V28.7128Z" fill="#231F20" />
            </g>
            <defs>
                <linearGradient id="paint0_linear_14330_74212" x1="28.2524" y1="41.2083" x2="85.2524" y2="41.2083" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FECA00" />
                    <stop offset="0.90687" stopColor="#FECA00" />
                    <stop offset="0.908622" stopColor="#6F6F6F" />
                    <stop offset="1" stopColor="#6F6F6F" />
                </linearGradient>
                <clipPath id="clip0_14330_74212">
                    <rect width="85" height="55" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}

export default IconCamionS;
