import { planningToolApiUrls } from "../../configs/planningToolApiUrls";
import basicRequest from "../basicRequest";

export const masterDataService = {
    getAllBusinessFlowTypes: async () => {
        const request = new basicRequest();
        return await request.get(planningToolApiUrls.getBusinessFlowTypes);
    },
    getRelativeBusinessFlowTypes: async (departmentId: number) => {
        const request = new basicRequest();
        return await request.get(planningToolApiUrls.getBusinessFlowTypes + '/' + departmentId);
    },
    getDepartments: async () => {
        const request = new basicRequest();
        return await request.get(planningToolApiUrls.getDepartments);
    },
    getEquipments: async () => {
        const request = new basicRequest();
        return await request.get(planningToolApiUrls.getEquipments);
    },
    getDeposits: async (favorite: boolean = false, page: number = 1, pageSize: number = 100) => {
        const request = new basicRequest();
        return await request.get(planningToolApiUrls.getDeposits, {
            favorite: favorite,
            page: page,
            pageSize: pageSize
        });
    },
    searchDeposits: async (searchFilter: string, page: number = 1) => {
        const request = new basicRequest();
        return await request.get(planningToolApiUrls.getDeposits, {
            searchFilter: searchFilter,
            page: page,
            pageSize: 25
        });
    },
    addRemoveDepositToFavourites: async (id: number, favorite: boolean) => {
        const request = new basicRequest();
        return await request.put((planningToolApiUrls.getDeposits + '/favorite'), [
            {
                id: id,
                favorite: favorite
            }
        ]);
    },
    getCarriers: async (favorite: boolean = false, page: number = 1, pageSize: number = 100) => {
        const request = new basicRequest();
        return await request.get(planningToolApiUrls.getCarriers, {
            favorite: favorite,
            page: page,
            pageSize: pageSize
        });
    },
    searchCarriers: async (searchFilter: string, page: number = 1) => {
        const request = new basicRequest();
        return await request.get(planningToolApiUrls.getCarriers, {
            searchFilter: searchFilter,
            page: page,
            pageSize: 25
        });
    },
    addRemoveCarrierToFavourites: async (id: number, favorite: boolean) => {
        const request = new basicRequest();
        return await request.put((planningToolApiUrls.getCarriers + '/favorite'), [
            {
                id: id,
                favorite: favorite
            }
        ]);
    },
    getPartners: async (favorite: boolean = false, page: number = 1, pageSize: number = 100) => {
        const request = new basicRequest();
        return await request.get(planningToolApiUrls.partners, {
            favorite: favorite,
            page: page,
            pageSize: pageSize
        });
    },
    searchPartners: async (searchFilter: string, page: number = 1) => {
        const request = new basicRequest();
        return await request.get(planningToolApiUrls.partners, {
            searchFilter: searchFilter,
            page: page,
            pageSize: 25
        });
    },
    addRemovePartnerToFavourites: async (id: number, favorite: boolean) => {
        const request = new basicRequest();
        return await request.put((planningToolApiUrls.partners + '/favorite'), [
            {
                id: id,
                favorite: favorite
            }
        ]);
    },
    getVehicles: async (favorite: boolean = false, trailer: boolean, page: number = 1, pageSize: number = 100) => {
        const request = new basicRequest();
        return await request.get(planningToolApiUrls.vehicle, {
            trailer: trailer,
            favorite: favorite,
            page: page,
            pageSize: pageSize
        });
    },
    searchTrailer: async (searchFilter: string, page: number = 1) => {
        const request = new basicRequest();
        return await request.get(planningToolApiUrls.vehicle, {
            trailer: true,
            searchFilter: searchFilter,
            page: page,
            pageSize: 25
        });
    },
    addRemoveTrailerToFavourites: async (id: number, favorite: boolean) => {
        const request = new basicRequest();
        return await request.put((planningToolApiUrls.vehicle + '/favorite'), [
            {
                id: id,
                favorite: favorite,
                trailer: true
            }
        ]);
    },
    searchTruck: async (searchFilter: string, page: number = 1) => {
        const request = new basicRequest();
        return await request.get(planningToolApiUrls.vehicle, {
            trailer: false,
            searchFilter: searchFilter,
            page: page,
            pageSize: 25
        });
    },
    addRemoveTruckToFavourites: async (id: number, favorite: boolean) => {
        const request = new basicRequest();
        return await request.put((planningToolApiUrls.vehicle + '/favorite'), [
            {
                id: id,
                favorite: favorite,
                trailer: false
            }
        ]);
    },
    getTripRules: async () => {
        const request = new basicRequest();
        return await request.get(planningToolApiUrls.createTrip + '/tripRule');
    },
    getTriageCount: async () => {
        const request = new basicRequest();
        return await request.get(planningToolApiUrls.getOrders + '/triageCounter');
    },
    getTripsAndGroupsCount: async () => {
        const request = new basicRequest();
        return await request.get(planningToolApiUrls.users + '/counter');
    },
    updateDepartments: async (departments: any, loadingMessage: string | null = null, successMessage: string | null = null, errorMessage: string | null = null) => {
        const request = new basicRequest();
        return await request.post(planningToolApiUrls.getDepartments, { departments: departments }, null, loadingMessage, successMessage, errorMessage);
    },
    deleteDepartments: async (ids: any, loadingMessage: string | null = null, successMessage: string | null = null, errorMessage: string | null = null) => {
        const request = new basicRequest();
        return await request.delete(planningToolApiUrls.getDepartments, { departmentIds: ids }, null, loadingMessage, successMessage, errorMessage);
    },
    getTripStatusOptions: async () => {
        const request = new basicRequest();
        return await request.get(planningToolApiUrls.createTrip + '/statuses');
    },
}