import React, { useEffect, useMemo, useRef, useState } from "react";
import Button from "../../ui/button/Button";
import Modal from "../../ui/modal/Modal";

type Props = {
    callbackCloseModal: () => any;
    missingFields: string[]
}

const ModalErrorMissingFields: React.FC<Props> = ({
    callbackCloseModal,
    missingFields
}) => {
    return (
        <Modal
            callbackCloseModal={callbackCloseModal}
            title="Missing fields"
            footer={
                <div className="w-full flex items-center justify-end">
                    <Button
                        callback={callbackCloseModal}
                    >
                        close
                    </Button>
                </div>
            }
            customStyleChildrenContainer={{
                overflow: "auto",
            }}       
        >
            <div className="h-full overflow-auto">
                Missing fields:
                <ul>
                    {
                        missingFields.map((field, index) => {
                            return (
                                <li key={index}>
                                    - {field}
                                </li>
                            );
                        })
                    }
                </ul>
            </div>
        </Modal>
    )
}

export default ModalErrorMissingFields;