import React, { useContext, useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { ITripsContext, TripsContext } from "../context/TripContext";
import SidebarTrips from "../components/sidebarTrips/SidebarTrips";
import SidebarMissingOrders from "../components/sidebarMissingOrders/SidebarMissingOrders";
import { planningToolRouteUrls } from "../configs/planningToolRouteUrls";
import { IPlanningToolContext, PlanningToolContext } from "../context/PlanningToolContext";
import ModalShowGroupsStatus from "../components/modals/modalShowGroupsStatus/ModalShowGroupsStatus";
import SignalRConnector from "../utils/signalr-connection";
import { IPushMessage, IUpdatedTripMessage, PushMessagesTypes } from "../types/pushMessages";
import AuthorizedComponent from "../components/permissions/AuthorizedComponent";
import { PermissionAreas, PermissionOperations } from "../types/permissions";
import SidebarDev from "../components/sidebarDev/SidebarDev";

const PlanningToolLayout: React.FC = () => {
	
	const location = useLocation();
	const { pushMessagesEvents } = SignalRConnector();

	const {
		isOpenSidebarTrips,
		setIsOpenSidebarTrips,
		trips,
		setTrips,
		isOpenSidebarDev,
		setIsOpenSidebarDev,
		activeTripId,
		refreshOrders
	} = React.useContext(TripsContext) as ITripsContext;

	const {
		isOpenSidebarMissingOrders,
		setIsOpenSidebarMissingOrders,
		setActiveBftId,
		setActiveTradingPartnerId,
		isOpenGroupsPopup,
		setIsOpenGroupsPopup
	} = useContext(PlanningToolContext) as IPlanningToolContext;

	const [pushMessage, setPushMessage] = useState<IPushMessage | null>(null);

	const handleOpenCloseSidebarMissingOrders = () => {
		if (isOpenSidebarMissingOrders === true) {
			setActiveBftId(null);
			setActiveTradingPartnerId(null);
		}

		setIsOpenSidebarMissingOrders(!isOpenSidebarMissingOrders)
	}

	const handleNewPushMessageReceived = () => {
		let msg = pushMessage;
		if (msg === null) {
			return;
		}

        if (msg.type === PushMessagesTypes.UPDATED_TRIP) {
            let updatedTripMessage = (msg as IUpdatedTripMessage);
			let activeTrips = updatedTripMessage.trips;


			// activeTrips.forEach(trip => {
				
			// });

			let tripsToUpdate = !!trips ? [...trips] : [];
			tripsToUpdate.forEach(toUpdate => {
				let newData = activeTrips.find(x => x.id === toUpdate.id);
				if (!!newData) {
					let volume = toUpdate.volume;
					Object.assign(toUpdate, newData);
					toUpdate.volume = volume;
				}
			});

			//tripsToUpdate = [...tripsToUpdate.filter(x => !activeTrips.map(at => at.id).includes(x.id)), ...activeTrips];
			
			if (activeTripId != null && activeTrips.map(x => x.id).includes(activeTripId)) {
				refreshOrders();
			}

			setTrips(tripsToUpdate);
        }
    };

	const subscribeToPushMessagesEvents = () => {
        pushMessagesEvents((msg) => {
			setPushMessage(msg);
        });
    };

	useEffect(() => {
		handleNewPushMessageReceived();
	}, [pushMessage]);

	useEffect(() => {
		subscribeToPushMessagesEvents();
	}, []);

	useEffect(() => {
		setIsOpenSidebarTrips(false);
	}, [location.pathname]);

	return (
		<div>
			<div id="planning-tool-layout" className="mt-6 z-40 relative">
				<Outlet />
			</div>

			{
				(location.pathname === planningToolRouteUrls.planning.extended || location.pathname === planningToolRouteUrls.planningVisualization.extended) &&
				<>
					{/* Sidebar Trips */}
					<SidebarTrips
						isOpen={isOpenSidebarTrips}
						callbackOpenCloseSidebar={() => setIsOpenSidebarTrips(!isOpenSidebarTrips)}
					/>
				</>
			}

			{
				<AuthorizedComponent area={PermissionAreas.DeveloperOperations} operation={PermissionOperations.TestDebugActions}>
					<SidebarDev
						isOpen={isOpenSidebarDev}
						callbackOpenCloseSidebar={() => setIsOpenSidebarDev(!isOpenSidebarDev)}
					/>
				</AuthorizedComponent>
			}

			{
				// Sidebar Missing Orders
				location.pathname === planningToolRouteUrls.dashboard.extended &&
				<SidebarMissingOrders
					isOpen={isOpenSidebarMissingOrders}
					callbackOpenCloseSidebar={handleOpenCloseSidebarMissingOrders}
				/>
			}

			{
				isOpenGroupsPopup === true &&
				<ModalShowGroupsStatus
					callbackCloseModal={() => setIsOpenGroupsPopup(false)}
				/>
			}
		</div>
	);
};

export default PlanningToolLayout;