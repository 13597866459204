import loadable from '@loadable/component';

// Trading Partner Filter
export const TradingPartnerFilter = loadable(() => import("./tradingpartner-filter/TradingPartnerFilter"), {
	resolveComponent: (components) => components.TradingPartnerFilter,
});

// Trading Partner Filter
export const CarrierFilter = loadable(() => import("./carrier-filter/CarrierFilter"), {
	resolveComponent: (components) => components.CarrierFilter,
});

//Card List Container
export const TOCardsList = loadable(() => import("./to-cards-list/TOCardsList"), {
	resolveComponent: (components) => components.TOCardsList,
});

// Event Monitoring
export const EventMonitoring = loadable(() => import("./event-monitoring-container/EventMonitoring"), {
	resolveComponent: (components) => components.EventMonitoring,
});

// Grid Card Container
export const WarningGrid = loadable(() => import("./warning-grid/WarningGrid"), {
	resolveComponent: (components) => components.WarningGrid,
});
export const AnomaliesGrid = loadable(() => import("./anomalies-grid/AnomaliesGrid"), {
	resolveComponent: (components) => components.AnomaliesGrid,
});
export const StoragesGrid = loadable(() => import("./storages-grid/StoragesGrid"), {
	resolveComponent: (components) => components.StoragesGrid,

});

export const TOsGrid = loadable(() => import("./TOs-grid/TOsGrid"), {
	resolveComponent: (components) => components.TOsGrid,
});

export const TOsExectuionGridSSR = loadable(() => import("./TOs-grid-ssr/TOsExecutionGridSSR"), {
	resolveComponent: (components) => components.TOsExectuionGridSSR,
});
export const TOsGridSSR = loadable(() => import("./TOs-grid-ssr/TOsGridSSR"), {
	resolveComponent: (components) => components.TOsGridSSR,
});
export const SalesOrdersGrid = loadable(() => import("./salesorders-grid/SalesOrdersGrid"), {
	resolveComponent: (components) => components.SalesOrdersGrid,
});
export const SalesOrdersGridSSR = loadable(() => import("./salesorders-grid-ssr/SalesOrdersGridSSR"), {
	resolveComponent: (components) => components.SalesOrdersGridSSR,
});
export const OngoingTripsGrid = loadable(() => import("./ongoingtrips-grid/OngoingTripsGrid"), {
	resolveComponent: (components) => components.OngoingTripsGrid,
});
export const OngoingTripsGridSSR = loadable(() => import("./ongoingtrips-grid-ssr/OngoingTripsGridSSR"), {
	resolveComponent: (components) => components.OngoingTripsGridSSR,
});
export const AttributesGrid = loadable(() => import("./attributes-grid/AttributesGrid"), {
	resolveComponent: (components) => components.AttributesGrid,
});
export const EventsGrid = loadable(() => import("./events-grid/EventsGrid"), {
	resolveComponent: (components) => components.EventsGrid,
});
export const SUGrid = loadable(() => import("./SU-grid/SUGrid"), {
	resolveComponent: (components) => components.SUGrid,
});
export const ASGrid = loadable(() => import("./AS-grid/ASGrid"), {
	resolveComponent: (components) => components.ASGrid,
});
export const NotesGrid = loadable(() => import("./notes-grid/NotesGrid"), {
	resolveComponent: (components) => components.NotesGrid,
});
export const TDGrid = loadable(() => import("./TD-grid/TDGrid"), {
	resolveComponent: (components) => components.TDGrid,
});
export const PackageGrid = loadable(() => import("./package-grid/PackageGrid"), {
	resolveComponent: (components) => components.PackageGrid,
});
export const StopsGrid = loadable(() => import("./stops-grid/StopsGrid"), {
	resolveComponent: (components) => components.StopsGrid,
});
export const ContainedLegsGrid = loadable(() => import("./containedlegs-grid/ContainedLegsGrid"), {
	resolveComponent: (components) => components.ContainedLegsGrid,
});
export const StatusHistoryGrid = loadable(() => import("./statushistory-grid/StatusHistoryGrid"), {
	resolveComponent: (components) => components.StatusHistoryGrid,
});
export const OrderVarianceGrid = loadable(() => import("./order-variance-grid/OrderVarianceGrid"), {
	resolveComponent: (components) => components.OrderVarianceGrid,
});
export const EventMonitoringGrid = loadable(() => import("./event-monitoring-grid/EventMonitoringGrid"), {
	resolveComponent: (components) => components.EventMonitoringGrid,
});
export const AdrDetailsGrid = loadable(() => import("./adrDetails-grid/AdrDetailsGrid"), {
	resolveComponent: (components) => components.AdrDetailsGrid,
});
export const PhoneBookingGrid = loadable(() => import("./phoneBooking-grid/PhoneBookingGrid"), {
	resolveComponent: (components) => components.PhoneBookingGrid,
});
export const ActivitiesTimelineGrid = loadable(() => import("./activitiesTimeline-grid/ActivitiesTimelineGrid"), {
	resolveComponent: (components) => components.ActivitiesTimelineGrid,
});
export const RelatedOrdersGrid = loadable(() => import("./relatedOrders-grid/RelatedOrdersGrid"), {
	resolveComponent: (components) => components.RelatedOrdersGrid,
});

// SubHeader Container
export const SubHeaderContainer = loadable(() => import("./subheader-container/SubHeaderContainer"), {
	resolveComponent: (components) => components.SubHeaderContainer,
});
export const SubHeaderNoFilters = loadable(() => import("./subheader-container/SubHeaderNoFilters"), {
	resolveComponent: (components) => components.SubHeaderNoFilters,
});

// Search Container
export const SearchContainer = loadable(() => import("./search-container/SearchContainer"), {
	resolveComponent: (components) => components.SearchContainer,
});

// Detail Containers
export const SODetailPageContainer = loadable(() => import("./detailpage-container/SODetailPageContainer"), {
	resolveComponent: (components) => components.SODetailPageContainer,
});
export const TODetailPageContainer = loadable(() => import("./detailpage-container/TODetailPageContainer"), {
	resolveComponent: (components) => components.TODetailPageContainer,
});
export const TOCustomerDetailPageContainer = loadable(() => import("./detailpage-container/TOCustomerDetailPageContainer"), {
	resolveComponent: (components) => components.TOCustomerDetailPageContainer,
});
export const LegDetailPageContainer = loadable(() => import("./detailpage-container/LegDetailPageContainer"), {
	resolveComponent: (components) => components.LegDetailPageContainer,
});
export const TripDetailPageContainer = loadable(() => import("./detailpage-container/TripDetailPageContainer"), {
	resolveComponent: (components) => components.TripDetailPageContainer,
});
export const InvPartyDetailContainer = loadable(() => import("./invparty-detail-container/InvPartyDetailContainer"), {
	resolveComponent: (components) => components.InvPartyDetailContainer,
});
export const SingleInvPartyContainer = loadable(() => import("./single-invparty-container/SingleInvPartyContainer"), {
	resolveComponent: (components) => components.SingleInvPartyContainer,
});
export const DetailLegContainer = loadable(() => import("./detail-leg-container/DetailLegContainer"), {
	resolveComponent: (components) => components.DetailLegContainer,
});
export const SingleTripDetailContainer = loadable(() => import("./single-trip-detail-container/SingleTripDetailContainer"), {
	resolveComponent: (components) => components.SingleTripDetailContainer,
});
export const TimelineTOLegContainer = loadable(() => import("./timeline-toleg/TimelineTOLegContainer"), {
	resolveComponent: (components) => components.TimelineTOLegContainer,
});
export const OrderStatusHistoryContainer = loadable(() => import("./order-status-history/OrderStatusHistoryContainer"), {
	resolveComponent: (components) => components.OrderStatusHistoryContainer,
});
export const OrderEventsContainer = loadable(() => import("./order-events/OrderEventsContainer"), {
	resolveComponent: (components) => components.OrderEventsContainer,
});
export const TimelineTripContainer = loadable(() => import("./timeline-trip/TimelineTripContainer"), {
	resolveComponent: (components) => components.TimelineTripContainer,
});
export const TimelineMilestonesLegContainer = loadable(() => import("./timeline-toleg/TimelineMilestonesLegContainer"), {
	resolveComponent: (components) => components.TimelineMilestonesLegContainer,
});
export const DetailDocumentsContainer = loadable(() => import("./detail-documents-container/DetailDocumentsContainer"), {
	resolveComponent: (components) => components.DetailDocumentsContainer,
});
export const SingleComparisonDetailContainer = loadable(() => import("./single-comparison-detail-container/SingleComparisonDetailContainer"), {
	resolveComponent: (components) => components.SingleComparisonDetailContainer,
});
export const SingleCustomerComparisonDetailContainer = loadable(() => import("./single-customer-comparison-detail-container/SingleCustomerComparisonDetailContainer"), {
	resolveComponent: (components) => components.SingleCustomerComparisonDetailContainer,
});
// Report Page
export const ReportFilterContainer = loadable(() => import("./reportfilter-container/ReportFilterContainer"), {
	resolveComponent: (components) => components.ReportFilterContainer,
});

// Settings Page
export const SettingsTmsCustomersContainer = loadable(() => import("./settings-tmscustomers-container/SettingsTmsCustomersContainer"), {
	resolveComponent: (components) => components.SettingsTmsCustomersContainer,
});
export const TmsCustomersEditor = loadable(() => import("./tmscustomers-editor/TmsCustomersEditor"), {
	resolveComponent: (components) => components.TmsCustomersEditor,
});
export const CustomersEditor = loadable(() => import("./customers-editor/CustomersEditor"), {
	resolveComponent: (components) => components.CustomersEditor,
});

// Notification Center
export const NotificationCenter = loadable(() => import("./notification-center/NotificationCenter"), {
	resolveComponent: (components) => components.NotificationCenter,
});

// Save Preset
export const SavePresetContainer = loadable(() => import("./save-preset-container/SavePresetContainer"), {
	resolveComponent: (components) => components.SavePresetContainer,
});

// Map
export const MapPopupContainer = loadable(() => import("./map-container/mappopup-container/MapPopupContainer"), {
	resolveComponent: (components) => components.MapPopupContainer,
});

// MapSide
export const ChecklistFilter = loadable(() => import("./map-side-filters/ChecklistFilter"), {
	resolveComponent: (components) => components.ChecklistFilter,
});

// Custom Tooltips
export const CarrierTooltip = loadable(() => import("./custom-tooltips/CarrierTooltip"), {
	resolveComponent: (components) => components.CarrierTooltip,
});
export const TimelineTooltip = loadable(() => import("./custom-tooltips/TimelineTooltip"), {
	resolveComponent: (components) => components.TimelineTooltip,
});
export const TripSourcesTooltip = loadable(() => import("./custom-tooltips/TripSourcesTooltip"), {
	resolveComponent: (components) => components.TripSourcesTooltip,
});
export const FiltersTooltip = loadable(() => import("./custom-tooltips/FiltersTooltip"), {
	resolveComponent: (components) => components.FiltersTooltip,
});