import React, {useEffect} from "react";
import config from "../../../settings"
import { parse } from 'qs';
import {authAPI} from "../../../api/auth/authAPI"

export default function TokenPage() {
    useEffect(() => {
        
        const { code } = parse(window.location.search, { ignoreQueryPrefix: true });

        if (!code) {
            window.location.href = "/authorize";
        }

        const codeVerifier = window.sessionStorage.getItem('codeVerifier');

        authAPI.getToken(code, window.location.origin + config.loginCallbackUrl, "authorization_code", config.clientId, codeVerifier, data => {
            
            window.sessionStorage.setItem('accessToken', data.access_token);
            window.sessionStorage.setItem('idToken', data.id_token);

            window.location.href = window.sessionStorage.getItem("ingressPath") || "/";
        });

    }, []);

    return (
        <>
            <div className="loading-container"></div>
            <div className="loading-widget"><div className="loading-img"></div></div>
        </>
    );
}
