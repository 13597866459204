import React from "react";
import { OriginDestinationType } from "../../../types/orders";
import useImageFlag from "../../../utils/hooks/useImageFlag";

type Props = {
    destination: OriginDestinationType | null,
    type: 'origin' | 'destination'
}

const TableTooltipPartner: React.FC<Props> = ({ destination, type }) => {

    const { image, loading } = useImageFlag(!!destination ? destination.countryCode : '');

    return (
        <div className="shadow-banner overflow-hidden min-w-[180px] rounded-[4px]">
            {
                !!destination &&
                <>
                    <div className="p-2 font-bold bg-grey">{type === "origin" ? 'Origin' : 'Destination'}</div>
                    <div className="p-2 bg-white">
                        <div className="flex">
                            {
                                !loading &&
                                <div className="mr-1">
                                    {/* @ts-ignore */}
                                    <img src={image} alt="test" />
                                </div>
                            }
                            <div className="font-bold">{destination.name}</div>
                        </div>

                        <div className="text-darkGrey mb-[3px]">
                            {destination.street}, {destination.zipCode} {destination.city}
                        </div>                        
                    </div>
                </>
            }
        </div>
    )
}

export default TableTooltipPartner;