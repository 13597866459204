import config from "../settings";

//const BASE_URL = "https://localhost:7017";
// const BASE_URL = "https://apiservice-test.arcese.com";
// const BASE_URL = "https://planningtoolbetest.azurewebsites.net";
// const BRE_BASE_URL =  "https://arcombreruleuploadfunctiondev.azurewebsites.net/api";

const BASE_URL = config.planningToolApiBaseUrl;

const settings = {
    BASE_URL: BASE_URL,
    apiBaseUrl: `${BASE_URL}/api`,
    signalRHubUrl: `${BASE_URL}/hubs/signalhub`
}

export default settings;