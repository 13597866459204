import { createSelector, createSlice } from '@reduxjs/toolkit';
import { selectActiveTOCard, selectActiveTripsCard } from '../card-configuration/cardConfigurationSlice';
import {OwnerCompany} from '../../../utils/AuthUtils'

export function getUrlForGrid(url) {
    switch (url){
        case '/listpage/trips': return 'trip';
        case '/listpage/toscustomer': return 'tocustomer';
        case '/listpage/tosexecution': return 'toexecution';
		case '/listpage/sos': return 'so';
		default: return 'toexecution';
    }
}
const dashboardInitialState = {
	showTO: getUrlForGrid(window.location.pathname),
	// Owner Company selected
	selectedActiveCompany: OwnerCompany(),
	// Branches fetched from server
	allBranches: [],
};

const dashboardSlice = createSlice({
	name: 'dashboard',
	initialState: dashboardInitialState,
	reducers: {
		setShowTO(state, action) {
			state.showTO = getUrlForGrid(window.location.pathname);
			console.log(action)
		},
		setActiveTripsCard(state, action) {
			state.activeTripsCard = action.payload;
		},
		setActiveTOCard(state, action) {
			state.activeTOCard = action.payload;
		},
		setActiveCompany(state, action) {
			state.selectedActiveCompany = action.payload;
		},
		storeAllBranches(state, action) {
			state.allBranches = action.payload;
		},
	}
});
export const selectShowTO = state => state.dashboard.showTO;
export const selectActiveCompany = state => state.dashboard.selectedActiveCompany;

export const selectActiveCard = createSelector(
	selectShowTO,
	selectActiveTOCard,
	selectActiveTripsCard,
	(showTO, TOCard, tripsCard) => (showTO == 'trip' ?  tripsCard : TOCard)
);

export const {
	setShowTO,
	setActiveCompany,
	storeAllBranches
} = dashboardSlice.actions;

export default dashboardSlice.reducer;