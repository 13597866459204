import React, { useEffect, useMemo, useRef, useState } from 'react'
import CommonTable, { TableRef } from '../../components/commonTable/CommonTable';
import { IUser } from '../../types/users';
import { usersService } from '../../api/usersService/usersService';
import Select from '../../components/ui/select/Select';
import dayjs from 'dayjs';
import { customConfirmAlert } from '../../utils/functions/customConfirmAlert';
import { groupsService } from '../../api/groupsService/groupsService';
import { tripsService } from '../../api/tripsService/tripsService';
import { toast } from 'react-toastify';

const PlanningToolManageCharges = () => {

    // #region State
    const [users, setUsers] = useState<IUser[] | undefined>(undefined);
    const [selectedUser, setSelectedUser] = useState<IUser | undefined>(undefined);

    const groupsCommonTableRef = useRef<TableRef>(null);
    const tripsCommonTableRef = useRef<TableRef>(null);
    // #endregion

    // #region Memo
    const containerStyle = useMemo(() => ({ width: '100%', height: '100%' }), []);

    const usersOptions = useMemo(() => {
        if (!!!users) {
            return [];
        }

        return users.map((user) => {
            return {
                value: user.username,
                label: `${user.name} ${user.surname} - ${user.username}`
            }
        });
    }, [users]);

    const groupsColumnDefs = useMemo(() => [
        {
            field: "department.name",
            headerName: "DEPARTMENT"
        },
        {
            field: "name",
            headerName: "Name"
        },
        {
            field: "actions",
            headerName: "Actions",
            cellRenderer: (props: any) => {
                return (
                    <div
                        onClick={() => handleRevoke("group", props.data.id)}
                        className={"flex items-center cursor-pointer"}
                    >
                        <span className="mr-[8px]">
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clipPath="url(#clip0_14321_52331)">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M8.00004 1.83203C4.59428 1.83203 1.83337 4.59294 1.83337 7.9987C1.83337 11.4045 4.59428 14.1654 8.00004 14.1654C11.4058 14.1654 14.1667 11.4045 14.1667 7.9987C14.1667 4.59294 11.4058 1.83203 8.00004 1.83203ZM0.833374 7.9987C0.833374 4.04066 4.042 0.832031 8.00004 0.832031C11.9581 0.832031 15.1667 4.04066 15.1667 7.9987C15.1667 11.9567 11.9581 15.1654 8.00004 15.1654C4.042 15.1654 0.833374 11.9567 0.833374 7.9987Z" fill="#CF0A2C" />
                                    <path fillRule="evenodd" clipRule="evenodd" d="M2.93246 2.9316C3.12772 2.73634 3.4443 2.73634 3.63956 2.9316L13.0677 12.3598C13.263 12.555 13.263 12.8716 13.0677 13.0669C12.8724 13.2621 12.5559 13.2621 12.3606 13.0669L2.93246 3.63871C2.7372 3.44345 2.7372 3.12686 2.93246 2.9316Z" fill="#CF0A2C" />
                                    <path fillRule="evenodd" clipRule="evenodd" d="M13.0677 2.9316C13.263 3.12686 13.263 3.44345 13.0677 3.63871L3.63956 13.0669C3.4443 13.2621 3.12772 13.2621 2.93246 13.0669C2.7372 12.8716 2.7372 12.555 2.93246 12.3598L12.3606 2.9316C12.5559 2.73634 12.8724 2.73634 13.0677 2.9316Z" fill="#CF0A2C" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_14321_52331">
                                        <rect width="16" height="16" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>
                        </span>
                        <span className="hover:underline">Revoke</span>
                    </div>
                );
            }
        }
    ], [selectedUser]);

    const tripsColumnDefs = useMemo(() => [
        {
            field: "date",
            headerName: "Date",
            cellRenderer: (props: any) => {
                return (<>{dayjs(props.data.date).format("DD/MM/YYYY")}</>);
            }
        },
        {
            field: "dailyId",
            headerName: "Daily ID"
        },
        {
            field: "actions",
            headerName: "Actions",
            cellRenderer: (props: any) => {
                return (
                    <div
                        onClick={() => handleRevoke("trip", props.data.id)}
                        className={"flex items-center cursor-pointer"}
                    >
                        <span className="mr-[8px]">
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clipPath="url(#clip0_14321_52331)">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M8.00004 1.83203C4.59428 1.83203 1.83337 4.59294 1.83337 7.9987C1.83337 11.4045 4.59428 14.1654 8.00004 14.1654C11.4058 14.1654 14.1667 11.4045 14.1667 7.9987C14.1667 4.59294 11.4058 1.83203 8.00004 1.83203ZM0.833374 7.9987C0.833374 4.04066 4.042 0.832031 8.00004 0.832031C11.9581 0.832031 15.1667 4.04066 15.1667 7.9987C15.1667 11.9567 11.9581 15.1654 8.00004 15.1654C4.042 15.1654 0.833374 11.9567 0.833374 7.9987Z" fill="#CF0A2C" />
                                    <path fillRule="evenodd" clipRule="evenodd" d="M2.93246 2.9316C3.12772 2.73634 3.4443 2.73634 3.63956 2.9316L13.0677 12.3598C13.263 12.555 13.263 12.8716 13.0677 13.0669C12.8724 13.2621 12.5559 13.2621 12.3606 13.0669L2.93246 3.63871C2.7372 3.44345 2.7372 3.12686 2.93246 2.9316Z" fill="#CF0A2C" />
                                    <path fillRule="evenodd" clipRule="evenodd" d="M13.0677 2.9316C13.263 3.12686 13.263 3.44345 13.0677 3.63871L3.63956 13.0669C3.4443 13.2621 3.12772 13.2621 2.93246 13.0669C2.7372 12.8716 2.7372 12.555 2.93246 12.3598L12.3606 2.9316C12.5559 2.73634 12.8724 2.73634 13.0677 2.9316Z" fill="#CF0A2C" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_14321_52331">
                                        <rect width="16" height="16" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>
                        </span>
                        <span className="hover:underline">Revoke</span>
                    </div>
                );
            }
        }
    ], [selectedUser]);
    // #endregion

    // #region UI Handlers
    const handleRevoke = (type: "group" | "trip", id: number) => {
        confirmRevoke(type, id);
    };

    const handleSelectUser = (e: any) => {
        selectUser(e.target.value);
    };
    // #endregion

    // #region Util Functions
    const confirmRevoke = async (type: "group" | "trip", id: number) => {
        const wantToContinue = await customConfirmAlert({
            title: "Revoke Charge",
            message: "Are you sure to revoke charge?"
        });

        if (wantToContinue) {
            revokeCharge(type, id);
        }
    };

    const getUserCharges = async () => {
        if (!!!selectedUser) {
            return;
        }

        groupsCommonTableRef.current?.showLoadingOverlay();
        tripsCommonTableRef.current?.showLoadingOverlay();

        const response = await usersService.getUserCharges(selectedUser?.username);

        groupsCommonTableRef.current?.hideOverlay();
        tripsCommonTableRef.current?.hideOverlay();

        if (response.success) {
            groupsCommonTableRef.current?.setRowData(response.data.groupCharges);
            tripsCommonTableRef.current?.setRowData(response.data.tripCharges);
        } else {
            toast.error('Error retrieving charges');
        }
    };

    const revokeCharge = async (type: "group" | "trip", id: number) => {
        let response = null;

        if (type === "group") {
            response = await groupsService.deleteCharge([id], "Revoking charge...", "Charge revoked successfully", "Error while revoking charge");
        } else {
            response = await tripsService.revokeCharge(id, selectedUser?.username as string, false, "Revoking charge...", "Charge revoked successfully", "Error while revoking charge");
        }

        if (response.success) {
            getUserCharges();
        }
    };

    const selectUser = (username: string) => {
        setSelectedUser(users?.find(user => user.username === username));
    };

    const getUsers = async () => {
        const response = await usersService.getUsers();

        if (response.success) {
            setUsers(response.data);
        } else {
            toast.error('Error retrieving users');
        }
    };
    // #endregion

    // #region Use Effects
    useEffect(() => {
        getUsers();
    }, []);

    useEffect(() => {
        if (!!!selectedUser) {
            return;
        }

        getUserCharges();
    }, [selectedUser]);
    // #endregion

    return (
        <div className="w-full mb-6 bg-white p-[32px]">
            <div className="font-light text-lg leading-[25px]">Planning Tool</div>
            <div className="flex items-center pb-3 mb-6 border-b">
                <div className="md:mr-3 text-[32px] font-bold leading-[45px]">
                    Manage Charges
                </div>
            </div>

            <div>
                <div className='w-[30%] mb-6'>
                    <Select
                        options={usersOptions}
                        callbackOnChange={handleSelectUser}
                        value={selectedUser?.username}
                        placeholder={"Select user..."}
                        defaultOptionEnabled={true}
                        className='!border-black'
                    />
                </div>

                <div className='mb-6'>
                    <div className="md:mr-3 text-[20px] font-bold leading-[45px]">
                        Groups
                    </div>
                    <div style={containerStyle}>
                        <CommonTable
                            ref={groupsCommonTableRef}
                            columnDefs={groupsColumnDefs}
                            options={{
                                editable: false,
                                filter: false,
                                floatingFilter: false,
                                pageSize: 10
                            }}
                        />
                    </div>
                </div>

                <div>
                    <div className="md:mr-3 text-[20px] font-bold leading-[45px]">
                        Trips
                    </div>
                    <div style={containerStyle}>
                        <CommonTable
                            ref={tripsCommonTableRef}
                            columnDefs={tripsColumnDefs}
                            options={{
                                editable: false,
                                filter: false,
                                floatingFilter: false,
                                pageSize: 10
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PlanningToolManageCharges;