import React from "react";
import { confirmAlert } from "react-confirm-alert";
import Modal from "../../components/ui/modal/Modal";
import Button from "../../components/ui/button/Button";

type Props = {
    title: string;
    message: string;
}

export const customConfirmAlert: (props: Props) => any = ({
    title,
    message,
}) => {
    return new Promise((resolve) => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div id="planning-tool" style={{zIndex: 900}}>
                        <Modal
                            title={title}
                            callbackCloseModal={() => {
                                resolve(false);
                                onClose();
                            }}
                            footer={
                                <div className="flex items-center justify-end">
                                    <Button
                                        callback={() => {
                                            resolve(false);
                                            onClose();
                                        }}
                                        className="border border-darkGrey text-black border-[1.4px]"
                                    >
                                        Cancel
                                    </Button>

                                    <div className="ml-4">
                                        <Button
                                            callback={() => {
                                                resolve(true);
                                                onClose();
                                            }}
                                        >
                                            Confirm
                                        </Button>
                                    </div>

                                </div>
                            }
                        >
                            <div>{message}</div>
                        </Modal>
                    </div>
                );
            },
            closeOnClickOutside: false,
        });
    });
}