import React from "react";
import { Card } from "./Card";

export const HorizontalView = ({ data, noData, suData, isToCustomer}) => {
    const cards = data ? data.map((card, index) => <Card key={index} data={card} suData={suData} isToCustomer={isToCustomer}/>) : [];

    return (
        <>
            {data?.length > 0 && !noData ?
                cards
            : data?.length == 0 && !noData ?
                <div className="order-progress-loader" style={{ "height": "400px", "width": "100%" }}>
                    <div className="loading-container"></div>
                    <div className="loading-widget"><div className="loading-img"></div></div>
                </div>
            : 
                <div></div>
            }
        </>
    );
};