import React, { useEffect, useState, memo, useContext } from "react";
import { IOrder, Leg } from "../../types/orders";
import { ITripsContext, TripsContext } from "../../context/TripContext";
import { customConfirmAlert } from "../../utils/functions/customConfirmAlert";
import { LegPlanningTypesEnum, LegStatusEnum } from "../../types/leg";
import { CommonContextMenuRef } from "../commonContextMenu/CommonContextMenu";
import { IUserContext, UserContext } from "../../context/UserContext";
import { isConfirmedDeposit, isConfirmedLeg, isDepositOpacited, isDepositSelectable, isLegInError, isLegOpacited, isLegSelectable, isPlannedDeposit, isPlannedLeg, legCanBeDispatchable } from "../../utils/utilFunctions";
import { LegContextMenuActions } from "../../types/legContextMenu";
import { toast } from "react-toastify";
import { TripStatusEnum } from "../../types/trips";
import usePermissions from "../../utils/hooks/usePermissions";
import { PermissionAreas, PermissionOperations } from "../../types/permissions";
import { Tooltip } from 'primereact/tooltip';
import "./Tooltip.css";

type Props = {
    order: IOrder,
    callbackOnReady: any,
    isDeliverToPartner?: boolean;
    groupsInCharge: number[] | null;
    legContextMenuRef: React.RefObject<CommonContextMenuRef> | null;
    selectedLegs: number[] | undefined;
    selectedDeposits: number[];
    isTriagePage: boolean;
    canEdit: boolean;
    callbackRestoreRoute: (() => any) | null,
    callbackSelectedLegOrDeposit: (type: "leg" | "deposit", legId: number, index: number) => any,
    callbackOnContextMenu: (e: React.MouseEvent, plan: Leg) => any;
    callbackUpdateOrderLegs: (orderId: number) => any;
    callbackRemoveDeposit: (orderId: number, leg: Leg) => any;
    callbackRemoveLegFromTrip: (leg: Leg) => any;
}

const Planning: React.FC<Props> = memo(({
    order,
    groupsInCharge,
    legContextMenuRef,
    selectedLegs,
    selectedDeposits,
    isTriagePage,
    canEdit,
    callbackRestoreRoute,
    callbackSelectedLegOrDeposit,
    callbackOnContextMenu,
    callbackUpdateOrderLegs,
    callbackRemoveDeposit,
    callbackRemoveLegFromTrip
}) => {

    const { isOpenSidebarTrips, activeTripId } = useContext(TripsContext) as ITripsContext;
    const { actualUser } = useContext(UserContext) as IUserContext;
    const { hasPermission } = usePermissions();

    const [plans, setPlans] = useState<Leg[] | undefined>(undefined);

    useEffect(() => {
        setPlans(order.legs);
    }, [order]);

    const removeDeposit = async (e: any, plan: Leg) => {
        e.preventDefault();
        e.stopPropagation();

        let wantToRemove = await customConfirmAlert({ title: "Remove Deposit", message: "Are you sure to remove deposit from Order?" });
        if (wantToRemove) {
            callbackRemoveDeposit(plan.orderId, plan);
        }
    }

    const handleCallbackSelectLegOrDeposit = (type: "leg" | "deposit", plan: Leg, index: number) => () => {
        if (isOpenSidebarTrips) {
            return;
        }

        if (type === "leg") {
            if (!isLegSelectable(plan, order, canEdit, activeTripId, isTriagePage, groupsInCharge).result) {
                return;
            }
        } else if (type === "deposit") {
            if (!isDepositSelectable(plan, order, groupsInCharge, isTriagePage, canEdit).result) {
                return;
            }
        }

        callbackSelectedLegOrDeposit(type, plan.id, index);
    }

    const getDepositStyleClasses = (leg: Leg) => {
        let classes: string = " border-2";

        if (isDepositOpacited(leg, order, groupsInCharge, isTriagePage, canEdit).result) {
            classes += " opacity-50";
        }

        if (isConfirmedDeposit(leg, order).result) {
            classes += " bg-green";
        } else if (isPlannedDeposit(leg, order).result) {
            classes += " border-green";
        }

        if (!!selectedDeposits && selectedDeposits.includes(leg.id)) {
            classes += " border-[#fc5b00]";
        } else {
            if (!isPlannedDeposit(leg, order).result) {
                classes += " border-lightGrey";
            }
        }

        if (isDepositSelectable(leg, order, groupsInCharge, isTriagePage, canEdit).result) {
            classes += " cursor-pointer";
        }

        return classes;
    };

    const getDepotDebugTooltip = (leg: Leg) => {
        let result: string[] = [];

        if (!hasPermission(PermissionAreas.DeveloperOperations, PermissionOperations.TestDebugActions)) {
            return "";
        }

        let res = isDepositOpacited(leg, order, groupsInCharge, isTriagePage, canEdit);
        if (!!res.causes && res.causes.length > 0) {
            result.push("OPACITED CAUSES:");
            result.push(...res?.causes as string[]);
            result.push("\n");
        }

        res = isConfirmedDeposit(leg, order);
        if (!!res.causes && res.causes.length > 0) {
            result.push("CONFIRMED CAUSES:");
            result.push(...res?.causes as string[]);
            result.push("\n");
        }

        res = isPlannedDeposit(leg, order);
        if (!!res.causes && res.causes.length > 0) {
            result.push("PLANNED CAUSES:");
            result.push(...res?.causes as string[]);
            result.push("\n");
        }

        res = isDepositSelectable(leg, order, groupsInCharge, isTriagePage, canEdit);
        if (!!res.causes && res.causes.length > 0) {
            result.push("UNSELECTABLE CAUSES:");
            result.push(...res?.causes as string[]);
            result.push("\n");
        }

        return result.join("\n");
    };

    const getLegStyleClasses = (leg: Leg) => {
        let classes: string = " border-2";

        if (isLegOpacited(leg, order, canEdit, activeTripId, isTriagePage, groupsInCharge).result) {
            classes += " opacity-50";
        }

        if (isLegInError(leg)) {
            classes += ' bg-red'
        } else if (isConfirmedLeg(leg).result) {
            classes += " bg-green";
        } else if (isPlannedLeg(leg).result) {
            classes += " border-green";
        }

        if (!!selectedLegs && selectedLegs.includes(leg.id)) {
            classes += " border-[#fc5b00]";
        } else {
            if (!isPlannedLeg(leg).result) {
                classes += " border-lightGrey";
            }
        }

        if (isLegSelectable(leg, order, canEdit, activeTripId, isTriagePage, groupsInCharge).result) {
            classes += " cursor-pointer";
        }

        return classes;
    };

    const getLegDebugTooltip = (leg: Leg) => {
        let result: string[] = [];

        if (!hasPermission(PermissionAreas.DeveloperOperations, PermissionOperations.TestDebugActions)) {
            return "";
        }

        let res = isLegOpacited(leg, order, canEdit, activeTripId, isTriagePage, groupsInCharge);
        if (!!res.causes && res.causes.length > 0) {
            result.push("OPACITED CAUSES:");
            result.push(...res?.causes as string[]);
            result.push("\n");
        }

        res = isConfirmedLeg(leg);
        if (!!res.causes && res.causes.length > 0) {
            result.push("CONFIRMED CAUSES:");
            result.push(...res?.causes as string[]);
            result.push("\n");
        }

        res = isPlannedLeg(leg);
        if (!!res.causes && res.causes.length > 0) {
            result.push("PLANNED CAUSES:");
            result.push(...res?.causes as string[]);
            result.push("\n");
        }

        res = isLegSelectable(leg, order, canEdit, activeTripId, isTriagePage, groupsInCharge);
        if (!!res.causes && res.causes.length > 0) {
            result.push("UNSELECTABLE CAUSES:");
            result.push(...res?.causes as string[]);
            result.push("\n");
        }

        return result.join("\n");
    };

    const handleOnLegContextMenu = (e: any, plan: Leg) => {
        e.preventDefault();
        if (!canEdit) {
            return;
        }

        if (!!activeTripId && !!plan.trip?.id && plan.trip.id !== activeTripId) {
            return;
        }

        if ((!isLegOpacited(plan, order, canEdit, activeTripId, isTriagePage, groupsInCharge).result || plan.status?.id === LegStatusEnum.ERROR) && !!legContextMenuRef) {
            let disabledOptions = [];
            if (!(plan.planningType.id === LegPlanningTypesEnum.StandBy || plan.planningType.id === LegPlanningTypesEnum.Network || ((plan.origin.type === "Partner" || plan.destination.type === "Partner") && plan.trip === null))) {
                disabledOptions.push(LegContextMenuActions.UNDO_ACTION);
            }

            if (!legCanBeDispatchable(plan)) {
                disabledOptions.push(LegContextMenuActions.SET_AS_DISPATCHABLE);
            }

            if (!(plan.planningType.id === LegPlanningTypesEnum.Network && plan.status?.id === LegStatusEnum.ERROR) && !(plan.planningType.id === LegPlanningTypesEnum.StandBy && plan.status?.id === LegStatusEnum.ERROR)) {
                disabledOptions.push(LegContextMenuActions.REPLAN);
            } else {
                disabledOptions.push(LegContextMenuActions.UNDO_ACTION);
                disabledOptions.push(LegContextMenuActions.SET_AS_DISPATCHABLE);
            }

            if (plan.planningType.id === LegPlanningTypesEnum.StandBy && plan.status?.id !== LegStatusEnum.NOT_DISPATCHABLE) {
                disabledOptions.push(LegContextMenuActions.UNDO_ACTION);
            }

            if (plan.status?.id === LegStatusEnum.ERROR) {
                disabledOptions.push(LegContextMenuActions.SET_AS_DISPATCHABLE);
            }

            if (plan.status?.id === LegStatusEnum.ERROR && plan.trip !== null) {
                disabledOptions.push(LegContextMenuActions.SET_AS_DISPATCHABLE);
            }

            if (!groupsInCharge?.includes(plan.groupId)) {
                disabledOptions.push(LegContextMenuActions.UNDO_ACTION);
                disabledOptions.push(LegContextMenuActions.SET_AS_DISPATCHABLE);
                disabledOptions.push(LegContextMenuActions.REPLAN);
            }

            legContextMenuRef.current?.handleRightClick(e, plan.id, disabledOptions);
        }
    };

    const handleCallbackRestoreRoute = () => {

        let canGo = true;
        for (let index = 0; index < order.legs.length; index++) {
            const plan = order.legs[index];

            if (
                !isLegOpacited(plan, order, canEdit, activeTripId, isTriagePage, groupsInCharge).result &&
                (
                    isPlannedLeg(plan).result /*||
                    plan.planningType.id === LegPlanningTypesEnum.Partner*/
                )
            ) {
                canGo = false;
                break;
            }
        }

        if (!!callbackRestoreRoute && canGo === true) {
            callbackRestoreRoute();
        } else {
            toast.error("You can\'t restore the routing if it\'s already planned. Undo all actions and retry.")
        }
    }

    return (
        <>
            {
                !!plans &&
                <div className="select-none w-full flex items-center leading-[16px] text-[13px]">
                    {
                        !!plans && plans.length > 0 &&
                        <>
                            <div className="grow flex items-center">
                                {
                                    plans.sort((a, b) => a.orderPosition - b.orderPosition).map((plan, index) => {
                                        return (
                                            <div key={index} className="flex items-center grow">
                                                {
                                                    !!plan.status &&
                                                    (
                                                        plan.status.id === LegStatusEnum.DISPATCHABLE ||
                                                        plan.status.id === LegStatusEnum.DISPATCHING ||
                                                        plan.status.id === LegStatusEnum.DISPATCHED
                                                    ) &&
                                                    <Tooltip target={('.leg-piece-' + plan.id)} />
                                                }

                                                {/* Deposit */}
                                                {
                                                    plan.origin.type === "Deposit" &&
                                                    <div
                                                        className={"text-xs rounded-[4px] h-full border-[1.5px] p-[6px] mr-2" + getDepositStyleClasses(plan)}
                                                        onClick={handleCallbackSelectLegOrDeposit("deposit", plan, index)}
                                                        onContextMenu={(e => e.preventDefault())}
                                                        title={getDepotDebugTooltip(plan)}
                                                    >
                                                        <div className={"flex items-center font-bold"}>
                                                            <div className="text-[13px] leading-[16px]">
                                                                {plan.origin.code}
                                                            </div>
                                                            {
                                                                !!canEdit && isPlannedLeg(plan).result === false && !isDepositOpacited(plan, order, groupsInCharge, isTriagePage, canEdit).result && isPlannedDeposit(plan, order).result === false && (!!!selectedDeposits || !selectedDeposits.includes(plan.id)) &&
                                                                <div onClick={(e) => removeDeposit(e, plan)} className="cursor-pointer ml-1">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" stroke="#cf0a2c" fill="#cf0a2c" className="w-[14px] h-[14px]">
                                                                        <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z" />
                                                                    </svg>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                }

                                                {/* Leg */}
                                                <div
                                                    data-pr-tooltip={"Leg status: " + plan.status?.name}
                                                    className={('leg-piece-' + plan.id) + " flex grow items-center text-xs rounded-[4px] border-[1.5px] p-1 mr-2 " + getLegStyleClasses(plan)}
                                                    onClick={handleCallbackSelectLegOrDeposit('leg', plan, index)}
                                                    onContextMenu={(e: any) => handleOnLegContextMenu(e, plan)}
                                                    title={getLegDebugTooltip(plan)}
                                                >
                                                    <div
                                                        className="min-w-[115px] w-full text-center bg-blue text-white rounded-[4px] p-[5px] pt-1 font-semibold"
                                                    >
                                                        {plan.type.name}
                                                    </div>

                                                    {
                                                        plan.planningType.id === LegPlanningTypesEnum.StandBy &&
                                                        <div className="bg-white ml-1 rounded-[4px] p-[3.5px] pl-[4px] font-semibold border text-black leading-[12px]">
                                                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M4 3.5H5.5V8.5H4V3.5ZM6.5 3.5H8V8.5H6.5V3.5Z" fill="#333333" />
                                                            </svg>
                                                        </div>
                                                    }

                                                    {
                                                        plan.planningType.id === LegPlanningTypesEnum.Network &&
                                                        <div className="bg-white ml-1 rounded-[4px] py-[3px] px-[5px] font-semibold border text-black leading-[12px]">
                                                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M6 1C3.243 1 1 3.243 1 6C1 8.757 3.243 11 6 11C8.757 11 11 8.757 11 6C11 3.243 8.757 1 6 1ZM2 6C2 5.5505 2.078 5.119 2.2155 4.7155L3 5.5L4 6.5V7.5L5 8.5L5.5 9V9.9655C3.5305 9.718 2 8.036 2 6ZM9.165 8.4365C8.8385 8.1735 8.3435 8 8 8V7.5C8 7.23478 7.89464 6.98043 7.70711 6.79289C7.51957 6.60536 7.26522 6.5 7 6.5H5V5C5.26522 5 5.51957 4.89464 5.70711 4.70711C5.89464 4.51957 6 4.26522 6 4V3.5H6.5C6.76522 3.5 7.01957 3.39464 7.20711 3.20711C7.39464 3.01957 7.5 2.76522 7.5 2.5V2.2945C8.964 2.889 10 4.325 10 6C9.99991 6.88235 9.70614 7.73957 9.165 8.4365Z" fill="#333333" />
                                                            </svg>
                                                        </div>
                                                    }

                                                    {
                                                        plan.trip !== null && plan.trip?.dailyId !== null &&
                                                        <div className="flex items-center">
                                                            <div className="bg-white ml-1 border rounded-[4px] py-[5px] px-[8px] font-semibold text-black leading-[10px]">
                                                                {plan.trip?.dailyId}
                                                            </div>

                                                            {
                                                                !!canEdit && !!plan.trip?.chargeUser && !!actualUser && (plan.trip.chargeUser === actualUser.username) && !plan.planned && !isLegOpacited(plan, order, canEdit, activeTripId, isTriagePage, groupsInCharge).result && !!plan.trip && (plan.trip.statusId === TripStatusEnum.NOT_DISPATCHABLE || plan.trip.statusId === TripStatusEnum.REPLANNING) &&
                                                                <div
                                                                    onClick={() => callbackRemoveLegFromTrip(plan)}
                                                                    className="bg-white ml-1 rounded-[4px] border cursor-pointer p-[3px] font-semibold text-black"
                                                                >
                                                                    <svg xmlns="http://www.w3.org/2000/svg" stroke="#cf0a2c" fill="#cf0a2c" viewBox="0 0 20 20" className="w-[14px] h-[14px]">
                                                                        <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z" />
                                                                    </svg>
                                                                </div>
                                                            }
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        );
                                    })
                                }
                            </div>

                            {
                                callbackRestoreRoute !== null && canEdit && order.legs.some(x => !isLegOpacited(x, order, canEdit, activeTripId, isTriagePage, groupsInCharge).result) &&
                                <div onClick={handleCallbackRestoreRoute} className="cursor-pointer">
                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M5.76693 6.81185H9.76693C10.8696 6.81185 11.7669 7.70918 11.7669 8.81185C11.7669 9.91452 10.8696 10.8118 9.76693 10.8118H7.76693V12.1452H9.76693C11.6049 12.1452 13.1003 10.6498 13.1003 8.81185C13.1003 6.97385 11.6049 5.47852 9.76693 5.47852H5.76693V3.47852L2.43359 6.14518L5.76693 8.81185V6.81185Z" fill="#6F6F6F" />
                                    </svg>
                                </div>
                            }
                        </>
                    }

                    {/* CASO Leg.Length === 0 => Ordine in Triage Routing */}
                    {
                        !!plans && plans.length === 0 &&
                        <>
                            <div className="w-full flex items-center leading-[16px] text-[13px]">
                                <div className="grow flex items-center justify-items-center">
                                    <div className="flex m-0 m-auto">
                                        <svg className="text-red" xmlns="http://www.w3.org/2000/svg" width="1.6em" height="1.6em" viewBox="0 0 24 24"><path fill="currentColor" d="m8.4 17l3.6-3.6l3.6 3.6l1.4-1.4l-3.6-3.6L17 8.4L15.6 7L12 10.6L8.4 7L7 8.4l3.6 3.6L7 15.6zm3.6 5q-2.075 0-3.9-.788t-3.175-2.137q-1.35-1.35-2.137-3.175T2 12q0-2.075.788-3.9t2.137-3.175q1.35-1.35 3.175-2.137T12 2q2.075 0 3.9.788t3.175 2.137q1.35 1.35 2.138 3.175T22 12q0 2.075-.788 3.9t-2.137 3.175q-1.35 1.35-3.175 2.138T12 22" /></svg>
                                        <span className="font-bold ml-2 mt-0.5">NO MATCHING ROUTING</span>
                                    </div>

                                </div>
                            </div>
                        </>
                    }
                </div>
            }
        </>
    );
})

export default Planning;