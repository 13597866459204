import React, { useContext } from "react";
import { IMasterDataContext, MasterDataContext } from "../../context/MasterDataContext";
import { Table } from "react-bootstrap";
import { IRuleCombinationTrip } from "../../types/rulesCombinationsTrips";

type TdElementType = {
    ruleCombs: IRuleCombinationTrip[];
    value: 'rule' | 'c' | "legType" | "legOrigin" | "legDestination" | "legOriginCountry" | "legDestinationCountry";
}
const TableRulesCombinationsTrips = () => {
    const {
        rulesCombinationsTrips
    } = useContext(MasterDataContext) as IMasterDataContext;

    const TdElement: React.FC<TdElementType> = ({
        ruleCombs,
        value,
    }) => {
        return (
            <td>
                {
                    !(value === "rule") &&
                    <>
                        {
                            ruleCombs.map((ruleComb, j) => {
                                return (
                                    <div key={j}>
                                        <div>{ruleComb[value]}</div>
                                    </div>
                                )
                            })
                        }
                    </>
                }
                {
                    !!(value === "rule") &&
                    <div>
                        <div>{ruleCombs[0][value]}</div>
                    </div>
                }
            </td>
        )
    }

    return (
        <div className="w-full">
            {/* @ts-ignore */}
            <Table striped bordered responsive>
                <thead>
                    <tr>
                        <th>Rule</th>
                        <th>C</th>
                        <th>Leg Type</th>
                        <th>Leg Origin</th>
                        <th>Leg Destination</th>
                        <th>Leg Origin Country</th>
                        <th>Leg Destination Country</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        rulesCombinationsTrips.map((ruleCombs, index) => {
                            return (
                                <tr key={index}>
                                    <TdElement
                                        ruleCombs={ruleCombs}
                                        value={'rule'}
                                    />

                                    <TdElement
                                        ruleCombs={ruleCombs}
                                        value={'c'}
                                    />

                                    <TdElement
                                        ruleCombs={ruleCombs}
                                        value={'legType'}
                                    />

                                    <TdElement
                                        ruleCombs={ruleCombs}
                                        value={'legOrigin'}
                                    />

                                    <TdElement
                                        ruleCombs={ruleCombs}
                                        value={'legDestination'}
                                    />

                                    <TdElement
                                        ruleCombs={ruleCombs}
                                        value={'legOriginCountry'}
                                    />

                                    <TdElement
                                        ruleCombs={ruleCombs}
                                        value={'legDestinationCountry'}
                                    />
                                </tr>
                            )
                        })
                    }
                </tbody>
            </Table>
        </div>
    )
}

export default TableRulesCombinationsTrips;