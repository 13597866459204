import React, { useContext } from 'react';
import { Outlet } from 'react-router-dom';
import usePermissions from './utils/hooks/usePermissions';
import { PermissionAreas, PermissionOperations } from './types/permissions';
import { IPermissionsContext, PermissionsContext } from './context/PermissionsContext';

const AuthRoutePlanningTool: React.FC<{area: PermissionAreas, operation?: PermissionOperations}> = ({area, operation}) => {
    const { hasPermission } = usePermissions();
    const { permissions } = useContext(PermissionsContext) as IPermissionsContext;

    return (
        <>
            {permissions === undefined && 
                <img src='/media/svg/general/loadingAnimated.svg' alt='Loading...' width={"80px"} />
            }

            {permissions !== undefined &&
                <>
                    {hasPermission(area, operation) &&
                        <Outlet/>
                    }
        
                    {!hasPermission(area, operation) &&
                        <> Unauthorized </>
                    }
                </>
            }
        </>
    );
}

export default AuthRoutePlanningTool