import React, { useMemo, useState, useContext } from "react";
import Button from "../ui/button/Button";
import { IOrder, Leg } from "../../types/orders";
import SelectAsyncPreference from "../ui/selectAsyncPreference/SelectAsyncPreference";
import { IMasterDataContext, MasterDataContext } from "../../context/MasterDataContext";
import { ResultType } from "../ui/selectTextInputAsync/SelectTextInputAsync";
import { masterDataService } from "../../api/masterDataService/masterDataService";
import { distinct } from "../../utils/utilFunctions";

type Props = {
    isOpen: boolean;
    allOrders: IOrder[] | undefined;
    selectedDeposits: number[];
    setSelectedDepositToModify: React.Dispatch<React.SetStateAction<null | number>>;
    callbackOpenCloseModifyDepositsBanner: () => any;
    callbackRemoveDepots: () => any;
    callbackChangeDepots: () => any;
}

export type DataObjType = null | {
    volume: number;
    weight: number;
    taxWeight: number;
    shippingUnits: number;
    linearMeters: number | string;
}

const ModifyDepositsBanner: React.FC<Props> = ({
    allOrders,
    selectedDeposits,
    isOpen,
    setSelectedDepositToModify,
    callbackOpenCloseModifyDepositsBanner,
    callbackRemoveDepots,
    callbackChangeDepots
}) => {

    const { deposits, updateDeposits } = useContext(MasterDataContext) as IMasterDataContext;

    const [dropdownSelectedDeposit, setDropdownSelectedDeposit] = useState<ResultType | null>(null);

    const handleChangeDropdownValue = (e: ResultType | null) => {
        setDropdownSelectedDeposit(e);
        setSelectedDepositToModify(!!e ? e.id : null);
    };

    const selectedLegsData = useMemo(() => {
        let legs: Leg[] = [];
        if (allOrders === undefined) {
            return legs;
        }

        allOrders.forEach(order => {
            order.legs.forEach(leg => {
                if (selectedDeposits.includes(leg.id)) {
                    legs.push(leg);
                }
            });
        });

        return legs;
    }, [allOrders, selectedDeposits]);

    return (
        <>
            <div style={{ zIndex: 40 }} className={"sticky bottom-[40px] transition w-full mt-[24px]" + (isOpen === false ? ' translate-x-[calc(100%+70.5px)]' : '')}>
                <div className="relative bg-white shadow-banner rounded-[4px] pr-6 flex justify-between">

                    {/* Badge Banner */}
                    <div onClick={callbackOpenCloseModifyDepositsBanner} className="absolute top-0 left-0 -translate-x-full rounded-l-[4px] cursor-pointer shadow-card bg-white uppercase font-bold px-[16px] bg-blue h-full">
                        <div className={"absolute select-none top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 flex flex-col items-center" + (isOpen === false ? ' text-blue' : ' text-black')}>
                            {
                                isOpen === false &&
                                <>
                                    <div className="mb-1">{selectedDeposits?.length}</div>

                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M18.75 19.5l-7.5-7.5 7.5-7.5m-6 15L5.25 12l7.5-7.5" />
                                    </svg>
                                </>
                            }

                            {
                                isOpen === true &&
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M11.25 4.5l7.5 7.5-7.5 7.5m-6-15l7.5 7.5-7.5 7.5" />
                                </svg>
                            }
                        </div>
                    </div>

                    <div className="flex items-center">
                        <div className="h-[100px] w-[24px] relative">
                            <div className="absolute left-0 top-0 bg-white h-full w-full aspect-square"></div>
                        </div>

                        <div className="flex items-center">
                            <div className="mr-8">
                                <div className="text-blue text-[12px] font-bold mb-2 uppercase">Selected deposits</div>
                                <div className="text-[24px] font-bold">{selectedDeposits?.length}</div>
                            </div>
                        </div>
                    </div>

                    <div className="flex items-center">
                        {selectedDeposits.length === distinct(selectedLegsData.map(l => l.orderId)).length &&
                            <>
                                <SelectAsyncPreference
                                    initialOptions={deposits}
                                    value={dropdownSelectedDeposit}
                                    name="deposit"
                                    placeholder="Select depot..."
                                    callbackOnSelect={handleChangeDropdownValue}
                                    dropDownPositionY="top"
                                    apiServiceSearch={masterDataService.searchDeposits}
                                    apiServiceAddRemoveToFavourites={masterDataService.addRemoveDepositToFavourites}
                                    callbackOnAddedToFavourites={updateDeposits}
                                    callbackOnRemovedFromFavourites={updateDeposits}
                                    paginated={true}
                                    paginationCount={25}
                                />

                                <Button
                                    callback={callbackChangeDepots}
                                    className="bg-darkGrey text-white ml-3"
                                >
                                    <div className="mt-[1px]">Change depot</div>
                                </Button>
                            </>
                        }

                        <div className="mx-3 w-[1px] h-[60px] bg-lightGrey"></div>

                        <Button
                            callback={callbackRemoveDepots}
                        >
                            <div className="mt-[1px]">Remove depots</div>
                        </Button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ModifyDepositsBanner;