import React, { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { Layout } from "../components/layout/Layout";
import { IsAuthorized, existClaim, hasPlanningToolAccess, hasUomAccess } from "../../utils/AuthUtils";
import UnauthorizedPage from "../pages/auth/UnauthorizedPage";
import { PlanningToolPermissionsProvider } from "../../planning-tool/context/PermissionsContext";
import config from "../../settings";
import { pagesLinks } from "../../utils/PageUtils";

const GeneralAuthLayout: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        // Check the token
        if (!IsAuthorized()) {
            // se ingressPath è == /signout-callback-oidc OPPURE se è == /authorize lo setta uguale a /dashboard altrimenti lo mantiene uguale  
            window.sessionStorage.setItem("ingressPath", (window.location.pathname === config.logoutCallbackUrl || window.location.pathname === '/authorize') ? pagesLinks.dashboard : window.location.pathname);

            navigate("/authorize");
        }
    }, [location]);

    return (
        (!hasUomAccess() && !hasPlanningToolAccess()) ?
            <UnauthorizedPage missingClaim={!existClaim("email") ? "email" : null} app={"UOM / Planning Tool"} />
            :
            <PlanningToolPermissionsProvider>
                <Layout>
                    <Outlet />
                </Layout>
            </PlanningToolPermissionsProvider>
    );
}

export default GeneralAuthLayout;