export type ObjParamsBaseOriginDestination = {
    operator?: null | 'AND' | 'OR',
    conditions: {
        type?: "startsWith" | "contains",
        value: any,
    }[]
}

export const getFilterParamsOriginDestinationSSR = (colId: 'origin' | 'destination', filterModel: any) => {
    if(!filterModel[colId]) {
        return null;
    }

    let objBase: ObjParamsBaseOriginDestination = {
        operator: null,
        conditions: []
    }

    if (!!filterModel[colId].conditions && filterModel[colId].conditions.length > 0 && !!filterModel[colId].operator) {
        objBase.operator = filterModel[colId].operator;
        objBase.conditions = filterModel[colId].conditions.map((condition: any) => {
            return {
                type: condition.type,
                value: condition.filter,
            }
        })
    } else {
        objBase.conditions = [{
            value: filterModel[colId].filter,
            type: filterModel[colId].type
        }];
    }

    return objBase;
}