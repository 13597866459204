import React, { useContext, useEffect, useRef, useState } from "react";
import Modal from "../../ui/modal/Modal";
import Button from "../../ui/button/Button";
import { ITripsContext, TRIP_PAGINATION_COUNT, TripsContext, advancedFiltersInitObj } from "../../../context/TripContext";
import { ITrip } from "../../../types/trips";
import _debounce from 'lodash/debounce';
import TableRulesCombinationsTrips from "../../tableRulesCombinationsTrips/TableRulesCombinationsTrips";
import { tripsService } from "../../../api/tripsService/tripsService";
import { errorConstants } from "../../../configs/errorConstants";
import Spinner from "../../ui/spinner/Spinner";
import dayjs from "dayjs";

type Props = {
    callbackCloseModal: () => any;
    selectedLegs: number[];
    callbackOnLegAdded: () => any;
    callbackSetOrderIdsInError: (orderIds: number[]) => any;
}

const ModalAddToTrip: React.FC<Props> = ({
    callbackCloseModal,
    selectedLegs,
    callbackOnLegAdded,
    callbackSetOrderIdsInError
}) => {

    const {
        tripFilterOptions,
        setActiveTripId,
        setIsOpenSidebarTrips,
        setAdvancedFiltersRef,
        advancedFiltersRef
    } = useContext(TripsContext) as ITripsContext;

    const [showedTab, setShowedTab] = useState<"trip_selection" | "rules_visualization">('trip_selection');
    const [loadingAddToTrip, setLoadingAddToTrip] = useState(false);

    const [trips, setTrips] = useState<ITrip[]>([]);

    const [addToTripDescription, setAddToTripDescription] = useState('');
    const [selectedTripToAdd, setSelectedTripToAdd] = useState<null | ITrip>(null);
    const handleSelectionAddToTrip = (trip: ITrip) => {
        if (!!selectedTripToAdd) {
            if (selectedTripToAdd.id !== trip.id) {
                setSelectedTripToAdd(trip);
                setAddToTripDescription(!!trip.description ? trip.description : '');
            } else {
                setSelectedTripToAdd(null)
            }
        } else {
            setSelectedTripToAdd(trip);
            setAddToTripDescription(!!trip.description ? trip.description : '');
        }
    }

    const [IDErrorTrip, setIDErrorTrip] = useState<null | number>(null);
    const addToTrip = async () => {
        // Take currentTripDraft and add its orders to the selected trip
        if (selectedTripToAdd !== null) {
            setLoadingAddToTrip(true);
            const response = await tripsService.addTripLegs(selectedTripToAdd.id, {
                legsIds: selectedLegs,
                description: addToTripDescription
            }, "Adding Leg to Trip...", "Leg added successfully", "Error adding Leg to Trip");

            if (response.success) {
                callbackCloseModal();                
                setActiveTripId(selectedTripToAdd.id);
                setIsOpenSidebarTrips(true);
                setAdvancedFiltersRef({...advancedFiltersRef.current, tripDate: selectedTripToAdd.creationTime.split('T')[0]});
                callbackOnLegAdded();
            } else {
                if (response.data.code === errorConstants.tripRuleForbidden) {
                    setIDErrorTrip(selectedTripToAdd.id);
                }

                if (response.data.code === errorConstants.ON_TRIP_LEG_COMBINATION_EXCEPTION) {
                    callbackSetOrderIdsInError(response.data.detailObject);
                }
            }
            setLoadingAddToTrip(false);
        }
    }

    // ---- START: Handle pagination ---- //
    const tripsContainerRef = useRef<null | HTMLDivElement>(null);
    useEffect(() => {
        const listener = () => {
            if (!!tripsContainerRef.current) {
                const scrollTop = tripsContainerRef.current?.scrollTop;
                const offsetHeight = tripsContainerRef.current?.offsetHeight;
                const scrollHeight = tripsContainerRef.current?.scrollHeight - 1;

                if (scrollTop + offsetHeight >= scrollHeight) {
                    getTripsWithPagination();
                }
            }
        }

        if (!!tripsContainerRef.current) {
            if (tripsContainerRef && tripsContainerRef.current !== null) {
                tripsContainerRef.current.addEventListener('scroll', listener);
            }
        }

        return () => {
            if (!!tripsContainerRef.current) {
                tripsContainerRef.current.removeEventListener('scroll', listener)
            }
        }
    }, [trips]);

    const [loadingTrips, setLoadingTrips] = useState<boolean>(false);
    const loadingTripsRef = React.useRef(loadingTrips);
    const setLoadingTripsRef = (isLoading: boolean) => {
        loadingTripsRef.current = isLoading;
        setLoadingTrips(isLoading);
    };

    const [currentPage, setCurrentPage] = useState(1);
    const currentPageRef = React.useRef(currentPage);
    const setCurrentPageRef = (data: number) => {
        currentPageRef.current = data;
        setCurrentPage(data);
    };

    const [lengthLastRequest, setLengthLastRequest] = useState<number | null>(null);
    const lengthLastRequestRef = React.useRef(lengthLastRequest);
    const setLengthLastRequestRef = (data: null | number) => {
        lengthLastRequestRef.current = data;
        setLengthLastRequest(data);
    };
    // ---- END: Handle pagination ---- //

    /** ---- START MANAGE UPDATE TRIPS ---- */
    const canGo = useRef(true);
    useEffect(() => {
        if (canGo.current) {
            canGo.current = false;
            getTripsWithPagination();
        }
    }, []);

    const canGoNext = () => {
        return (lengthLastRequestRef.current === null || (lengthLastRequestRef.current !== null && !(lengthLastRequestRef.current < TRIP_PAGINATION_COUNT)))
    }

    const getTripsWithPagination = async () => {
        const option = tripFilterOptions.find(tO => tO.type === 'owned');
        if (!!option && !loadingTripsRef.current && canGoNext()) {
            setLoadingTripsRef(true);
            const response = await tripsService.getTrips(option.type, '', null, null, currentPageRef.current, 20);
            setLoadingTripsRef(false);
            if (response.success) {
                setLengthLastRequestRef(response.data.length);
                const newData = [...trips, ...response.data];
                setTrips(newData);
                setCurrentPageRef(currentPageRef.current + 1);
            }
        }
    }
    /** ---- END MANAGE UPDATE TRIPS ---- */

    return (
        <Modal
            callbackCloseModal={callbackCloseModal}
            title="Add to trip"
            footer={
                showedTab === "trip_selection" ?
                    <div className="flex items-center justify-end">
                        <Button
                            callback={addToTrip}
                            loading={loadingAddToTrip}
                        >
                            Add to selected trip
                        </Button>
                    </div>
                    : <div></div>
            }
            customStyleContainer={{
                height: '80vh'
            }}
            customStyleChildrenContainer={{
                padding: 24
            }}
        >
            <>
                {
                    showedTab === "trip_selection" &&
                    <div className="h-full flex flex-col">
                        <div className="flex-shrink-0 mb-4">
                            <div className="w-full flex items-center justify-between">
                                <div className="cursor-pointer flex items-center uppercase text-blue text-[15px] font-bold">
                                    <div className="mr-1 select-none">Select a Trip</div>
                                </div>
                                <div className="text-lg font-bold leading-[21.6px]">
                                    Owned By Me
                                </div>
                            </div>
                        </div>

                        <div ref={tripsContainerRef} className={"grow overflow-auto"}>
                            <div>
                                {
                                    trips?.map((trip, index) => {
                                        return (
                                            <div key={index}>
                                                <div
                                                    className={"mb-6 p-3 border rounded-[4px] grid grid-cols-12 gap-2 cursor-pointer" + (selectedTripToAdd && selectedTripToAdd.id === trip.id ? ' bg-grey border-blue' : '')}
                                                    onClick={() => handleSelectionAddToTrip(trip)}
                                                >
                                                    <div className="col-span-3">
                                                        <div className="text-blue text-[13px] font-bold mb-1 uppercase">Trip ID</div>
                                                        <div >
                                                            <div className="text-[24px] font-bold">{trip.dailyId}</div>
                                                            <div className="text-[12px] font-bold">{dayjs(trip.creationTime).format("DD/MM/YYYY")}</div>
                                                        </div>
                                                    </div>
                                                    <div className="col-span-3">
                                                        <div className="text-blue text-[13px] font-bold mb-1 uppercase">Equipment</div>
                                                        <div className="text-[24px] font-bold">{trip.equipment.name}</div>
                                                    </div>

                                                    <div className="col-span-5">
                                                        <div className="text-blue text-[13px] font-bold mb-1 uppercase">Description</div>
                                                        <div className="text-[13px]">{!!trip.description ? trip.description : '-'}</div>
                                                    </div>

                                                    {
                                                        !!selectedTripToAdd && IDErrorTrip !== null && IDErrorTrip === trip.id &&
                                                        <div className="col-span-1 h-full flex items-center">
                                                            <svg onClick={() => setShowedTab("rules_visualization")} className="text-warning ml-2 cursor-pointer" xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" viewBox="0 0 16 16"><path fill="currentColor" fillRule="evenodd" d="M6.285 1.975C7.06.68 8.939.68 9.715 1.975l5.993 9.997c.799 1.333-.161 3.028-1.716 3.028H2.008C.453 15-.507 13.305.292 11.972zM8 5a.75.75 0 0 1 .75.75v3a.75.75 0 0 1-1.5 0v-3A.75.75 0 0 1 8 5m1 6.5a1 1 0 1 1-2 0a1 1 0 0 1 2 0" clipRule="evenodd" /></svg>
                                                        </div>
                                                    }
                                                </div>

                                                {
                                                    !!selectedTripToAdd && selectedTripToAdd.id === trip.id &&
                                                    <div className="mb-6">
                                                        <label className="mb-3 block text-[15px]" htmlFor="tripDescription">Add a description (optional)</label>
                                                        <textarea
                                                            onChange={(e) => setAddToTripDescription(e.target.value)}
                                                            value={addToTripDescription}
                                                            name="tripDescription"
                                                            id="tripDescription"
                                                            className="w-full text-[15px] border border-black rounded-[4px] min-h-[80px] p-2"
                                                        >
                                                        </textarea>
                                                    </div>
                                                }
                                            </div>
                                        );
                                    })
                                }

                                {
                                    loadingTripsRef.current === true &&
                                    <div className="flex justify-center mt-2">
                                        <Spinner />
                                    </div>
                                }

                                {
                                    (trips === null || trips.length === 0) && !loadingTripsRef.current &&
                                    <div className="mb-6 font-bold">There's no trip to select.</div>
                                }
                            </div>
                        </div>
                    </div>
                }

                {
                    showedTab === "rules_visualization" &&
                    <div>
                        <div className="flex items-center mb-4">
                            <div onClick={() => {
                                setShowedTab("trip_selection");
                                setIDErrorTrip(null);
                            }} className="cursor-pointer">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-8 h-8">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </div>

                            <div className="ml-4 text-[18px] font-bold">Trip Combination Rules</div>
                        </div>
                        <TableRulesCombinationsTrips />
                    </div>
                }

            </>
        </Modal>
    )
}

export default ModalAddToTrip;