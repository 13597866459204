import React, { useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import config from "../../../settings";
import { MapService } from "../../../services/mapService";
import { TripStop } from "../../types/trips";
import { DetailsMapService } from "../../../services/detailsMapService";
import "./SidebarTripsMap.css";
import { BaseMapService, MapSettings } from "../../../services/baseMapService";

type Props = {
    stops: TripStop[];
    isFullScreen: boolean;
    handleCallbackFullScreen: () => any;
}

const SidebarTripsMap: React.FC<Props> = ({
    stops,
    isFullScreen,
    handleCallbackFullScreen
}) => {
    const mapRef = useRef<null | HTMLDivElement>(null);
    const [hMap, setHMap] = useState();
    const [ui, setUi] = useState();

    const [populatedStops, setPopulatedStops] = useState<TripStop[]>([]);

    const stopsCoordinates = useMemo(() => {
        const coords = [];

        for (let index = 0; index < populatedStops.length; index++) {
            const element = populatedStops[index];

            if (element.address.lat !== null && element.address.lon !== null) {
                coords.push({
                    lat: element.address.lat.replace(",", "."),
                    lng: element.address.lon.replace(",", ".")
                })
            }
        }

        return coords;
    }, [populatedStops]);

    useEffect(() => {
        populateEmptyCoordinates(stops)
    }, [stops])

    const populateEmptyCoordinates = async (stops: TripStop[]) => {
        const newStops: TripStop[] = [];

        for (let index = 0; index < stops.length; index++) {
            const stop = stops[index];

            if (!!stop.address.lat && !!stop.address.lon) {
                newStops.push(stop);
            } else {
                const requestedAddress = stop.address.addressLine + ', ' + stop.address.city + ', ' + stop.address.zipCode + ', ' + stop.address.countryCode;
                const coord: { lat: number | null; lng: number | null; } = await new Promise((resolve, reject) => {
                    if (!!MapSettings && !!MapSettings.searchService) {
                        // @ts-ignore
                        MapSettings.searchService.geocode({ q: requestedAddress },
                            (result: any) => {
                                if (result && result.items[0]) {
                                    const position: { lat: number | null; lng: number | null; } = result.items[0].position;
                                    resolve(position)
                                }
                            },
                            (error: any) => {
                                // handling error
                                resolve({ lat: null, lng: null });
                            });
                    }
                });

                newStops.push({
                    ...stop,
                    address: {
                        ...stop.address,
                        lat: !!coord.lat ? coord.lat.toString() : null,
                        lon: !!coord.lng ? coord.lng.toString() : null
                    }
                });
            }
        }

        setPopulatedStops(newStops);
        BaseMapService.disposeMap();
    }

    const mapCenter = useMemo(() => {
        const lats = stopsCoordinates.map(stop => parseFloat(stop.lat));
        const lngs = stopsCoordinates.map(stop => parseFloat(stop.lng));

        if (lats.length > 0 && lngs.length > 0) {
            return {
                // @ts-ignore
                lat: (Math.min(...lats) + Math.max(...lats)) / 2,
                // @ts-ignore
                lng: (Math.min(...lngs) + Math.max(...lngs)) / 2
            };
        }

        return {
            lat: null,
            lng: null
        }
    }, [stopsCoordinates]);

    const initMapCanGo = useRef(true);
    useLayoutEffect(() => {
        if (!!initMapCanGo && !!initMapCanGo.current) {
            initMapCanGo.current = false;
            BaseMapService.initMap(mapRef);
        }

        // `mapRef.current` will be `undefined` when this hook first runs; edge case that        
        if (!!mapRef && !!mapRef.current && !!populatedStops && populatedStops.length > 0) {
            // @ts-ignore
            const H = window.H;
            const platform = new H.service.Platform({
                apikey: config.HMap_AppKey
            });
            const defaultLayers = platform.createDefaultLayers();
            /*  NOTE (by Marino Didio): I had to replace defaultLayers.vector to defaultLayers.raster since the vector one
                would cause "Cannot access property _immediateRedraw, t._scene is null" when the component unmounts.
                I'm aware that this is a temporary fix, and it would need further investigation.
                FIXME 
            */

            const map = new H.Map(mapRef.current, defaultLayers.raster.normal.map, {
                center: mapCenter,
                zoom: 5,
                pixelRatio: window.devicePixelRatio || 1,
                padding: { top: 50, left: 50, bottom: 50, right: 50 }
            });

            MapService.setBaseTileLayer("00000000F", map, "reduced.day", config.HMap_AppKey);

            // add a resize listener to make sure that the map occupies the whole container
            window.addEventListener('resize', MapService.events.resize(map));

            // pointer move management
            map.addEventListener('pointermove', MapService.events.pointerMove(map, H), false);
            //eslint-disable-next-line 
            const behavior = new H.mapevents.Behavior(new H.mapevents.MapEvents(map));

            const ui = H.ui.UI.createDefault(map, defaultLayers);

            ui.removeControl('mapsettings');

            ui.addControl('custom_mapsettings', new H.ui.MapSettingsControl({
                baseLayers: [{
                    label: 'Map view',
                    layer: MapService.getBaseTileLayer("00000000F", "reduced.day", config.HMap_AppKey)
                }, {
                    label: 'Satellite',
                    layer: defaultLayers.raster.satellite.map
                }],
                layers: [{
                    label: "Traffic conditions",
                    layer: defaultLayers.vector.normal.traffic
                }, {
                    label: "Show traffic incidents",
                    layer: defaultLayers.vector.normal.trafficincidents
                }],
                alignment: H.ui.LayoutAlignment.BOTTOM_RIGHT
            }));

            setHMap(map);
            setUi(ui);

            // This will act as a cleanup to run once this hook runs again.
            // This includes when the component un-mounts
            return () => {
                map.dispose();
                MapService.events.detachAll(map);
            };
        }
    }, [mapRef, isFullScreen, populatedStops]); // This will run this hook every time this ref is updated

    const buildPositionMarker = (position: { lat: string; lng: string }, index: number) => {
        const domElement = document.createElement('div');

        // ! TODO CONTROLLARE CLASSE
        domElement.className = 'position-history-icon';
        domElement.innerHTML = (index + 1).toString();

        // @ts-ignore
        const domIcon = new window.H.map.DomIcon(domElement);

        // @ts-ignore
        const marker = new window.H.map.DomMarker(position, { icon: domIcon, zIndex: 2 });

        return marker;
    }

    useEffect(() => {
        if (!!hMap && !!ui) {
            if (stopsCoordinates.length > 0) {
                // Line route
                DetailsMapService.events.renderLineRoute(hMap, stopsCoordinates, true);

                const markers = [...stopsCoordinates].map((coord, index) => {
                    return buildPositionMarker({
                        lat: coord.lat,
                        lng: coord.lng
                    }, index);
                });

                // @ts-ignore
                hMap.addObjects([...markers]);
            }
        }
    }, [stopsCoordinates, hMap, ui]);

    return (
        <div className="relative map-plannig-tool-container z-[800]">
            <div
                className={(isFullScreen === false ? "w-full h-[30vh]" : "fixed top-0 left-0 h-[calc(100vh-80px)] w-full bg-[#1e242773] p-[16px]")}
                ref={mapRef}
            >
                {/*  FULL SCREEN BTN */}
                <div
                    className="absolute z-40 top-[16px] right-[32px] h-[50px] w-[50px]"
                    onClick={handleCallbackFullScreen}
                >
                    <svg className="cursor-pointer" width="68" height="68" viewBox="0 0 68 68" fill="none" xmlns="http://www.w3.org/2000/svg"><g filter="url(#filter0_d_8506_8290)"><rect x="12" y="8" width="44" height="44" rx="4" fill="white"></rect><path d="M30 21V24C30 24.5304 29.7893 25.0391 29.4142 25.4142C29.0391 25.7893 28.5304 26 28 26H25M43 26H40C39.4696 26 38.9609 25.7893 38.5858 25.4142C38.2107 25.0391 38 24.5304 38 24V21M38 39V36C38 35.4696 38.2107 34.9609 38.5858 34.5858C38.9609 34.2107 39.4696 34 40 34H43M25 34H28C28.5304 34 29.0391 34.2107 29.4142 34.5858C29.7893 34.9609 30 35.4696 30 36V39" stroke="#3987E2" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path></g><defs><filter id="filter0_d_8506_8290" x="0" y="0" width="68" height="68" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB"><feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood><feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"></feColorMatrix><feOffset dy="4"></feOffset><feGaussianBlur stdDeviation="6"></feGaussianBlur><feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"></feColorMatrix><feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_8506_8290"></feBlend><feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_8506_8290" result="shape"></feBlend></filter></defs></svg>
                </div>
            </div>
        </div>
    )
}

export default SidebarTripsMap;