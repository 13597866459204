import React, { useEffect, useMemo, useRef, useState } from "react";
import { IGroup } from "../../types/orders";
import { toast } from "react-toastify";

export type GroupSelectType = {
    group: IGroup;
    total: number;
    planned: number;
}

type Props = {
    departmentGroups: IGroup[];
    planningGroups: IGroup[] | undefined;
    callbackOnChange: (groups: IGroup[]) => any;
}

const GroupSelectProgress: React.FC<Props> = ({
    departmentGroups,
    planningGroups,
    callbackOnChange
}) => {

    const openClose = () => {
        setOpen(!open);
    }

    const [open, setOpen] = useState(false);
    const selectRef = useRef<null | HTMLDivElement>(null);
    useEffect(() => {
        const listener = (e: any) => {
            if (selectRef && selectRef.current && !selectRef.current.contains(e.target)) {
                setOpen(false);
            }
        }
        document.addEventListener('click', listener);
        return () => {
            document.removeEventListener('click', listener);
        }
    }, []);

    const handleChange = (e: any, id: number) => {
        if (!!planningGroups) {
            if (planningGroups.length <= 1 && e.target.checked === false) {
                toast.error('At least one group must be selected for planning');
            } else {
                let newPlanningGroups: IGroup[] = [];
                if (!!!planningGroups) {
                    return;
                }

                if (e.target.checked) {
                    newPlanningGroups = planningGroups.concat(departmentGroups.find(x => x.id === id) as IGroup);
                } else {
                    newPlanningGroups = planningGroups.filter(x => x.id !== id);
                }

                callbackOnChange(newPlanningGroups);
            }
        }
    }

    const planningGroupsMapped = useMemo(() => {
        return !!planningGroups ? [...planningGroups]?.map((x: IGroup) => x.id) : [];
    }, [planningGroups]);

    return (
        <div ref={selectRef} onClick={openClose} className="w-[250px] select-none">
            <div
                className={"relative text-black text-[15px] rounded-[8px] p-[10px] w-full"}
            >
                <div className="cursor-pointer flex items-center text-darkGrey uppercase font-semibold text-[10px]">
                    <div className="flex items-center mr-[6px] mt-[3px]">
                        <div className="mr-1">
                            Selected groups
                        </div>
                        <div className="bg-blue h-[13px] w-[13px] flex items-center justify-center text-white rounded-full">
                            {planningGroups?.length}
                        </div>
                    </div>
                    <div className={"transition" + (!open ? ' rotate-180' : '')}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7.707 16.4141L12 12.1211L16.293 16.4141L17.707 15.0001L12 9.29306L6.293 15.0001L7.707 16.4141Z" fill="#4F4C43" />
                        </svg>
                    </div>
                </div>

                {
                    !!open &&
                    <ul className="rounded-[4px] absolute z-10 top-full bg-white w-full left-0 border !border-darkGrey max-h-[220px] overflow-y-auto overflow-x-hidden whitespace-nowrap">
                        {
                            departmentGroups.map((group, index) => {
                                return (
                                    <li
                                        key={index}
                                        className="px-[10px] py-[12px] flex items-center w-full justify-between cursor-default"
                                    >
                                        <div className="flex items-center overflow-x-hidden whitespace-nowrap">
                                            <input
                                                type="checkbox"
                                                name="checked"
                                                id={'checkbox_group_' + group.id}
                                                checked={planningGroupsMapped.includes(group.id)}
                                                onChange={(e) => handleChange(e, group.id)}
                                                className="mr-[10px]"
                                            />

                                            <span onClick={() => handleChange({target: {checked: !planningGroupsMapped.includes(group.id)}}, group.id)} className="text-ellipsis text-[12px] font-medium text-black overflow-x-hidden max-w-[calc(100%-20px)]">
                                                {group.name}
                                            </span>
                                        </div>

                                        <div>
                                            <span className="text-[12px] text-darkGrey uppercase font-bold ml-3">{group.volumeToBePlanned.toFixed(2)} m³</span>
                                        </div>
                                    </li>
                                )
                            })
                        }
                    </ul>
                }
            </div>
        </div>
    );
}

export default GroupSelectProgress;