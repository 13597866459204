import { useEffect, useState } from 'react'

const useImageFlag = (countryISO: string) => {
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)
    const [image, setImage] = useState(null)

    const [mount, setMount] = useState(true);

    useEffect(() => {
        fetchImage();

        return () => {
            setMount(false);
        }
    }, [countryISO]);

    const fetchImage = async () => {
        try {
            const response = await import('../../assets/images/country_flags/' + countryISO + '/xs (24x16).svg') // change relative path to suit your needs
            setImage(response.default)
        } catch (err: any) {
            setError(err)
        } finally {
            if (mount === true) {
                setLoading(false);
            }
        }
    }

    return {
        loading,
        error,
        image,
    }
}

export default useImageFlag;