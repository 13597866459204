import React, { useState } from 'react'
import Button from "../../components/ui/button/Button";
import InputText from "../../components/ui/inputText/InputText";
import { ordersService } from '../../api/ordersService/ordersService';
import { OrderInfo } from '../../types/orders';
import dayjs from 'dayjs';

const PlanningToolOrderInfo = () => {

    // #region State
    const [orderNumber, setOrderNumber] = useState<string>("");
    const [orderInfo, setOrderInfo] = useState<OrderInfo | undefined>(undefined);
    // #endregion

    // #region Memo
    
    // #endregion

    // #region UI Handlers
    const handleChangeOrderNumberInput = (e: any) => {
        setOrderNumber(e);
    };

    const handleClickSearchButton = async () => {
        debugger;
        var response = await ordersService.getOrderInfo(orderNumber);

        if (response.success) {
            setOrderInfo(response.data);
        }
    };
    // #endregion

    // #region Util Functions
    
    // #endregion

    // #region Use Effects
    
    // #endregion

    return (
        <div className="w-full mb-6 bg-white p-[32px]">
            <div className="font-light text-lg leading-[25px]">Planning Tool</div>
            <div className="flex items-center pb-3 mb-6 border-b">
                <div className="md:mr-3 text-[32px] font-bold leading-[45px]">
                    Order Info
                </div>
            </div>

            <div>
                <label>
                    Insert Order Number to view Info
                </label>
                
                <div className='mt-2 flex'>
                    <InputText
                        callbackOnChange={handleChangeOrderNumberInput}
                        name="order_number"
                        placeholder=""
                        value={orderNumber ?? ""}
                    />

                    <Button
                        className="bg-blue text-white !px-[12px] !py-[8px] ml-[12px]"
                        callback={handleClickSearchButton}
                    >
                        Search
                    </Button>
                </div>

                {!!orderInfo &&
                (<div className='mt-5'>
                    <table className="text-left w-full">
                        <thead className="bg-[#F9F7F6] text-black font-semibold text-[12px] uppercase border-t border-[#6F6F6F] border-b">
                            <tr>
                                <th className="p-3">Added in queue</th>
                                <th className="p-3">Inserted</th>
                                <th className="p-3">Updated</th>
                                <th className="p-3">Sent</th>
                                <th className="p-3">Is in Group</th>
                                <th className="p-3">Feedback Collection</th>
                                <th className="p-3">Feedback Delivery</th>
                                <th className="p-3">Legs</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className="border-b border-[#F2F2F2] uppercase text-[12px] font-semibold text-[#6F6F6F] align-top">
                                <td>
                                    {dayjs(orderInfo?.orderAddedInQueue).format('DD/MM/YYYY HH:mm')}
                                </td>
                                <td>
                                    {dayjs(orderInfo?.orderInsert).format('DD/MM/YYYY HH:mm')}
                                </td>
                                <td>
                                    <div>
                                        {orderInfo?.orderUpdated.map((update) => {
                                            return (
                                                <p>
                                                    {dayjs(update).format('DD/MM/YYYY HH:mm')}
                                                </p>
                                            );
                                        })}
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        {orderInfo?.orderSent.map((sent) => {
                                            return (
                                                <p>
                                                    {dayjs(sent).format('DD/MM/YYYY HH:mm')}
                                                </p>
                                            );
                                        })}
                                    </div>
                                </td>
                                <td>
                                    {orderInfo?.isInGroup ? 'Yes' : 'No'}
                                </td>
                                <td>
                                    {!!orderInfo.feedbackCollection &&
                                        <>
                                            <p>Success: {orderInfo?.feedbackCollection?.success ? "Yes" : "No"}</p>
                                            <p>Time: {dayjs(orderInfo?.feedbackCollection?.time).format('DD/MM/YYYY HH:mm')}</p>
                                        </>
                                    }
                                </td>
                                <td>
                                    {!!orderInfo.feedbackDelivery && 
                                        <>
                                            <p>Success: {orderInfo?.feedbackDelivery?.success ? "Yes" : "No"}</p>
                                            <p>Time: {dayjs(orderInfo?.feedbackDelivery?.time).format('DD/MM/YYYY HH:mm')}</p>   
                                        </>
                                    }
                                </td>
                                <td>
                                    {orderInfo?.legs.map((leg) => {
                                        return (
                                            <div>
                                                <p>Name: {leg.name}</p>
                                                <p>Status: {leg.status}</p>
                                                <p>Planning Status: {leg.planningStatus}</p>
                                                <p>Trip Id: {leg.tripId}</p>    
                                                <p>Group: {leg.groupName}</p>

                                                <p>Status History:</p>
                                                <div className='ml-4'>
                                                    {leg.legsStatusHistory.map((status) => {
                                                        return (
                                                            <div>
                                                                <p>Status: {status.status}</p>
                                                                <p>TimeStamp: {dayjs(status?.timeStamp).format('DD/MM/YYYY HH:mm')}</p>
                                                                <p>User: {status.user}</p>
                                                                <hr/>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                        )
                                    })}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>)}
            </div>
        </div>
    );
};

export default PlanningToolOrderInfo;