// Use "compact" to define routes paths in the BrowserRouter (Routes.js),
// and use "extended" instead to navigate between routes
export const planningToolRouteUrls = {
    dashboard: {
        compact: "dashboard",
        extended: "/planning-tool/dashboard" 
    },
    groupsCharge: {
        compact: "groups-charge",
        extended: "/planning-tool/groups-charge"
    },
    planning: {
        compact: "planning",
        extended: "/planning-tool/planning"
    },
    planningVisualization: {
        compact: "planningVisualization",
        extended: "/planning-tool/planningVisualization"
    },
    exportExcel: {
        compact: "export-excel",
        extended: "/planning-tool/export-excel"
    },
    groupsAssociation: {
        compact: "groups-association",
        extended: "/planning-tool/groups-association"
    },
    defaultRoutingAssignment: {
        compact: "routing-assignment",
        extended: "/planning-tool/routing-assignment"
    },
    cutoff: {
        compact: "cutoff",
        extended: "planning-tool/cutoff"
    },
    manageDepartments: {
        compact: "manage-departments",
        extended: "/planning-tool/manage-departments"
    },
    manageGroups: {
        compact: "manage-groups",
        extended: "/planning-tool/manage-groups"
    },
    triage: {
        compact: "triage",
        extended: "/planning-tool/triage"
    },
    manageCharges: {
        compact: "manage-charges",
        extended: "/planning-tool/manage-charges"
    },
    orderInfo: {
        compact: "order-info",
        extended: "/planning-tool/order-info"
    },
    tripInfo: {
        compact: "trip-info",
        extended: "/planning-tool/trip-info"
    }
}