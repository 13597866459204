import React, { useEffect, useRef, useState } from "react";
import Modal from "../../ui/modal/Modal";
import Button from "../../ui/button/Button";
import { tripsService } from "../../../api/tripsService/tripsService";
import { Table } from "react-bootstrap";

type Props = {
    callbackOnClose: () => any;
    tripId: number;
}

type TripErrorType = {
    id: number,
    source: string;
    errCode: string,
    errDesc: string,
    tripId: number,
    timestamp: string
}


const ModalTripError: React.FC<Props> = ({
    callbackOnClose,
    tripId
}) => {

    const [loading, setLoading] = useState<boolean>(true);
    const [errors, setErrors] = useState<TripErrorType[]>([]);

    const canGo = useRef(true);
    useEffect(() => {
        if (!!canGo && !!canGo.current) {
            canGo.current = false;

            getSetErrors(tripId);
        }
    }, [tripId]);

    const getSetErrors = async (tripId: number) => {
        setLoading(true);
        const response = await tripsService.getTripErrors(tripId);

        if (response.success) {
            setErrors(response.data);
        }

        setLoading(false);
    }

    return (
        <Modal
            callbackCloseModal={callbackOnClose}
            title={"Trip errors"}
            footer={
                <div className="flex items-center justify-end">
                    <Button
                        callback={callbackOnClose}
                    >
                        Close
                    </Button>
                </div>
            }
            customStyleChildrenContainer={{
                overflow: "auto"
            }}
            customStyleContainer={{
                maxHeight: '75%',
                width: '80%',
                maxWidth: '1920px'
            }}
        >
            <div>
                {
                    !loading &&
                    <>
                        {
                            !!errors && errors.length === 0 &&
                            <div>
                                At least one LEG of the Trip contains error.
                            </div>
                        }

                        {
                            !!errors && errors.length > 0 &&
                            <Table>
                                <thead className="bg-grey">
                                    <tr>
                                        <th className="p-2">ID</th>
                                        <th className="p-2">Code</th>
                                        <th className="p-2">Source</th>
                                        <th className="p-2">Error</th>
                                    </tr>
                                </thead>
                                <tbody className="bg-white">
                                    {
                                        errors.map((error, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td className="p-2">{index + 1}</td>
                                                    <td className="p-2">{error.errCode}</td>
                                                    <td className="p-2">{error.source}</td>
                                                    <td className="p-2">{error.errDesc}</td>
                                                </tr>
                                            );
                                        })
                                    }
                                </tbody>
                            </Table>
                        }
                    </>
                }

                {
                    !!loading &&
                    <div>
                        Loading...
                    </div>
                }
            </div>
        </Modal>
    );
}

export default ModalTripError;