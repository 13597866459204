import React from "react";
import Spinner from "../spinner/Spinner";

interface IButton {
    children?: React.ReactNode;
    loading?: boolean;
    callback: () => (any | void);
    className?: string;
    loadingColor?: string;
    disabled?: boolean;
    title?: string | null;
    style?: any | null;
}

const Button: React.FC<IButton> = ({
    children,
    loading = false,
    callback,
    className = "text-white bg-blue border border-[1.4px] border-blue",
    loadingColor = "#FFFFFF",
    disabled = false,    
    title = null,
    style
}) => {
    const handleCallback = () => {
        if (!loading && !disabled) {
            callback();
        }
    }

    return (
        <button
            title={!!title ? title : undefined}
            onClick={handleCallback}
            className={"select-none hover:opacity-80 text-[15px] uppercase leading-[18px] font-semibold py-[9px] px-[18px] rounded-[4px]" + (!!disabled ? ' opacity-50 cursor-not-allowed' : '') + (!!className ? (" " + className) : "")}
            disabled={disabled}
            style={style}
        >
            {
                !loading &&
                children
            }
            {
                loading &&
                <Spinner color={loadingColor} />
            }
        </button>
    );
}

export default Button;