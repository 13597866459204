import { planningToolApiUrls } from "../../configs/planningToolApiUrls";
import basicRequest from "../basicRequest";

export type ChangeDepositsOrders = {
    orderId: number
    orderActions: ChangeDepositsOrderActions[]
};

export type ChangeDepositsOrderActions = {
    depositAddressId?: number,
    newDepositId?: number,
    legId?: number
};

export const routingService = {
    resetDefault: async (orderIds: number[], loadingMessage: string | null = null, successMessage: string | null = null, errorMessage: string | null = null) => {
        const request = new basicRequest();
        return await request.put(`${planningToolApiUrls.routing}/reset`, orderIds, undefined, loadingMessage, successMessage, errorMessage);
    },
    addDeposits: async (input: ChangeDepositsOrders[], loadingMessage: string | null = null, successMessage: string | null = null, errorMessage: string | null = null, partialSuccessMessage: string | null = null) => {
        const request = new basicRequest();
        return await request.post(`${planningToolApiUrls.routing}/deposits`, { actions: input }, undefined, loadingMessage, successMessage, errorMessage, partialSuccessMessage);
    },
    changeDeposits: async (input: ChangeDepositsOrders[], loadingMessage: string | null = null, successMessage: string | null = null, errorMessage: string | null = null, partialSuccessMessage: string | null = null) => {
        const request = new basicRequest();
        return await request.put(`${planningToolApiUrls.routing}/deposits`, { actions: input }, undefined, loadingMessage, successMessage, errorMessage, partialSuccessMessage);
    },
    removeDeposits: async (input: ChangeDepositsOrders[], loadingMessage: string | null = null, successMessage: string | null = null, errorMessage: string | null = null, partialSuccessMessage: string | null = null) => {
        const request = new basicRequest();
        return await request.delete(`${planningToolApiUrls.routing}/deposits`, { actions: input }, undefined, loadingMessage, successMessage, errorMessage, partialSuccessMessage);
    }
}