import React, { ReactNode, useContext } from 'react';
import { PermissionAreas, PermissionOperations } from '../../types/permissions';
import { IPermissionsContext, PermissionsContext } from '../../context/PermissionsContext';

interface AuthorizedComponentProps {
    area: PermissionAreas;
    operation: PermissionOperations;
    children: ReactNode;
};

const AuthorizedComponent: React.FC<AuthorizedComponentProps> = ({area, operation, children}) => {
    const { permissions } = useContext(PermissionsContext) as IPermissionsContext;

    if (!!permissions && !!permissions.find(p => p.area === area && p.opeation === operation)) {
        return <>{children}</>
    }
    return null;
};

export default AuthorizedComponent;