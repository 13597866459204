import React from "react";
import { Outlet } from "react-router-dom";
import { existClaim, hasUomAccess } from "../../utils/AuthUtils";
import UnauthorizedPage from "../pages/auth/UnauthorizedPage";

const AuthLayout: React.FC = () => {

    return (
        !hasUomAccess() ?
            <UnauthorizedPage missingClaim={!existClaim("email") ? "email" : null} app={"UOM"}/>
            :
            <Outlet />
    );
}

export default AuthLayout;