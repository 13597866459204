import React, { useEffect, useMemo, useRef, useState } from 'react';
import CommonTable, { TableRef } from '../../commonTable/CommonTable';
import { legsService } from '../../../api/legsService/legsService';
import Modal from '../../ui/modal/Modal';

type Props = {
    legNumber: number | null;
    businessFlowTypeId: number;
    callbackCloseModal: () => any;
};

const ModalShowPlanningRules: React.FC<Props> = ({
    legNumber,
    businessFlowTypeId,
    callbackCloseModal
}) => {

    // #region State and Refs
    const [tableData, setTableData] = useState<any>(undefined);
    const commonTableRef = useRef<TableRef>(null);
    // #endregion

    // #region Memo
    const columnDefs: any = useMemo(() => {
        if (legNumber === 1) {
            return [
                {
                    field: "businessFlow.name",
                    headerName: "Business Flow Type"
                },
                {
                    field: "type.name",
                    headerName: "Leg Type"
                },
                {
                    field: "planningType.name",
                    headerName: "Output"
                }
            ];
        } else if (legNumber === 2) {
            return [
                {
                    field: "businessFlow.name",
                    headerName: "Business Flow Type"
                },
                {
                    field: "firstType.name",
                    headerName: "Leg Type 1"
                },
                {
                    field: "firstPlanningType.name",
                    headerName: "Output 1"
                },
                {
                    field: "secondType.name",
                    headerName: "Leg Type 2"
                },
                {
                    field: "secondPlanningType.name",
                    headerName: "Output 2"
                }
            ];
        } else if (legNumber === 3) {
            return [
                {
                    field: "businessFlow.name",
                    headerName: "Business Flow Type"
                },
                {
                    field: "firstType.name",
                    headerName: "Leg Type 1"
                },
                {
                    field: "firstPlanningType.name",
                    headerName: "Output 1"
                },
                {
                    field: "secondType.name",
                    headerName: "Leg Type 2"
                },
                {
                    field: "secondPlanningType.name",
                    headerName: "Output 2"
                },
                {
                    field: "thirdType.name",
                    headerName: "Leg Type 3"
                },
                {
                    field: "thirdPlanningType.name",
                    headerName: "Output 3"
                }
            ];
        }
        return [];
    }, [tableData]);
    // #endregion

    // #region Util Functions
    const getPlanningRules = async () => {
        const response = await legsService.getPlanningRules();
        if (response.success) {
            let dataToSet: any = [];
            if (legNumber === 1) {
                dataToSet = response.data.oneLeg;
            } else if (legNumber === 2) {
                dataToSet = response.data.twoLeg;
            } else if (legNumber === 3) {
                dataToSet = response.data.threeLeg;
            }

            dataToSet = dataToSet.filter((x: any) => x.businessFlow.id === businessFlowTypeId);
            setTableData(dataToSet);
        }
    };
    // #endregion

    // #region Use Effects
    useEffect(() => {
        getPlanningRules();
    }, []);

    useEffect(() => {
        commonTableRef.current?.setTableDataState(tableData);
    }, [tableData]);
    // #endregion

    return (
        <Modal
            callbackCloseModal={callbackCloseModal}
            title="Planning Rules"
            customStyleChildrenContainer={{
                overflow: "auto",
            }}
            customStyleContainer={{
                maxHeight: '75%',
                width: '80%',
                maxWidth: '1920px'
            }}
        >
            <>
                <CommonTable
                    ref={commonTableRef}
                    columnDefs={columnDefs}
                    options={{
                        showRowsNumber: false,
                        filter: false,
                        floatingFilter: false,
                        editable: false,
                        pageSize: 10
                    }}
                />
            </>
        </Modal>        
    )
};

export default ModalShowPlanningRules;