import axios from "axios";
import config from "../../settings";

export const gridConfigurationAPI = {

    addOrUpdateGridConfiguration: (params, thenCallback) => {
        axios.post(`${config.baseWebApiUrl}${config.addOrUpdateGridConfiguration}`, params)
            .then(res => {
                thenCallback(res);
            })
            .catch(err => {
                console.log(err);
            })
    },
    getGridConfiguration: (params, thenCallback) => {
        axios.get(`${config.baseWebApiUrl}${config.getGridConfiguration}`, { params: { userMail: params.userMail, gridId: params.gridId } })
            .then(res => {
                thenCallback(res.data);
            })
            .catch(err => {
                console.log(err);
            })
    },
    removeGridConfiguration: (params, thenCallback) => {
        axios.post(`${config.baseWebApiUrl}${config.removeGridConfiguration}`, params)
            .then(res => {
                thenCallback(res);
            })
            .catch(err => {
                console.log(err);
            })
    }

}