import React, { useRef, useEffect } from "react";
import { Params, useNavigate, useParams } from "react-router-dom";

type Props = {
    to: string
}

// This is not a real redirect
const Redirect: React.FC<Props> = ({ to }) => {

    const navigate = useNavigate();
    const params = useParams();

    const updateTo = (to: string, params: Readonly<Params<string>>) => {
        const entries = Object.entries(params);
        let path = `${to}`;

        entries.forEach(([key, value]) => {
            path = path.replace(`:${key}`, `${value}`);
        });

        return path;
    };

    const canGo = useRef(true);
    useEffect(() => {
        if (canGo.current === true) {
            canGo.current = false;

            navigate(updateTo(to, params));
        }
    }, []);

    return (
        <></>
    )
}

export default Redirect;