import { planningToolApiUrls } from "../../configs/planningToolApiUrls";
import { GroupCondition } from "../../types/orders";
import basicRequest from "../basicRequest";

export const groupsService = {
    getGroups: async (my: boolean | null = null, departmentId: number | null = null) => {
        const request = new basicRequest();
        return await request.get(planningToolApiUrls.getGroups, {
            my: my,
            departmentId: departmentId
        });
    },
    takeCharge: async (selectedGroups: number[]) => {
        const request = new basicRequest();
        return await request.put(planningToolApiUrls.takeCharge, {
            groups: selectedGroups
        });
    },
    deleteCharge: async (selectedGroups: number[], loadingMessage: string | null = null, successMessage: string | null = null, errorMessage: string | null = null) => {
        const request = new basicRequest();
        return await request.delete(planningToolApiUrls.takeCharge, {
            groups: selectedGroups
        }, undefined, loadingMessage, successMessage, errorMessage);
    },
    getAllGroupConditions: async () => {
        const request = new basicRequest();
        return await request.get(planningToolApiUrls.groupConditions);
    },
    deleteGroupConditions: async (ids: number[], loadingMessage: string | null = null, successMessage: string | null = null, errorMessage: string | null = null) => {
        const request = new basicRequest();
        return await request.delete(planningToolApiUrls.groupConditions, {
            conditionIds: ids
        }, undefined, loadingMessage, successMessage, errorMessage);
    },
    updateGroupConditions: async (groupConditions: GroupCondition[], loadingMessage: string | null = null, successMessage: string | null = null, errorMessage: string | null = null) => {
        const request = new basicRequest();
        return await request.put(planningToolApiUrls.groupConditions, {
            groupConditions: groupConditions
        }, null, loadingMessage, successMessage, errorMessage);
    },
    updateGroups: async (groups: any, loadingMessage: string | null = null, successMessage: string | null = null, errorMessage: string | null = null) => {
        const request = new basicRequest();
        return await request.post(planningToolApiUrls.getGroups, { groups: groups }, null, loadingMessage, successMessage, errorMessage);
    },
    deleteGroups: async (ids: any, loadingMessage: string | null = null, successMessage: string | null = null, errorMessage: string | null = null) => {
        const request = new basicRequest();
        return await request.delete(planningToolApiUrls.getGroups, { groupIds: ids }, null, loadingMessage, successMessage, errorMessage);
    }
}