import React, { useContext, useEffect, useRef, useState } from "react";
import { IUserContext, UserContext } from "../../context/UserContext";
import { dashboardService } from "../../api/dashboardService/dashboardService";
import { getBftOrdersReceivedPercent, getObjectBftPercents } from "../../utils/functions/dashboardHelpers";
import { Bft } from "../../types/dashboard";
import { IPlanningToolContext, PlanningToolContext } from "../../context/PlanningToolContext";
import { toast } from "react-toastify";
import ReactDatePicker from "react-datepicker";

export type PortalStatistic = {
    PTOrderCount: number;
    PTOrderVolume: number;
    PTOrderWeight: number;
    RPAOrderCount: number;
    RPAOrderVolume: number;
    RPAOrderWeight: number;
    bothDeclaredVolume: number;
    bothDeclaredWeight: number;
    bothOrderCount: number;
    businessFlowType: "Inbound Return" | "Inbound Production";
    businessFlowTypeId: number;
    tradingPartner: string;
    tradingPartnerId: number;
}

export type ComputedPortalStatistic = {
    tradingPartner: {
        name: string;
        id: number;
    };
    inboundReturn: null | Bft;
    inboundProduction: null | Bft;
}

const PlanningToolDashboard: React.FC = () => {
    const { actualUser } = useContext(UserContext) as IUserContext;

    const {
        setIsOpenSidebarMissingOrders,
        setActiveBftId,
        setActiveTradingPartnerId,
        activeDashboardDate,
        setActiveDashboardDate
    } = useContext(PlanningToolContext) as IPlanningToolContext;

    const [openDatePicker, setOpenDatePicker] = useState<boolean>(false);
    const [loading, setLoading] = useState(true);

    const [data, setData] = useState<ComputedPortalStatistic[]>([]);
    useEffect(() => {
        getSetPortalStats(activeDashboardDate);
    }, [activeDashboardDate]);

    const getSetPortalStats = async (selectedDatetime: Date) => {
        setLoading(true);
        const response = await dashboardService.getPortalStatistics(selectedDatetime);
        if (response.success) {
            const responseData: PortalStatistic[] = response.data;
            const computedData: ComputedPortalStatistic[] = [];
            for (let index = 0; index < responseData.length; index++) {
                const element = responseData[index];

                const indexFoundTradingPartner = computedData.findIndex(e => e.tradingPartner.id === element.tradingPartnerId);
                if (indexFoundTradingPartner !== null && indexFoundTradingPartner !== undefined && indexFoundTradingPartner !== -1) {
                    const type = element.businessFlowType === "Inbound Production" ? 'inboundProduction' : 'inboundReturn'
                    computedData[indexFoundTradingPartner][type] = {
                        id: element.businessFlowTypeId,
                        PTOrderCount: element.PTOrderCount,
                        PTOrderVolume: element.PTOrderVolume,
                        PTOrderWeight: element.PTOrderWeight,
                        RPAOrderCount: element.RPAOrderCount,
                        RPAOrderVolume: element.RPAOrderVolume,
                        RPAOrderWeight: element.RPAOrderWeight,
                        bothDeclaredVolume: element.bothDeclaredVolume,
                        bothDeclaredWeight: element.bothDeclaredWeight,
                        bothOrderCount: element.bothOrderCount,
                    }
                } else {
                    const newDataBft = {
                        id: element.businessFlowTypeId,
                        PTOrderCount: element.PTOrderCount,
                        PTOrderVolume: element.PTOrderVolume,
                        PTOrderWeight: element.PTOrderWeight,
                        RPAOrderCount: element.RPAOrderCount,
                        RPAOrderVolume: element.RPAOrderVolume,
                        RPAOrderWeight: element.RPAOrderWeight,
                        bothDeclaredVolume: element.bothDeclaredVolume,
                        bothDeclaredWeight: element.bothDeclaredWeight,
                        bothOrderCount: element.bothOrderCount,
                    };

                    computedData.push({
                        tradingPartner: {
                            id: element.tradingPartnerId,
                            name: element.tradingPartner
                        },
                        inboundReturn: element.businessFlowType === "Inbound Production" ? null : newDataBft,
                        inboundProduction: element.businessFlowType === "Inbound Return" ? null : newDataBft
                    });
                }
            }

            setData(computedData);
        } else {
            toast.error('Error retrieving data');
        }

        setLoading(false);
    }

    const getProgressWidthPercent = (row: ComputedPortalStatistic) => {
        return {
            inboundReturn: getObjectBftPercents(row.inboundReturn),
            inboundProduction: getObjectBftPercents(row.inboundProduction)
        }
    }

    const getOrdersReceivedPercent = (row: ComputedPortalStatistic) => {
        return {
            inboundReturn: getBftOrdersReceivedPercent(row.inboundReturn),
            inboundProduction: getBftOrdersReceivedPercent(row.inboundProduction)
        }
    }

    const handleGetMissingOrders = (bftId: number | undefined, tradingPartnerId: number) => {
        if (!!bftId && tradingPartnerId) {
            setIsOpenSidebarMissingOrders(true);
            setActiveBftId(bftId);
            setActiveTradingPartnerId(tradingPartnerId);
        }
    }

    return (
        <div className="w-full mb-6 bg-white p-[32px]">
            <div className="mb-6 flex items-end justify-between">
                <div>
                    <h1 className="mb-1 text-[40px] font-bold leading-[56px]">Hello {actualUser?.name}</h1>
                    <h3 className="text-[18px] leading-[25px]">This is the status of the orders:</h3>
                </div>

                <div>
                    <label className="relative">
                        <ReactDatePicker
                            selected={activeDashboardDate}
                            onFocus={() => setOpenDatePicker(true)}
                            onBlur={() => setOpenDatePicker(false)}
                            onChange={(e: Date) => { setActiveDashboardDate(e); setOpenDatePicker(false) }}
                            dateFormat={"dd/MM/yyyy"}
                            open={openDatePicker}
                            popperPlacement="bottom"
                            maxDate={new Date}
                        />
                    </label>
                </div>
            </div>

            {
                !loading &&
                <div className="text-[12px] leading-[16px] font-medium">
                    <div className="grid grid-cols-12 gap-[10px] text-darkGrey uppercase border-top border-bottom py-2">
                        <div className="col-span-2">Trading Partner</div>
                        <div className="col-span-5">Orders</div>
                        <div className="col-span-2">% Orders Received</div>
                        <div className="col-span-1">N° Orders</div>
                        <div className="col-span-1">Weight</div>
                        <div className="col-span-1">Volume</div>
                    </div>
                    <div>
                        {
                            data.map((row, index) => {
                                const progressWidth = getProgressWidthPercent(row);
                                const ordersReceivedPercents = getOrdersReceivedPercent(row);
                                return (
                                    <div key={index} className="grid grid-cols-12 gap-[10px] text-darkGrey uppercase border-top border-bottom py-4">
                                        <div className="col-span-2 font-bold text-[18px] leading-[24px] text-black">{row.tradingPartner.name}</div>
                                        <div className="col-span-10">
                                            {/* Inbound Production */}
                                            {
                                                row.inboundProduction !== null && progressWidth.inboundProduction !== null &&
                                                <div style={{ marginBottom: row.inboundReturn !== null ? 20 : 0 }} className="w-full grid grid-cols-10 gap-[10px]">
                                                    {/* PROGRESS */}
                                                    <div className="col-span-5 uppercase">
                                                        <div className="flex items-center h-full">
                                                            <div className="uppercase w-[20%] max-w-[75px] text-[12px] leading-[11px]">Inbound Production</div>
                                                            <div className="w-[80%]">
                                                                <div className="flex items-center mb-[1px]">
                                                                    <div className="w-[130px] mr-2 text-[13px] leading-[17px] uppercase text-black text-right">Customer Portal</div>
                                                                    <div className="grow">
                                                                        <div style={{ width: progressWidth.inboundProduction["RPAOrderCount"].toString() + '%' }} className="bg-[#efb295] h-[18px] rounded-tr-[13px] rounded-br-[13px] pr-[4px]"></div>
                                                                    </div>
                                                                </div>
                                                                <div className="flex items-center mb-[1px]">
                                                                    <div className="w-[130px] mr-2 text-[13px] leading-[17px] uppercase text-black text-right">Both</div>
                                                                    <div className="grow">
                                                                        <div style={{ width: progressWidth.inboundProduction["bothOrderCount"].toString() + '%' }} className="bg-[#f4cbb8] h-[18px] rounded-tr-[13px] rounded-br-[13px] pr-[4px]"></div>
                                                                    </div>
                                                                </div>
                                                                <div className="flex items-center mb-[1px]">
                                                                    <div className="w-[130px] mr-2 text-[13px] leading-[17px] uppercase text-black text-right">Planning Tool</div>
                                                                    <div className="grow">
                                                                        <div style={{ width: progressWidth.inboundProduction["PTOrderCount"].toString() + '%' }} className="bg-yellow h-[18px] rounded-tr-[13px] rounded-br-[13px] pr-[4px]"></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* % ORDERS RECEIVED */}
                                                    <div className="col-span-2">
                                                        <div className="flex items-center h-full">
                                                            <div className="flex items-center">
                                                                <div className="flex items-center">
                                                                    <div className={"h-[20px] w-[20px] rounded-[10px] mr-1 " + (row.inboundProduction["RPAOrderCount"] !== 0 && (row.inboundProduction["RPAOrderCount"] === row.inboundProduction["bothOrderCount"]) ? "bg-[#e9e8e8]" : "bg-red")}></div>
                                                                    <div className={"h-[20px] w-[20px] rounded-[10px] " + ((row.inboundProduction["RPAOrderCount"] !== 0 ? ((row.inboundProduction["RPAOrderCount"] > row.inboundProduction["bothOrderCount"]) ? "bg-[#e9e8e8]" : "bg-green") : "bg-[#e9e8e8]"))}></div>
                                                                </div>
                                                                <div className="grow pl-5 font-bold text-[18px] leading-[24px] text-black">{ordersReceivedPercents.inboundProduction?.toFixed(2)}%</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* N. ORDERS */}
                                                    <div className="col-span-1">
                                                        <div className="flex items-center h-full">
                                                            <div className="text-[14px] text-black leading-[18px] normal-case">
                                                                <div>{row.inboundProduction.RPAOrderCount}</div>
                                                                <div className={" " + ((row.inboundProduction["RPAOrderCount"] !== 0 && ((row.inboundProduction["RPAOrderCount"] > row.inboundProduction["bothOrderCount"]) ? "text-red" : "")))}>{row.inboundProduction.bothOrderCount}</div>
                                                                <div>{row.inboundProduction.PTOrderCount}</div>
                                                            </div>
                                                            {
                                                                (row.inboundProduction["RPAOrderCount"] !== 0 && (row.inboundProduction["RPAOrderCount"] > row.inboundProduction["bothOrderCount"])) &&
                                                                <div onClick={() => handleGetMissingOrders(row.inboundProduction?.id, row.tradingPartner.id)} className="ml-[30px] cursor-pointer">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="#cf0a2c" className="w-6 h-6">
                                                                        <path strokeLinecap="round" strokeLinejoin="round" d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z" />
                                                                    </svg>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                    {/* VOLUME */}
                                                    <div className="col-span-1">
                                                        <div className="flex items-center h-full">
                                                            <div className="text-[14px] text-black leading-[18px] normal-case">
                                                                <div>{row.inboundProduction.RPAOrderWeight.toFixed(2)} kg</div>
                                                                <div className={" " + ((row.inboundProduction["RPAOrderCount"] !== 0 && ((row.inboundProduction["RPAOrderCount"] > row.inboundProduction["bothOrderCount"]) ? "text-red" : "")))}>{row.inboundProduction.bothDeclaredWeight.toFixed(2)} kg</div>
                                                                <div>{row.inboundProduction.PTOrderWeight.toFixed(2)} kg</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* WEIGHT */}
                                                    <div className="col-span-1">
                                                        <div className="flex items-center h-full">
                                                            <div className="text-[14px] text-black leading-[18px] normal-case">
                                                                <div>{row.inboundProduction.RPAOrderVolume.toFixed(2)} m³</div>
                                                                <div>{row.inboundProduction.bothDeclaredVolume.toFixed(2)} m³</div>
                                                                <div>{row.inboundProduction.PTOrderVolume.toFixed(2)} m³</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }

                                            {/* Inbound Return */}
                                            {
                                                row.inboundReturn !== null && progressWidth.inboundReturn !== null &&
                                                <div className="w-full grid grid-cols-10 gap-[10px]">
                                                    {/* PROGRESS */}
                                                    <div className="col-span-5">
                                                        <div className="flex items-center h-full w-full">
                                                            <div className="uppercase w-[20%] max-w-[75px] text-[12px] leading-[11px]">Inbound Returns</div>
                                                            <div className="w-[80%]">
                                                                <div className="flex items-center mb-[1px]">
                                                                    <div className="w-[130px] mr-2 text-[13px] leading-[17px] uppercase text-black text-right">Customer Portal</div>
                                                                    <div className="grow">
                                                                        <div style={{ width: progressWidth.inboundReturn["RPAOrderCount"].toString() + '%' }} className="bg-[#b3c7f7] h-[18px] rounded-tr-[13px] rounded-br-[13px] pr-[4px]"></div>
                                                                    </div>
                                                                </div>
                                                                <div className="flex items-center mb-[1px]">
                                                                    <div className="w-[130px] mr-2 text-[13px] leading-[17px] uppercase text-black text-right">Both</div>
                                                                    <div className="grow">
                                                                        <div style={{ width: progressWidth.inboundReturn["bothOrderCount"].toString() + '%' }} className="bg-[#d9e4ff] h-[18px] rounded-tr-[13px] rounded-br-[13px] pr-[4px]"></div>
                                                                    </div>
                                                                </div>
                                                                <div className="flex items-center mb-[1px]">
                                                                    <div className="w-[130px] mr-2 text-[13px] leading-[17px] uppercase text-black text-right">Planning Tool</div>
                                                                    <div className="grow">
                                                                        <div style={{ width: progressWidth.inboundReturn["PTOrderCount"].toString() + '%' }} className="bg-yellow h-[18px] rounded-tr-[13px] rounded-br-[13px] pr-[4px]"></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* % ORDERS RECEIVED */}
                                                    <div className="col-span-2">
                                                        <div className="flex items-center h-full w-full">
                                                            <div className="flex items-center">
                                                                <div className={"h-[20px] w-[20px] rounded-[10px] mr-1 " + (row.inboundReturn["RPAOrderCount"] !== 0 && (row.inboundReturn["RPAOrderCount"] === row.inboundReturn["bothOrderCount"]) ? "bg-[#e9e8e8]" : "bg-red")}></div>
                                                                <div className={"h-[20px] w-[20px] rounded-[10px] " + (row.inboundReturn["RPAOrderCount"] !== 0 ? ((row.inboundReturn["RPAOrderCount"] > row.inboundReturn["bothOrderCount"]) ? "bg-[#e9e8e8]" : "bg-green") : "bg-[#e9e8e8]")}></div>
                                                            </div>
                                                            <div className="grow pl-5 font-bold text-[18px] leading-[24px] text-black">{ordersReceivedPercents.inboundReturn?.toFixed(2)}%</div>
                                                        </div>
                                                    </div>
                                                    {/* N. ORDERS */}
                                                    <div className="col-span-1">
                                                        <div className="flex items-center h-full">
                                                            <div className="text-[14px] text-black leading-[18px]">
                                                                <div>{row.inboundReturn.RPAOrderCount}</div>
                                                                <div className={" " + ((row.inboundReturn["RPAOrderCount"] !== 0 && ((row.inboundReturn["RPAOrderCount"] > row.inboundReturn["bothOrderCount"]) ? "text-red" : "")))}>{row.inboundReturn.bothOrderCount}</div>
                                                                <div>{row.inboundReturn.PTOrderCount}</div>
                                                            </div>

                                                            {
                                                                (row.inboundReturn["RPAOrderCount"] !== 0 && (row.inboundReturn["RPAOrderCount"] > row.inboundReturn["bothOrderCount"])) &&
                                                                <div onClick={() => handleGetMissingOrders(row.inboundReturn?.id, row.tradingPartner.id)} className="ml-[30px] cursor-pointer">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="#cf0a2c" className="w-6 h-6">
                                                                        <path strokeLinecap="round" strokeLinejoin="round" d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z" />
                                                                    </svg>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                    {/* VOLUME */}
                                                    <div className="col-span-1">
                                                        <div className="flex items-center h-full">
                                                            <div className="text-[14px] text-black leading-[18px] normal-case">
                                                                <div>{row.inboundReturn.RPAOrderWeight} kg</div>
                                                                <div className={" " + ((row.inboundReturn["RPAOrderCount"] !== 0 && ((row.inboundReturn["RPAOrderCount"] > row.inboundReturn["bothOrderCount"]) ? "text-red" : "")))}>{row.inboundReturn.bothDeclaredWeight.toFixed(2)} kg</div>
                                                                <div>{row.inboundReturn.PTOrderWeight.toFixed(2)} kg</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* WEIGHT */}
                                                    <div className="col-span-1">
                                                        <div className="flex items-center h-full">
                                                            <div className="text-[14px] text-black leading-[18px] normal-case">
                                                                <div>{row.inboundReturn.RPAOrderVolume.toFixed(2)} m³</div>
                                                                <div>{row.inboundReturn.bothDeclaredVolume.toFixed(2)} m³</div>
                                                                <div>{row.inboundReturn.PTOrderVolume.toFixed(2)} m³</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                );
                            })
                        }
                    </div>
                </div>
            }

            {
                !!loading &&
                <div>Loading data...</div>
            }
        </div>
    )
}

export default PlanningToolDashboard;