import { LegPlanningTypesEnum, LegStatusEnum } from "../types/leg";
import { IOrder, Leg } from "../types/orders";

export const distinct: (array: any[]) => any[] = (array: any[]) => {
    return [...new Set(array)];
};

export class PlanningElementActionResult {
    constructor(public result: boolean, public causes?: string[] | undefined) {
        this.result = result;
        this.causes = !!causes ? causes : [];
    }
};

// #region DEPOSITS
export const isDepositOpacited = (leg: Leg, order: IOrder, groupsInCharge: number[] | null, isTriagePage: boolean = false, canEdit: boolean = true): PlanningElementActionResult => {
    let result: PlanningElementActionResult = new PlanningElementActionResult(false);
    
    if (!canEdit) {
        return result;
    }

    if (isTriagePage) {
        result.result = true;
        result.causes?.push("You are in Triage Page");
    }

    let orderLegs = order.legs;
    let currentLeg = orderLegs.find(x => x.id === leg.id);

    let prevLegOrderPosition = orderLegs.filter(ol => !!currentLeg?.orderPosition && ol.orderPosition < currentLeg?.orderPosition).sort((a, b) => b.orderPosition - a.orderPosition)[0]?.orderPosition;
    let prevLeg = prevLegOrderPosition !== undefined ? orderLegs.find(x => !!currentLeg?.orderPosition && x.orderPosition === prevLegOrderPosition) : undefined;

    if ((!currentLeg?.plannable || (groupsInCharge !== null && !groupsInCharge.includes(currentLeg.groupId))) && (!prevLeg?.plannable || (groupsInCharge !== null && !groupsInCharge.includes(prevLeg.groupId)))) {
        result.result = true;
        result.causes?.push("(Current Leg not plannable or not in this group) AND (Previous Leg not plannable or not in this group)");
    }

    if (currentLeg?.planned || prevLeg?.planned) {
        result.result = true;
        result.causes?.push("Current Leg or Previous Leg is already planned");
    }

    if (order.dropped) {
        result.result = true;
        result.causes?.push("Order is dropped");
    }

    return result;
};

export const isPlannedDeposit = (leg: Leg, order: IOrder): PlanningElementActionResult => {
    let result: PlanningElementActionResult = new PlanningElementActionResult(false);

    let orderLegs = order.legs;
    let currentLeg = orderLegs.find(x => x.id === leg.id);

    let prevLegOrderPosition = orderLegs.filter(ol => !!currentLeg?.orderPosition && ol.orderPosition < currentLeg?.orderPosition).sort((a, b) => b.orderPosition - a.orderPosition)[0]?.orderPosition;
    let prevLeg = prevLegOrderPosition !== undefined ? orderLegs.find(x => !!currentLeg?.orderPosition && x.orderPosition === prevLegOrderPosition) : undefined;

    if (currentLeg !== undefined) {
        if (result.result || isPlannedLeg(currentLeg).result) {
            result.result = true;
            result.causes?.push("Current Leg is already planned");
        } else {
            result.result = false;
        }
    }

    if (prevLeg !== undefined) {
        if (result.result || isPlannedLeg(prevLeg).result) {
            result.result = true;
            result.causes?.push("Previous Leg is already planned");
        } else {
            result.result = false;
        }
    }

    return result;
};

export const isConfirmedDeposit = (leg: Leg, order: IOrder): PlanningElementActionResult => {
    let result: PlanningElementActionResult = new PlanningElementActionResult(false);

    let orderLegs = order.legs;
    let currentLeg = orderLegs.find(x => x.id === leg.id);

    let prevLegOrderPosition = orderLegs.filter(ol => !!currentLeg?.orderPosition && ol.orderPosition < currentLeg?.orderPosition).sort((a, b) => b.orderPosition - a.orderPosition)[0]?.orderPosition;
    let prevLeg = prevLegOrderPosition !== undefined ? orderLegs.find(x => !!currentLeg?.orderPosition && x.orderPosition === prevLegOrderPosition) : undefined;

    if (currentLeg !== undefined) {
        if (result.result || isConfirmedLeg(currentLeg).result) {
            result.result = true;
            result.causes?.push("Current Leg is already confirmed");
        } else {
            result.result = false;
        }
    }

    if (prevLeg !== undefined) {
        if (result.result || isConfirmedLeg(prevLeg).result) {
            result.result = true;
            result.causes?.push("Previous Leg is already confirmed");
        } else {
            result.result = false;
        }
    }

    return result;
};

export const isDepositSelectable = (leg: Leg, order: IOrder, groupsInCharge: number[] | null, isTriagePage: boolean = false, canEdit: boolean = true): PlanningElementActionResult => {
    let result: PlanningElementActionResult = new PlanningElementActionResult(true);
    
    if (!canEdit) {
        result.result = false;
        result.causes?.push("You are not enabled to edit depots");
        return result;
    }

    if (isTriagePage) {
        result.result = false;
        result.causes?.push("You are in Triage Page");
        return result;
    }

    if (order.dropped) {
        result.result = false;
        result.causes?.push("Order is dropped");
        return result;
    }

    let orderLegs = order.legs;
    let currentLeg = orderLegs.find(x => x.id === leg.id);

    let prevLegOrderPosition = orderLegs.filter(ol => !!currentLeg?.orderPosition && ol.orderPosition < currentLeg?.orderPosition).sort((a, b) => b.orderPosition - a.orderPosition)[0]?.orderPosition;
    let prevLeg = prevLegOrderPosition !== undefined ? orderLegs.find(x => !!currentLeg?.orderPosition && x.orderPosition === prevLegOrderPosition) : undefined;

    if ((!currentLeg?.plannable || (groupsInCharge !== null && !groupsInCharge.includes(currentLeg.groupId))) && (!prevLeg?.plannable || (groupsInCharge !== null && !groupsInCharge.includes(prevLeg.groupId)))) {
        result.result = false;
        result.causes?.push("(Current Leg not plannable or not in this group) AND (Previous Leg not plannable or not in this group)");
        return result;
    }

    if (currentLeg?.planned || prevLeg?.planned) {
        result.result = false;
        result.causes?.push("Current Leg or Previous Leg is already planned");
        return result;
    }

    if (currentLeg?.trip !== null || prevLeg?.trip !== null) {
        result.result = false;
        result.causes?.push("Current Leg or Previous Leg is already in a trip");
        return result;
    }

    if (order.dropped) {
        result.result = false;
        result.causes?.push("Order is dropped");
        return result;
    }

    if (currentLeg !== undefined) {
        if (result.result && !isPlannedLeg(currentLeg).result) {
            result.result = true;
        } else {
            result.result = false;
            result.causes?.push("Current Leg is already planned");
        }
    }

    if (prevLeg !== undefined) {
        if (result.result && !isPlannedLeg(prevLeg).result) {
            result.result = true;
        } else {
            result.result = false;
            result.causes?.push("Previous Leg is already planned");
        }
    }    

    return result;
};


// #region LEGS
export const isPlannedLeg = (leg: Leg): PlanningElementActionResult => {
    let result: PlanningElementActionResult = new PlanningElementActionResult(false);

    if (leg.planningType.id === LegPlanningTypesEnum.Network || leg.planningType.id === LegPlanningTypesEnum.StandBy) {
        result.result = true;
        result.causes?.push("Leg is Network or StandBy");
    }

    if (leg.trip !== null) {
        result.result = true;
        result.causes?.push("Leg is in a trip");
    }

    return result;
};

export const isLegOpacited = (leg: Leg, order: IOrder, canEdit: boolean, activeTripId: number | null | undefined, isTriagePage: boolean, groupsInCharge: number[] | null): PlanningElementActionResult => {
    let result: PlanningElementActionResult = new PlanningElementActionResult(false);
    
    if (!canEdit) {
        return result;
    }

    if (!!activeTripId && !!leg.trip && leg.trip.id !== activeTripId) {
        result.result = true;
        result.causes?.push("Leg is in another trip");
        return result;
    }

    if (isTriagePage) {
        if (leg.groupId === 0) {
            return result;
        } else {
            result.result = true;
            result.causes?.push("Leg is already in a Group");
            return result;
        }
    }

    if (groupsInCharge !== null && !groupsInCharge.includes(leg.groupId)) {
        result.result = true;
        result.causes?.push("Leg is not in the group in charge");
        return result;
    }

    if (leg.plannable === false) {
        result.result = true;
        result.causes?.push("Leg is not plannable");
        return result;
    }

    if (order.dropped) {
        result.result = true;
        result.causes?.push("Order is dropped");
        return result;
    }

    return result;
};

export const isLegSelectable = (leg: Leg, order: IOrder, canEdit: boolean, activeTripId: number | null | undefined, isTriagePage: boolean, groupsInCharge: number[] | null): PlanningElementActionResult => {
    let result: PlanningElementActionResult = new PlanningElementActionResult(true);
    
    if (!canEdit) {
        result.result = false;
        result.causes?.push("You are not enabled to edit legs");
        return result;
    }

    if (!!activeTripId && !!leg.trip && leg.trip.id !== activeTripId) {
        result.result = false;
        result.causes?.push("Leg is in another trip");
        return result;
    }

    if (isTriagePage) {
        if (leg.groupId === 0) {
            result.result = true;
            return result;
        } else {
            result.result = false;
            result.causes?.push("Leg is already in a Group");
            return result;
        }
    }

    if (groupsInCharge !== null && !groupsInCharge.includes(leg.groupId)) {
        result.result = false;
        result.causes?.push("Leg is not in the group in charge");
        return result;
    }

    if (leg.planningType.id === LegPlanningTypesEnum.StandBy || leg.planningType.id === LegPlanningTypesEnum.Network) {
        result.result = false;
        result.causes?.push("Leg is Network or StandBy");
        return result;
    }

    if (leg.plannable === false || leg.planned === true) {
        result.result = false;
        result.causes?.push("Leg is not plannable or is already planned");
        return result;
    }

    if (leg.trip !== null) {
        result.result = false;
        result.causes?.push("Leg is in a trip");
        return result;
    }

    if (order.dropped) {
        result.result = false;
        result.causes?.push("Order is dropped");
        return result;
    }

    return result;
};

export const isConfirmedLeg = (leg: Leg): PlanningElementActionResult => {
    let result: PlanningElementActionResult = new PlanningElementActionResult(false);

    if (leg.planned) {
        result.result = true;
        result.causes?.push("Leg is already planned");
    }

    return result;
};

export const legCanBeDispatchable = (leg: Leg) => {
    return leg.trip !== null || leg.planningType.id === LegPlanningTypesEnum.Trip || leg.planningType.id === LegPlanningTypesEnum.Network || leg.planningType.id === LegPlanningTypesEnum.StandBy;
};

export const isLegInError = (leg: Leg) => {
    return leg.status?.id === LegStatusEnum.ERROR;
}