import React, { useCallback, useContext, useMemo, useRef } from 'react';
import { cutoffService } from '../../api/cutoffService/cutoffService';
import CommonTable, { TableRef } from '../../components/commonTable/CommonTable';
import { IMasterDataContext, MasterDataContext } from '../../context/MasterDataContext';
import dayjs from 'dayjs';
import { ColDef, GridApi, RowNode } from '@ag-grid-community/core';
import Button from '../../components/ui/button/Button';
import { customConfirmAlert } from '../../utils/functions/customConfirmAlert';
import { toast } from 'react-toastify';
import { TimePicker } from 'antd';
import usePermissions from '../../utils/hooks/usePermissions';
import { PermissionAreas, PermissionOperations } from '../../types/permissions';
import AuthorizedComponent from '../../components/permissions/AuthorizedComponent';

const PlanningToolCutoff: React.FC = () => {

    const { hasPermission } = usePermissions();
    const { businessFlowTypes } = useContext(MasterDataContext) as IMasterDataContext;

    // #region State
    const commonTableRef = useRef<TableRef>(null);
    // #endregion

    // #region Memo
    const containerStyle = useMemo(() => ({ width: '100%', height: '100%' }), []);

    const columnDefs = useMemo(() => [
        {
            field: "tradingPartner.name",
            headerName: "Trading Partner",
            editable: false,
            rowGroup: true,
            hide: true
        },
        {
            field: "businessFlowType.name",
            headerName: "Business Flow Type",
            editable: false,
            filter: "agSetColumnFilter",
            filterParams: {
                values: businessFlowTypes.map(x => x.name),
                suppressSelectAll: true
            }
        },
        {
            field: "time",
            headerName: "Time",
            editable: false,
            cellRenderer: (props: { data: any, node: RowNode, api: GridApi }) => {
                if (!props.node.group) {
                    const format = 'HH:mm';
                    let defaultValue = props.data.time !== null ? dayjs(props.data.time, format) : null;
                    return (
                        <>
                            {hasPermission(PermissionAreas.Cutoff, PermissionOperations.Modify) &&
                                <TimePicker
                                    format={format}
                                    defaultValue={defaultValue}
                                    onChange={(value) => handleChangeTime(value, props.node, props.api)}
                                />
                            }

                            {!hasPermission(PermissionAreas.Cutoff, PermissionOperations.Modify) &&
                                <>{props.data.time !== null && <>{defaultValue?.format("HH:mm")}</>}</>
                            }
                        </>
                    )
                }

                return null;
            }
        }
    ], [businessFlowTypes]);

    const autoGroupColumnDef = useMemo<ColDef>(() => {
        return {
            field: 'tradingPartner.name',
            headerName: 'Trading Partner',
            sort: 'asc',
            editable: false
        };
    }, []);
    // #endregion

    // #region UI Handlers
    const handleChangeTime = (value: any, node: RowNode, api: GridApi) => {
        let newValue = value !== null ? value.format("HH:mm:ss") : null;
        node.setDataValue("time", newValue);
        api.redrawRows({ rowNodes: [node] })
    };

    const onClickSaveButton = async () => {
        await confirmSaveConfiguration();
    };
    // #endregion

    // #region Util Functions
    const saveConfiguration = async () => {
        let tableRows = commonTableRef.current?.getTableRows();

        if (tableRows !== undefined && tableRows?.length === 0) {
            toast.info("No configuration to save");
            return;
        }

        if (tableRows?.some(x => !!!x.time)) {
            toast.warning("Please set all cutoff times");
            return;
        }

        const response = await cutoffService.saveCutoffs(tableRows as any, "Saving configuration", "Configuration saved successfully", "Error saving configuration");
        if (response.success) {
            commonTableRef.current?.setUnsavedCells([]);
            setTimeout(function () { commonTableRef.current?.redrawTableRows() }, 0)
        }
    };

    const confirmSaveConfiguration = async () => {
        commonTableRef.current?.setStopEditing();
        const wantToSave = await customConfirmAlert({
            title: "Save configuration",
            message: "Are you sure to save the configuration?"
        });

        if (wantToSave) {
            await saveConfiguration();
        }
    };

    const getCutoffs = async () => {
        commonTableRef.current?.showLoadingOverlay();
        const response = await cutoffService.getCutoffs();
        commonTableRef.current?.hideOverlay();
        if (response.success) {
            commonTableRef.current?.setRowData(response.data);
        } else {
            toast.error('Error retrieving data');
        }
    };
    // #endregion

    // #region CallBack
    const onGridReady = useCallback(() => {
        getCutoffs();
    }, []);
    // #endregion

    return (
        <div className='w-full mb-6 bg-white p-[32px]'>
            <div className="font-light text-lg leading-[25px]">Planning Tool</div>
            <div className="flex items-center pb-3 mb-6 border-b">
                <div className="md:mr-3 text-[32px] font-bold leading-[45px]">
                    Cutoff Configuration
                </div>
            </div>

            <AuthorizedComponent area={PermissionAreas.Cutoff} operation={PermissionOperations.Modify}>
                <div className="mb-6 flex">
                    <Button
                        callback={onClickSaveButton}
                        className="bg-transparent text-black border-[1.4px] border-black"
                    >
                        Save configuration
                    </Button>
                </div>
            </AuthorizedComponent>

            <div style={containerStyle}>
                <CommonTable
                    ref={commonTableRef}
                    columnDefs={columnDefs}
                    autoGroupColumnDef={autoGroupColumnDef}
                    onGridReady={onGridReady}
                    options={{
                        pageSize: 10,
                        editable: hasPermission(PermissionAreas.Cutoff, PermissionOperations.Modify)
                    }}
                />
            </div>
        </div>
    )
}

export default PlanningToolCutoff;