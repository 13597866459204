import React, { useContext, useEffect, useMemo, useRef } from 'react';
import CommonTable, { TableRef } from '../../commonTable/CommonTable';
import { GroupCondition, IGroup } from '../../../types/orders';
import { IMasterDataContext, MasterDataContext } from '../../../context/MasterDataContext';

type Props = {
    conflicts: GroupCondition[];
    allGroups: IGroup[] | undefined;
    callbackCloseModal: () => any;
};

const ModalShowGroupConditionsConflicts: React.FC<Props> = ({
    conflicts,
    allGroups,
    callbackCloseModal
}) => {
    const { businessFlowTypes, departments } = useContext(MasterDataContext) as IMasterDataContext;
    // #region State and Refs
    const commonTableRef = useRef<TableRef>(null);
    // #endregion

    // #region Memo
    const containerStyle = useMemo(() => ({ width: '100%', height: '100%' }), []);

    const columnDefs: any = useMemo(() => [
        {
            field: "customerCode",
            headerName: "CUSTOMER CODE"
        },
        {
            field: "businessFlowTypeId",
            headerName: "BUSINESS FLOW",
            tooltipField: "businessFlowTypeId",
            tooltipComponent: (props: any) => <div className='bg-white'>{businessFlowTypes.find(x => x.id === props.data.businessFlowTypeId)?.name}</div>,
            cellRenderer: (props: { data: GroupCondition }) => {
                return (
                    <>{businessFlowTypes.find(x => x.id === props.data.businessFlowTypeId)?.name}</>
                )
            }
        },
        {
            field: "originCountry",
            headerName: "ORIGIN COUNTRY"
        },
        {
            field: "originPostCodeFrom",
            headerName: "ORIGIN POSTCODE FROM"
        },
        {
            field: "originPostCodeTo",
            headerName: "ORIGIN POSTCODE TO"
        },
        {
            field: "destinationCountry",
            headerName: "DESTINATION COUNTRY"
        },
        {
            field: "destinationPostCodeFrom",
            headerName: "DESTINATION POSTCODE FROM"
        },
        {
            field: "destinationPostCodeTo",
            headerName: "DESTINATION POSTCODE TO"
        },
        {
            field: "departmentId",
            headerName: "DEPARTMENT",
            tooltipField: "groupId",
            tooltipComponent: (props: any) => <div className='bg-white'>{departments.find(x => allGroups?.find(x => x.id === props.data.groupId)?.departmentId === x.id)?.name}</div>,
            cellRenderer: (props: { data: GroupCondition }) => {
                return (
                    <>{departments.find(x => allGroups?.find(x => x.id === props.data.groupId)?.departmentId === x.id)?.name}</>
                )
            }
        },
        {
            field: "groupId",
            headerName: "GROUP",
            tooltipField: "groupId",
            tooltipComponent: (props: any) => <div className='bg-white'>{!!allGroups && allGroups.find(x => x.id === props.data.groupId)?.name}</div>,
            cellRenderer: (props: { data: GroupCondition }) => {
                return (
                    <>{!!allGroups && allGroups.find(x => x.id === props.data.groupId)?.name}</>
                )
            }
        }
    ], [businessFlowTypes, departments, allGroups]);
    // #endregion

    // #region Util Functions
    
    // #endregion

    // #region Use Effects
    useEffect(() => {
        let insertionRow: any = conflicts.find(x => x.id === null);
        if (!!insertionRow) {
            insertionRow.isHighligthed = true;
        }
        
        commonTableRef.current?.setTableDataState(conflicts);
    }, [conflicts]);
    // #endregion

    return (
        <div style={{ zIndex: 9999 }} className="fixed top-0 left-0 h-screen w-screen bg-[#00000080] flex items-center justify-center">
            <div className="bg-white max-h-[75%] w-[95%] lg:w-[80%] max-w-[1920px] flex flex-col">
                <div className="shrink-0 flex justify-between border-b p-[30px]">
                    <div className="text-lg font-bold">
                        Conflict rows
                    </div>
                    <div onClick={callbackCloseModal} className="cursor-pointer">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-8 h-8">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </div>
                </div>

                {/* MAIN CONTENT */}
                <div className="grow py-8 px-[30px] select-none overflow-auto relative">
                    <div className="mt-5" style={containerStyle}>
                        <CommonTable
                            ref={commonTableRef}
                            columnDefs={columnDefs}
                            options={{
                                showRowsNumber: false,
                                filter: false,
                                floatingFilter: false,
                                editable: false,
                                pageSize: 10
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
};

export default ModalShowGroupConditionsConflicts;