import { planningToolApiUrls } from "../../configs/planningToolApiUrls";
import basicRequest from "../basicRequest";

export const cutoffService = {
    getCutoffs: async (tradingPartnerId: number | null = null, bftId: number | null = null) => {
        const request = new basicRequest();
        return await request.get(planningToolApiUrls.cutoff, {
            tradingPartner: tradingPartnerId,
            bftId: bftId
        });
    },
    saveCutoffs: async (configuration: any, loadingMessage: string | null = null, successMessage: string | null = null, errorMessage: string | null = null) => {
        const request = new basicRequest();
        return await request.put(planningToolApiUrls.cutoff, configuration, null, loadingMessage, successMessage, errorMessage);
    },
}