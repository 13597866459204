import React from "react";

type Props = {
    label?: string | undefined;
    type?: string,
    placeholder: string;
    value: string;
    name: string;
    callbackOnChange: (e: any) => any;
    callbackOnFocus?: () => any;
    callbackOnBlur?: () => any;
    containerClass?: string;
    hasError?: boolean;
    errorMessage?: string;
    disabled?: boolean;
    isRequired?: boolean;
    pattern?: string;
}

const InputText: React.FC<Props> = ({
    label = '',
    type = 'text',
    placeholder,
    value,
    name,
    callbackOnChange,
    callbackOnFocus,
    callbackOnBlur,
    containerClass,
    hasError = false,
    errorMessage,
    disabled = false,
    isRequired = false,
    pattern = ""
}) => {

    const handleChange = (e: any) => {
        if (callbackOnChange && !disabled) {
            callbackOnChange(e.target.value);
        }
    }

    const handleFocus = () => {
        if (callbackOnFocus) {
            callbackOnFocus();
        }
    }

    const handleBlur = () => {
        if (callbackOnBlur) {
            callbackOnBlur();
        }
    }

    return (
        <div className={containerClass}>
            {
                !!label &&
                <label
                    className="font-semibold text-sm text-blue uppercase leading-[17px] block mb-[5px]"
                    htmlFor={name}
                >
                    {label}

                    {
                        isRequired &&
                        <span className="text-red ml-1">*</span>
                    }
                </label>
            }

            <input
                className={"input-text outline-none leading-[18px] text-black text-[15px] rounded-[8px] p-[10px] w-full" + (hasError ? ' border-[1px] !border-red' : ' border border-[#6F6F6F]') + (!!disabled ? ' cursor-not-allowed bg-[#fafafa]' : ' bg-white')}
                type={type}
                placeholder={placeholder}
                value={value}
                name={name}
                id={name}
                onChange={handleChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                disabled={disabled}
                required={isRequired}
                pattern={pattern}
            />

            {
                hasError && !!errorMessage &&
                <div className="text-xs text-red font-semibold mt-1">
                    {errorMessage}
                </div>
            }
        </div>
    );
}

export default InputText;