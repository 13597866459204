import React from "react";
import { IOrder } from "../../../types/orders";
import { TradingPartnersEnum } from "../../../types/tradingPartners";

const TableTooltipOrderNumbers: React.FC<{ order: IOrder }> = ({ order }) => {
    return (
        <div className="bg-white shadow-banner p-2 min-w-[80px] rounded-[4px]">
            {
                !!order.orderNumbers && order.orderNumbers.map((oN, j) => {
                    return (
                        <div className="mb-1" style={{ lineHeight: '12px' }} key={j}>
                            Order Number: {oN} - TradingPartner: {Object.keys(TradingPartnersEnum)[Object.values(TradingPartnersEnum).indexOf(order.tradingPartnerId)]} - CustomerCode: {order.tmsCustomerCode}
                        </div>
                    )
                })
            }
        </div>
    )
}

export default TableTooltipOrderNumbers;