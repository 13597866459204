import { ITrip } from "./trips";
import { IUser } from "./users";

export enum PushMessagesTypes {
    NEW_ORDERS = "NEW_ORDERS",
    USER_CHARGE_COUNTER = "USER_CHARGE_COUNTER",
    TRIAGE_COUNTER = "TRIAGE_COUNTER",
    UPDATED_TRIP = "UPDATED_TRIP",
    INSTANT_LOG = "INSTANT_LOG"
};

export interface IPushMessage {
    type: string
};

// #region INewOrdersMessage
export interface INewOrdersMessage extends IPushMessage {
    infos: INewOrdersMessageInfo[],
    sentTime: Date
};

export interface INewOrdersMessageInfo {
    groupId: number,
    orderIds: number[]
};

export class NewOrdersAlertInfo {
    constructor(public show: boolean, public time: Date, public number: number, public orderIds: number[]) {
        this.show = show;
        this.time = time;
        this.number = number;
        this.orderIds = orderIds;
    }
};
// #endregion

// #region IUserChargeCounterMessage
export interface IUserChargeCounterMessage extends IPushMessage {
    groupCounter: number,
    tripCounter: number
};
// #endregion

// #region ITriageCounterMessage
export interface ITriageCounterMessage extends IPushMessage {
    routing: number,
    group: number
};
// #endregion

// #region IUpdatedTripMessage
export interface IUpdatedTripMessage extends IPushMessage {
    trips: ITrip[]
};
// #endregion

// #region IInstantLogMessage
export interface IInstantLogMessage extends IPushMessage {
    sentTime: Date,
    user: IUser,
    message: string
};
// #endregion