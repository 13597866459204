import React from 'react';

export const pagesLinks = {
  dashboardRootTitle: 'dashboard',
  listRootTitle: 'listpage',
  detailRootTitle: 'detail',
  bookmarksRootTitle: 'bookmarks',
  analyticsRootTitle: 'analytics',
  searchResultsRootTitle: 'search-results',
  simpleSearchResultsRootTitle: 'simple-search-results',
  settingsRootTitle: 'settings',
  tripDetailsRootTitle: 'trip',
  orderDetailsRootTitle: 'order',
  orderCustomerDetailsRootTitle: 'order-customer',
  salesOrderDetailsRootTitle: 'so',

  // TODO: delete? ------------
  viewAllAnomaliesGrid: '/listpage/anomalies',
  viewAllWarningGrid: '/listpage/warnings',
  viewAllUnfilteredWarningGrid: '/listpage/allwarnings',
  viewAllTOsPickedupGrid: '/listpage/tospickedup',
  viewAllTOsDeliveredGrid: '/listpage/tosdelivered',
  viewAllTOsDeliveredDelayGrid: '/listpage/tosdelivereddelay',
  viewAllAnomaliesReportedGrid: '/listpage/anomaliesreported',
  viewAllTOsNotDeliveredGridWithEvents: '/listpage/tosnotdeliveredwithevents',
  viewAllTOsNotDeliveredGridWithoutEvents: '/listpage/tosnotdeliveredwithoutevents',
  viewAllTOsNotPickedupGrid: '/listpage/tosnotpickedup',
  // --------------------------

  eventMonitoring: '/listpage/eventmonitoring',
  orderVariance: '/listpage/ordvariance',
  dashboard: '/dashboard',
  SODetailPage: '/detail/SO',
  TODetailPage: '/detail/TO',
  TripDetailPage: '/detail/TRIP',

  newTripDetailsPage: '/trip',
  newOrderDetailsPage: '/order',
  newOrderCustomerDetailsPage: '/order-customer',
  newSODetailsPage: '/so',

  favourites: "/bookmarks",
  settings: "/settings",

  // view all grids
  viewAllOngoingTripsGrid: '/listpage/trips',
  viewAllTOsExecutionGrid: '/listpage/tosexecution',
  viewAllTOsCustomerGrid: '/listpage/toscustomer',
  viewAllSalesOrdersGrid: '/listpage/sos',
  viewAllStoragesGrid: '/listpage/storages',
  // tos cards
  viewAllOrdersToBeCollectedGrid: '/listpage/tostobecollected',
  viewAllOrdersWithoutCollectionLegGrid: '/listpage/toswithoutcollectionleg',
  viewAllCollectionInProgressGrid: '/listpage/toscollectioninprogress',
  viewAllOrdersToBeDeliveredGrid: '/listpage/tostobedelivered',
  viewAllCollectedOrdersWithoutDMIGrid: '/listpage/toscollectedorderswithoutdmi',
  viewAllOrdersInXdockGrid: '/listpage/tosinxdock',
  viewAllOutForCollectionGrid: '/listpage/tosoutforcollection',
  viewAllOutForDeliveryGrid: '/listpage/tosoutfordelivery',
  viewAllMissedCollectionsGrid: '/listpage/tosmissedcollections',
  viewAllOrdersWithoutDeliveryLegGrid: '/listpage/toswithoutdeliveryleg',
  viewAllMissedDeliveriesGrid: '/listpage/tosmisseddeliveries',
  viewAllPartialDeliveriesGrid: '/listpage/tospartialdeliveries',
  viewAllOrdersInTransitGrid: '/listpage/tosintransit',
  viewAllOrdersWithoutDataEntryGrid: '/listpage/toswithoutdataentry',
  // trips cards
  viewAllActiveTripGrid: '/listpage/tripsall',
  viewAllBookingGrid: '/listpage/tripsbooking',
  viewAllInTransitGrid: '/listpage/tripsintransit',
  viewAllAtLoadingUnloadingPlaceGrid: '/listpage/tripsatloadingunloadingplace',
  viewAllGPSGrid: '/listpage/tripsgps',
  viewAllNOGPSGrid: '/listpage/tripsnogps',

};

export const GetDummyBreadcrumbs = function (locationPath, navigatedMenu) {
  if (locationPath.includes(pagesLinks.tripDetailsRootTitle) ||
    locationPath.includes(pagesLinks.viewAllOngoingTripsGrid))
    return "Trips";
  else if (locationPath.includes(pagesLinks.orderDetailsRootTitle) ||
    locationPath.includes(pagesLinks.viewAllTOsExecutionGrid) ||
    locationPath.includes(pagesLinks.viewAllSalesOrdersGrid))
    return "Orders";
  else if (locationPath.includes(pagesLinks.orderCustomerDetailsRootTitle) ||
    locationPath.includes(pagesLinks.viewAllTOsCustomerGrid))
    return "Orders Customer";
  else if (locationPath.indexOf(pagesLinks.detailRootTitle) > 0 ||
    locationPath.indexOf(pagesLinks.searchResultsRootTitle) > 0 ||
    locationPath.indexOf(pagesLinks.simpleSearchResultsRootTitle) > 0)
    return "Search results";

  else if (locationPath.indexOf(pagesLinks.bookmarksRootTitle) > 0)
    return "Bookmarks";

  else if (locationPath.indexOf(pagesLinks.viewAllUnfilteredWarningGrid) >= 0)
    return "Warnings";

  else if (locationPath.indexOf(pagesLinks.analyticsRootTitle) > 0)
    return "Analytics / " + navigatedMenu;

  else if (locationPath.indexOf(pagesLinks.settingsRootTitle) > 0)
    return "Settings";
  else if (locationPath.indexOf(pagesLinks.dashboardRootTitle) > 0)
    return "Dashboard";

  return "Homepage";
};

// gestisce il bottone ritorno indietro di tot pagine
export const parsePath = function (path, title) {
    var pathElements = path.split("/");
    var formattedPath = "";

  for (var i = 0; i < pathElements.length; i++) {
    // non considero gli elementi vuoti (gli /) e l'ultimo, perchè è già title
    if (pathElements[i] != "" && i < pathElements.length - 1) {
      var tempPathElement = i != 1 ? pathElements[i] : "homepage";
      // tempPathElement deve essere passato ad una classe di servizio "Breadcrumb" che fa la conversione
      formattedPath = <span>
        {formattedPath}
        {/*eslint-disable-next-line*/}
        <button className="menu-link menu-toggle text-muted font-weight-bolder font-size-h5 text-left btn" style={{ paddingRight: '0.5rem' }} onClick={() => goBack(history, tempPathElement)}>
          {tempPathElement.charAt(0).toUpperCase() + tempPathElement.slice(1)}
          <span style={{ paddingLeft: '0.6rem' }}>{"/"}</span>
        </button>
      </span>;
    }
  }
  return (
    <div>
      {formattedPath}
      <button className="text-dark-75 font-weight-bolder font-size-h5 btn" style={{ verticalAlign: "middle", paddingLeft: '0' }}>
        {title}
      </button>
    </div>
  );
};

export const goBack = function (history, path) {
  switch (path) {
    case "homepage":
      history.push("/dashboard");
      return 1;
    default:
      return -1;
  }
};

const viewAllTOs = [pagesLinks.viewAllTOsExecutionGrid,
pagesLinks.viewAllSalesOrdersGrid,
pagesLinks.viewAllStoragesGrid,
pagesLinks.viewAllOrdersToBeCollectedGrid,
pagesLinks.viewAllOrdersWithoutCollectionLegGrid,
pagesLinks.viewAllCollectionInProgressGrid,
pagesLinks.viewAllOrdersToBeDeliveredGrid,
pagesLinks.viewAllCollectedOrdersWithoutDMIGrid,
pagesLinks.viewAllOrdersInXdockGrid,
pagesLinks.viewAllOutForCollectionGrid,
pagesLinks.viewAllOutForDeliveryGrid,
pagesLinks.viewAllMissedCollectionsGrid,
pagesLinks.viewAllOrdersWithoutDeliveryLegGrid,
pagesLinks.viewAllMissedDeliveriesGrid,
pagesLinks.viewAllPartialDeliveriesGrid,
pagesLinks.viewAllInTransitGrid,
pagesLinks.viewAllOrdersWithoutDataEntryGrid,
pagesLinks.eventMonitoring,
pagesLinks.orderVariance,];

const viewAllTOsCustomer = [pagesLinks.viewAllTOsCustomerGrid]

const viewAllTrips = [pagesLinks.viewAllOngoingTripsGrid,
pagesLinks.viewAllActiveTripGrid,
pagesLinks.viewAllBookingGrid,
pagesLinks.viewAllInTransitGrid,
pagesLinks.viewAllAtLoadingUnloadingPlaceGrid,
pagesLinks.viewAllGPSGrid,
pagesLinks.viewAllNOGPSGrid];

export const isTOViewAll = (str) => {
  for (var i = 0; i < viewAllTOs.length; i++) {
    if (str.includes(viewAllTOs[i])) return true;
  }

  return false;
};

export const isTOCustomerViewAll = (str) => {
  for (var i = 0; i < viewAllTOsCustomer.length; i++) {
    if (str.includes(viewAllTOsCustomer[i])) return true;
  }

  return false;
};

export const isTripsViewAll = (str) => {
  for (var i = 0; i < viewAllTrips.length; i++) {
    if (str.includes(viewAllTrips[i])) return true;
  }

  return false;
};