import axios from "axios";
import config from "../../settings";

export const authAPI = {
    getToken: (authorizationCode, loginCallbackUrl, grantType, clientId, codeVerifier, thenCallback) => {
        axios.post(`${config.baseWebApiUrl}${config.getToken}`, {
            AuthorizationCode: authorizationCode,
            RedirectUri: loginCallbackUrl,
            GrantType: grantType,
            ClientId: clientId,
            CodeVerifier: codeVerifier
        })
            .then(res => {
                thenCallback(res.data);
            })
            .catch(err => {
                console.log(err);
            });
    }
};