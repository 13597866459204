import React, { createContext, useState } from "react";
import { setIsOpenGroupsPopup } from "../../redux/features/planning-tool/planningToolSlice";
import { connect } from "react-redux";

export interface IPlanningToolContext {
    isOpenSidebarMissingOrders: boolean;
    setIsOpenSidebarMissingOrders: React.Dispatch<React.SetStateAction<boolean>>;
    activeTradingPartnerId: null | number;
    setActiveTradingPartnerId: React.Dispatch<React.SetStateAction<null | number>>;
    activeBftId: null | number;
    setActiveBftId: React.Dispatch<React.SetStateAction<null | number>>;
    isOpenGroupsPopup: boolean;
    setIsOpenGroupsPopup: (open: boolean) => void;
    activeDashboardDate: Date,
    setActiveDashboardDate: React.Dispatch<React.SetStateAction<Date>>;
}

export const PlanningToolContext = createContext<null | IPlanningToolContext>(null);

interface Props {
    children: React.ReactNode,
    isOpenGroupsPopup: boolean,
    setIsOpenGroupsPopup: (open: boolean) => void;
}

export const PlanningToolProviderFn: React.FC<Props> = ({ children, isOpenGroupsPopup, setIsOpenGroupsPopup }) => {
    // Sidebar Missing Orders
    const [isOpenSidebarMissingOrders, setIsOpenSidebarMissingOrders] = useState<boolean>(false);
    const [activeTradingPartnerId, setActiveTradingPartnerId] = useState<null | number>(null);
    const [activeBftId, setActiveBftId] = useState<null | number>(null);
    const [activeDashboardDate, setActiveDashboardDate] = useState<Date>(new Date);

    const value: IPlanningToolContext = {
        setIsOpenSidebarMissingOrders,
        isOpenSidebarMissingOrders,
        activeTradingPartnerId,
        setActiveTradingPartnerId,
        activeBftId,
        setActiveBftId,
        activeDashboardDate,
        setActiveDashboardDate,

        // Groups popup
        isOpenGroupsPopup,
        setIsOpenGroupsPopup
    };

    return (
        <PlanningToolContext.Provider
            value={value}
        >
            {children}
        </PlanningToolContext.Provider>
    );
};

const mapStateToProps = function (state: any) {
    return {
        isOpenGroupsPopup: state.planningTool.isOpenGroupsPopup
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        setIsOpenGroupsPopup: (open: boolean) => {
            dispatch(setIsOpenGroupsPopup(open));
        },
    };
};

// @ts-ignore
export const PlanningToolProvider = connect(mapStateToProps, mapDispatchToProps)(PlanningToolProviderFn);