import React, { useContext, useEffect, useMemo, useState } from "react";
import Button from "../../components/ui/button/Button";
import { groupsService } from "../../api/groupsService/groupsService";
import { IMasterDataContext, MasterDataContext } from "../../context/MasterDataContext";
import { IGroup } from "../../types/orders";
import Spinner from "../../components/ui/spinner/Spinner";
import { useNavigate } from "react-router-dom";
import { planningToolRouteUrls } from "../../configs/planningToolRouteUrls";
import { IUserContext, UserContext } from "../../context/UserContext";
import { PermissionAreas, PermissionOperations } from "../../types/permissions";
import { customConfirmAlert } from "../../utils/functions/customConfirmAlert";
import Select from "../../components/ui/select/Select";
import usePermissions from "../../utils/hooks/usePermissions";
import { toast } from "react-toastify";

export interface IComb {
    departmentId: number;
    groups: IGroup[];
};

const PlanningToolGroupsCharge: React.FC = () => {

    const navigate = useNavigate();
    const { hasPermission } = usePermissions();

    const { actualUser } = useContext(UserContext) as IUserContext;
    const { departments } = useContext(MasterDataContext) as IMasterDataContext;

    const [loading, setLoading] = useState<boolean>(false);
    /* ---- START GROUP AND DEPARTMENT SELECTION ---- */
    const [preliminarGroupSelection, setPreliminarGroupSelection] = useState<"my_groups" | "other_groups" | null>("other_groups")
    const [selectedDepartment, setSelectedDepartment] = useState<number | null>(null);
    const [otherGroupsResult, setOtherGroupsResult] = useState<IGroup[]>([]);
    const [myGroupsResult, setMyGroupsResult] = useState<IComb[]>([]);
    const [selectedGroups, setSelectedGroups] = useState<IGroup[]>([]);

    const departmentOptions = useMemo(() => {
        return [...departments].map((d => {
            return {
                value: d.id,
                label: d.name
            }
        }));
    }, [departments]);

    useEffect(() => {
        if (!!departments && departments.length > 0) {
            setSelectedDepartment(departments[0].id);
        }
    }, [departments]);


    /* ---- END GROUP AND DEPARTMENT SELECTION ---- */

    useEffect(() => {
        if (preliminarGroupSelection === "other_groups" && selectedDepartment !== null) {
            getOtherGroups();
        }

        getMyGroups();
        setSelectedGroups([]);
    }, [selectedDepartment, preliminarGroupSelection]);

    const getOtherGroups = async () => {
        if (selectedDepartment !== null) {
            setLoading(true);
            const response = await groupsService.getGroups(null, selectedDepartment);
            if (response.success) {
                setOtherGroupsResult(response.data);
            } else {
                toast.error('Error retrieving groups');
            }
            setLoading(false);
        }
    }

    const getMyGroups = async () => {
        if (preliminarGroupSelection !== null) {
            setLoading(true);
            const response = await groupsService.getGroups(true, selectedDepartment);
            if (response.success) {
                // Find all combinations of departmentId
                const combs: IComb[] = [];

                response.data.forEach((group: IGroup, index: number) => {
                    const indexComb = combs.findIndex(comb => {
                        return comb.departmentId === group.departmentId;
                    });

                    if (indexComb !== -1 && indexComb !== null && indexComb !== undefined) {
                        combs[indexComb].groups.push(group);
                    } else {
                        combs.push({
                            departmentId: group.departmentId,
                            groups: [group]
                        });
                    }
                });

                setMyGroupsResult(combs);
            } else {
                toast.error('Error retrieving groups');
            }

            setLoading(false);
        }
    }

    const handleSelectGroup = (e: any, group: IGroup) => {
        if (e.target.checked) {
            if (!selectedGroups.find(g => g.id === group.id)) {
                setSelectedGroups([...selectedGroups, group]);
            } else {
                const indexGroup = selectedGroups.findIndex(g => g.id === group.id);
                if (indexGroup !== -1 && indexGroup !== null && indexGroup !== undefined) {
                    const copySelectedGroups = JSON.parse(JSON.stringify(selectedGroups));
                    copySelectedGroups.splice(indexGroup, 1);
                    setSelectedGroups(copySelectedGroups);
                }
            }
        } else {
            const indexGroup = selectedGroups.findIndex(g => g.id === group.id);
            if (indexGroup !== -1 && indexGroup !== null && indexGroup !== undefined) {
                const copySelectedGroups = JSON.parse(JSON.stringify(selectedGroups));
                copySelectedGroups.splice(indexGroup, 1);
                setSelectedGroups(copySelectedGroups);
            }
        }
    }

    const [loadingTakeCharge, setLoadingTakeCharge] = useState<boolean>(false);
    const handleTakeCharge = async () => {
        if (selectedGroups.length > 0 && !loadingTakeCharge) {
            setLoadingTakeCharge(true);
            const response = await groupsService.takeCharge(selectedGroups.map(g => g.id));
            if (response.success) {
                toast.success('Group taken in charge correctly');
                getMyGroups();
                if (preliminarGroupSelection === "other_groups") {
                    getOtherGroups();
                    setSelectedGroups([]);
                }
            } else {
                toast.error('There was an error taking charge of selected groups');
                console.log('error');
            }
            setLoadingTakeCharge(false);
        }
    }

    const handlePlanComb = (combs: IGroup[] | undefined) => () => {
        if (!!combs) {
            navigate(planningToolRouteUrls.planning.extended, {
                state: {
                    groups: combs
                }
            });
        }
    };

    const handleViewOnlyGroup = (group: IGroup) => {
        navigate(planningToolRouteUrls.planningVisualization.extended, {
            state: {
                groups: [group]
            }
        });
    };

    const handleRevokeCharge = async (group: IGroup) => {
        const confirmed = await customConfirmAlert({
            title: "Revoke charge",
            message: "Are you sure you want to revoke charge for this group?"
        });

        if (confirmed) {
            const response = await groupsService.deleteCharge([group.id], 'Revoking charge', 'Group revoked correctly', 'Error during the operation');

            if (response.success) {
                getMyGroups();
                if (preliminarGroupSelection === "other_groups") {
                    getOtherGroups();
                }
            }
        }
    }

    const MyGroupCurrentTable: React.FC<{ myGroupsResults: IComb[], departmentId: number | null }> = ({
        myGroupsResults,
        departmentId
    }) => {
        const comb = myGroupsResults.find((c) => c.departmentId == departmentId);
        return (
            <div>
                <table className="text-left w-full">
                    <thead className="bg-[#F9F7F6] text-black font-semibold text-[12px] uppercase border-t border-[#6F6F6F] border-b">
                        <tr>
                            <th className="p-3">Group</th>
                            <th className="p-3">Status</th>
                            <th className="p-3">User</th>
                            <th className="p-3">Remaining</th>
                            <th className="p-3">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            !!comb && comb.groups.length > 0 &&
                            <>
                                {
                                    comb?.groups.map((group, index) => {
                                        return (
                                            <tr key={index} className="border-b border-[#F2F2F2] uppercase text-[12px] font-semibold text-[#6F6F6F]">
                                                {/* Group */}
                                                <td style={{ minWidth: "170px" }} className="p-3">{group.name}</td>
                                                {/* Status */}
                                                <td style={{ minWidth: "170px" }} className="p-3">{group.status}</td>
                                                {/* User */}
                                                <td style={{ minWidth: "170px" }} className="p-3">
                                                    Me
                                                </td>
                                                {/* Remaining */}
                                                <td style={{ minWidth: "170px" }} className="p-3">{group.volumeToBePlanned.toFixed(2)} m³</td>
                                                {/* Action */}
                                                <td style={{ minWidth: "170px" }} className="p-3">
                                                    <div
                                                        onClick={() => {
                                                            if (hasPermission(PermissionAreas.TakeInCharge, PermissionOperations.TakeInCharge)) {
                                                                handleRevokeCharge(group);
                                                            }
                                                        }}
                                                        className={"flex items-center cursor-pointer" + (!hasPermission(PermissionAreas.TakeInCharge, PermissionOperations.TakeInCharge) ? ' opacity-30 cursor-default' : '')}
                                                    >
                                                        <span className="mr-[8px]">
                                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <g clipPath="url(#clip0_14321_52331)">
                                                                    <path fillRule="evenodd" clipRule="evenodd" d="M8.00004 1.83203C4.59428 1.83203 1.83337 4.59294 1.83337 7.9987C1.83337 11.4045 4.59428 14.1654 8.00004 14.1654C11.4058 14.1654 14.1667 11.4045 14.1667 7.9987C14.1667 4.59294 11.4058 1.83203 8.00004 1.83203ZM0.833374 7.9987C0.833374 4.04066 4.042 0.832031 8.00004 0.832031C11.9581 0.832031 15.1667 4.04066 15.1667 7.9987C15.1667 11.9567 11.9581 15.1654 8.00004 15.1654C4.042 15.1654 0.833374 11.9567 0.833374 7.9987Z" fill="#CF0A2C" />
                                                                    <path fillRule="evenodd" clipRule="evenodd" d="M2.93246 2.9316C3.12772 2.73634 3.4443 2.73634 3.63956 2.9316L13.0677 12.3598C13.263 12.555 13.263 12.8716 13.0677 13.0669C12.8724 13.2621 12.5559 13.2621 12.3606 13.0669L2.93246 3.63871C2.7372 3.44345 2.7372 3.12686 2.93246 2.9316Z" fill="#CF0A2C" />
                                                                    <path fillRule="evenodd" clipRule="evenodd" d="M13.0677 2.9316C13.263 3.12686 13.263 3.44345 13.0677 3.63871L3.63956 13.0669C3.4443 13.2621 3.12772 13.2621 2.93246 13.0669C2.7372 12.8716 2.7372 12.555 2.93246 12.3598L12.3606 2.9316C12.5559 2.73634 12.8724 2.73634 13.0677 2.9316Z" fill="#CF0A2C" />
                                                                </g>
                                                                <defs>
                                                                    <clipPath id="clip0_14321_52331">
                                                                        <rect width="16" height="16" fill="white" />
                                                                    </clipPath>
                                                                </defs>
                                                            </svg>
                                                        </span>
                                                        <span className="hover:underline">Revoke</span>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </>
                        }

                        {
                            (!!!comb || comb.groups.length === 0) &&
                            <div>There are no groups to show.</div>
                        }
                    </tbody>
                </table>

                {
                    hasPermission(PermissionAreas.TakeInCharge, PermissionOperations.TakeInCharge) &&
                    <div className="mt-6">
                        <Button
                            callback={handlePlanComb(comb?.groups)}
                        >
                            Plan my groups
                        </Button>
                    </div>
                }
            </div>
        );
    }

    const OtherGroupsCurrentTable: React.FC<{ otherGroupsResult: IGroup[], myGroupsResults: IComb[], departmentId: number | null }> = ({
        otherGroupsResult,
        departmentId,
        myGroupsResults
    }) => {
        const comb = myGroupsResults.find((c) => c.departmentId == departmentId);
        return (
            <>
                <table className="text-left w-full">
                    <thead className="bg-[#F9F7F6] text-black font-semibold text-[12px] uppercase border-t border-[#6F6F6F] border-b">
                        <tr>
                            <th className="p-3">Group</th>
                            <th className="p-3">Status</th>
                            <th className="p-3">User</th>
                            <th className="p-3">Remaining</th>
                            <th className="p-3">Action</th>
                            {/* <th className="p-3">Take Charge</th> */}
                        </tr>
                    </thead>
                    <tbody>
                        {
                            otherGroupsResult.map((group, index) => {
                                return (
                                    <tr key={index} className="border-b border-[#F2F2F2] uppercase text-[12px] font-semibold text-[#6F6F6F]">
                                        {/* Group */}
                                        <td className="p-3">{group.name}</td>
                                        {/* Status */}
                                        <td className="p-3">{group.status}</td>
                                        {/* User */}
                                        <td className="p-3">
                                            {
                                                group.chargeUser !== null &&
                                                <>
                                                    {
                                                        group.chargeUser.username === actualUser?.username ? 'Me' : (group.chargeUser.name + ' ' + group.chargeUser.surname)
                                                    }
                                                </>
                                            }
                                            {
                                                group.chargeUser === null &&
                                                '-'
                                            }
                                        </td>
                                        {/* Remaining */}
                                        <td className="p-3">{group.volumeToBePlanned.toFixed(2)} m³</td>
                                        {/* Action */}
                                        <td className="p-3">
                                            <div className="flex items-center">
                                                {
                                                    group.chargeUser === null &&
                                                    <div className="flex items-center mr-[32px]">
                                                        <div className="mr-[4px]">
                                                            <input
                                                                checked={!!selectedGroups.find(g => g.id === group.id)}
                                                                type="checkbox"
                                                                onChange={(e) => handleSelectGroup(e, group)}
                                                                disabled={!hasPermission(PermissionAreas.TakeInCharge, PermissionOperations.TakeInCharge)}
                                                                id={"take-charge-input" + group.id}
                                                                className="align-middle"
                                                            />
                                                        </div>

                                                        <div className="flex items-center">
                                                            <label
                                                                htmlFor={"take-charge-input" + group.id}
                                                                className={"cursor-pointer hover:underline font-medium normal-case mb-0 select-none" + (!hasPermission(PermissionAreas.TakeInCharge, PermissionOperations.TakeInCharge) ? ' opacity-30 cursor-default' : '')}
                                                            >Take charge</label>
                                                        </div>
                                                    </div>
                                                }

                                                {
                                                    !!group.chargeUser &&
                                                    <>
                                                        {
                                                            (group.chargeUser?.username !== actualUser?.username) ?
                                                                <span onClick={() => handleViewOnlyGroup(group)} className="cursor-pointer text-blue text-underlined font-medium normal-case">View</span>
                                                                :
                                                                <div
                                                                    onClick={() => {
                                                                        if (hasPermission(PermissionAreas.TakeInCharge, PermissionOperations.TakeInCharge)) {
                                                                            handleRevokeCharge(group);
                                                                        }
                                                                    }}
                                                                    className={"flex items-center cursor-pointer" + (!hasPermission(PermissionAreas.TakeInCharge, PermissionOperations.TakeInCharge) ? ' opacity-30 cursor-default' : '')}
                                                                >
                                                                    <span className="mr-[8px]">
                                                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <g clipPath="url(#clip0_14321_52331)">
                                                                                <path fillRule="evenodd" clipRule="evenodd" d="M8.00004 1.83203C4.59428 1.83203 1.83337 4.59294 1.83337 7.9987C1.83337 11.4045 4.59428 14.1654 8.00004 14.1654C11.4058 14.1654 14.1667 11.4045 14.1667 7.9987C14.1667 4.59294 11.4058 1.83203 8.00004 1.83203ZM0.833374 7.9987C0.833374 4.04066 4.042 0.832031 8.00004 0.832031C11.9581 0.832031 15.1667 4.04066 15.1667 7.9987C15.1667 11.9567 11.9581 15.1654 8.00004 15.1654C4.042 15.1654 0.833374 11.9567 0.833374 7.9987Z" fill="#CF0A2C" />
                                                                                <path fillRule="evenodd" clipRule="evenodd" d="M2.93246 2.9316C3.12772 2.73634 3.4443 2.73634 3.63956 2.9316L13.0677 12.3598C13.263 12.555 13.263 12.8716 13.0677 13.0669C12.8724 13.2621 12.5559 13.2621 12.3606 13.0669L2.93246 3.63871C2.7372 3.44345 2.7372 3.12686 2.93246 2.9316Z" fill="#CF0A2C" />
                                                                                <path fillRule="evenodd" clipRule="evenodd" d="M13.0677 2.9316C13.263 3.12686 13.263 3.44345 13.0677 3.63871L3.63956 13.0669C3.4443 13.2621 3.12772 13.2621 2.93246 13.0669C2.7372 12.8716 2.7372 12.555 2.93246 12.3598L12.3606 2.9316C12.5559 2.73634 12.8724 2.73634 13.0677 2.9316Z" fill="#CF0A2C" />
                                                                            </g>
                                                                            <defs>
                                                                                <clipPath id="clip0_14321_52331">
                                                                                    <rect width="16" height="16" fill="white" />
                                                                                </clipPath>
                                                                            </defs>
                                                                        </svg>
                                                                    </span>
                                                                    <span className="hover:underline">Revoke</span>
                                                                </div>
                                                        }
                                                    </>
                                                }
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>

                <div className="flex items-center">
                    {
                        !!comb?.groups && hasPermission(PermissionAreas.TakeInCharge, PermissionOperations.TakeInCharge) &&
                        <div className="mt-6 mr-2">
                            <Button
                                callback={handlePlanComb(comb?.groups)}
                            >
                                Plan my groups
                            </Button>
                        </div>
                    }

                    {
                        selectedGroups.length > 0 && hasPermission(PermissionAreas.TakeInCharge, PermissionOperations.TakeInCharge) &&
                        <div className="mt-6">
                            <div className="flex items-center">
                                <Button
                                    loading={loadingTakeCharge}
                                    callback={handleTakeCharge}
                                >
                                    Take charge
                                </Button>
                            </div>
                        </div>
                    }
                </div>
            </>
        );
    }

    return (
        <div className="w-full mb-6 bg-white p-[32px]">
            <div className="font-light text-lg leading-[25px]">Planning Tool</div>
            <div className="flex items-center mb-3">
                <div className="md:mr-3 text-[32px] font-bold leading-[45px]">
                    Group Charge
                </div>
            </div>

            <h3 className="text-[15px] mb-6">Select your department and group to take charge or check orders.</h3>

            <div className="flex items-center justify-between pb-[24px]">
                <div className="flex items-center">
                    {
                        departmentOptions.map((option, index) => {
                            return (
                                <div
                                    key={index}
                                    onClick={() => setSelectedDepartment(option.value)}
                                    className={"uppercase cursor-pointer border border-darkBlue border-[1.4px] px-[18px] py-[9px] rounded-[4px]" + (selectedDepartment === option.value ? (" bg-blue text-white text-sm font-semibold") : (" bg-white text-darkBlue text-sm font-semibold"))}
                                >
                                    {option.label}
                                </div>
                            );
                        })
                    }
                </div>

                {
                    <div className="flex items-center">
                        <label
                            htmlFor="filter"
                            className="mt-1 uppercase font-semibold text-[13px] text-black mr-4 leading-[16px]"
                        >
                            Filter
                        </label>
                        <Select
                            name="filter"
                            options={[
                                {
                                    label: "All groups",
                                    value: "other_groups"
                                },
                                {
                                    label: "My groups",
                                    value: "my_groups"
                                }
                            ]}
                            callbackOnChange={(e) => setPreliminarGroupSelection(e.target.value)}
                            value={preliminarGroupSelection}
                        />
                    </div>
                }
            </div>

            {/* My Groups Table */}
            {
                preliminarGroupSelection === "my_groups" &&
                <>
                    {
                        !loading &&
                        <>
                            {
                                myGroupsResult.length > 0 &&
                                <MyGroupCurrentTable
                                    myGroupsResults={myGroupsResult}
                                    departmentId={selectedDepartment}
                                />
                            }
                            {
                                myGroupsResult.length === 0 &&
                                <div>There are no groups matching the search parameters.</div>
                            }
                        </>
                    }

                    {
                        loading &&
                        <Spinner />
                    }
                </>
            }

            {/* Other Groups Table */}
            {
                (preliminarGroupSelection === "other_groups" && selectedDepartment !== null) &&
                <>
                    {
                        !loading &&
                        <div>
                            {
                                otherGroupsResult.length > 0 &&
                                <OtherGroupsCurrentTable
                                    otherGroupsResult={otherGroupsResult}
                                    myGroupsResults={myGroupsResult}
                                    departmentId={selectedDepartment}
                                />
                            }

                            {
                                otherGroupsResult.length === 0 &&
                                <div>There are no groups matching the search parameters.</div>
                            }
                        </div>
                    }

                    {
                        loading &&
                        <Spinner />
                    }
                </>
            }
        </div>
    )
}

export default PlanningToolGroupsCharge;