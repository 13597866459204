export class DefaultRoutingConfiguration {
    public customerCode?: string = "";
    public businessFlow?: string | string[] = "";
    public originCountry?: string = "";
    public originZipCodeFrom?: string = "";
    public originZipCodeTo?: string = "";
    public destinationCountry?: string = "";
    public destinationZipCodeFrom?: string = "";
    public destinationZipCodeTo?: string = "";
    public route?: string[] = [];
}