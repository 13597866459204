import { createSelector, createSlice } from '@reduxjs/toolkit';
import { CardConsts } from '../../../consts/cardConst';
import { availableTOCardsList, availableTripsCardsList, translateTOCards, translateTripsCards, availableTOCardsListOnMap } from '../../../utils/CardConfigurationUtils';

const orderIds = CardConsts.cardIDs.orders;
const tripIds = CardConsts.cardIDs.trips;

const orderCardsInitialState = {
  cardConfigurationList: [],
  // activeId: orderIds.toBeColl,
  // activeCardData: availableTOCardsList.findById(orderIds.toBeColl),
  activeId: null,
  activeCardData: null,
  hasFetched: false
};

const tripCardsInitialState = {
  cardConfigurationList: [tripIds.active, tripIds.booking, tripIds.trInTransit, tripIds.loadUnload, tripIds.gps, tripIds.noGps],
  activeId: tripIds.active,
  activeCardData: availableTripsCardsList.findById(tripIds.active),
  hasFetched: false
};

const commonReducers = {
  addCardConfiguration(state, action) {
    state.cardConfigurationList = action.payload;

    var i = 0;
    var pos = -1;
    while (i < state.cardConfigurationList.length) {
      var index = availableTOCardsListOnMap.indexOf(state.cardConfigurationList[i]);

      if (index != -1) {
        pos = i;
        i = state.cardConfigurationList.length;
      }

      i++;
    }

    state.activeId = state.cardConfigurationList[pos];
    state.activeCardData = availableTOCardsList.findById(state.cardConfigurationList[pos]);
  },
  setHasFetched(state, action) {
    state.hasFetched = action.payload;
  },
  setActiveCardValue(state, action) {    
    state.activeCardData.value = action.payload;
  }
};

const TOCardsSlice = createSlice({
  name: 'TOCards',
  initialState: orderCardsInitialState,
  reducers: {
    ...commonReducers,
    setActiveId(state, action) {
      state.activeId = action.payload;
      state.activeCardData = availableTOCardsList.findById(action.payload);
    }
  }
});

const tripsCardsSlice = createSlice({
  name: 'tripsCards',
  initialState: tripCardsInitialState,
  reducers: {
    ...commonReducers,
    setActiveId(state, action) {
      state.activeId = action.payload;
      state.activeCardData = availableTripsCardsList.findById(action.payload);
    }
  }
});

// TODO: This can be refactored
export const selectTOCards = createSelector(
  state => state.TOCards.cardConfigurationList,
  state => state.TOCards.activeId,
  (cards, activeId) => translateTOCards(cards, activeId)
);

export const selectTOHasFetched = state => state.TOCards.hasFetched;

export const selectTripsCards = createSelector(
  state => state.tripsCards.cardConfigurationList,
  state => state.tripsCards.activeId,
  (cards, activeId) => translateTripsCards(cards, activeId)
);

export const selectTripsHasFetched = state => state.tripsCards.hasFetched;

export const selectActiveTOCard = state => state.TOCards.activeCardData;
export const selectActiveTripsCard = state => state.tripsCards.activeCardData;

export const TOCardsActions = TOCardsSlice.actions;
export const TripsCardsActions = tripsCardsSlice.actions;

export const TOCardsReducer = TOCardsSlice.reducer;
export const TripsCardsReducer = tripsCardsSlice.reducer;