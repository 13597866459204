import React from "react";

type Props = {
    name?: string;
    placeholder?: string | null;
    options: {
        value: string | number;
        label: string
    }[];
    label?: string | null;
    value?: number | undefined | string | null;
    defaultValue?: any;
    callbackOnChange: (e: any) => void;
    disabledValues?: number[] | string[];
    isRequired?: boolean;
    callbackOnFocus?: () => any;
    hasError?: boolean;
    errorMessage?: string;
    disabled?: boolean;
    defaultOptionEnabled?: boolean | undefined,
    className?: string | undefined,
    style?: any | undefined
}

const Select: React.FC<Props> = ({
    name,
    placeholder,
    options,
    label,
    value,
    defaultValue = "",
    callbackOnChange,
    disabledValues = [],
    isRequired = false,
    callbackOnFocus,
    hasError = false,
    errorMessage = '',
    disabled = false,
    defaultOptionEnabled,
    className,
    style
}) => {

    const handleChange = (e: any) => {
        if (!disabled) {
            callbackOnChange(e);
        }
    }

    return (
        <div>
            {
                !!label &&
                <label
                    className="font-semibold text-sm text-blue uppercase leading-[17px] block mb-[5px]"
                    htmlFor={name}
                >
                    {label}

                    {
                        isRequired &&
                        <span className="text-red ml-1">*</span>
                    }
                </label>
            }

            <select
                onFocus={callbackOnFocus}
                required={isRequired}
                onChange={handleChange}
                value={(value !== null && value !== undefined) ? value : undefined}
                disabled={disabled}
                className={(className ?? " ") + " block font-normal outline-none p-[10px] border border-[#6F6F6F] leading-[18px] rounded-[8px] text-[15px] w-full" + (hasError ? ' !border-red' : '') + (!!disabled ? ' cursor-not-allowed bg-[#fafafa]' : '')}
                name={name}
                id={name}
                style={style}>
                {
                    (!!placeholder &&
                        <>
                            {defaultOptionEnabled === undefined &&
                                <option value="" disabled>{placeholder}</option>
                            }

                            {defaultOptionEnabled !== undefined && defaultOptionEnabled === true &&
                                <option value="">{placeholder}</option>
                            }
                        </>
                    )
                }
                {
                    !!options && options.length > 0 &&
                    options.map((option, index) => {
                        return (
                            <option
                                key={index}
                                disabled={disabledValues.some(v => v == option.value)}
                                value={option.value}
                                style={{
                                    backgroundColor: disabledValues.some(v => v == option.value) ? '#F2F2F2' : '',
                                }}
                            >
                                {option.label}
                            </option>
                        )
                    })
                }
            </select>

            {
                hasError && !!errorMessage &&
                <div className="text-xs text-red font-semibold mt-1">
                    {errorMessage}
                </div>
            }
        </div>
    );
}

export default Select;