import React from "react";

type Props = {
    checked: boolean;
    callbackOnChange?: (e: boolean) => void;
    checkedElement: string | JSX.Element | number;
    uncheckedElement: string | JSX.Element | number;
    backgroundColor?: string;
    pinBackgroundColor?: string;
    disabled?: boolean;
    width?: string;
}

const Switch: React.FC<Props> = ({
    callbackOnChange,
    checked,
    backgroundColor,
    pinBackgroundColor,
    checkedElement,
    uncheckedElement,
    disabled = false,
    width = "28px"
}) => {
    const handleCallback = () => {
        if (!disabled && !!callbackOnChange) {
            callbackOnChange(!checked)
        }
    }

    return (
        <div className={`relative h-[18px] w-[${width}] rounded-[10px]` + (!!backgroundColor ? `bg-[${backgroundColor}]` : ' bg-bgGrey')}>
            <div onClick={handleCallback} className={"transition absolute text-white leading-[28px] text-[12px] font-semibold w-[18px] h-[18px] rounded-full cursor-pointer top-0" + (!!pinBackgroundColor ? ` bg-${pinBackgroundColor}` : ' bg-blue') + (checked === true ? ' left-[calc(100%-18px)]' : ' left-0')}>
                {
                    checked === false &&
                    <div className="h-full w-full flex items-center justify-center">
                        {uncheckedElement}
                    </div>
                }

                {
                    checked === true &&
                    <>
                        <div className="h-full w-full flex items-center justify-center">
                            {checkedElement}
                        </div>
                    </>
                }
            </div>
        </div>
    )
}

export default Switch;