import React, { useEffect, useMemo, useRef, useState } from "react";
import Button from "../../ui/button/Button";
import Modal from "../../ui/modal/Modal";
import { ordersService } from "../../../api/ordersService/ordersService";
import { Table } from "react-bootstrap";
import dayjs from "dayjs";


export type LegErrorType = {
    orderNumber: string,
    errors:
    {
        isCollection: boolean,
        info:
        {
            source: string,
            errCode: string,
            errDesc: string,
            timestamp: string
        }[];
    }[];
}

type Props = {
    callbackCloseModal: () => any;
    orderId: number;
}

const ModalOrderLegsErrors: React.FC<Props> = ({
    callbackCloseModal,
    orderId,
}) => {

    const [errors, setErrors] = useState<LegErrorType[]>([]);

    const canGo = useRef(true);
    useEffect(() => {
        if (!!canGo && !!canGo.current) {
            canGo.current = false;

            getSetErrors(orderId);
        }
    }, [orderId]);

    const getSetErrors = async (orderId: number) => {
        const response = await ordersService.getOrderErrors(orderId);

        if (response.success) {
            setErrors(response.data);
        }
    }

    return (
        <Modal
            callbackCloseModal={callbackCloseModal}
            title="Order legs errors"
            footer={
                <div className="w-full flex items-center justify-end">
                    <Button
                        callback={callbackCloseModal}
                    >
                        close
                    </Button>
                </div>
            }
            customStyleChildrenContainer={{
                overflow: "auto",
            }}
            customStyleContainer={{
                maxHeight: '75%',
                width: '80%',
                maxWidth: '1920px'
            }}
        >
            <div className="h-full overflow-auto">
                {/* @ts-ignore */}
                <Table bordered responsive>
                    <thead>
                        <tr>
                            <th className="w-[10%]">Order Number</th>
                            <th></th>
                        </tr>
                    </thead>

                    <tbody>
                        {
                            !!errors && errors.map((error, index) => {
                                return (
                                    <tr key={'1-' + index}>
                                        <td>{error.orderNumber}</td>

                                        <td>
                                            {/* @ts-ignore */}
                                            <Table bordered responsive>
                                                <thead>
                                                    <th className="w-[10%]">Leg Type</th>
                                                    <th></th>
                                                </thead>
                                                <tbody>
                                                    {
                                                        error.errors.map((subError, j) => {
                                                            return (
                                                                <tr key={'2-' + j}>
                                                                    <td>{subError.isCollection ? 'Collection' : 'Delivery'}</td>

                                                                    <td>
                                                                        {/* @ts-ignore */}
                                                                        <Table style={{ tableLayout: 'fixed' }} bordered responsive>
                                                                            <thead>
                                                                                <th>Source</th>
                                                                                <th>Error Code</th>
                                                                                <th>Error Description</th>
                                                                                <th>Error Date</th>
                                                                            </thead>
                                                                            <tbody>
                                                                                {
                                                                                    subError.info.map((info, k) => {
                                                                                        return (
                                                                                            <tr key={'3-' + k}>
                                                                                                <td>{info.source}</td>
                                                                                                <td>{info.errCode}</td>
                                                                                                <td>{info.errDesc}</td>
                                                                                                <td>{dayjs(info.timestamp).format('DD/MM/YYYY HH:mm')}</td>
                                                                                            </tr>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </tbody>
                                                                        </Table>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </Table>
                                        </td>
                                    </tr>
                                );
                            })
                        }
                    </tbody>
                </Table>
            </div>
        </Modal>
    )
}

export default ModalOrderLegsErrors;