import React, { useCallback, useContext, useMemo, useRef, useState } from 'react';
import CommonTable, { TableRef } from '../../components/commonTable/CommonTable';
import Button from '../../components/ui/button/Button';
import { groupsService } from '../../api/groupsService/groupsService';
import { ColDef, GridApi, RowNode, ValueSetterParams } from '@ag-grid-community/core';
import { IMasterDataContext, MasterDataContext } from '../../context/MasterDataContext';
import Select from '../../components/ui/select/Select';
import { toast } from 'react-toastify';
import { customConfirmAlert } from '../../utils/functions/customConfirmAlert';
import { IGroup } from '../../types/orders';
import usePermissions from '../../utils/hooks/usePermissions';
import { PermissionAreas, PermissionOperations } from '../../types/permissions';

const containerStyle = { width: '100%', height: '100%' };

const PlanningToolManageGroups: React.FC = () => {

    const { departments } = useContext(MasterDataContext) as IMasterDataContext;
    const { hasPermission } = usePermissions();

    // #region State
    const commonTableRef = useRef<TableRef>(null);
    const [inAdding, setInAdding] = useState<boolean>(false);
    // #endregion

    // #region Memo
    const departmentsOptions = useMemo(() => {
        return departments.map((e) => {
            return {
                value: e.id,
                label: e.name
            }
        });
    }, [departments]);

    const columnDefs = useMemo(() => {
        return [
            {
                field: "departmentId",
                headerName: "Department",
                cellClass: "agCellAlignCenter",
                rowGroup: true,
                editable: false,
                filter: "agSetColumnFilter",
                filterParams: {
                    values: departments.map(x => x.id),
                    valueFormatter: (params: any) => departments.find(x => x.id === params.value)?.name,
                    suppressSelectAll: true
                },
                cellRenderer: (props: { data: any, node: RowNode, api: GridApi, colDef: any }) => {
                    if (!props.node.group) {
                        return (
                            <Select
                                disabled={!hasPermission(PermissionAreas.ManageGroup, PermissionOperations.Modify)}
                                style={{ backgroundColor: "transparent" }}
                                callbackOnChange={async (e) => { await handleChangeDepartment(e.target.value, props.node, props.api) }}
                                options={departmentsOptions}
                                value={props.data.departmentId}
                                defaultOptionEnabled={true}
                                placeholder={"Select Department"}
                                className="h-[30px] p-0 w-full"
                            />
                        )
                    } else {
                        if (commonTableRef.current?.isGroupColumnCell(props)) {
                            let value = departmentsOptions.find(x => x?.value?.toString() === props?.node?.key)?.label;
                            return !!value ? value?.toUpperCase() : value;
                        }
                    }

                    return null;
                }
            },
            {
                field: "name",
                headerName: "Group name",
                filter: "agSetColumnFilter",
            },
            {
                field: "priority",
                headerName: "Group Priority",
                cellDataType: "text",
                sort: 'asc',
                filter: "agSetColumnFilter",
            },
            {
                field: "actions",
                headerName: "Actions",
                editable: false,
                filter: false,
                sortable: false,
                cellRenderer: (props: { data: any, node: RowNode }) => {
                    return (
                        <>
                            {
                                <div className="flex">
                                    {
                                        props.node.rowPinned === "top" &&
                                        <div
                                            onClick={onClickCancelNewRow}
                                            className={"flex text items-center cursor-pointer"}
                                        >
                                            <span className="mr-[8px]">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path fill="#3987E2" d="M12 2c5.53 0 10 4.47 10 10s-4.47 10-10 10S2 17.53 2 12S6.47 2 12 2m3.59 5L12 10.59L8.41 7L7 8.41L10.59 12L7 15.59L8.41 17L12 13.41L15.59 17L17 15.59L13.41 12L17 8.41z" /></svg>
                                            </span>
                                            <span className="hover:underline not-italic ">Remove</span>
                                        </div>
                                    }
                                </div>
                            }
                        </>
                    )
                },
            }
        ];
    }, [departmentsOptions]);

    const autoGroupColumnDef = useMemo<ColDef>(() => {
        return {
            field: 'departmentId',
            headerName: "Department",
            editable: false,
            filter: false,
            valueGetter: () => {
                return "";
            }
        };
    }, []);
    // #endregion

    // #region UI Handlers
    const handleChangeDepartment = async (value: any, node: RowNode, api: GridApi) => {
        node.setDataValue("departmentId", Number(value));
        api.redrawRows({ rowNodes: [node] });
    };

    const onClickSaveButton = () => {
        confirmSaveGroups();
    };

    const onClickAddButton = () => {
        addNewGroup();
    };

    const onClickCancelNewRow = () => {
        cancelNewRow();
    };

    const onClickDeleteButton = () => {
        confirmDeleteGroup();
    };
    // #endregion

    // #region Util Functions
    const customValueSetter = (params: ValueSetterParams) => {
        let newValue = params.newValue;
        let field = params.colDef.field as string;

        // Validation
        if (newValue !== null && newValue !== undefined) {
            if (field === "priority") {
                if (isNaN(newValue)) {
                    toast.warning("The value cannot include letters");
                    return false;
                }
            }
        }

        params.data[field] = newValue;
        return true;
    };

    const confirmSaveGroups = async () => {
        commonTableRef.current?.setStopEditing();
        const wantToSave = await customConfirmAlert({
            message: "Are you sure to save groups?",
            title: "Save groups"
        });

        if (wantToSave) {
            await saveGroups();
        }
    };

    const saveGroups = async () => {
        let tableNodes: any = commonTableRef.current?.getTableNodes();
        let tableRows: any[] = tableNodes.map((x: any) => x.data as any);
        let newRowToInsert = commonTableRef.current?.getNewRowToInsert();
        tableRows = newRowToInsert !== undefined ? [newRowToInsert, ...tableRows] : tableRows;

        if (tableRows.length === 0) {
            toast.info("No groups to save");
            return;
        }

        // Check some value null
        if (tableRows.some((x: IGroup) => !!!x.name || !!!x.priority || !!!x.departmentId)) {
            toast.warning("There are some null values");
            return;
        }

        const response = await groupsService.updateGroups(tableRows, "Saving groups", "Groups saved successfully");
        if (response.success) {
            commonTableRef.current?.cancelNewRowInsertion();
            commonTableRef.current?.setUnsavedCells([]);
            commonTableRef.current?.setRowsInError([]);
            setInAdding(false);
            setTimeout(function () { getData() }, 0)
        } else if (response.data.detail !== undefined) {
            commonTableRef.current?.setRowsInError(response.data.detailObject);
            setTimeout(function () { commonTableRef.current?.redrawTableRows() }, 0)
        }
    };

    const confirmDeleteGroup = async () => {
        let selectedRows: any[] = commonTableRef.current?.getSelectedRows() as any[];
        let idsToRemove: number[] = selectedRows?.map(x => x.id);

        if (idsToRemove.length === 0) {
            toast.info("No groups selected");
            return;
        }

        const wantToDelete = await customConfirmAlert({
            title: "Delete groups",
            message: "Are you sure to delete the selected groups?"
        });

        if (wantToDelete) {
            await deleteGroups(idsToRemove);
            commonTableRef.current?.redrawTableRows();
        }
    };

    const deleteGroups = async (idsToRemove: number[]) => {
        let selectedRows: any[] = commonTableRef.current?.getSelectedRows() as any[];

        const response = await groupsService.deleteGroups(idsToRemove, "Deleting groups", "Groups deleted successfully");
        if (response.success) {
            commonTableRef.current?.deleteTableRows(selectedRows);
        }
    };

    const cancelNewRow = () => {
        commonTableRef.current?.cancelNewRowInsertion();
        setInAdding(false);
    };

    const addNewGroup = () => {
        let newRow: any = {};
        commonTableRef.current?.addNewRowInsertion(newRow);
        setInAdding(true);
    };

    const getData = async () => {
        commonTableRef.current?.showLoadingOverlay();
        const response = await groupsService.getGroups();
        commonTableRef.current?.hideOverlay();

        if (response.success) {
            commonTableRef.current?.setRowData(response.data);
        } else {
            toast.error('Error retrieving groups');
        }
    };
    // #endregion

    // #region CallBack
    const onGridReady = useCallback(() => {
        getData();
    }, []);
    // #endregion

    return (
        <div id="planning-tool-manage-groups" className="w-full mb-6 bg-white p-[32px]">
            <div className="font-light text-lg leading-[25px]">Planning Tool</div>
            <div className="flex items-center pb-3 mb-6 border-b">
                <div className="md:mr-3 text-[32px] font-bold leading-[45px]">
                    Groups Configuration
                </div>
            </div>

            {
                hasPermission(PermissionAreas.ManageGroup, PermissionOperations.Modify) &&
                <div className="mb-6 flex">
                    <Button
                        className="bg-blue text-white !px-[12px] !py-[8px]"
                        callback={onClickAddButton}
                        disabled={inAdding}
                    >
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10.3333 9.16602V9.66602H10.8333H15.3333V10.3327H10.8333H10.3333V10.8327V15.3327H9.66667V10.8327V10.3327H9.16667H4.66667V9.66602H9.16667H9.66667V9.16602V4.66602H10.3333V9.16602Z" fill="#3987E2" stroke="white" />
                        </svg>
                    </Button>

                    <Button
                        className="bg-blue text-white !px-[12px] !py-[8px] ml-[12px]"
                        callback={onClickDeleteButton}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path fill="currentColor" d="M19 4h-3.5l-1-1h-5l-1 1H5v2h14M6 19a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V7H6z" /></svg>
                    </Button>

                    <div className="ml-[12px]">
                        <Button
                            callback={onClickSaveButton}
                            className="bg-transparent text-black border-[1.4px] border-black"
                        >
                            Save configuration
                        </Button>
                    </div>
                </div>
            }

            <div style={containerStyle}>
                <CommonTable
                    ref={commonTableRef}
                    columnDefs={columnDefs}
                    autoGroupColumnDef={autoGroupColumnDef}
                    valueSetter={customValueSetter}
                    onGridReady={onGridReady}
                    options={{
                        pageSize: 10,
                        showSelectionCheckbox: hasPermission(PermissionAreas.ManageGroup, PermissionOperations.Modify),
                        editable: hasPermission(PermissionAreas.ManageGroup, PermissionOperations.Modify)
                    }}
                />
            </div>
        </div>
    )
};

export default PlanningToolManageGroups;