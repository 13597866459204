import qs from "qs";
import {formatDate} from "../utils/DateUtils"
import {GetAccessToken} from "../utils/AuthUtils"
import config from "../settings"

export default function setupAxios(axios, store) {

  axios.default.paramsSerializer = (params) => {
    
      return  qs.stringify(params, {
      serializeDate: (date) => { 
        console.log("axios " + date); 
        formatDate(date); 
      } })
    };

  axios.interceptors.request.use(
    conf => {
      
      if (config.wApiKey && config.wApiKey !== null && config.wApiKey !== undefined && config.wApiKey !== "")
        conf.headers["x-wapi-key"] = config.wApiKey;

      if (conf.url !== config.baseWebApiUrl + config.getToken) {
        const accessToken = GetAccessToken();
        
        if (accessToken) {
          conf.headers.Authorization = `Bearer ${accessToken}`;
        }
        else window.location.href = "/";
      }

      return conf;
    },

    err => Promise.reject(err)
  );

}
