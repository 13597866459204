import moment from "moment"
import React, { useEffect, useState } from "react";
import { removeTZ } from "./DateUtils"


export const dateValueFormatter = params => {
    if (params.value !== undefined && params.value !== null && params.value.trim() !== "") {
        return moment(params.value).format('DD/MM/YYYY HH:mm');
    }
    else
        return "";
}


function isNumeric(str) {
    if (typeof str != "string") return false;
    return !isNaN(str) && !isNaN(parseFloat(str));
}


const MyFilterCellRenderer = (params) => {
    const [loading, setLoading] = useState(true); // Stato di caricamento iniziale
    const [lastValue, setLastValue] = useState(null); // Stato per tenere traccia dell'ultimo valore visto

    useEffect(() => {
        // Imposta loading a true quando il componente è montato o quando params.value cambia
        setLoading(true);

        // Controlla se params.value è "(Select All)"
        if (params.value === "(Select All)") {
            // Simula il caricamento dei dati (puoi sostituire questa parte con la tua logica di caricamento effettiva)
            setTimeout(() => {
                setLoading(false); // Interrompi il caricamento dopo un certo periodo di tempo (simulato)
                setLastValue(params.value); // Memorizza l'ultimo valore visto
            }, 1000); // Tempo di simulazione del caricamento in millisecondi (1 secondo in questo caso)
        } else {
            // Se params.value non è "(Select All)", interrompi immediatamente il caricamento
            setLoading(false);
            setLastValue(params.value); // Memorizza l'ultimo valore visto
        }
    }, [params.value]);


    // Logica di rendering basata sul valore
    let valueString = null;
    if (!loading) {
        if (
            Number.isNaN(Date.parse(params.value)) ||
            isNumeric(params.value.toString()) ||
            !(params.value.includes(":") && params.value.includes("-"))
        ) {
            if (params.value === "false" || params.value === "true") {
                valueString = params.value === "true" ? "YES" : "NO";
            } else {
                valueString = params.value;
            }
        } else {
            valueString = dateValueFormatter(params);
        }
    }

    return (
        <div>
            {loading && params.value === "(Select All)" ? 'Loading...' : (valueString || lastValue)}
        </div>
    );
};

export const statusEventMonitoringValueFormatter = params => {
    if (params.value !== undefined && params.value !== null && params.value.trim() !== "") {
        var status = parseInt(params.value);
        if (status == 1) return "OK";
        else if (status == 2) return "OK but delayed";
        else if (status == 3) return "Not OK";
        else if (status == 0) return "Waiting for events";
        else return "";
    }
    else return "";
}

function renderViewSULink(params) {
    var element = document.createElement("a");
    //console.log(params)

    element.text = "SU Details"
    element.style.setProperty("color", "#64a2c8", "important");

    return element;
}

export const dateValueFormatterNoTime = params => {
    if (params.value !== undefined && params.value !== null && params.value.trim() !== "")
        return moment(removeTZ(params.value)).format('DD/MM/yyyy');
    else return "";
}

export const TOColumnsGrid = (hiddenCols) => {
    var cols = [
        // {
        //     headerName: "External Order ID",
        //     field: "externalOrderId",
        //     width: 240,
        //     colId: "externalOrderId"
        // },
        {
            headerName: "TMS Order ID",
            field: "tmsOrderId",
            width: 200,
            colId: "tmsOrderId"
        },
        {
            headerName: "TMS Collection ID",
            field: "collectionId",
            width: 210,
            colId: "collectionId"
        },
        // {
        //     headerName: "Customer Collection ID",
        //     field: "customerCollectionID",
        //     width: 222,
        //     colId: "customerCollectionID"
        // },
        // {
        //     headerName: "Customer Direct ID",
        //     field: "customerDirectID",
        //     width: 200,
        //     colId: "customerDirectID"
        // },
        // {
        //     headerName: "Customer Linehaul ID",
        //     field: "customerLinehaulID",
        //     width: 205,
        //     colId: "customerLinehaulID"
        // },
        {
            headerName: "Customer",
            field: "customer",
            width: 285,
            colId: "customer",
        },
        {
            headerName: "TMS Customer",
            field: "tmsCustomer",
            width: 285,
            colId: "tmsCustomer"
        },
        {
            headerName: "Status",
            field: "status",
            width: 190,
            colId: "status"
        },
        {
            headerName: "Reference 1",
            field: "reference1",
            width: 210,
            colId: "reference1"
        },
        {
            headerName: "Reference 2",
            field: "reference2",
            width: 210,
            colId: "reference2"
        },
        {
            headerName: "Reference 3",
            field: "reference3",
            width: 210,
            colId: "reference3"
        },
        {
            headerName: "Sender (Name)",
            field: "shipperName",
            width: 285,
            colId: "shipperName"
        },
        {
            headerName: "Sender (Postal Code)",
            field: "shipperPostalCode",
            width: 260,
            colId: "shipperPostalCode"
        },
        {
            headerName: "Sender (City)",
            field: "shipperCity",
            width: 210,
            colId: "shipperCity"
        },
        // {
        //     headerName: "Sender (Province)",
        //     field: "shipperProvince",
        //     width: 235,
        //     colId: "shipperProvince"
        // },
        {
            headerName: "Sender (Country)",
            field: "shipperCountry",
            width: 235,
            colId: "shipperCountry"
        },
        // {
        //     headerName: "Sender (Booking Reference)",
        //     field: "shipperBookingReference",
        //     width: 310,
        //     colId: "shipperBookingReference"
        // },
        // {
        //     headerName: "Shipper (Hall)",
        //     field: "shipperHall",
        //     width: 235,
        //     colId: "shipperHall"
        // },
        // {
        //     headerName: "Shipper (Plant)",
        //     field: "shipperPlant",
        //     width: 235,
        //     colId: "shipperPlant"
        // },
        // {
        //     headerName: "Shipper (Dock)",
        //     field: "shipperDock",
        //     width: 235,
        //     colId: "shipperDock"
        // },
        {
            headerName: "Origin (Name)",
            field: "originName",
            width: 285,
            colId: "originName"
        },
        {
            headerName: "Origin (Postal Code)",
            field: "originPostalCode",
            width: 260,
            colId: "originPostalCode"
        },
        {
            headerName: "Origin (City)",
            field: "originCity",
            width: 210,
            colId: "originCity"
        },
        {
            headerName: "Origin (Province)",
            field: "originProvince",
            width: 235,
            colId: "originProvince"
        },
        {
            headerName: "Origin (Country)",
            field: "originCountry",
            width: 235,
            colId: "originCountry"
        },
        // {
        //     headerName: "Origin (Booking Reference)",
        //     field: "originBookingReference",
        //     width: 310,
        //     colId: "originBookingReference"
        // },
        // {
        //     headerName: "Origin (Hall)",
        //     field: "originHall",
        //     width: 235,
        //     colId: "originHall"
        // },
        // {
        //     headerName: "Origin (Plant)",
        //     field: "originPlant",
        //     width: 235,
        //     colId: "originPlant"
        // },
        // {
        //     headerName: "Origin (Dock)",
        //     field: "originDock",
        //     width: 235,
        //     colId: "originDock"
        // },
        // {
        //     headerName: "Loading Halle",
        //     field: "loadingHalle",
        //     width: 210
        // },
        // {
        //     headerName: "Loading Dock",
        //     field: "moloLoading",
        //     width: 210
        // },
        {
            headerName: "Consignee (Name)",
            field: "consigneeName",
            width: 285,
            colId: "consigneeName"
        },
        {
            headerName: "Consignee (Postal Code)",
            field: "consigneePostalCode",
            width: 280,
            colId: "consigneePostalCode"
        },
        {
            headerName: "Consignee (City)",
            field: "consigneeCity",
            width: 220,
            colId: "consigneeCity"
        },
        // {
        //     headerName: "Consignee (Province)",
        //     field: "consigneeProvince",
        //     width: 250,
        //     colId: "consigneeProvince"
        // },
        {
            headerName: "Consignee (Country)",
            field: "consigneeCountry",
            width: 250,
            colId: "consigneeCountry"
        },
        // {
        //     headerName: "Consignee (Booking Reference)",
        //     field: "consigneeBookingReference",
        //     width: 330,
        //     colId: "consigneeBookingReference"
        // },
        // {
        //     headerName: "Consignee (Hall)",
        //     field: "consigneeHall",
        //     width: 250,
        //     colId: "consigneeHall"
        // },
        // {
        //     headerName: "Consignee (Plant)",
        //     field: "consigneePlant",
        //     width: 250,
        //     colId: "consigneePlant"
        // },
        // {
        //     headerName: "Consignee (Dock)",
        //     field: "consigneeDock",
        //     width: 250,
        //     colId: "consigneeDock"
        // },
        {
            headerName: "Destination (Name)",
            field: "destinationName",
            width: 250,
            colId: "destinationName"
        },
        {
            headerName: "Destination (Postal Code)",
            field: "destinationPostalCode",
            width: 280,
            colId: "destinationPostalCode"
        },
        {
            headerName: "Destination (City)",
            field: "destinationCity",
            width: 220,
            colId: "destinationCity"
        },
        {
            headerName: "Destination (Province)",
            field: "destinationProvince",
            width: 250,
            colId: "destinationProvince"
        },
        {
            headerName: "Destination (Country)",
            field: "destinationCountry",
            width: 250,
            colId: "destinationCountry"
        },
        // {
        //     headerName: "Destination (Booking Reference)",
        //     field: "destinationBookingReference",
        //     width: 330,
        //     colId: "destinationBookingReference"
        // },
        // {
        //     headerName: "Destination (Hall)",
        //     field: "destinationHall",
        //     width: 250,
        //     colId: "destinationHall"
        // },
        // {
        //     headerName: "Destination (Plant)",
        //     field: "destinationPlant",
        //     width: 250,
        //     colId: "destinationPlant"
        // },
        // {
        //     headerName: "Destination (Dock)",
        //     field: "destinationDock",
        //     width: 250,
        //     colId: "destinationDock"
        // },
        // {
        //     headerName: "Unloading Halle",
        //     field: "unloadingHalle",
        //     width: 225
        // },
        // {
        //     headerName: "Unloading Dock",
        //     field: "moloUnloading",
        //     width: 220
        // },
        {
            headerName: "Requested Early Pickup Date",
            field: "requestedEarlyPickUpDate",
            cellRenderer: (data) => {
                return data.value != ' ' ? moment(data.value).format('DD/MM/YYYY HH:mm') : ""
            },
            width: 300,
            valueFormatter: dateValueFormatter,
            colId: "requestedEarlyPickUpDate"
        },
        {
            headerName: "Requested Late Pickup Date",
            field: "requestedLatePickUpDate",
            cellRenderer: (data) => {
                return data.value != ' ' ? moment(data.value).format('DD/MM/YYYY HH:mm') : ""
            },
            width: 300,
            valueFormatter: dateValueFormatter,
            colId: "requestedLatePickUpDate"
        },
        {
            headerName: "Actual Pickup Date",
            field: "actualPickUpDate",
            cellRenderer: (data) => {
                return data.value != ' ' ? moment(data.value).format('DD/MM/YYYY HH:mm') : ""
            },
            width: 240,
            valueFormatter: dateValueFormatter,
            colId: "actualPickUpDate"
        },
        {
            headerName: "Requested Early Delivery Date",
            field: "requestedEarlyDeliveryDate",
            cellRenderer: (data) => {
                return data.value != ' ' ? moment(data.value).format('DD/MM/YYYY HH:mm') : ""
            },
            width: 320,
            valueFormatter: dateValueFormatter,
            colId: "requestedEarlyDeliveryDate"
        },
        {
            headerName: "Requested Late Delivery Date",
            field: "requestedLateDeliveryDate",
            cellRenderer: (data) => {
                return data.value != ' ' ? moment(data.value).format('DD/MM/YYYY HH:mm') : ""
            },
            width: 315,
            valueFormatter: dateValueFormatter,
            colId: "requestedLateDeliveryDate"
        },
        {
            headerName: "Actual Delivery Date",
            field: "actualDeliveryDate",
            cellRenderer: (data) => {
                return data.value != ' ' ? moment(data.value).format('DD/MM/YYYY HH:mm') : ""
            },
            width: 250,
            valueFormatter: dateValueFormatter,
            colId: "actualDeliveryDate"
        },
        {
            headerName: "Gross Weight",
            field: "grossWeight",
            width: 210,
            colId: "grossWeight"
        },
        {
            headerName: "Volume",
            field: "volume",
            width: 170,
            colId: "volume"
        },
        {
            headerName: "Loading Meters",
            field: "loadingMeters",
            width: 220,
            colId: "loadingMeters"
        },
        {
            headerName: "Total Ship Unit Count",
            field: "totaleShipUnitCount",
            width: 260,
            colId: "totaleShipUnitCount"
        },
        // {
        //     headerName: "Last Event",
        //     field: "lastEvent",
        //     width: 190,
        //     colId: "lastEvent"
        // },
        {
            headerName: "First Trip",
            field: "firstTrip",
            width: 190,
            colId: "firstTrip"
        },
        {
            headerName: "Last Trip",
            field: "lastTrip",
            width: 190,
            colId: "lastTrip"
        },
        {
            headerName: "Last Leg Type",
            field: "lastTripType",
            width: 220,
            colId: "lastTripType"
        },
        {
            headerName: "Owner Branch",
            field: "ownerBranch",
            width: 210,
            colId: "ownerBranch"
        },
        // {
        //     headerName: "Business Flow Type",
        //     field: "businessFlowType",
        //     width: 210,
        //     colId: "businessFlowType"
        // },
        // {
        //     headerName: "Direction",
        //     field: "direction",
        //     width: 210,
        //     colId: "direction"
        // },
        // {
        //     headerName: "TMS Competence Date",
        //     field: "tmsCompetenceDate",
        //     width: 210,
        //     valueFormatter: dateValueFormatterNoTime,
        //     colId: "tmsCompetenceDate"
        // },
        {
            headerName: "Lane",
            field: "lane",
            width: 210,
            colId: "lane"
        },
        // {
        //     headerName: "Customer Administrative Reference",
        //     field: "customerAdministrativeReference",
        //     width: 300,
        //     colId: "customerAdministrativeReference"
        // },
        {
            headerName: "Customer Main Order Reference",
            field: "customerMainOrderReference",
            width: 285,
            colId: "customerMainOrderReference"
        },
        // {
        //     headerName: "Handling Order Reference",
        //     field: "handlingOrderReference",
        //     width: 255,
        //     colId: "handlingOrderReference"
        // },
        // {
        //     headerName: "Transport Mode",
        //     field: "transportMode",
        //     width: 210,
        //     colId: "transportMode"
        // },
        {
            headerName: "TMS Order Insert User",
            field: "tmsOrderInsertUser",
            width: 250,
            colId: "tmsOrderInsertUser"
        },
        {
            headerName: "TMS Order Insert Date",
            field: "tmsOrderInsertDate",
            cellRenderer: (data) => {
                return data.value != ' ' ? moment(data.value).format('DD/MM/YYYY HH:mm') : ""
            },
            width: 250,
            valueFormatter: dateValueFormatter,
            colId: "tmsOrderInsertDate"
        },
        {
            headerName: "TMS Collection Insert User",
            field: "tmsCollectionInsertUser",
            width: 250,
            colId: "tmsCollectionInsertUser"
        },
        {
            headerName: "TMS Collection Insert Date",
            field: "tmsCollectionInsertDate",
            cellRenderer: (data) => {
                return moment(data.value).isValid() ? moment(data.value).format('DD/MM/YYYY HH:mm') : data.value != "" ? data.value : ""
            },
            width: 250,
            valueFormatter: dateValueFormatter,
            colId: "tmsCollectionInsertDate"
        },
        // {
        //     headerName: "TMS Status",
        //     field: "tmsStatus",
        //     width: 250,
        //     colId: "tmsStatus"
        // },
        // {
        //     headerName: "Last Revision Date",
        //     field: "lastRevisionDate",
        //     width: 250,
        //     valueFormatter: dateValueFormatter,
        //     colId: "lastRevisionDate"
        // },
        {
            headerName: "Requested Asset Type",
            field: "requestedAssetType",
            width: 210,
            colId: "requestedAssetType"
        },
        {
            headerName: "Service Type",
            field: "serviceType",
            width: 210,
            colId: "serviceType"
        },
        // {
        //     headerName: "Priority",
        //     field: "priority",
        //     width: 210,
        //     colId: "priority"
        // },
        {
            headerName: "Booking Service",
            field: "bookingService",
            colId: "bookingService",
            width: 210
        },
        {
            headerName: "Booking Result",
            field: "bookingResult",
            colId: "bookingResult",
            width: 210
        },
        {
            headerName: "Booking Date",
            field: "bookingDate",
            cellRenderer: (data) => {
                return data.value != ' ' ? moment(data.value).format('DD/MM/YYYY HH:mm') : ""
            },
            colId: "bookingDate",
            valueFormatter: dateValueFormatter,
            width: 210
        },
        {
            headerName: "Business Flow Type",
            field: "businessFlowType",
            width: 200,
            colId: "businessFlowType"
        },
        {
            headerName: "Last Leg Status",
            field: "lastLegStatus",
            width: 200,
            colId: "lastLegStatus"
        }
        ,
        {
            headerName: "Legacy Code",
            field: "legacyCode",
            width: 170,
            colId: "legacyCode"
        }
    ];

    var filteredCols = cols;
    if (hiddenCols && hiddenCols.length > 0)
        filteredCols = cols.filter(c => hiddenCols.indexOf(c.field) < 0);

    return filteredCols;
};

export const TOExecutionColumnsGrid = (hiddenCols, valuesObj = {}, currentColumnOrder = []) => {
    var cols = [
        {
            headerName: "TMS Order ID",
            field: "tmsOrderId",
            width: 200,
            colId: "tmsOrderId",
            filter: "agSetColumnFilter",
            filterParams: {
                refreshValuesOnOpen: true,
                cellRenderer: MyFilterCellRenderer,
                values: valuesObj['tmsOrderId'] || []
            }
        },
        {
            headerName: "TMS Collection ID",
            field: "collectionId",
            width: 210,
            colId: "collectionId",
            filter: "agSetColumnFilter",
            filterParams: {
                refreshValuesOnOpen: true,
                cellRenderer: MyFilterCellRenderer,
                values: valuesObj['collectionId'] || []
            }
        },
        {
            headerName: "Customer",
            field: "customer",
            width: 285,
            colId: "customer",
            cellRenderer: (params) => {
                return params.value.includes("BaCliFor") ? params.value.split('>').pop() : params.value;
            },
            tooltipValueGetter: (params) => {
                return params.value.includes("BaCliFor") ? params.value.split('>').pop() : params.value;
            },
            filter: "agSetColumnFilter",
            filterParams: {
                refreshValuesOnOpen: true,
                cellRenderer: MyFilterCellRenderer,
                values: valuesObj['customer'] || []
            },
        },
        {
            headerName: "TMS Customer",
            field: "tmsCustomer",
            width: 285,
            colId: "tmsCustomer",
            filter: "agSetColumnFilter",
            filterParams: {
                refreshValuesOnOpen: true,
                cellRenderer: MyFilterCellRenderer,
                values: valuesObj['tmsCustomer'] || []
            }
        },
        {
            headerName: "Status",
            field: "status",
            width: 190,
            colId: "status",
            filter: "agSetColumnFilter",
            filterParams: {
                refreshValuesOnOpen: true,
                cellRenderer: MyFilterCellRenderer,
                values: valuesObj['status'] || []
            }
        },
        {
            headerName: "Reference 1",
            field: "reference1",
            width: 210,
            colId: "reference1",
            filter: "agSetColumnFilter",
            filterParams: {
                refreshValuesOnOpen: true,
                cellRenderer: MyFilterCellRenderer,
                values: valuesObj['reference1'] || []
            }
        },
        {
            headerName: "Reference 2",
            field: "reference2",
            width: 210,
            colId: "reference2",
            filter: "agSetColumnFilter",
            filterParams: {
                refreshValuesOnOpen: true,
                cellRenderer: MyFilterCellRenderer,
                values: valuesObj['reference2'] || []
            }
        },
        {
            headerName: "Reference 3",
            field: "reference3",
            width: 210,
            colId: "reference3",
            filter: "agSetColumnFilter",
            filterParams: {
                refreshValuesOnOpen: true,
                cellRenderer: MyFilterCellRenderer,
                values: valuesObj['reference3'] || []
            }
        },
        {
            headerName: "Sender (Name)",
            field: "shipperName",
            width: 285,
            colId: "shipperName",
            filter: "agSetColumnFilter",
            filterParams: {
                refreshValuesOnOpen: true,
                cellRenderer: MyFilterCellRenderer,
                values: valuesObj['shipperName'] || []
            }
        },
        {
            headerName: "Sender (Postal Code)",
            field: "shipperPostalCode",
            width: 260,
            colId: "shipperPostalCode",
            filter: "agSetColumnFilter",
            filterParams: {
                refreshValuesOnOpen: true,
                cellRenderer: MyFilterCellRenderer,
                values: valuesObj['shipperPostalCode'] || []
            }
        },
        {
            headerName: "Sender (City)",
            field: "shipperCity",
            width: 210,
            colId: "shipperCity",
            filter: "agSetColumnFilter",
            filterParams: {
                refreshValuesOnOpen: true,
                cellRenderer: MyFilterCellRenderer,
                values: valuesObj['shipperCity'] || []
            }
        },
        {
            headerName: "Sender (Country)",
            field: "shipperCountry",
            width: 235,
            colId: "shipperCountry",
            filter: "agSetColumnFilter",
            filterParams: {
                refreshValuesOnOpen: true,
                cellRenderer: MyFilterCellRenderer,
                values: valuesObj['shipperCountry'] || []
            }
        },
        {
            headerName: "Origin (Name)",
            field: "originName",
            width: 285,
            colId: "originName",
            filter: "agSetColumnFilter",
            filterParams: {
                refreshValuesOnOpen: true,
                cellRenderer: MyFilterCellRenderer,
                values: valuesObj['originName'] || []
            }
        },
        {
            headerName: "Origin (Postal Code)",
            field: "originPostalCode",
            width: 260,
            colId: "originPostalCode",
            filter: "agSetColumnFilter",
            filterParams: {
                refreshValuesOnOpen: true,
                cellRenderer: MyFilterCellRenderer,
                values: valuesObj['originPostalCode'] || []
            }
        },
        {
            headerName: "Origin (City)",
            field: "originCity",
            width: 210,
            colId: "originCity",
            filter: "agSetColumnFilter",
            filterParams: {
                refreshValuesOnOpen: true,
                cellRenderer: MyFilterCellRenderer,
                values: valuesObj['originCity'] || []
            }
        },
        {
            headerName: "Origin (Province)",
            field: "originProvince",
            width: 235,
            colId: "originProvince",
            filter: "agSetColumnFilter",
            filterParams: {
                refreshValuesOnOpen: true,
                cellRenderer: MyFilterCellRenderer,
                values: valuesObj['originProvince'] || []
            }
        },
        {
            headerName: "Origin (Country)",
            field: "originCountry",
            width: 235,
            colId: "originCountry",
            filter: "agSetColumnFilter",
            filterParams: {
                refreshValuesOnOpen: true,
                cellRenderer: MyFilterCellRenderer,
                values: valuesObj['originCountry'] || []
            }
        },
        {
            headerName: "Consignee (Name)",
            field: "consigneeName",
            width: 285,
            colId: "consigneeName",
            filter: "agSetColumnFilter",
            filterParams: {
                refreshValuesOnOpen: true,
                cellRenderer: MyFilterCellRenderer,
                values: valuesObj['consigneeName'] || []
            }
        },
        {
            headerName: "Consignee (Postal Code)",
            field: "consigneePostalCode",
            width: 280,
            colId: "consigneePostalCode",
            filter: "agSetColumnFilter",
            filterParams: {
                refreshValuesOnOpen: true,
                cellRenderer: MyFilterCellRenderer,
                values: valuesObj['consigneePostalCode'] || []
            }
        },
        {
            headerName: "Consignee (City)",
            field: "consigneeCity",
            width: 220,
            colId: "consigneeCity",
            filter: "agSetColumnFilter",
            filterParams: {
                refreshValuesOnOpen: true,
                cellRenderer: MyFilterCellRenderer,
                values: valuesObj['consigneeCity'] || []
            }
        },
        {
            headerName: "Consignee (Country)",
            field: "consigneeCountry",
            width: 250,
            colId: "consigneeCountry",
            filter: "agSetColumnFilter",
            filterParams: {
                refreshValuesOnOpen: true,
                cellRenderer: MyFilterCellRenderer,
                values: valuesObj['consigneeCountry'] || []
            }
        },
        {
            headerName: "Destination (Name)",
            field: "destinationName",
            width: 250,
            colId: "destinationName",
            filter: "agSetColumnFilter",
            filterParams: {
                refreshValuesOnOpen: true,
                cellRenderer: MyFilterCellRenderer,
                values: valuesObj['destinationName'] || []
            }
        },
        {
            headerName: "Destination (Postal Code)",
            field: "destinationPostalCode",
            width: 280,
            colId: "destinationPostalCode",
            filter: "agSetColumnFilter",
            filterParams: {
                refreshValuesOnOpen: true,
                cellRenderer: MyFilterCellRenderer,
                values: valuesObj['destinationPostalCode'] || []
            }
        },
        {
            headerName: "Destination (City)",
            field: "destinationCity",
            width: 220,
            colId: "destinationCity",
            filter: "agSetColumnFilter",
            filterParams: {
                refreshValuesOnOpen: true,
                cellRenderer: MyFilterCellRenderer,
                values: valuesObj['destinationCity'] || []
            }
        },
        {
            headerName: "Destination (Province)",
            field: "destinationProvince",
            width: 250,
            colId: "destinationProvince",
            filter: "agSetColumnFilter",
            filterParams: {
                refreshValuesOnOpen: true,
                cellRenderer: MyFilterCellRenderer,
                values: valuesObj['destinationProvince'] || []
            }
        },
        {
            headerName: "Destination (Country)",
            field: "destinationCountry",
            width: 250,
            colId: "destinationCountry",
            filter: "agSetColumnFilter",
            filterParams: {
                refreshValuesOnOpen: true,
                cellRenderer: MyFilterCellRenderer,
                values: valuesObj['destinationCountry'] || []
            }
        },
        {
            headerName: "Requested Early Pickup Date",
            field: "requestedEarlyPickUpDate",
            cellRenderer: (data) => {
                return data.value != ' ' ? moment(data.value).format('DD/MM/YYYY HH:mm') : ""
            },
            width: 300,
            valueFormatter: dateValueFormatter,
            colId: "requestedEarlyPickUpDate",
            filter: 'agDateColumnFilter',
            filterParams: {
                filterOptions: ['equals', 'lessThan', 'greaterThan', 'inRange', 'blank'],
                defaultOption: 'equals',
            },
        },
        {
            headerName: "Requested Late Pickup Date",
            field: "requestedLatePickUpDate",
            cellRenderer: (data) => {
                return data.value != ' ' ? moment(data.value).format('DD/MM/YYYY HH:mm') : ""
            },
            width: 300,
            valueFormatter: dateValueFormatter,
            colId: "requestedLatePickUpDate",
            filter: 'agDateColumnFilter',
            filterParams: {
                filterOptions: ['equals', 'lessThan', 'greaterThan', 'inRange', 'blank'],
                defaultOption: 'equals',
            },
        },
        {
            headerName: "Actual Pickup Date",
            field: "actualPickUpDate",
            cellRenderer: (data) => {
                return data.value != ' ' ? moment(data.value).format('DD/MM/YYYY HH:mm') : ""
            },
            width: 240,
            valueFormatter: dateValueFormatter,
            colId: "actualPickUpDate",
            filter: 'agDateColumnFilter',
            filterParams: {
                filterOptions: ['equals', 'lessThan', 'greaterThan', 'inRange', 'blank'],
                defaultOption: 'equals',
            },
        },
        {
            headerName: "Requested Early Delivery Date",
            field: "requestedEarlyDeliveryDate",
            cellRenderer: (data) => {
                return data.value != ' ' ? moment(data.value).format('DD/MM/YYYY HH:mm') : ""
            },
            width: 320,
            valueFormatter: dateValueFormatter,
            colId: "requestedEarlyDeliveryDate",
            filter: 'agDateColumnFilter',
            filterParams: {
                filterOptions: ['equals', 'lessThan', 'greaterThan', 'inRange', 'blank'],
                defaultOption: 'equals',
            },
        },
        {
            headerName: "Requested Late Delivery Date",
            field: "requestedLateDeliveryDate",
            cellRenderer: (data) => {
                return data.value != ' ' ? moment(data.value).format('DD/MM/YYYY HH:mm') : ""
            },
            width: 315,
            valueFormatter: dateValueFormatter,
            colId: "requestedLateDeliveryDate",
            filter: 'agDateColumnFilter',
            filterParams: {
                filterOptions: ['equals', 'lessThan', 'greaterThan', 'inRange', 'blank'],
                defaultOption: 'equals',
            },
        },
        {
            headerName: "Actual Delivery Date",
            field: "actualDeliveryDate",
            cellRenderer: (data) => {
                return data.value != ' ' ? moment(data.value).format('DD/MM/YYYY HH:mm') : ""
            },
            width: 250,
            valueFormatter: dateValueFormatter,
            colId: "actualDeliveryDate",
            filter: 'agDateColumnFilter',
            filterParams: {
                filterOptions: ['equals', 'lessThan', 'greaterThan', 'inRange', 'blank'],
                defaultOption: 'equals',
            },
        },
        {
            headerName: "Gross Weight",
            field: "grossWeight",
            width: 210,
            colId: "grossWeight",
            filter: "agSetColumnFilter",
            filterParams: {
                refreshValuesOnOpen: true,
                cellRenderer: MyFilterCellRenderer,
                values: valuesObj['grossWeight'] || []
            }
        },
        {
            headerName: "Volume",
            field: "volume",
            width: 170,
            colId: "volume",
            filter: "agSetColumnFilter",
            filterParams: {
                refreshValuesOnOpen: true,
                cellRenderer: MyFilterCellRenderer,
                values: valuesObj['volume'] || []
            }
        },
        {
            headerName: "Loading Meters",
            field: "loadingMeters",
            width: 220,
            colId: "loadingMeters",
            filter: "agSetColumnFilter",
            filterParams: {
                refreshValuesOnOpen: true,
                cellRenderer: MyFilterCellRenderer,
                values: valuesObj['loadingMeters'] || []
            }
        },
        {
            headerName: "Total Ship Unit Count",
            field: "totaleShipUnitCount",
            width: 260,
            colId: "totaleShipUnitCount",
            filter: "agSetColumnFilter",
            filterParams: {
                refreshValuesOnOpen: true,
                cellRenderer: MyFilterCellRenderer,
                values: valuesObj['totaleShipUnitCount'] || []
            }
        },
        {
            headerName: "First Trip",
            field: "firstTrip",
            width: 190,
            colId: "firstTrip",
            filter: "agSetColumnFilter",
            filterParams: {
                refreshValuesOnOpen: true,
                cellRenderer: MyFilterCellRenderer,
                values: valuesObj['firstTrip'] || []
            }
        },
        {
            headerName: "Last Trip",
            field: "lastTrip",
            width: 190,
            colId: "lastTrip",
            filter: "agSetColumnFilter",
            filterParams: {
                refreshValuesOnOpen: true,
                cellRenderer: MyFilterCellRenderer,
                values: valuesObj['lastTrip'] || []
            }
        },
        {
            headerName: "Last Leg Type",
            field: "lastTripType",
            width: 220,
            colId: "lastTripType",
            filter: "agSetColumnFilter",
            filterParams: {
                refreshValuesOnOpen: true,
                cellRenderer: MyFilterCellRenderer,
                values: valuesObj['lastTripType'] || []
            }
        },
        {
            headerName: "Owner Branch",
            field: "ownerBranch",
            width: 210,
            colId: "ownerBranch",
            filter: "agSetColumnFilter",
            filterParams: {
                refreshValuesOnOpen: true,
                cellRenderer: MyFilterCellRenderer,
                values: valuesObj['ownerBranch'] || []
            }
        },
        {
            headerName: "Lane",
            field: "lane",
            width: 210,
            colId: "lane",
            filter: "agSetColumnFilter",
            filterParams: {
                refreshValuesOnOpen: true,
                cellRenderer: MyFilterCellRenderer,
                values: valuesObj['lane'] || []
            }
        },
        {
            headerName: "Customer Main Order Reference",
            field: "customerMainOrderReference",
            width: 285,
            colId: "customerMainOrderReference",
            filter: "agSetColumnFilter",
            filterParams: {
                refreshValuesOnOpen: true,
                cellRenderer: MyFilterCellRenderer,
                values: valuesObj['customerMainOrderReference'] || []
            }
        },
        {
            headerName: "TMS Order Insert User",
            field: "tmsOrderInsertUser",
            width: 250,
            colId: "tmsOrderInsertUser",
            filter: "agSetColumnFilter",
            filterParams: {
                refreshValuesOnOpen: true,
                cellRenderer: MyFilterCellRenderer,
                values: valuesObj['tmsOrderInsertUser'] || []
            }
        },
        {
            headerName: "TMS Order Insert Date",
            field: "tmsOrderInsertDate",
            cellRenderer: (data) => {
                return data.value != ' ' ? moment(data.value).format('DD/MM/YYYY HH:mm') : ""
            },
            width: 250,
            valueFormatter: dateValueFormatter,
            colId: "tmsOrderInsertDate",
            filter: 'agDateColumnFilter',
            filterParams: {
                filterOptions: ['equals', 'lessThan', 'greaterThan', 'inRange', 'blank'],
                defaultOption: 'equals',
            },
        },
        {
            headerName: "TMS Collection Insert User",
            field: "tmsCollectionInsertUser",
            width: 250,
            colId: "tmsCollectionInsertUser",
            filter: "agSetColumnFilter",
            filterParams: {
                refreshValuesOnOpen: true,
                cellRenderer: MyFilterCellRenderer,
                values: valuesObj['tmsCollectionInsertUser'] || []
            }
        },
        {
            headerName: "TMS Collection Insert Date",
            field: "tmsCollectionInsertDate",
            cellRenderer: (data) => {
                return moment(data.value).isValid() ? moment(data.value).format('DD/MM/YYYY HH:mm') : data.value != "" ? data.value : ""
            },
            width: 250,
            valueFormatter: dateValueFormatter,
            colId: "tmsCollectionInsertDate",
            filter: 'agDateColumnFilter',
            filterParams: {
                filterOptions: ['equals', 'lessThan', 'greaterThan', 'inRange', 'blank'],
                defaultOption: 'equals',
            },
        },
        {
            headerName: "Requested Asset Type",
            field: "requestedAssetType",
            width: 210,
            colId: "requestedAssetType",
            filter: "agSetColumnFilter",
            filterParams: {
                refreshValuesOnOpen: true,
                cellRenderer: MyFilterCellRenderer,
                values: valuesObj['requestedAssetType'] || []
            }
        },
        {
            headerName: "Service Type",
            field: "serviceType",
            width: 210,
            colId: "serviceType",
            filter: "agSetColumnFilter",
            filterParams: {
                refreshValuesOnOpen: true,
                cellRenderer: MyFilterCellRenderer,
                values: valuesObj['serviceType'] || []
            }
        },
        {
            headerName: "Booking Service",
            field: "bookingService",
            width: 210,
            colId: "bookingService",
            filter: "agSetColumnFilter",
            filterParams: {
                refreshValuesOnOpen: true,
                cellRenderer: MyFilterCellRenderer,
                values: valuesObj['bookingService'] || []
            }
        },
        {
            headerName: "Booking Result",
            field: "bookingResult",
            width: 210,
            colId: "bookingResult",
            filter: "agSetColumnFilter",
            filterParams: {
                refreshValuesOnOpen: true,
                cellRenderer: MyFilterCellRenderer,
                values: valuesObj['bookingResult'] || []
            }
        },
        {
            headerName: "Booking Date",
            field: "bookingDate",
            cellRenderer: (data) => {
                return data.value != ' ' ? moment(data.value).format('DD/MM/YYYY HH:mm') : ""
            },
            width: 210,
            valueFormatter: dateValueFormatter,
            colId: "bookingDate",
            filter: 'agDateColumnFilter',
            filterParams: {
                filterOptions: ['equals', 'lessThan', 'greaterThan', 'inRange', 'blank'],
                defaultOption: 'equals',
            },
        },
        {
            headerName: "Business Flow Type",
            field: "businessFlowType",
            width: 200,
            colId: "businessFlowType",
            filter: "agSetColumnFilter",
            filterParams: {
                refreshValuesOnOpen: true,
                cellRenderer: MyFilterCellRenderer,
                values: valuesObj['businessFlowType'] || []
            }
        },
        {
            headerName: "Last Leg Status",
            field: "lastLegStatus",
            width: 200,
            colId: "lastLegStatus",
            filter: "agSetColumnFilter",
            filterParams: {
                refreshValuesOnOpen: true,
                cellRenderer: MyFilterCellRenderer,
                values: valuesObj['lastLegStatus'] || []
            }
        },
        {
            headerName: "Legacy Code",
            field: "legacyCode",
            width: 170,
            colId: "legacyCode",
            filter: "agSetColumnFilter",
            filterParams: {
                refreshValuesOnOpen: true,
                cellRenderer: MyFilterCellRenderer,
                values: valuesObj['legacyCode'] || []
            }
        }
    ];

    // Filtro le colonne nascoste
    var filteredCols = cols;
    if (hiddenCols && hiddenCols.length > 0)
        filteredCols = cols.filter(c => hiddenCols.indexOf(c.field) < 0);

    // Ordino le colonne secondo l'ordine attuale
    if (currentColumnOrder.length > 0) {
        filteredCols.sort((a, b) => {
            const indexA = currentColumnOrder.indexOf(a.colId);
            const indexB = currentColumnOrder.indexOf(b.colId);
            return indexA - indexB;
        });
    }

    return filteredCols;
};


export const TOCustomerColumnsGrid = (hiddenCols, valuesObj = {}, currentColumnOrder = []) => {
    var cols = [
        {
            headerName: "Transport Order Number",
            field: "transportOrderNumber",
            width: 250,
            colId: "transportOrderNumber",
            filter: "agSetColumnFilter",
            filterParams: {
                refreshValuesOnOpen: true,
                cellRenderer: MyFilterCellRenderer,
                values: !!valuesObj['transportOrderNumber'] ? valuesObj['transportOrderNumber'] : []
            }
        },
        {
            headerName: "Is Manual",
            field: "isManual",
            cellRenderer: (data) => {
                return data.value == '0' ? 'NO' : data.value == '1' ? "YES" : ""
            },
            width: 150,
            colId: "isManual",
            tooltipValueGetter: (data) => {
                return data.value == '0' ? 'NO' : data.value == '1' ? "YES" : ""
            },
            filter: "agSetColumnFilter",
            filterParams: {
                refreshValuesOnOpen: true,
                cellRenderer: MyFilterCellRenderer,
                values: !!valuesObj['isManual'] ? valuesObj['isManual'] : []
            }
        },
        {
            headerName: "Contract",
            field: "contract",
            width: 200,
            colId: "contract",
            filter: "agSetColumnFilter",
            filterParams: {
                refreshValuesOnOpen: true,
                cellRenderer: MyFilterCellRenderer,
                values: !!valuesObj['contract'] ? valuesObj['contract'] : []
            }
        },
        {
            headerName: "Customer",
            field: "customer",
            width: 250,
            colId: "customer",
            cellRenderer: (params) => {
                return params.value.includes("BaCliFor") ? params.value.split('>').pop() : params.value;
            },
            tooltipValueGetter: (params) => {
                return params.value.includes("BaCliFor") ? params.value.split('>').pop() : params.value;
            },
            filter: "agSetColumnFilter",
            filterParams: {
                refreshValuesOnOpen: true,
                cellRenderer: MyFilterCellRenderer,
                values: !!valuesObj['customer'] ? valuesObj['customer'] : []
            }
        },
        {
            headerName: "Tms Customer",
            field: "tmsCustomer",
            width: 250,
            colId: "tmsCustomer",
            filter: "agSetColumnFilter",
            filterParams: {
                refreshValuesOnOpen: true,
                cellRenderer: MyFilterCellRenderer,
                values: !!valuesObj['tmsCustomer'] ? valuesObj['tmsCustomer'] : []
            }
        },
        {
            headerName: "Business Flow Type",
            field: "businessFlowType",
            width: 200,
            colId: "businessFlowType",
            filter: "agSetColumnFilter",
            filterParams: {
                refreshValuesOnOpen: true,
                cellRenderer: MyFilterCellRenderer,
                values: !!valuesObj['businessFlowType'] ? valuesObj['businessFlowType'] : []
            }
        },
        {
            headerName: "Priority",
            field: "priority",
            width: 150,
            colId: "priority",
            filter: "agSetColumnFilter",
            filterParams: {
                refreshValuesOnOpen: true,
                cellRenderer: MyFilterCellRenderer,
                values: !!valuesObj['priority'] ? valuesObj['priority'] : []
            }
        },
        {
            headerName: "Order Reference 1",
            field: "orderReference1",
            width: 250,
            colId: "orderReference1",
            filter: "agSetColumnFilter",
            filterParams: {
                refreshValuesOnOpen: true,
                cellRenderer: MyFilterCellRenderer,
                values: !!valuesObj['orderReference1'] ? valuesObj['orderReference1'] : []
            }
        },
        {
            headerName: "Order Reference 2",
            field: "orderReference2",
            width: 250,
            colId: "orderReference2",
            filter: "agSetColumnFilter",
            filterParams: {
                refreshValuesOnOpen: true,
                cellRenderer: MyFilterCellRenderer,
                values: !!valuesObj['orderReference2'] ? valuesObj['orderReference2'] : []
            }
        },
        {
            headerName: "Order Reference 3",
            field: "orderReference3",
            width: 250,
            colId: "orderReference3",
            filter: "agSetColumnFilter",
            filterParams: {
                refreshValuesOnOpen: true,
                cellRenderer: MyFilterCellRenderer,
                values: !!valuesObj['orderReference3'] ? valuesObj['orderReference3'] : []
            }
        },
        {
            headerName: "Good Type Desc",
            field: "goodsTypeDesc",
            width: 250,
            colId: "goodsTypeDesc",
            filter: "agSetColumnFilter",
            filterParams: {
                refreshValuesOnOpen: true,
                cellRenderer: MyFilterCellRenderer,
                values: !!valuesObj['goodsTypeDesc'] ? valuesObj['goodsTypeDesc'] : []
            }
        },
        {
            headerName: "Early Pickup Date",
            field: "earlyPickupDate",
            cellRenderer: (data) => {
                return data.value != ' ' ? moment(data.value).format('DD/MM/YYYY HH:mm') : ""
            },
            width: 250,
            valueFormatter: dateValueFormatter,
            colId: "earlyPickupDate",
            filter: 'agDateColumnFilter',
            filterParams: {
                filterOptions: ['equals', 'lessThan', 'greaterThan', 'inRange', 'blank'],
                defaultOption: 'equals',
            },
        },
        {
            headerName: "Late Pickup Date",
            field: "latePickupDate",
            cellRenderer: (data) => {
                return data.value != ' ' ? moment(data.value).format('DD/MM/YYYY HH:mm') : ""
            },
            width: 250,
            valueFormatter: dateValueFormatter,
            colId: "latePickupDate",
            filter: 'agDateColumnFilter',
            filterParams: {
                filterOptions: ['equals', 'lessThan', 'greaterThan', 'inRange', 'blank'],
                defaultOption: 'equals',
            },
        },
        {
            headerName: "Early Delivery Date",
            field: "earlyDeliveryDate",
            cellRenderer: (data) => {
                return data.value != ' ' ? moment(data.value).format('DD/MM/YYYY HH:mm') : ""
            },
            width: 250,
            valueFormatter: dateValueFormatter,
            colId: "earlyDeliveryDate",
            filter: 'agDateColumnFilter',
            filterParams: {
                filterOptions: ['equals', 'lessThan', 'greaterThan', 'inRange', 'blank'],
                defaultOption: 'equals',
            },
        },
        {
            headerName: "Late Delivery Date",
            field: "lateDeliveryDate",
            cellRenderer: (data) => {
                return data.value != ' ' ? moment(data.value).format('DD/MM/YYYY HH:mm') : ""
            },
            width: 250,
            valueFormatter: dateValueFormatter,
            colId: "lateDeliveryDate",
            filter: 'agDateColumnFilter',
            filterParams: {
                filterOptions: ['equals', 'lessThan', 'greaterThan', 'inRange', 'blank'],
                defaultOption: 'equals',
            },
        },
        {
            headerName: "Total Weight",
            field: "totalWeight",
            width: 200,
            colId: "totalWeight",
            filter: "agSetColumnFilter",
            filterParams: {
                refreshValuesOnOpen: true,
                cellRenderer: MyFilterCellRenderer,
                values: !!valuesObj['totalWeight'] ? valuesObj['totalWeight'] : []
            }
        },
        {
            headerName: "Total Weight UOM",
            field: "totalWeightUom",
            width: 200,
            colId: "totalWeightUom",
            filter: "agSetColumnFilter",
            filterParams: {
                refreshValuesOnOpen: true,
                cellRenderer: MyFilterCellRenderer,
                values: !!valuesObj['totalWeightUom'] ? valuesObj['totalWeightUom'] : []
            }
        },
        {
            headerName: "Total Volume",
            field: "totalVolume",
            width: 200,
            colId: "totalVolume",
            filter: "agSetColumnFilter",
            filterParams: {
                refreshValuesOnOpen: true,
                cellRenderer: MyFilterCellRenderer,
                values: !!valuesObj['totalVolume'] ? valuesObj['totalVolume'] : []
            }
        },
        {
            headerName: "Total Volume UOM",
            field: "totalVolumeUom",
            width: 200,
            colId: "totalVolumeUom",
            filter: "agSetColumnFilter",
            filterParams: {
                refreshValuesOnOpen: true,
                cellRenderer: MyFilterCellRenderer,
                values: !!valuesObj['totalVolumeUom'] ? valuesObj['totalVolumeUom'] : []
            }
        },
        {
            headerName: "Total Linear Dimension",
            field: "totalLinearDimension",
            width: 250,
            colId: "totalLinearDimension",
            filter: "agSetColumnFilter",
            filterParams: {
                refreshValuesOnOpen: true,
                cellRenderer: MyFilterCellRenderer,
                values: !!valuesObj['totalLinearDimension'] ? valuesObj['totalLinearDimension'] : []
            }
        },
        {
            headerName: "Total Linear Dimension UOM",
            field: "totalLinearDimensionUOM",
            width: 250,
            colId: "totalLinearDimensionUOM",
            filter: "agSetColumnFilter",
            filterParams: {
                refreshValuesOnOpen: true,
                cellRenderer: MyFilterCellRenderer,
                values: !!valuesObj['totalLinearDimensionUOM'] ? valuesObj['totalLinearDimensionUOM'] : []
            }
        },
        {
            headerName: "Total Ship Unit Count",
            field: "totalShipUnitCount",
            width: 250,
            colId: "totalShipUnitCount",
            filter: "agSetColumnFilter",
            filterParams: {
                refreshValuesOnOpen: true,
                cellRenderer: MyFilterCellRenderer,
                values: !!valuesObj['totalShipUnitCount'] ? valuesObj['totalShipUnitCount'] : []
            }
        },
        {
            headerName: "Customer Incoterm",
            field: "customerIncoterm",
            width: 250,
            colId: "customerIncoterm",
            filter: "agSetColumnFilter",
            filterParams: {
                refreshValuesOnOpen: true,
                cellRenderer: MyFilterCellRenderer,
                values: !!valuesObj['customerIncoterm'] ? valuesObj['customerIncoterm'] : []
            }
        },
        {
            headerName: "Customer Administrative Reference",
            field: "customerAdministrativeReference",
            width: 300,
            colId: "customerAdministrativeReference",
            filter: "agSetColumnFilter",
            filterParams: {
                refreshValuesOnOpen: true,
                cellRenderer: MyFilterCellRenderer,
                values: !!valuesObj['customerAdministrativeReference'] ? valuesObj['customerAdministrativeReference'] : []
            }
        },
        {
            headerName: "Customer Collection ID",
            field: "customerCollectionID",
            width: 250,
            colId: "customerCollectionID",
            filter: "agSetColumnFilter",
            filterParams: {
                refreshValuesOnOpen: true,
                cellRenderer: MyFilterCellRenderer,
                values: !!valuesObj['customerCollectionID'] ? valuesObj['customerCollectionID'] : []
            }
        },
        {
            headerName: "Customer Direct ID",
            field: "customerDirectID",
            width: 250,
            colId: "customerDirectID",
            filter: "agSetColumnFilter",
            filterParams: {
                refreshValuesOnOpen: true,
                cellRenderer: MyFilterCellRenderer,
                values: !!valuesObj['customerDirectID'] ? valuesObj['customerDirectID'] : []
            }
        },
        {
            headerName: "Customer Linehaul ID",
            field: "customerLinehaulID",
            width: 250,
            colId: "customerLinehaulID",
            filter: "agSetColumnFilter",
            filterParams: {
                refreshValuesOnOpen: true,
                cellRenderer: MyFilterCellRenderer,
                values: !!valuesObj['customerLinehaulID'] ? valuesObj['customerLinehaulID'] : []
            }
        },
        {
            headerName: "Customer Main Order Reference",
            field: "customerMainOrderReference",
            width: 300,
            colId: "customerMainOrderReference",
            filter: "agSetColumnFilter",
            filterParams: {
                refreshValuesOnOpen: true,
                cellRenderer: MyFilterCellRenderer,
                values: !!valuesObj['customerMainOrderReference'] ? valuesObj['customerMainOrderReference'] : []
            }
        },
        {
            headerName: "Transport Mode",
            field: "transportMode",
            width: 200,
            colId: "transportMode",
            filter: "agSetColumnFilter",
            filterParams: {
                refreshValuesOnOpen: true,
                cellRenderer: MyFilterCellRenderer,
                values: !!valuesObj['transportMode'] ? valuesObj['transportMode'] : []
            }
        },
        {
            headerName: "Requested Equipment",
            field: "requestedEquipment",
            width: 250,
            colId: "requestedEquipment",
            filter: "agSetColumnFilter",
            filterParams: {
                refreshValuesOnOpen: true,
                cellRenderer: MyFilterCellRenderer,
                values: !!valuesObj['requestedEquipment'] ? valuesObj['requestedEquipment'] : []
            }
        },
        // Sender
        {
            headerName: "Sender (Name)",
            field: "senderName",
            width: 260,
            colId: "senderName",
            filter: "agSetColumnFilter",
            filterParams: {
                refreshValuesOnOpen: true,
                cellRenderer: MyFilterCellRenderer,
                values: !!valuesObj['senderName'] ? valuesObj['senderName'] : []
            }
        },
        {
            headerName: "Sender (Postal Code)",
            field: "senderZipcode",
            width: 260,
            colId: "senderZipcode",
            filter: "agSetColumnFilter",
            filterParams: {
                refreshValuesOnOpen: true,
                cellRenderer: MyFilterCellRenderer,
                values: !!valuesObj['senderZipcode'] ? valuesObj['senderZipcode'] : []
            }
        },
        {
            headerName: "Sender (City)",
            field: "senderCity",
            width: 260,
            colId: "senderCity",
            filter: "agSetColumnFilter",
            filterParams: {
                refreshValuesOnOpen: true,
                cellRenderer: MyFilterCellRenderer,
                values: !!valuesObj['senderCity'] ? valuesObj['senderCity'] : []
            }
        },
        {
            headerName: "Sender (Country)",
            field: "senderCountryCode",
            width: 260,
            colId: "senderCountryCode",
            filter: "agSetColumnFilter",
            filterParams: {
                refreshValuesOnOpen: true,
                cellRenderer: MyFilterCellRenderer,
                values: !!valuesObj['senderCountryCode'] ? valuesObj['senderCountryCode'] : []
            }
        },

        // Origin
        {
            headerName: "Origin (Name)",
            field: "originName",
            width: 260,
            colId: "originName",
            filter: "agSetColumnFilter",
            filterParams: {
                refreshValuesOnOpen: true,
                cellRenderer: MyFilterCellRenderer,
                values: !!valuesObj['originName'] ? valuesObj['originName'] : []
            }
        },
        {
            headerName: "Origin (Postal Code)",
            field: "originZipcode",
            width: 260,
            colId: "originZipcode",
            filter: "agSetColumnFilter",
            filterParams: {
                refreshValuesOnOpen: true,
                cellRenderer: MyFilterCellRenderer,
                values: !!valuesObj['originZipcode'] ? valuesObj['originZipcode'] : []
            }
        },
        {
            headerName: "Origin (City)",
            field: "originCity",
            width: 260,
            colId: "originCity",
            filter: "agSetColumnFilter",
            filterParams: {
                refreshValuesOnOpen: true,
                cellRenderer: MyFilterCellRenderer,
                values: !!valuesObj['originCity'] ? valuesObj['originCity'] : []
            }
        },
        {
            headerName: "Origin (Province)",
            field: "originProvince",
            width: 260,
            colId: "originProvince",
            filter: "agSetColumnFilter",
            filterParams: {
                refreshValuesOnOpen: true,
                cellRenderer: MyFilterCellRenderer,
                values: !!valuesObj['originProvince'] ? valuesObj['originProvince'] : []
            }
        },
        {
            headerName: "Origin (Country)",
            field: "originCountryCode",
            width: 260,
            colId: "originCountryCode",
            filter: "agSetColumnFilter",
            filterParams: {
                refreshValuesOnOpen: true,
                cellRenderer: MyFilterCellRenderer,
                values: !!valuesObj['originCountryCode'] ? valuesObj['originCountryCode'] : []
            }
        },

        // Destination
        {
            headerName: "Destination (Name)",
            field: "destinationName",
            width: 260,
            colId: "destinationName",
            filter: "agSetColumnFilter",
            filterParams: {
                refreshValuesOnOpen: true,
                cellRenderer: MyFilterCellRenderer,
                values: !!valuesObj['destinationName'] ? valuesObj['destinationName'] : []
            }
        },
        {
            headerName: "Destination (Postal Code)",
            field: "destinationZipcode",
            width: 260,
            colId: "destinationZipcode",
            filter: "agSetColumnFilter",
            filterParams: {
                refreshValuesOnOpen: true,
                cellRenderer: MyFilterCellRenderer,
                values: !!valuesObj['destinationZipcode'] ? valuesObj['destinationZipcode'] : []
            }
        },
        {
            headerName: "Destination (City)",
            field: "destinationCity",
            width: 260,
            colId: "destinationCity",
            filter: "agSetColumnFilter",
            filterParams: {
                refreshValuesOnOpen: true,
                cellRenderer: MyFilterCellRenderer,
                values: !!valuesObj['destinationCity'] ? valuesObj['destinationCity'] : []
            }
        },
        {
            headerName: "Destination (Province)",
            field: "destinationProvince",
            width: 260,
            colId: "destinationProvince",
            filter: "agSetColumnFilter",
            filterParams: {
                refreshValuesOnOpen: true,
                cellRenderer: MyFilterCellRenderer,
                values: !!valuesObj['destinationProvince'] ? valuesObj['destinationProvince'] : []
            }
        },
        {
            headerName: "Destination (Country)",
            field: "destinationCountryCode",
            width: 260,
            colId: "destinationCountryCode",
            filter: "agSetColumnFilter",
            filterParams: {
                refreshValuesOnOpen: true,
                cellRenderer: MyFilterCellRenderer,
                values: !!valuesObj['destinationCountryCode'] ? valuesObj['destinationCountryCode'] : []
            }
        },

        // Consignee
        {
            headerName: "Consignee (Name)",
            field: "consigneeName",
            width: 260,
            colId: "consigneeName",
            filter: "agSetColumnFilter",
            filterParams: {
                refreshValuesOnOpen: true,
                cellRenderer: MyFilterCellRenderer,
                values: !!valuesObj['consigneeName'] ? valuesObj['consigneeName'] : []
            }
        },
        {
            headerName: "Consignee (Postal Code)",
            field: "consigneeZipcode",
            width: 260,
            colId: "consigneeZipcode",
            filter: "agSetColumnFilter",
            filterParams: {
                refreshValuesOnOpen: true,
                cellRenderer: MyFilterCellRenderer,
                values: !!valuesObj['consigneeZipcode'] ? valuesObj['consigneeZipcode'] : []
            }
        },
        {
            headerName: "Consignee (City)",
            field: "consigneeCity",
            width: 260,
            colId: "consigneeCity",
            filter: "agSetColumnFilter",
            filterParams: {
                refreshValuesOnOpen: true,
                cellRenderer: MyFilterCellRenderer,
                values: !!valuesObj['consigneeCity'] ? valuesObj['consigneeCity'] : []
            }
        },
        {
            headerName: "Consignee (Country)",
            field: "consigneeCountryCode",
            width: 260,
            colId: "consigneeCountryCode",
            filter: "agSetColumnFilter",
            filterParams: {
                refreshValuesOnOpen: true,
                cellRenderer: MyFilterCellRenderer,
                values: !!valuesObj['consigneeCountryCode'] ? valuesObj['consigneeCountryCode'] : []
            }
        }
    ];

    // Filtro le colonne nascoste
    var filteredCols = cols;
    if (hiddenCols && hiddenCols.length > 0)
        filteredCols = cols.filter(c => hiddenCols.indexOf(c.field) < 0);

    // Ordino le colonne secondo l'ordine attuale
    if (currentColumnOrder.length > 0) {
        filteredCols.sort((a, b) => {
            const indexA = currentColumnOrder.indexOf(a.colId);
            const indexB = currentColumnOrder.indexOf(b.colId);
            return indexA - indexB;
        });
    }

    return filteredCols;
};

export const TOColumnsGridLite = (hiddenCols) => {
    var cols = [
        {
            headerName: "TMS Order ID",
            field: "tmsOrderId",
            width: 200,
            colId: "tmsOrderId"
        },
        {
            headerName: "TMS Collection ID",
            field: "collectionId",
            width: 210,
            colId: "collectionId"
        },
        {
            headerName: "Customer Collection ID",
            field: "customerCollectionID",
            width: 222,
            colId: "customerCollectionID"
        },
        {
            headerName: "Customer Direct ID",
            field: "customerDirectID",
            width: 200,
            colId: "customerDirectID"
        },
        {
            headerName: "Customer Linehaul ID",
            field: "customerLinehaulID",
            width: 205,
            colId: "customerLinehaulID"
        },
        {
            headerName: "TMS Customer",
            field: "tmsCustomer",
            width: 285,
            colId: "tmsCustomer"
        },
        {
            headerName: "Reference 1",
            field: "reference1",
            width: 210,
            colId: "reference1"
        },
        {
            headerName: "Reference 2",
            field: "reference2",
            width: 210,
            colId: "reference2"
        },
        {
            headerName: "Reference 3",
            field: "reference3",
            width: 210,
            colId: "reference3"
        },
        {
            headerName: "Requested Early Pickup Date",
            field: "requestedEarlyPickUpDate",
            cellRenderer: (data) => {
                return data.value != ' ' ? moment(data.value).format('DD/MM/YYYY HH:mm') : ""
            },
            width: 300,
            valueFormatter: dateValueFormatter,
            colId: "requestedEarlyPickUpDate"
        },
        {
            headerName: "Requested Late Pickup Date",
            field: "requestedLatePickUpDate",
            cellRenderer: (data) => {
                return data.value != ' ' ? moment(data.value).format('DD/MM/YYYY HH:mm') : ""
            },
            width: 300,
            valueFormatter: dateValueFormatter,
            colId: "requestedLatePickUpDate"
        },
        {
            headerName: "Actual Pickup Date",
            field: "actualPickUpDate",
            cellRenderer: (data) => {
                return data.value != ' ' ? moment(data.value).format('DD/MM/YYYY HH:mm') : ""
            },
            width: 240,
            valueFormatter: dateValueFormatter,
            colId: "actualPickUpDate"
        },
        {
            headerName: "Requested Early Delivery Date",
            field: "requestedEarlyDeliveryDate",
            cellRenderer: (data) => {
                return data.value != ' ' ? moment(data.value).format('DD/MM/YYYY HH:mm') : ""
            },
            width: 320,
            valueFormatter: dateValueFormatter,
            colId: "requestedEarlyDeliveryDate"
        },
        {
            headerName: "Requested Late Delivery Date",
            field: "requestedLateDeliveryDate",
            cellRenderer: (data) => {
                return data.value != ' ' ? moment(data.value).format('DD/MM/YYYY HH:mm') : ""
            },
            width: 315,
            valueFormatter: dateValueFormatter,
            colId: "requestedLateDeliveryDate"
        },
        {
            headerName: "Actual Delivery Date",
            field: "actualDeliveryDate",
            cellRenderer: (data) => {
                return data.value != ' ' ? moment(data.value).format('DD/MM/YYYY HH:mm') : ""
            },
            width: 250,
            valueFormatter: dateValueFormatter,
            colId: "actualDeliveryDate"
        },
        {
            headerName: "Owner Branch",
            field: "ownerBranch",
            width: 210,
            colId: "ownerBranch"
        },
        {
            headerName: "Business Flow Type",
            field: "businessFlowType",
            width: 210,
            colId: "businessFlowType"
        },
        {
            headerName: "Direction",
            field: "direction",
            width: 210,
            colId: "direction"
        },
        {
            headerName: "TMS Competence Date",
            field: "tmsCompetenceDate",
            width: 210,
            valueFormatter: dateValueFormatterNoTime,
            colId: "tmsCompetenceDate"
        },
        {
            headerName: "Lane",
            field: "lane",
            width: 210,
            colId: "lane"
        },
        {
            headerName: "Customer Administrative Reference",
            field: "customerAdministrativeReference",
            width: 300,
            colId: "customerAdministrativeReference"
        },
        {
            headerName: "Customer Main Order Reference",
            field: "customerMainOrderReference",
            width: 285,
            colId: "customerMainOrderReference"
        },
        {
            headerName: "Transport Mode",
            field: "transportMode",
            width: 210,
            colId: "transportMode"
        },
        {
            headerName: "TMS Order Insert User",
            field: "tmsOrderInsertUser",
            width: 250,
            colId: "tmsOrderInsertUser"
        },
        {
            headerName: "TMS Order Insert Date",
            field: "tmsOrderInsertDate",
            cellRenderer: (data) => {
                return data.value != ' ' ? moment(data.value).format('DD/MM/YYYY HH:mm') : ""
            },
            width: 250,
            valueFormatter: dateValueFormatter,
            colId: "tmsOrderInsertDate"
        },
        {
            headerName: "TMS Collection Insert User",
            field: "tmsCollectionInsertUser",
            width: 250,
            colId: "tmsCollectionInsertUser"
        },
        {
            headerName: "TMS Collection Insert Date",
            field: "tmsCollectionInsertDate",
            cellRenderer: (data) => {
                return data.value != ' ' ? moment(data.value).format('DD/MM/YYYY HH:mm') : ""
            },
            width: 250,
            valueFormatter: dateValueFormatter,
            colId: "tmsCollectionInsertDate"
        },
        {
            headerName: "Last Revision Date",
            field: "lastRevisionDate",
            cellRenderer: (data) => {
                return data.value != ' ' ? moment(data.value).format('DD/MM/YYYY HH:mm') : ""
            },
            width: 250,
            valueFormatter: dateValueFormatter,
            colId: "lastRevisionDate"
        },
        {
            headerName: "Requested Asset Type",
            field: "requestedAssetType",
            width: 210,
            colId: "requestedAssetType"
        },
        {
            headerName: "Priority",
            field: "priority",
            width: 210,
            colId: "priority"
        },
    ];

    var filteredCols = cols;
    if (hiddenCols && hiddenCols.length > 0)
        filteredCols = cols.filter(c => hiddenCols.indexOf(c.field) < 0);

    return filteredCols;
};

export const SOColumnsGrid = () => {
    return [
        {
            headerName: "External SO",
            field: "externalSO",
            width: 200,
            colId: "externalSO"
        },
        {
            headerName: "Customer",
            field: "customer",
            width: 285,
            colId: "customer"
        },
        {
            headerName: "Brand",
            field: "brand",
            width: 190,
            colId: "brand"
        },
        {
            headerName: "TO Exec",
            field: "toExecutionNumber",
            width: 170,
            colId: "toExecutionNumber"
        },
        {
            headerName: "Request Date",
            field: "requestDate",
            cellRenderer: (data) => {
                return data.value != ' ' ? moment(data.value).format('DD/MM/YYYY HH:mm') : ""
            },
            valueFormatter: dateValueFormatter,
            width: 210,
            colId: "requestDate"
        },
        {
            headerName: "Response Date",
            field: "responseDate",
            cellRenderer: (data) => {
                return data.value != ' ' ? moment(data.value).format('DD/MM/YYYY HH:mm') : ""
            },
            valueFormatter: dateValueFormatter,
            width: 210,
            colId: "responseDate"
        },
        {
            headerName: "Commodity",
            field: "commodity",
            width: 190,
            colId: "commodity"
        },
        {
            headerName: "Revision",
            field: "revision",
            width: 190,
            colId: "revision"
        },
        {
            headerName: "Insert Subject",
            field: "insertSubject",
            width: 220,
            colId: "insertSubject"
        },
        {
            headerName: "Customer Incoterm",
            field: "customerIncoterm",
            width: 250,
            colId: "customerIncoterm"
        },
        {
            headerName: "Preplanned Trip Id",
            field: "preplannedTripId",
            width: 245,
            colId: "preplannedTripId"
        },
        {
            headerName: "Total Weight",
            field: "totalWeight",
            width: 230,
            colId: "totalWeight"
        },
        {
            headerName: "Total Volume",
            field: "totalVolume",
            width: 230,
            colId: "totalVolume"
        },
        {
            headerName: "Linear Meters",
            field: "linearMeters",
            width: 210,
            colId: "linearMeters"
        },
        {
            headerName: "Total Ship Unit Count",
            field: "totalShipUnitCount",
            width: 255,
            colId: "totalShipUnitCount"
        },
        {
            headerName: "Flow Type",
            field: "flowType",
            width: 190,
            colId: "flowType"
        },
        {
            headerName: "Status",
            field: "status",
            width: 190,
            colId: "status"
        }
    ]
};

export const SOColumnsGridSSR = (valuesObj = {}, currentColumnOrder = []) => {
    var cols = [
        {
            headerName: "External SO",
            field: "externalSalesOrderId",
            width: 200,
            colId: "externalSalesOrderId",
            filter: "agSetColumnFilter",
            filterParams: {
                refreshValuesOnOpen: true,
                cellRenderer: MyFilterCellRenderer,
                values: !!valuesObj['externalSalesOrderId'] ? valuesObj['externalSalesOrderId'] : []
            }
        },
        {
            headerName: "Customer",
            field: "customer",
            width: 285,
            colId: "customer",
            filter: "agSetColumnFilter",
            filterParams: {
                refreshValuesOnOpen: true,
                cellRenderer: MyFilterCellRenderer,
                values: !!valuesObj['customer'] ? valuesObj['customer'] : []
            }
        },
        {
            headerName: "Brand",
            field: "brand",
            width: 190,
            colId: "brand",
            filter: "agSetColumnFilter",
            filterParams: {
                refreshValuesOnOpen: true,
                cellRenderer: MyFilterCellRenderer,
                values: !!valuesObj['brand'] ? valuesObj['brand'] : []
            }
        },
        {
            headerName: "TO Exec",
            field: "toExecutionNumber",
            width: 170,
            colId: "toExecutionNumber",
            filter: "agSetColumnFilter",
            filterParams: {
                refreshValuesOnOpen: true,
                cellRenderer: MyFilterCellRenderer,
                values: !!valuesObj['toExecutionNumber'] ? valuesObj['toExecutionNumber'] : []
            }
        },
        {
            headerName: "Request Date",
            field: "requestDate",
            cellRenderer: (data) => {
                return data.value != ' ' ? moment(data.value).format('DD/MM/YYYY HH:mm') : ""
            },
            width: 250,
            valueFormatter: dateValueFormatter,
            colId: "requestDate",
            filter: 'agDateColumnFilter',
            filterParams: {
                filterOptions: ['equals', 'lessThan', 'greaterThan', 'inRange', 'blank'],
                defaultOption: 'equals',
            },
        },
        {
            headerName: "Response Date",
            field: "expectedResponseDate",
            cellRenderer: (data) => {
                return data.value != ' ' ? moment(data.value).format('DD/MM/YYYY HH:mm') : ""
            },
            width: 250,
            valueFormatter: dateValueFormatter,
            colId: "expectedResponseDate",
            filter: 'agDateColumnFilter',
            filterParams: {
                filterOptions: ['equals', 'lessThan', 'greaterThan', 'inRange', 'blank'],
                defaultOption: 'equals',
            },
        },
        {
            headerName: "Commodity",
            field: "commodity",
            width: 190,
            colId: "commodity",
            filter: "agSetColumnFilter",
            filterParams: {
                refreshValuesOnOpen: true,
                cellRenderer: MyFilterCellRenderer,
                values: !!valuesObj['commodity'] ? valuesObj['commodity'] : []
            }
        },
        {
            headerName: "Revision",
            field: "revision",
            width: 190,
            colId: "revision",
            filter: "agSetColumnFilter",
            filterParams: {
                refreshValuesOnOpen: true,
                cellRenderer: MyFilterCellRenderer,
                values: !!valuesObj['revision'] ? valuesObj['revision'] : []
            }
        },
        {
            headerName: "Insert Subject",
            field: "soInsertSubject",
            width: 220,
            colId: "soInsertSubject",
            filter: "agSetColumnFilter",
            filterParams: {
                refreshValuesOnOpen: true,
                cellRenderer: MyFilterCellRenderer,
                values: !!valuesObj['soInsertSubject'] ? valuesObj['soInsertSubject'] : []
            }
        },
        {
            headerName: "Customer Incoterm",
            field: "customerIncoterm",
            width: 250,
            colId: "customerIncoterm",
            filter: "agSetColumnFilter",
            filterParams: {
                refreshValuesOnOpen: true,
                cellRenderer: MyFilterCellRenderer,
                values: !!valuesObj['customerIncoterm'] ? valuesObj['customerIncoterm'] : []
            }
        },
        {
            headerName: "Preplanned Trip Id",
            field: "preplannedTrip",
            width: 245,
            colId: "preplannedTrip",
            filter: "agSetColumnFilter",
            filterParams: {
                refreshValuesOnOpen: true,
                cellRenderer: MyFilterCellRenderer,
                values: !!valuesObj['preplannedTrip'] ? valuesObj['preplannedTrip'] : []
            }
        },
        {
            headerName: "Total Weight (KG)",
            field: "totalWeight",
            width: 230,
            colId: "totalWeight",
            filter: "agSetColumnFilter",
            filterParams: {
                refreshValuesOnOpen: true,
                cellRenderer: MyFilterCellRenderer,
                values: !!valuesObj['totalWeight'] ? valuesObj['totalWeight'] : []
            }
        },
        {
            headerName: "Total Volume (M3)",
            field: "totalVolume",
            width: 230,
            colId: "totalVolume",
            filter: "agSetColumnFilter",
            filterParams: {
                refreshValuesOnOpen: true,
                cellRenderer: MyFilterCellRenderer,
                values: !!valuesObj['totalVolume'] ? valuesObj['totalVolume'] : []
            }
        },
        {
            headerName: "Linear Meters",
            field: "linearMeters",
            width: 210,
            colId: "linearMeters",
            filter: "agSetColumnFilter",
            filterParams: {
                refreshValuesOnOpen: true,
                cellRenderer: MyFilterCellRenderer,
                values: !!valuesObj['linearMeters'] ? valuesObj['linearMeters'] : []
            }
        },
        {
            headerName: "Total Ship Unit Count",
            field: "totalShipUnitCont",
            width: 255,
            colId: "totalShipUnitCont",
            filter: "agSetColumnFilter",
            filterParams: {
                refreshValuesOnOpen: true,
                cellRenderer: MyFilterCellRenderer,
                values: !!valuesObj['totalShipUnitCont'] ? valuesObj['totalShipUnitCont'] : []
            }
        },
        {
            headerName: "Flow Type",
            field: "businessFlowType",
            width: 190,
            colId: "businessFlowType",
            filter: "agSetColumnFilter",
            filterParams: {
                refreshValuesOnOpen: true,
                cellRenderer: MyFilterCellRenderer,
                values: !!valuesObj['businessFlowType'] ? valuesObj['businessFlowType'] : []
            }
        }
        /*  {
             headerName: "Status",
             field: "status",
             width: 190,
             colId: "status"
         }, */
        /* {
        headerName: "Trip Id",
        field: "tmsObjectNumber",
        width: 250,
        colId: "tmsObjectNumber",
        filter: "agSetColumnFilter",
        filterParams: {
            refreshValuesOnOpen: true,
            cellRenderer: MyFilterCellRenderer,
            values: !!valuesObj['tmsObjectNumber'] ? valuesObj['tmsObjectNumber'] : []
        }
    
        }   
        */
    ];

    var filteredCols = cols;

    // Ordino le colonne secondo l'ordine attuale
    if (currentColumnOrder.length > 0) {
        filteredCols.sort((a, b) => {
            const indexA = currentColumnOrder.indexOf(a.colId);
            const indexB = currentColumnOrder.indexOf(b.colId);
            return indexA - indexB;
        });
    }

    return filteredCols;
};

export const TripColumnsGrid = () => {
    return [
        {
            headerName: "Trip Id",
            field: "tripId",
            width: 250,
            colId: "tripId"
        },
        {
            headerName: "Carrier Name",
            field: "carrierName",
            width: 355,
            colId: "carrierName"
        },
        {
            headerName: "Actual Departure",
            field: "actualDeparture",
            cellRenderer: (data) => {
                return data.value != ' ' ? moment(data.value).format('DD/MM/YYYY HH:mm') : ""
            },
            width: 266,
            sort: "desc",
            valueFormatter: dateValueFormatter,
            colId: "actualDeparture",
            filter: 'agDateColumnFilter',
            filterParams: {
                filterOptions: ['equals', 'lessThan', 'greaterThan', 'inRange', 'blank'],
                defaultOption: 'equals',
            },
        },
        {
            headerName: "Actual Arrival",
            field: "actualArrival",
            cellRenderer: (data) => {
                return data.value != ' ' ? moment(data.value).format('DD/MM/YYYY HH:mm') : ""
            },
            width: 266,
            valueFormatter: dateValueFormatter,
            colId: "actualArrival"
        },
        {
            headerName: "Status",
            field: "status",
            width: 201,
            colId: "status"
        },
        {
            headerName: "Trip Description",
            field: "tripDesc",
            width: 479,
            colId: "tripDesc"
        },
        {
            headerName: "Expected Departure Date",
            field: "expectedStartDate",
            width: 248,
            cellRenderer: (data) => {
                return data.value != ' ' ? moment(data.value).format('DD/MM/YYYY HH:mm') : ""
            },
            valueFormatter: dateValueFormatter,
            colId: "expectedStartDate"
        },
        {
            headerName: "Expected Arrival Date",
            field: "expectedArrival",
            cellRenderer: (data) => {
                return data.value != ' ' ? moment(data.value).format('DD/MM/YYYY HH:mm') : ""
            },
            width: 228,
            valueFormatter: dateValueFormatter,
            colId: "expectedArrival"
        },
        {
            headerName: "Total Weight",
            field: "totalWeight",
            width: 170,
            colId: "totalWeight"
        },
        {
            headerName: "Total Volume",
            field: "totalVolume",
            width: 172,
            colId: "totalVolume"
        },
        {
            headerName: "Origin (Name)",
            field: "invParty_OriginName",
            width: 393,
            colId: "invParty_OriginName"
        },
        // {
        //     headerName: "Origin (External Location)",
        //     field: "invParty_OriginExternalLocation",
        //     width: 256,
        //     colId: "invParty_OriginExternalLocation"
        // },
        {
            headerName: "Origin (Zip Code)",
            field: "address_OriginZipCode",
            width: 191,
            colId: "address_OriginZipCode"
        },
        {
            headerName: "Origin (Country Code)",
            field: "address_OriginCountryCode",
            width: 228,
            colId: "address_OriginCountryCode"
        },
        {
            headerName: "Origin (Province)",
            field: "address_OriginProvince",
            width: 196,
            colId: "address_OriginProvince"
        },
        {
            headerName: "Destination (Name)",
            field: "invParty_DestinationName",
            width: 393,
            colId: "invParty_DestinationName"
        },
        // {
        //     headerName: "Destination (External Location)",
        //     field: "invParty_DestinationExternalLocation",
        //     width: 291,
        //     colId: "invParty_DestinationExternalLocation"
        // },
        {
            headerName: "Destination (Zip Code)",
            field: "address_DestinationZipCode",
            width: 226,
            colId: "address_DestinationZipCode"
        },
        {
            headerName: "Destination (Country Code)",
            field: "address_DestinationCountryCode",
            width: 263,
            colId: "address_DestinationCountryCode"
        },
        {
            headerName: "Destination (Province)",
            field: "address_DestinationProvince",
            width: 231,
            colId: "address_DestinationProvince"
        },
        {
            headerName: "TMS Competence Date",
            field: "tmsCompetenceDate",
            cellRenderer: (data) => {
                return data.value != ' ' ? moment(data.value).format('DD/MM/YYYY HH:mm') : ""
            },
            width: 275,
            sort: "desc",
            valueFormatter: dateValueFormatterNoTime,
            colId: "tmsCompetenceDate"
        },
        {
            headerName: "First Driver Name",
            field: "firstDriverName",
            width: 231,
            colId: "firstDriverName"
        },
        {
            headerName: "Second Driver Name",
            field: "secondDriverName",
            width: 231,
            colId: "secondDriverName"
        },
        {
            headerName: "TripMode",
            field: "tripMode",
            width: 231,
            colId: "tripMode"
        },
        {
            headerName: "Plate Number Truck",
            field: "plateNumberTruck",
            width: 231,
            colId: "plateNumberTruck"
        },
        {
            headerName: "Plate Number Trailer",
            field: "plateNumberSemiTrailer",
            width: 231,
            colId: "plateNumberSemiTrailer"
        }
    ]
};

export const TripColumnsGridSSR = (valuesObj = {}, currentColumnOrder = []) => {
    var cols = [
        {
            headerName: "Trip Id",
            field: "tmsObjectNumber",
            width: 250,
            colId: "tmsObjectNumber",
            filter: "agSetColumnFilter",
            filterParams: {
                refreshValuesOnOpen: true,
                cellRenderer: MyFilterCellRenderer,
                values: !!valuesObj['tmsObjectNumber'] ? valuesObj['tmsObjectNumber'] : []
            }
        },
        {
            headerName: "Carrier Name",
            field: "carrierName",
            width: 355,
            colId: "carrierName",
            filter: "agSetColumnFilter",
            filterParams: {
                refreshValuesOnOpen: true,
                cellRenderer: MyFilterCellRenderer,
                values: !!valuesObj['carrierName'] ? valuesObj['carrierName'] : []
            }
        },
        {
            headerName: "Actual Departure",
            field: "actualDeparture",
            cellRenderer: (data) => {
                return data.value != ' ' ? moment(data.value).format('DD/MM/YYYY HH:mm') : ""
            },
            width: 266,
            sort: "desc",
            valueFormatter: dateValueFormatter,
            colId: "actualDeparture",
            filter: 'agDateColumnFilter',
            filterParams: {
                filterOptions: ['equals', 'lessThan', 'greaterThan', 'inRange', 'blank'],
                defaultOption: 'equals',
            },
        },
        {
            headerName: "Actual Arrival",
            field: "actualArrival",
            cellRenderer: (data) => {
                return data.value != ' ' ? moment(data.value).format('DD/MM/YYYY HH:mm') : ""
            },
            width: 266,
            valueFormatter: dateValueFormatter,
            colId: "actualArrival",
            filter: 'agDateColumnFilter',
            filterParams: {
                filterOptions: ['equals', 'lessThan', 'greaterThan', 'inRange', 'blank'],
                defaultOption: 'equals',
            },
        },
        {
            headerName: "Status",
            field: "status",
            width: 201,
            colId: "status",
            filter: "agSetColumnFilter",
            filterParams: {
                refreshValuesOnOpen: true,
                cellRenderer: MyFilterCellRenderer,
                values: !!valuesObj['status'] ? valuesObj['status'] : []
            }
        },
        {
            headerName: "Trip Description",
            field: "tripDesc",
            width: 479,
            colId: "tripDesc",
            filter: "agSetColumnFilter",
            filterParams: {
                refreshValuesOnOpen: true,
                cellRenderer: MyFilterCellRenderer,
                values: !!valuesObj['tripDesc'] ? valuesObj['tripDesc'] : []
            }
        },
        {
            headerName: "Expected Departure Date",
            field: "expectedDeparture",
            width: 248,
            cellRenderer: (data) => {
                return data.value != ' ' ? moment(data.value).format('DD/MM/YYYY HH:mm') : ""
            },
            valueFormatter: dateValueFormatter,
            colId: "expectedDeparture",
            filter: 'agDateColumnFilter',
            filterParams: {
                filterOptions: ['equals', 'lessThan', 'greaterThan', 'inRange', 'blank'],
                defaultOption: 'equals',
            },
        },
        {
            headerName: "Expected Arrival Date",
            field: "expectedArrival",
            cellRenderer: (data) => {
                return data.value != ' ' ? moment(data.value).format('DD/MM/YYYY HH:mm') : ""
            },
            width: 228,
            valueFormatter: dateValueFormatter,
            colId: "expectedArrival",
            filter: 'agDateColumnFilter',
            filterParams: {
                filterOptions: ['equals', 'lessThan', 'greaterThan', 'inRange', 'blank'],
                defaultOption: 'equals',
            },
        },
        {
            headerName: "Total Weight",
            field: "totalWeight",
            width: 170,
            colId: "totalWeight",
            filter: "agSetColumnFilter",
            filterParams: {
                refreshValuesOnOpen: true,
                cellRenderer: MyFilterCellRenderer,
                values: !!valuesObj['totalWeight'] ? valuesObj['totalWeight'] : []
            }
        },
        {
            headerName: "Total Volume",
            field: "totalVolume",
            width: 172,
            colId: "totalVolume",
            filter: "agSetColumnFilter",
            filterParams: {
                refreshValuesOnOpen: true,
                cellRenderer: MyFilterCellRenderer,
                values: !!valuesObj['totalVolume'] ? valuesObj['totalVolume'] : []
            }
        },
        {
            headerName: "Origin (Name)",
            field: "originInvPartyName",
            width: 393,
            colId: "originInvPartyName",
            filter: "agSetColumnFilter",
            filterParams: {
                refreshValuesOnOpen: true,
                cellRenderer: MyFilterCellRenderer,
                values: !!valuesObj['originInvPartyName'] ? valuesObj['originInvPartyName'] : []
            }
        },
        {
            headerName: "Origin (Zip Code)",
            field: "originPostalCode",
            width: 191,
            colId: "originPostalCode",
            filter: "agSetColumnFilter",
            filterParams: {
                refreshValuesOnOpen: true,
                cellRenderer: MyFilterCellRenderer,
                values: !!valuesObj['originPostalCode'] ? valuesObj['originPostalCode'] : []
            }
        },
        {
            headerName: "Origin (Country Code)",
            field: "originCountry",
            width: 228,
            colId: "originCountry",
            filter: "agSetColumnFilter",
            filterParams: {
                refreshValuesOnOpen: true,
                cellRenderer: MyFilterCellRenderer,
                values: !!valuesObj['originCountry'] ? valuesObj['originCountry'] : []
            }
        },
        {
            headerName: "Origin (Province)",
            field: "originProvince",
            width: 196,
            colId: "originProvince",
            filter: "agSetColumnFilter",
            filterParams: {
                refreshValuesOnOpen: true,
                cellRenderer: MyFilterCellRenderer,
                values: !!valuesObj['originProvince'] ? valuesObj['originProvince'] : []
            }
        },
        {
            headerName: "Destination (Name)",
            field: "destinationInvPartyName",
            width: 393,
            colId: "destinationInvPartyName",
            filter: "agSetColumnFilter",
            filterParams: {
                refreshValuesOnOpen: true,
                cellRenderer: MyFilterCellRenderer,
                values: !!valuesObj['destinationInvPartyName'] ? valuesObj['destinationInvPartyName'] : []
            }
        },
        {
            headerName: "Destination (Zip Code)",
            field: "destinationPostalCode",
            width: 226,
            colId: "destinationPostalCode",
            filter: "agSetColumnFilter",
            filterParams: {
                refreshValuesOnOpen: true,
                cellRenderer: MyFilterCellRenderer,
                values: !!valuesObj['destinationPostalCode'] ? valuesObj['destinationPostalCode'] : []
            }
        },
        {
            headerName: "Destination (Country Code)",
            field: "destinationCountry",
            width: 263,
            colId: "destinationCountry",
            filter: "agSetColumnFilter",
            filterParams: {
                refreshValuesOnOpen: true,
                cellRenderer: MyFilterCellRenderer,
                values: !!valuesObj['destinationCountry'] ? valuesObj['destinationCountry'] : []
            }
        },
        {
            headerName: "Destination (Province)",
            field: "destinationProvince",
            width: 231,
            colId: "destinationProvince",
            filter: "agSetColumnFilter",
            filterParams: {
                refreshValuesOnOpen: true,
                cellRenderer: MyFilterCellRenderer,
                values: !!valuesObj['destinationProvince'] ? valuesObj['destinationProvince'] : []
            }
        },
        {
            headerName: "TMS Competence Date",
            field: "tmsCompetenceDate",
            cellRenderer: (data) => {
                return data.value != ' ' ? moment(data.value).format('DD/MM/YYYY HH:mm') : ""
            },
            width: 275,
            sort: "desc",
            valueFormatter: dateValueFormatterNoTime,
            colId: "tmsCompetenceDate",
            filter: 'agDateColumnFilter',
            filterParams: {
                filterOptions: ['equals', 'lessThan', 'greaterThan', 'inRange', 'blank'],
                defaultOption: 'equals',
            },
        },
        {
            headerName: "First Driver Name",
            field: "firstDriverName",
            width: 231,
            colId: "firstDriverName",
            filter: "agSetColumnFilter",
            filterParams: {
                refreshValuesOnOpen: true,
                cellRenderer: MyFilterCellRenderer,
                values: !!valuesObj['firstDriverName'] ? valuesObj['firstDriverName'] : []
            }
        },
        {
            headerName: "Second Driver Name",
            field: "secondDriverName",
            width: 231,
            colId: "secondDriverName",
            filter: "agSetColumnFilter",
            filterParams: {
                refreshValuesOnOpen: true,
                cellRenderer: MyFilterCellRenderer,
                values: !!valuesObj['secondDriverName'] ? valuesObj['secondDriverName'] : []
            }
        },
        {
            headerName: "TripMode",
            field: "tripMode",
            width: 231,
            colId: "tripMode",
            filter: "agSetColumnFilter",
            filterParams: {
                refreshValuesOnOpen: true,
                cellRenderer: MyFilterCellRenderer,
                values: !!valuesObj['tripMode'] ? valuesObj['tripMode'] : []
            }
        },
        {
            headerName: "Plate Number Truck",
            field: "plateNumberTruck",
            width: 231,
            colId: "plateNumberTruck",
            filter: "agSetColumnFilter",
            filterParams: {
                refreshValuesOnOpen: true,
                cellRenderer: MyFilterCellRenderer,
                values: !!valuesObj['plateNumberTruck'] ? valuesObj['plateNumberTruck'] : []
            }
        },
        {
            headerName: "Plate Number Trailer",
            field: "plateNumberSemiTrailer",
            width: 231,
            colId: "plateNumberSemiTrailer",
            filter: "agSetColumnFilter",
            filterParams: {
                refreshValuesOnOpen: true,
                cellRenderer: MyFilterCellRenderer,
                values: !!valuesObj['plateNumberSemiTrailer'] ? valuesObj['plateNumberSemiTrailer'] : []
            }
        },
        /*
        {
            headerName: "Dispatcher",
            field: "dispatcher",
            width: 355,
            colId: "dispatcher",
            filter: "agSetColumnFilter",
            filterParams: {
                refreshValuesOnOpen: true,
                cellRenderer: MyFilterCellRenderer,
                values: !!valuesObj['dispatcher'] ? valuesObj['dispatcher'] : []
            }
        }
        */
    ]

    var filteredCols = cols;

    // Ordino le colonne secondo l'ordine attuale
    if (currentColumnOrder.length > 0) {
        filteredCols.sort((a, b) => {
            const indexA = currentColumnOrder.indexOf(a.colId);
            const indexB = currentColumnOrder.indexOf(b.colId);
            return indexA - indexB;
        });
    }

    return filteredCols;
};

export const EventMonitoringColumnsGrid = (handleOpenSUPopUp, handleGoToPageTO) => {
    return [
        {
            headerName: "",
            field: "huDetail",
            cellRenderer: renderViewSULink,
            width: 136,
            colId: "huDetail",
            onCellClicked: (evt) => { handleOpenSUPopUp(evt); }
        },
        {
            headerName: "Status",
            field: "status",
            //valueFormatter: statusEventMonitoringValueFormatter,
            width: 150,
            colId: "status",
            onCellClicked: (evt) => { handleGoToPageTO(evt); }
        },
        // {
        //     headerName: "Invalid Sequence",
        //     field: "invalidSequence",
        //     width: 250,
        //     valueFormatter: (value) => {return ""},
        //     colId: "invalidSequence"
        // },
        {
            headerName: "TMS Object Number",
            field: "tmsObjectNumber",
            width: 150,
            colId: "tmsObjectNumber",
            onCellClicked: (evt) => { handleGoToPageTO(evt); }
        },
        {
            headerName: "TMS Customer",
            field: "tmsCustomer",
            width: 150,
            colId: "tmsCustomer",
            onCellClicked: (evt) => { handleGoToPageTO(evt); }
        },
        {
            headerName: "Sender Name",
            field: "senderName",
            width: 150,
            colId: "senderName",
            onCellClicked: (evt) => { handleGoToPageTO(evt); }
        },
        {
            headerName: "Sender CAP",
            field: "senderCAP",
            width: 150,
            colId: "senderCAP",
            onCellClicked: (evt) => { handleGoToPageTO(evt); }
        },
        {
            headerName: "Sender City",
            field: "senderCity",
            width: 150,
            colId: "senderCity",
            onCellClicked: (evt) => { handleGoToPageTO(evt); }
        },
        {
            headerName: "Consignee Name",
            field: "consigneeName",
            width: 150,
            colId: "consigneeName",
            onCellClicked: (evt) => { handleGoToPageTO(evt); }
        },
        {
            headerName: "Consignee CAP",
            field: "consigneeCAP",
            width: 150,
            colId: "consigneeCAP",
            onCellClicked: (evt) => { handleGoToPageTO(evt); }
        },
        {
            headerName: "Consignee City",
            field: "consigneeCity",
            width: 150,
            colId: "consigneeCity",
            onCellClicked: (evt) => { handleGoToPageTO(evt); }
        },
        {
            headerName: "Late Pickup Date",
            field: "latePickupDate",
            width: 150,
            valueFormatter: dateValueFormatter,
            colId: "latePickupDate",
            onCellClicked: (evt) => { handleGoToPageTO(evt); }
        },
        {
            headerName: "Late Delivery Date",
            field: "lateDeliveryDate",
            width: 150,
            valueFormatter: dateValueFormatter,
            colId: "lateDeliveryDate",
            onCellClicked: (evt) => { handleGoToPageTO(evt); }
        },
        {
            headerName: "Last Trip",
            field: "lastTrip",
            width: 150,
            colId: "lastTrip",
            onCellClicked: (evt) => { handleGoToPageTO(evt); }
        },
        {
            headerName: "Last Leg Type",
            field: "lastLegType",
            width: 150,
            colId: "lastLegType",
            onCellClicked: (evt) => { handleGoToPageTO(evt); }
        }
    ]
};

export const SuEventMonitoringColumnsGrid = () => {
    return [
        /*{
            headerName: "Template Code",
            field: "templateCode",
            width: 250,
            colId: "templateCode"
        },
        {
            headerName: "Transport Order ID",
            field: "transportOrderID",
            width: 250,
            colId: "transportOrderID"
        },*/
        {
            headerName: "Ship Unit Id",
            field: "shipUnitId",
            width: 250,
            colId: "shipUnitId"
        },
        {
            headerName: "Event Type",
            field: "eventType",
            width: 250,
            colId: "eventType"
        },
        {
            headerName: "Sequence",
            field: "sequence",
            width: 250,
            colId: "sequence"
        },
        {
            headerName: "Date Expected",
            field: "dateExpected",
            width: 250,
            valueFormatter: dateValueFormatter,
            colId: "dateExpected"
        },
        {
            headerName: "Event Date Ship Unit",
            field: "eventDateHU",
            width: 250,
            valueFormatter: dateValueFormatter,
            colId: "eventDateHU"
        },
        {
            headerName: "Status Ship Unit",
            field: "statusHU",
            width: 250,
            colId: "statusHU"
        },
        {
            headerName: "Is Ship Unit",
            field: "isHU",
            width: 250,
            colId: "isHU"
        }
    ]
};

export const TOSortModel = [{
    colId: 'requestedLatePickUpDate',
    sort: 'desc',
},
{
    colId: 'requestedLateDeliveryDate',
    sort: 'desc',
}];

export const OrderVarianceColumnsGrid = () => {
    return [
        {
            headerName: "External SO",
            field: "externalSO",
            width: 200,
            colId: "externalSO"
        },
        {
            headerName: "Customer",
            field: "customer",
            width: 285,
            colId: "customer"
        },
        {
            headerName: "TMS Customer",
            field: "tmsCustomer",
            width: 285,
            colId: "tmsCustomer"
        },
        {
            headerName: "TMS Order ID",
            field: "tmsOrderId",
            width: 200,
            colId: "tmsOrderId"
        },
        {
            headerName: "Collection ID",
            field: "collectionId",
            width: 210,
            colId: "collectionId"
        },
        {
            headerName: "Sender (Name)",
            field: "senderName",
            width: 285,
            colId: "senderName"
        },
        {
            headerName: "Consignee (Name)",
            field: "consigneeName",
            width: 285,
            colId: "consigneeName"
        },
        {
            headerName: "Reference 1",
            field: "reference1",
            width: 210,
            colId: "reference1"
        },
        {
            headerName: "Reference 2",
            field: "reference2",
            width: 210,
            colId: "reference2"
        },
        {
            headerName: "Reference 3",
            field: "reference3",
            width: 210,
            colId: "reference3"
        },
        {
            headerName: "Kg Expected",
            field: "kgExpected",
            width: 230,
            colId: "kgExpected"
        },
        {
            headerName: "Kg Actual",
            field: "kgActual",
            width: 230,
            colId: "kgActual"
        },
        {
            headerName: "Kg Diff",
            field: "kgDiff",
            width: 230,
            colId: "kgDiff"
        },
        {
            headerName: "Kg Net Expected",
            field: "kgNetExpected",
            width: 230,
            colId: "kgNetExpected"
        },
        {
            headerName: "Kg Net Actual",
            field: "kgNetActual",
            width: 230,
            colId: "kgNetActual"
        },
        {
            headerName: "Kg Net Diff",
            field: "kgNetDiff",
            width: 230,
            colId: "kgNetDiff"
        },
        {
            headerName: "Vol Expected",
            field: "volExpected",
            width: 230,
            colId: "volExpected"
        },
        {
            headerName: "Vol Actual",
            field: "volActual",
            width: 230,
            colId: "volActual"
        },
        {
            headerName: "Vol Diff",
            field: "volDiff",
            width: 230,
            colId: "volDiff"
        },
        {
            headerName: "Q.ty Expected",
            field: "qtyExpected",
            width: 230,
            colId: "qtyExpected"
        },
        {
            headerName: "Q.ty Actual",
            field: "qtyActual",
            width: 230,
            colId: "qtyActual"
        },
        {
            headerName: "Q.ty Diff",
            field: "qtyDiff",
            width: 230,
            colId: "qtyDiff"
        },
        {
            headerName: "Ptax Expected",
            field: "ptaxExpected",
            width: 230,
            colId: "ptaxExpected"
        },
        {
            headerName: "Ptax Actual",
            field: "ptaxActual",
            width: 230,
            colId: "ptaxActual"
        },
        {
            headerName: "Ptax Diff",
            field: "ptaxDiff",
            width: 230,
            colId: "ptaxDiff"
        },
        {
            headerName: "LDM Expected",
            field: "ldmExpected",
            width: 230,
            colId: "ldmExpected"
        },
        {
            headerName: "LDM Actual",
            field: "ldmActual",
            width: 230,
            colId: "ldmActual"
        },
        {
            headerName: "LDM Diff",
            field: "ldmDiff",
            width: 230,
            colId: "ldmDiff"
        },
        {
            headerName: "Delta Factor",
            field: "deltafactor",
            width: 230,
            colId: "deltafactor",
            cellRenderer: (params) => {
                if (params.value && params.value.indexOf(';') >= 0) {
                    return "Multiple";
                }

                return params.value;
            }
        }
    ]
};