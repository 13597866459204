import React from "react";
import { Outlet } from "react-router-dom";
import { existClaim, hasPlanningToolAccess } from "../../utils/AuthUtils";
import UnauthorizedPage from "../pages/auth/UnauthorizedPage";

const PlanningToolAuthLayout: React.FC = () => {

    return (
        (!hasPlanningToolAccess()) ?
            <UnauthorizedPage missingClaim={!existClaim("email") ? "email" : null} app={"Planning Tool"}/>
            :
            <Outlet />
    );
}

export default PlanningToolAuthLayout;