export enum LegPlanningTypesEnum {
    To_Be_Planned = 1,
    Trip = 2,
    Network = 3,
    StandBy = 4
};

export enum LegTypesEnum {
    Collection = 1,
    Delivery = 2,
    Direct = 3,
    Shuttle = 4,
    Network = 5,
    Delivery_To_Partner = 6,
    Collection_From_Partner = 7,
    Shuttle_To_Partner = 8,
    Shuttle_From_Partner = 9
};

export enum LegStatusEnum {
	NOT_DISPATCHABLE = 1,
	DISPATCHABLE = 2,
	DISPATCHING = 3,
	DISPATCHED = 4,
	ERROR = 5,
	REPLANNING = 6,
};