import React, { useEffect, useRef, useState } from "react";
import { OriginDestinationLeg } from "../../../types/orders";
import useImageFlag from "../../../utils/hooks/useImageFlag";
import { ordersService } from "../../../api/ordersService/ordersService";

type Props = {
    suggestedPlanningOrderId: number | null;
    callbackClose: () => void;
}

type CustomerRoutingItemProps = {
    address: OriginDestinationLeg
}

const TableTooltipSuggestedPlanning: React.FC<Props> = ({ suggestedPlanningOrderId, callbackClose }) => {

    const [suggestedPlanning, setSuggestedPlanning] = useState<null | OriginDestinationLeg[]>(null);
    const [loading, setLoading] = useState(true);

    const canGo = useRef(true);
    useEffect(() => {
        if (canGo.current === true && suggestedPlanningOrderId !== null) {
            canGo.current = false;
            handleGetSetCustomerRouting();

            const root = document.getElementById('root');
            if (!!root) {
                root.style.height = '100vh';
                root.style.overflow = 'hidden';
            }

            const planningToolLayout = document.getElementById('planning-tool-layout');
            if (!!planningToolLayout) {
                planningToolLayout.style.zIndex = '999';
            }

            return () => {
                if (!!root) {
                    root.removeAttribute("style");
                }

                if (!!planningToolLayout) {
                    planningToolLayout.removeAttribute("style");
                }
            }
        }
    }, []);

    const handleGetSetCustomerRouting = async () => {
        if (suggestedPlanningOrderId !== null) {
            setLoading(true);
            const response = await ordersService.getCustomerRouting(suggestedPlanningOrderId);

            if (response.success) {
                setSuggestedPlanning(response.data);
            }
            setLoading(false);
        }
    }

    const CustomerRoutingItem: React.FC<CustomerRoutingItemProps> = ({ address }) => {
        const { image, loading } = useImageFlag(address ? address.countryCode : '');
        return (
            <div className="text-[13px] leading-[17px] py-2 flex items-center">
                <div className="flex items-center mb-1">
                    {
                        !loading &&
                        <div className="mr-1">
                            {/* @ts-ignore */}
                            <img src={image} alt="test" />
                        </div>
                    }
                    <div className="font-bold">
                        {address.name}
                    </div>
                </div>
                <div className="text-darkGrey mb-[3px] ml-2">
                    {address.countryCode} - {address.addressLine} - {address.zipCode} - {address.city}
                </div>
            </div>
        )
    }

    return (
        <div style={{ zIndex: 999 }} className="fixed bg-[#00000080] top-0 left-0 h-screen w-screen flex items-center justify-center">
            <div className="rounded-[4px] overflow-hidden shadow-banner bg-white p-6">

                <div className="flex justify-between items-center mb-4">
                    <div className="font-bold text-[18px] mr-2">Planning suggested by the customer</div>
                    <div className="cursor-pointer" onClick={callbackClose}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" className="fill-blue w-[18px] h-[18px]">
                            <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z" />
                        </svg>
                    </div>
                </div>

                {
                    !!suggestedPlanning && suggestedPlanning.length > 0 &&
                    <div className="max-h-[300px] overflow-auto pr-6">
                        {
                            suggestedPlanning.map((address, index) => {
                                return (
                                    <CustomerRoutingItem
                                        address={address}
                                        key={index}
                                    />
                                )
                            })
                        }
                    </div>
                }

                {
                    !loading && (!suggestedPlanning || suggestedPlanning?.length === 0) &&
                    <div>
                        There's no data for this order's customer routing.
                    </div>
                }

                {
                    !!loading &&
                    <div>
                        Loading...
                    </div>
                }
            </div>
        </div>
    )
}

export default TableTooltipSuggestedPlanning;