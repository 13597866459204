import { useContext } from 'react'
import { IPermissionsContext, PermissionsContext } from '../../context/PermissionsContext';
import { PermissionAreas, PermissionOperations } from '../../types/permissions';

const usePermissions = () => {
    const { permissions } = useContext(PermissionsContext) as IPermissionsContext;

    const hasPermission = (area: PermissionAreas, operation: PermissionOperations | null = null) => {
        if (operation !== null) {
            return !!permissions && !!permissions.find(p => p.area === area && p.opeation === operation);
        }

        return !!permissions && !!permissions.find(p => p.area === area);
    };

    return { hasPermission };
}

export default usePermissions;