export const CardConsts = {

    cardIDs: {
        trips: {
            active: "active",
            booking: "booking",
            trInTransit: "trInTransit",
            loadUnload: "loadUnload",
            gps: "gps",
            noGps: "noGps"
        },
        orders: {
            soRecCanc: "soRecCanc",
            storages: "storages",
            toBeColl: "toBeColl",
            withoutCollLeg: "withoutCollLeg",
            // inProgress: "inProgress",
            toBeDelivered: "toBeDelivered",
            collWithoutDMI: "collWithoutDMI",
            inXdock: "inXdock",
            outForColl: "outForColl",
            outForDelivery: "outForDelivery",
            missColl: "missColl",
            withoutDeliveryLeg: "withoutDeliveryLeg",
            missDelivery: "missDelivery",
            partDelivery: "partDelivery",
            orInTransit: "orInTransit",
            withoutDE: "withoutDE"
        }
    }

}