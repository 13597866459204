import React, { useContext, useEffect, useMemo, useState } from "react";
import Button from "../ui/button/Button";
import { IMasterDataContext, MasterDataContext } from "../../context/MasterDataContext";
import { ITripsContext, TripsContext } from "../../context/TripContext";
import SelectAsyncPreference from "../ui/selectAsyncPreference/SelectAsyncPreference";
import { ResultType } from "../ui/selectTextInputAsync/SelectTextInputAsync";
import { masterDataService } from "../../api/masterDataService/masterDataService";
import { AddressTypesEnum } from "../../types/address";
import { distinct } from "../../utils/utilFunctions";
import { IOrder, Leg } from "../../types/orders";

type Props = {
    isOpen: boolean;
    selectedLegs: number[];
    allOrders: IOrder[] | undefined;
    isPlannedLeg: boolean;
    setSelectedDepositToModify: React.Dispatch<React.SetStateAction<null | number>>;
    callbackOpenCloseTripBanner: () => any;
    callBackCreateTrip: () => any;
    callBackAddToTrip: () => any;
    callBackStandBy: () => any;
    callBackFwdToNetwork: () => any;
    callbackAddDepot: () => any;
}

export type DataObjType = null | {
    volume: number;
    weight: number;
    taxWeight: number;
    shippingUnits: number;
    linearMeters: number | string;
}

const TripBanner: React.FC<Props> = ({
    isOpen,
    selectedLegs,
    allOrders,
    isPlannedLeg,
    setSelectedDepositToModify,
    callbackOpenCloseTripBanner,
    callBackCreateTrip,
    callBackAddToTrip,
    callBackStandBy,
    callBackFwdToNetwork,
    callbackAddDepot
}) => {

    const { currentTripDraft } = useContext(TripsContext) as ITripsContext;

    const [dropdownSelectedDeposit, setDropdownSelectedDeposit] = useState<ResultType | null>(null);

    const getOrderWithSelectedLegs = () => {
        let orders: IOrder[] = [];
        allOrders?.forEach(order => {
            if (JSON.parse(JSON.stringify(order.legs)).some((leg: Leg) => selectedLegs.includes(leg.id))) {
                orders.push(order);
            }
        });

        return orders;
    };

    const dataObj: DataObjType = React.useMemo(() => {
        let orderWithSelectedLegs = getOrderWithSelectedLegs();

        if (orderWithSelectedLegs.length === 0) {
            return null;
        }

        if (!!allOrders && selectedLegs.length > 0) {
            let linearMeters = 0;
            let type = orderWithSelectedLegs[0].equipment.id;
            let isAlwaysSameType = true;

            let volume = 0;
            let shippingUnits = 0;
            let taxWeight = 0;
            let weight = 0;

            for (let i = 0; i < orderWithSelectedLegs.length; i++) {
                let plan = orderWithSelectedLegs[i];

                volume += plan.volume;
                shippingUnits += plan.shipmentUnitsNumber;
                taxWeight += plan.taxWeight;
                weight += plan.weight;

                if (plan.equipment.id === type) {
                    linearMeters += !!plan.linearMeters ? Number(plan.linearMeters) : 0;
                } else {
                    isAlwaysSameType = false;
                }
            }

            return {
                volume: Number(volume.toFixed(2)),
                linearMeters: isAlwaysSameType ? linearMeters : '-',
                shippingUnits: shippingUnits,
                taxWeight: Number(taxWeight.toFixed(2)),
                weight: Number(weight.toFixed(2))
            }
        }

        return null;
    }, [allOrders, selectedLegs]);

    const { deposits, updateDeposits } = useContext(MasterDataContext) as IMasterDataContext;

    const addDepotEnabled = useMemo(() => {
        let enabled = true;

        if (allOrders === undefined) {
            enabled = true;
        }

        allOrders !== undefined && getOrderWithSelectedLegs().forEach(order => {
            let depositAddressIds: number[] = [];
            depositAddressIds = [...depositAddressIds, ...order.legs.filter(leg => leg.origin.type === AddressTypesEnum.Deposit).map(leg => leg.origin.id)];
            depositAddressIds = [...depositAddressIds, ...order.legs.filter(leg => leg.destination.type === AddressTypesEnum.Deposit).map(leg => leg.destination.id)];
            depositAddressIds = distinct(depositAddressIds);

            let orderSelectedLegsNumber = order.legs.filter(leg => selectedLegs.includes(leg.id)).length;
            if (depositAddressIds.length >= 2 || orderSelectedLegsNumber >= 2) {
                enabled = false;
                return;
            }
        });

        return enabled;
    }, [allOrders, selectedLegs]);

    useEffect(() => {
        if (selectedLegs === undefined) {
            return;
        }

        setSelectedDepositToModify(null);
        setDropdownSelectedDeposit(null);
    }, [selectedLegs]);

    const handleChangeDropdownValue = (e: ResultType | null) => {
        setDropdownSelectedDeposit(e);
        setSelectedDepositToModify(!!e ? e.id : null);
    };

    return (
        <>
            <div style={{ zIndex: 40 }} className={"sticky bottom-[40px] transition w-full mt-[24px]" + (isOpen === false ? ' translate-x-[calc(100%+70.5px)]' : '')}>
                <div className="relative bg-white shadow-banner rounded-[4px] pr-6 flex justify-between">

                    {/* Badge Banner */}
                    <div onClick={callbackOpenCloseTripBanner} className="absolute top-0 left-0 -translate-x-full rounded-l-[4px] cursor-pointer shadow-card bg-white uppercase font-bold px-[16px] bg-blue h-full">
                        <div className={"absolute select-none top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 flex flex-col items-center" + (isOpen === false ? ' text-blue' : ' text-black')}>
                            {
                                isOpen === false &&
                                <>
                                    <div className="mb-1">{currentTripDraft?.length}</div>

                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M18.75 19.5l-7.5-7.5 7.5-7.5m-6 15L5.25 12l7.5-7.5" />
                                    </svg>
                                </>
                            }

                            {
                                isOpen === true &&
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M11.25 4.5l7.5 7.5-7.5 7.5m-6-15l7.5 7.5-7.5 7.5" />
                                </svg>
                            }
                        </div>
                    </div>

                    <div className="flex items-center h-[100px]">
                        {isPlannedLeg &&
                            <>
                                <div className="h-[100px] w-[24px] relative">
                                    <div className="absolute left-0 top-0 bg-white h-full w-full aspect-square"></div>
                                </div>
                                <div className="flex items-center">
                                    <div className="mr-8">
                                        <div className="text-blue text-[12px] font-bold mb-2 uppercase">Selected legs</div>
                                        <div className="text-[24px] font-bold">{selectedLegs?.length}</div>
                                    </div>
                                </div>
                            </>
                        }
                        {!isPlannedLeg &&
                            <div className="ml-6 flex items-center">
                                <div>
                                    <div className="text-blue text-[12px] font-bold mb-2 uppercase">Volume</div>
                                    <div className="text-[24px] font-bold">{!!dataObj && dataObj.volume.toFixed(2)} M³</div>
                                </div>

                                <div className="ml-8">
                                    <div className="text-blue text-[12px] font-bold mb-2 uppercase">Weight</div>
                                    <div className="text-[24px] font-bold">{!!dataObj && dataObj.weight} KG</div>
                                </div>

                                <div className="ml-8">
                                    <div className="text-blue text-[12px] font-bold mb-2 uppercase">Tax-weight</div>
                                    <div className="text-[24px] font-bold">{!!dataObj && dataObj.taxWeight} KG</div>
                                </div>

                                <div className="ml-8">
                                    <div className="text-blue text-[12px] font-bold mb-2 uppercase">Shipping units</div>
                                    <div className="text-[24px] font-bold">{!!dataObj && dataObj.shippingUnits}</div>
                                </div>

                                <div className="ml-8">
                                    <div className="text-blue text-[12px] font-bold mb-2 uppercase">Linear meters</div>
                                    <div className="text-[24px] font-bold">{!!dataObj && dataObj.linearMeters}</div>
                                </div>
                            </div>
                        }
                    </div>

                    <div className="flex items-center">
                        {!isPlannedLeg &&
                            <>
                                <Button
                                    callback={callBackCreateTrip}
                                >
                                    <div className="mt-[1px]">Create trip</div>
                                </Button>

                                <Button
                                    callback={callBackAddToTrip}
                                    className="ml-3 bg-transparent text-black border-[1.4px] border-black"
                                >
                                    <div className="mt-[1px]">Add to My Trip</div>
                                </Button>

                                <div className="mx-3 w-[1px] h-[60px] bg-lightGrey"></div>

                                <Button
                                    title="Stand By"
                                    callback={callBackStandBy}
                                    className="bg-transparent text-black border-[1.4px] border-black px-3 py-2"
                                >
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6.66602 5.83301H9.16602V14.1663H6.66602V5.83301ZM10.8327 5.83301H13.3327V14.1663H10.8327V5.83301Z" fill="#333333" />
                                    </svg>
                                </Button>

                                <Button
                                    title="Forward to network"
                                    callback={callBackFwdToNetwork}
                                    className="bg-transparent text-black border-[1.4px] border-black px-3 py-2 ml-3"
                                >
                                    <svg width="1.3em" height="1.3em" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6 1C3.243 1 1 3.243 1 6C1 8.757 3.243 11 6 11C8.757 11 11 8.757 11 6C11 3.243 8.757 1 6 1ZM2 6C2 5.5505 2.078 5.119 2.2155 4.7155L3 5.5L4 6.5V7.5L5 8.5L5.5 9V9.9655C3.5305 9.718 2 8.036 2 6ZM9.165 8.4365C8.8385 8.1735 8.3435 8 8 8V7.5C8 7.23478 7.89464 6.98043 7.70711 6.79289C7.51957 6.60536 7.26522 6.5 7 6.5H5V5C5.26522 5 5.51957 4.89464 5.70711 4.70711C5.89464 4.51957 6 4.26522 6 4V3.5H6.5C6.76522 3.5 7.01957 3.39464 7.20711 3.20711C7.39464 3.01957 7.5 2.76522 7.5 2.5V2.2945C8.964 2.889 10 4.325 10 6C9.99991 6.88235 9.70614 7.73957 9.165 8.4365Z" fill="#333333" />
                                    </svg>
                                </Button>

                                <div className="mx-3 w-[1px] h-[60px] bg-lightGrey"></div>

                                {addDepotEnabled &&
                                    <>
                                        <SelectAsyncPreference
                                            initialOptions={deposits}
                                            value={dropdownSelectedDeposit}
                                            name="deposit"
                                            placeholder="Select depot..."
                                            callbackOnSelect={handleChangeDropdownValue}
                                            dropDownPositionY="top"
                                            apiServiceSearch={masterDataService.searchDeposits}
                                            apiServiceAddRemoveToFavourites={masterDataService.addRemoveDepositToFavourites}
                                            callbackOnAddedToFavourites={updateDeposits}
                                            callbackOnRemovedFromFavourites={updateDeposits}
                                            paginated={true}
                                            paginationCount={25}
                                        />

                                        <Button
                                            callback={callbackAddDepot}
                                            className="bg-darkGrey text-white ml-3"
                                        >
                                            <div className="mt-[1px]">Add depot</div>
                                        </Button>
                                    </>
                                }
                            </>
                        }

                        {isPlannedLeg &&
                            <Button
                                callback={() => { }}
                                className="bg-darkGrey text-white ml-3"
                            >
                                <div className="mt-[1px]">Set as dispatchable</div>
                            </Button>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default TripBanner;