import { LegStatusEnum, LegTypesEnum } from "./leg";

export type AddressType = {
    address: string;
    city: string;
    zip?: string;
}

export type DepositType = {
    id: number;
    name: string;
    address: AddressType
}

export type OriginDestinationType = {
    name: string;
    street: string;
    countryCode: string;
    zipCode: string;
    city: string;
}

export type PlanningElementType = {
    disabled: boolean;
    depositName?: string | null;
    type: "deposit" | "leg";
    selected: boolean;
    tripId: number | null;
    countryISO: string;
    inStandBy: boolean;
    forwardedToNetwork: boolean;
}

export type ShippingUnitDetails = {
    quantity: number,
    package: string,
    stackability: number,
    width: number,
    length: number,
    height: number
}

export type PartnerToDeliverType = {
    id: number,
    countryISO: string;
    name: string;
    address: string;
    city: string;
    zip: string;
}

export interface IBusinessFlowType {
    id: number;
    name: string;
    departments: IBusinessFlowTypeDepartment[]
}

export interface IBusinessFlowTypeDepartment {
    id: number,
    name: string
}

export interface IDepartment {
    id: number
    priority: number,
    name: string,
    businessFlowTypes: IDepartmentBft[]
}

export interface IDepartmentBft {
    id: number,
    name: string
}

export type DepositAddress = {
    street: string;
    city: string;
    countryCode: string;
    zipCode: string;
    id: number;
}
export interface IDeposit {
    id: number;
    name: string;
    externalId: string;
    address: DepositAddress;
}

export interface IChargeUser {
    username: string;
    name: string;
    surname: string;
    email: string;
}

export interface IGroup {
    id: number;
    status: string;
    name: string;
    departmentId: number;
    businessFlowTypeId: number;
    chargeTime: string;
    chargeUser: null | IChargeUser;
    priority: number;
    volumeToBePlanned: number;
    // workProgress: {
    //     total: number;
    //     planned: number;
    // };
}

export type LegType = {
    id: LegTypesEnum;
    name: "Collection" | "Shuttle" | "Delivery" | "Direct";
}

export type Leg = {
    id: number;
    groupId: number;
    orderId: number;
    orderPosition: number;
    type: LegType;
    status: {
        id: LegStatusEnum;
        name: string;
    } | null;
    planningType: {
        id: number;
        name: string;
    };
    origin: OriginDestinationLeg;
    destination: OriginDestinationLeg;
    standby: boolean;
    planned: boolean;
    plannable: boolean;
    trip?: LegTripInfo | null;
};

export type LegTripInfo = {
    id: number;
    dailyId: number;
    creationTime: Date;
    chargeUser?: string;
    statusId: number;
};

export type OriginDestinationLeg = {
    id: number;
    name: string;
    type: null | "Origin" | "Destination" | "Deposit" | "Partner";
    invPartyName: string
    code?: string;
    countryCode: string;
    addressLine: string;
    zipCode: string;
    city: string;
    hall: string;
    plant: string;
    dock: string;
    lat?: string;
    lon?: string;
};

export type Equipment = {
    id: number;
    name: string;
}

export type Carrier = {
    id: number;
    name: string;
}

export class IOrder {
    public id: number = 0;
    public orderNumbers: string[] | null = null;
    public origin?: OriginDestinationType;
    public destination?: OriginDestinationType;
    public originPartner?: OriginDestinationType;
    public destinationPartner?: OriginDestinationType;
    public earlyPickup: string = "";
    public earlyDelivery: string = "";
    public latePickup: string = "";
    public lateDelivery: string = "";
    public volume: number = 0;
    public weight: number = 0;
    public taxWeight: number = 0;
    public linearMeters?: number | null = null;
    public businessFlowType?: IOrderBusinessFlowType;
    public equipment: Equipment = { id: 0, name: "" };
    public legs: Leg[] = [];
    public customerCollectionID: string[] | null = null;
    public customerLinehaulID: string[] | null = null;
    public tmsCustomerCode: string = "";
    public importDate: string = "";
    public shipmentUnitsNumber: number = 0;
    public tradingPartnerId: number = 0;
    public dropped: boolean = false;
    public isGroup: boolean = false;
    public missingFields: string[] | null = null;
    public consigneeDock: string | null = null;
    public selectedLegs: number[] = [];
    public selectedDepots: number[] = [];
}

export interface IOrderBusinessFlowType {
    id: number;
    name: string;
}

// export interface IGroupCondition {
//     id: number | undefined,
//     customerCode: string,
//     originCountry: string,
//     originPostCodeFrom: string
//     originPostCodeTo: string,
//     destinationCountry: string,
//     destinationPostCodeFrom: string,
//     destinationPostCodeTo: string,
//     groupId: number,
//     departmentId: number,
//     businessFlowTypeId: number
// }

export class GroupCondition {
    public id?: number | null;
    public customerCode: string = "";
    public originCountry: string = "";
    public originPostCodeFrom: string = "";
    public originPostCodeTo: string = "";
    public destinationCountry: string = "";
    public destinationPostCodeFrom: string = "";
    public destinationPostCodeTo: string = "";
    public groupId: number | undefined = undefined;
    public departmentId: number | undefined = undefined;
    public businessFlowTypeId: number | undefined = undefined;
    public rowId: number | undefined = undefined;
}

export interface OrderInfo {
    orderAddedInQueue?: Date | null;
    orderInsert?: Date | null;
    orderUpdated: Date[];
    orderSent: Date[];
    feedbackCollection?: OrderInfoFeedbackResponse | null;
    feedbackDelivery?: OrderInfoFeedbackResponse | null;
    legs: OrderInfoLeg[];
    isInGroup?: boolean | null;
}

export interface OrderInfoFeedbackResponse {
    success?: boolean | null;
    time?: Date | null;
}

export interface OrderInfoLeg {
    name?: string | null;
    status?: string | null;
    planningStatus?: string | null;
    tripId?: number | null;
    groupName?: string | null;
    legsStatusHistory: OrderInfoLegStatusHistory[];
}

export interface OrderInfoLegStatusHistory {
    status?: string | null;
    timeStamp?: Date | null;
    user?: string | null;
}