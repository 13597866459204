import { dataAPI } from "../api/data/dataAPI";
import { toAbsoluteUrl } from "../_metronic/_helpers";
import {
    formatContentActiveTripsCard, formatContentAtLoadingUnloadingTripsCard, formatContentBookingTripsCard, formatContentCardOrdersReceived,
    formatContentDebugTooltip, formatContentGPSTripsCard, formatContentInTransitTripsCard, formatContentNoGPSTripsCard,
    formatContentCardSO,
    formatContentCardOrdersCollectionInProgress, formatContentCardOrdersInTransit, formatContentCardOrdersMissColl, formatContentCardOrdersInXdock, formatContentCardOrdersOutForCollection, formatContentCardOrdersOutForDelivery,
    formatContentCardOrdersParDel, formatContentCardOrdersToBeCollected, formatContentCardOrdersToBeDelivered, formatContentCardOrdersWithoutCollectionLeg, formatContentCardOrdersWithoutDMI, formatContentCardOrdersWithoutDelLeg,
    formatContentCardDeliveredOrdersInDelay, formatContentCardDeliveredOrders, formatContentCardPickedUpOrders, formatContentStoragesGrid, formatContentCardMissDel, formatContentCardWithoutDE,
} from "./TooltipUtils";
import { CardConsts } from "../consts/cardConst";
import { pagesLinks } from "./PageUtils";

const orderIds = CardConsts.cardIDs.orders;
const tripIds = CardConsts.cardIDs.trips;

class CardData {
    constructor({ id = "placeholder",
        title = "Placeholder",
        dataGetter = dataAPI.getSalesOrderReceived,
        icon = toAbsoluteUrl("/media/svg/new/valuecards/resting/alltrips.svg"),
        multiValueCharAggregator = "(",
        tooltipContent = formatContentDebugTooltip(),
        tooltipPlacement = "auto",
        hideOnMap = false,
        viewGridUrl = "/undefined",
        viewAllFromMap = false
    }) {
        this.id = id;
        this.title = title;
        this.dataGetter = dataGetter;
        this.icon = icon;
        this.multiValueCharAggregator = multiValueCharAggregator;
        this.tooltipContent = tooltipContent;
        this.tooltipPlacement = tooltipPlacement;
        this.hideOnMap = hideOnMap;
        this.viewGridUrl = viewGridUrl;
        this.viewAllFromMap = viewAllFromMap;
    }

    get absoluteUrlIcon() { return this.icon; }
}

// TODO: tooltips, dataGetters and icons needs to be replaced with the correct ones when they are defined in the
// analysis docs
const TOCardsList = [
    new CardData({
        id: orderIds.soRecCanc,
        title: "Sales Orders received and (cancelled)",
        dataGetter: dataAPI.getSalesOrderReceived,
        icon: toAbsoluteUrl("/media/svg/new/valuecards/resting/so.svg"),
        tooltipContent: formatContentCardSO(),
        hideOnMap: true,
        viewGridUrl: pagesLinks.viewAllSalesOrdersGrid,

    }),
    new CardData({
        id: orderIds.toBeColl,
        title: "To Be Collected",
        dataGetter: dataAPI.getValueOrderToBeCollected,
        icon: toAbsoluteUrl("/media/svg/new/valuecards/resting/toBeCollected.svg"),
        tooltipContent: formatContentCardOrdersToBeCollected(),
        viewGridUrl: pagesLinks.viewAllOrdersToBeCollectedGrid,
        viewAllFromMap: true
    }),
    new CardData({
        id: orderIds.toBeDelivered,
        title: "To Be Delivered",
        dataGetter: dataAPI.getValueOrderToBeDelivered,
        icon: toAbsoluteUrl("/media/svg/new/valuecards/resting/unloading.svg"),
        tooltipContent: formatContentCardOrdersToBeDelivered(),
        viewGridUrl: pagesLinks.viewAllOrdersToBeDeliveredGrid,
        viewAllFromMap: true
    }),
    new CardData({
        id: orderIds.inXdock,
        title: "Orders in XDock",
        dataGetter: dataAPI.getValueOrderInXdock,
        icon: toAbsoluteUrl("/media/svg/new/valuecards/resting/delayed.svg"),
        tooltipContent: formatContentCardOrdersInXdock(),
        viewGridUrl: pagesLinks.viewAllOrdersInXdockGrid,
        viewAllFromMap: true
    }),
    new CardData({
        id: orderIds.outForColl,
        title: "Out For Collection",
        dataGetter: dataAPI.getValueOrderOutForCollection,
        icon: toAbsoluteUrl("/media/svg/new/valuecards/resting/outForCollection.svg"),
        tooltipContent: formatContentCardOrdersOutForCollection(),
        viewGridUrl: pagesLinks.viewAllOutForCollectionGrid,
        viewAllFromMap: true
    }),
    new CardData({
        id: orderIds.missColl,
        title: "Missed Collections",
        dataGetter: dataAPI.getValueOrderMissedCollections,
        icon: toAbsoluteUrl("/media/svg/new/valuecards/resting/missedCollection.svg"),
        tooltipContent: formatContentCardOrdersMissColl(),
        viewGridUrl: pagesLinks.viewAllMissedCollectionsGrid,
        viewAllFromMap: true
    }),
    new CardData({
        id: orderIds.partDelivery,
        title: "Partial Delivery",
        dataGetter: dataAPI.getValueOrderPartialDeliveries,
        icon: toAbsoluteUrl("/media/svg/new/valuecards/resting/partialDelivery.svg"),
        tooltipContent: formatContentCardOrdersParDel(),
        viewGridUrl: pagesLinks.viewAllPartialDeliveriesGrid,
        viewAllFromMap: true
    }),
    new CardData({
        id: orderIds.storages,
        dataGetter: dataAPI.getValueStorage,
        title: "Storages",
        hideOnMap: true,
        icon: toAbsoluteUrl("/media/svg/new/valuecards/resting/storages.svg"),
        tooltipContent: formatContentStoragesGrid(),
        viewGridUrl: pagesLinks.viewAllStoragesGrid
    }),
    new CardData({
        id: orderIds.withoutCollLeg,
        dataGetter: dataAPI.getValueOrderNoCollectionLeg,
        title: "Orders Without Collection Leg",
        icon: toAbsoluteUrl("/media/svg/new/valuecards/resting/woCollLeg.svg"),
        tooltipContent: formatContentCardOrdersWithoutCollectionLeg(),
        viewGridUrl: pagesLinks.viewAllOrdersWithoutCollectionLegGrid,
        viewAllFromMap: true
    }),
    // 18/04/2023 - LOGICA DA RIVEDERE. IN ATTESA DI CAPIRE SE SI SOVRAPPONE ALLA OUT FOR COLLECTION, LA NASCONDIAMO TRA LE CARD SELEZIONABILI
    // new CardData({
    //     id: orderIds.inProgress,
    //     dataGetter: dataAPI.getValueCollectionInProgress,
    //     title: "Collections in Progress",
    //     icon: toAbsoluteUrl("/media/svg/new/valuecards/resting/loading.svg"),
    //     tooltipContent: formatContentCardOrdersCollectionInProgress(),
    //     viewGridUrl: pagesLinks.viewAllCollectionInProgressGrid,
    //     viewAllFromMap: true
    // }),
    // new CardData({
    //     id: orderIds.collWithoutDMI,
    //     dataGetter: dataAPI.getValueCollectedNoDMIOnLegs,
    //     title: "Collected Orders Without DMI Destination on Leg",
    //     icon: toAbsoluteUrl("/media/svg/new/valuecards/resting/woDMILeg.svg"),
    //     tooltipContent: formatContentCardOrdersWithoutDMI(),
    //     viewGridUrl: pagesLinks.viewAllCollectedOrdersWithoutDMIGrid,
    //     viewAllFromMap: true
    // }),
    new CardData({
        id: orderIds.outForDelivery,
        dataGetter: dataAPI.getValueOrderOutForDelivery,
        title: "Out For Delivery",
        icon: toAbsoluteUrl("/media/svg/new/valuecards/resting/outForDel.svg"),
        tooltipContent: formatContentCardOrdersOutForDelivery(),
        viewGridUrl: pagesLinks.viewAllOutForDeliveryGrid,
        viewAllFromMap: true
    }),
    new CardData({
        id: orderIds.withoutDeliveryLeg,
        dataGetter: dataAPI.getValueOrderWithoutDeliveryLeg,
        title: "Orders Without Delivery Leg",
        icon: toAbsoluteUrl("/media/svg/new/valuecards/resting/woDelLeg.svg"),
        tooltipContent: formatContentCardOrdersWithoutDelLeg(),
        viewGridUrl: pagesLinks.viewAllOrdersWithoutDeliveryLegGrid,
        viewAllFromMap: true
    }),
    new CardData({
        id: orderIds.missDelivery,
        dataGetter: dataAPI.getValueOrderMissedDeliveries,
        title: "Missed Deliveries",
        icon: toAbsoluteUrl("/media/svg/new/valuecards/resting/missDel.svg"),
        tooltipContent: formatContentCardMissDel(),
        viewGridUrl: pagesLinks.viewAllMissedDeliveriesGrid,
        viewAllFromMap: true
    }),
    new CardData({
        id: orderIds.orInTransit,
        dataGetter: dataAPI.getValueOrderInTransit,
        title: "In Transit",
        icon: toAbsoluteUrl("/media/svg/new/valuecards/resting/inTransit.svg"),
        tooltipContent: formatContentCardOrdersInTransit(),
        viewGridUrl: pagesLinks.viewAllOrdersInTransitGrid,
        viewAllFromMap: true
    }),
    new CardData({
        id: orderIds.withoutDE,
        dataGetter: dataAPI.getValueOrderWithoutDataEntry,
        title: "Orders Without Data Entry",
        icon: toAbsoluteUrl("/media/svg/new/valuecards/resting/woDataEntry.svg"),
        tooltipContent: formatContentCardWithoutDE(),
        viewGridUrl: pagesLinks.viewAllOrdersWithoutDataEntryGrid,
        viewAllFromMap: true
    })
];

export const availableTOCardsListOnMap = TOCardsList.map(el => {
    if (!el.hideOnMap) return el.id;
    else return null;
});

TOCardsList.findById = (id) => TOCardsList.find((el) => el.id === id);

export const availableTOCardsList = TOCardsList;


const tripsCardsList = [
    new CardData({
        id: tripIds.active,
        title: "Active (All)",
        dataGetter: dataAPI.getActiveTrips,
        icon: toAbsoluteUrl("/media/svg/new/valuecards/resting/alltrips.svg"),
        tooltipContent: formatContentActiveTripsCard(),
        viewGridUrl: pagesLinks.viewAllActiveTripGrid
    }),
    new CardData({
        id: tripIds.booking,
        title: "Booking",
        dataGetter: dataAPI.getBookingTrips,
        icon: toAbsoluteUrl("/media/svg/new/valuecards/resting/loading.svg"),
        tooltipContent: formatContentBookingTripsCard(),
        viewGridUrl: pagesLinks.viewAllBookingGrid
    }),
    new CardData({
        id: tripIds.trInTransit,
        dataGetter: dataAPI.getInTransitTrips,
        icon: toAbsoluteUrl("/media/svg/new/valuecards/resting/delayed.svg"),
        title: "In Transit",
        tooltipContent: formatContentInTransitTripsCard(),
        viewGridUrl: pagesLinks.viewAllInTransitGrid
    }),
    new CardData({
        id: tripIds.loadUnload,
        dataGetter: dataAPI.getLoadingUnloadingTrips,
        icon: toAbsoluteUrl("/media/svg/new/valuecards/resting/unloading.svg"),
        title: "At Loading / Unloading",
        tooltipContent: formatContentAtLoadingUnloadingTripsCard(),
        multiValueCharAggregator: "/",
        viewGridUrl: pagesLinks.viewAllAtLoadingUnloadingPlaceGrid
    }),
    new CardData({
        id: tripIds.gps,
        dataGetter: dataAPI.getGPSTrips,
        icon: toAbsoluteUrl("/media/svg/new/valuecards/resting/deliverysite.svg"),
        title: "GPS",
        tooltipContent: formatContentGPSTripsCard(),
        viewGridUrl: pagesLinks.viewAllGPSGrid
    }),
    new CardData({
        id: tripIds.noGps,
        dataGetter: dataAPI.getNoGPSTrips,
        icon: toAbsoluteUrl("/media/svg/new/valuecards/resting/ongoing.svg"),
        title: "No GPS",
        tooltipContent: formatContentNoGPSTripsCard(),
        viewGridUrl: pagesLinks.viewAllNOGPSGrid
    })
];

tripsCardsList.findById = (id) => tripsCardsList.find((el) => el.id === id);

export const availableTripsCardsList = tripsCardsList;

//TODO: Replace with correct placeholder card
export const placeholderCard = new CardData({});

export function translateTOCards(cards, activeId) {
    const addedCards = [];
    var activeSetted = false;
    if (cards && cards.length > 0) {
        const cardsList = JSON.parse(JSON.stringify(cards)).map((id) => {
            const card = TOCardsList.findById(id);
            // Checking that the index respects the required conditions of uniqness, not overflowing (>7) and
            // referencing a correct position in the array cardsList
            if (!addedCards.includes(id) && addedCards.length < 7 && card) {
                addedCards.push(id);
                if (activeId === id) {
                    activeSetted = true;
                    card.active = true;
                }
                else card.active = false;                
                return card;
            } //eslint-disable-next-line
            else if (addedCards.length >= 7) {
                return;
            }
            else {
                return placeholderCard;
            }
        });
        if (!activeSetted) cardsList[0].active = true;
        return cardsList;
    }
    return [];
}

export function translateTripsCards(cards, activeIndex) {
    const addedCards = [];
    var activeSetted = false;
    if (cards && cards.length > 0) {
        const cardsList = JSON.parse(JSON.stringify(cards)).map((id) => {
            const card = tripsCardsList.findById(id);
            // Checking that the index respects the required conditions of uniqness, not overflowing (>7) and
            // referencing a correct position in the array cardsList
            if (!addedCards.includes(id) && addedCards.length < 7 && card) {
                addedCards.push(id);
                if (activeIndex === id) {
                    activeSetted = true;
                    card.active = true;
                }
                else card.active = false;
                return card;
            }
            //eslint-disable-next-line 
            else if (addedCards.length >= 7) return;
            else return placeholderCard;
        });
        if (!activeSetted) cardsList[0].active = true;
        return cardsList;
    }
    return [];
}

export function minifyTOCards(cards) {
    return cards.map((card) => {
        const buffCard = TOCardsList.find(el => el.id === card.id);
        return buffCard ? buffCard.id : undefined;
    });
}

export function minifyTripsCards(cards) {
    return cards.map((card) => {
        const buffCard = tripsCardsList.find(el => el.id === card.id);
        return buffCard ? buffCard.id : undefined;
    });
}