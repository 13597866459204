import {configureStore, getDefaultMiddleware} from "@reduxjs/toolkit";
import {reduxBatch} from "@manaflair/redux-batch";
import {persistStore, persistReducer} from "redux-persist";
import {rootReducer} from "./rootReducer";
import sessionStorage from 'redux-persist/lib/storage/session'

const middleware = [
  ...getDefaultMiddleware({
    immutableCheck: false,
    serializableCheck: false,
    thunk: true
  })
];

const persistConfig = {
  key: 'uom-store',
  version: 1,
  storage: sessionStorage,
  whitelist: ["notificationCenter", "dashboard"]
}

 const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = configureStore({
  reducer: persistedReducer,
  middleware,
  devTools: true,
  enhancers: [reduxBatch]
});

/**
 * @see https://github.com/rt2zz/redux-persist#persiststorestore-config-callback
 * @see https://github.com/rt2zz/redux-persist#persistor-object
 */
export const persistor = persistStore(store);

export default store;
