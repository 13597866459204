import { IFilterParams } from "@ag-grid-community/core";
import React, { SetStateAction, useEffect, useState } from "react";

interface IProps extends IFilterParams {
    meta?: any;
}

interface IOption {
    value: PlanningFilterEnum;
    label: string;
}

export enum PlanningFilterEnum {
    ALL = 1,
    NOT_DISPATCHABLE = 2,
    NOT_PLANNED = 3,
    ERROR = 4
}

const radioOptions: IOption[] = [
    {
        label: "All",
        value: PlanningFilterEnum.ALL
    },
    {
        label: "Not dispatchable",
        value: PlanningFilterEnum.NOT_DISPATCHABLE
    },
    {
        label: "Not planned",
        value: PlanningFilterEnum.NOT_PLANNED
    },
    {
        label: "Error",
        value: PlanningFilterEnum.ERROR
    }
]

const FilterRadio = React.forwardRef((props: IProps, ref) => {
    const {
        planningFilterState,
        setPlanningFilterState
    } = props.context as { planningFilterState: PlanningFilterEnum, setPlanningFilterState: React.Dispatch<SetStateAction<PlanningFilterEnum>> };

    const [filterState, setFilterState] = useState<PlanningFilterEnum>(planningFilterState);

    useEffect(() => {
        setFilterState(planningFilterState);
    }, [planningFilterState]);

    // expose AG Grid Filter Lifecycle callbacks
    React.useImperativeHandle(ref, () => {
        return {
            isFilterActive() {
                return filterState !== undefined;
            },
            getModelAsString() {
                return radioOptions.find(o => o.value === filterState)?.label;
            },
            // FOR CLIENT SIDE
            // doesFilterPass(params: {data: IOrder}) {
            //     let canPass = false;
            //     if (filterState === 1) {
            //         canPass = true;
            //         if (!!params.data && params.data.legs.length > 0) {
            //             for (let index = 0; index < params.data.legs.length; index++) {
            //                 const element = params.data.legs[index];

            //                 if (element.trip !== null && element.plannable === true) {
            //                     canPass = false;
            //                 }
            //             }
            //         }
            //     } else if (filterState === 2) {
            //         if (!!params.data && params.data.legs.length > 0) {
            //             for (let index = 0; index < params.data.legs.length; index++) {
            //                 const element = params.data.legs[index];
            //                 if (element.trip !== null && element.plannable === true) {
            //                     canPass = true;
            //                     break;
            //                 }
            //             }
            //         }
            //     } else if (filterState === 3) {
            //         canPass = true;
            //     }

            //     return canPass;
            // },            
            // getModel() {                
            //     return filterState;
            // },               
            // SERVER SIDE
            getModel() {
                return { filter: filterState };
            },
            setModel: () => { }
        };
    });

    useEffect(() => {
        props.filterChangedCallback();
    }, [filterState]);

    const handleChangeFilterState = (val: PlanningFilterEnum) => {
        setFilterState(val)
        setPlanningFilterState(val);
    }

    return (
        <div>
            {
                radioOptions.map((option, i) => {
                    return (
                        <div
                            key={i}
                            onClick={() => handleChangeFilterState(option.value)}
                            className="flex p-2 items-center cursor-pointer"
                        >
                            <div
                                className="h-[16px] w-[16px] relative rounded-full border border-darkGrey border-[1.4px] flex items-center justify-center"
                            >
                                {
                                    option.value === filterState &&
                                    <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 h-[10px] w-[10px] bg-blue rounded-full"></div>
                                }
                            </div>
                            <div className="ml-2">{option.label}</div>
                        </div>
                    );
                })
            }
        </div>
    );
});

export default FilterRadio;