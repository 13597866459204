export const planningToolApiUrls = {
    getGroups: "/groups",
    getMyGroups: "/groups/me",
    takeCharge: "/groups/charge",
    getBusinessFlowTypes: "/businessflowtypes",
    getDepartments: "/departments",
    getOrders: "/orders",
    separateOrders: "/orders/separate",
    getEquipments: "/equipments",
    getDeposits: "/deposits",
    createTrip: "/trips",
    getCarriers: "/carriers",
    groupConditions: "/groups/conditions",
    routing: "/routing",
    authorizationGrant: "/authorizationgrant",
    partners: "/partners",
    legs: "/legs",
    takeChargeTrip: "/trips/takeCharge",
    revokeChargeTrip: "/trips/revokeCharge",
    vehicle: "/vehicle",
    cutoff: "/cutoff",
    users: "/users",
    portalStatistics: "/portalstatistics",
    triage: "/triage",
    exportPlanning: "/exportplanning",
    routingConditions: "/routingconditions",
    rollback: "/rollback"
}