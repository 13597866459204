import React, { createContext, useEffect, useRef, useState } from "react";
import { IPermission } from "../types/permissions";
import { permissionsService } from "../api/permissionsService/permissionsService";
import { toast } from "react-toastify";

export interface IPermissionsContext {
    permissions: IPermission[] | undefined;
}

export const PermissionsContext = createContext<null | IPermissionsContext>(null);

interface Props {
    children: React.ReactNode
}

export const PlanningToolPermissionsProvider: React.FC<Props> = ({ children }) => {
    const [permissions, setPermissions] = useState<IPermission[] | undefined>(undefined);

    const canGo = useRef(true);
    useEffect(() => {
        if (!!canGo && canGo.current === true) {
            canGo.current = false;

            updatePermissions();
        }
    }, []);

    const updatePermissions = async () => {
        const response = await permissionsService.getPermissions();
        if (response.success) {
            setPermissions(response.data);
        } else {
            toast.error('Error retrieving permissions');
        }
    };

    const value: IPermissionsContext = {
        permissions
    };

    return (
        <PermissionsContext.Provider
            value={value}
        >
            {children}
        </PermissionsContext.Provider>
    );
};