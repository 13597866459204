import React, { useCallback, useContext, useMemo, useRef, useState } from 'react';
import CommonTable, { TableRef } from '../../components/commonTable/CommonTable';
import Button from '../../components/ui/button/Button';
import { masterDataService } from '../../api/masterDataService/masterDataService';
import { RowNode, ValueSetterParams } from '@ag-grid-community/core';
import { customConfirmAlert } from '../../utils/functions/customConfirmAlert';
import { toast } from 'react-toastify';
import { IDepartment } from '../../types/orders';
import usePermissions from '../../utils/hooks/usePermissions';
import { PermissionAreas, PermissionOperations } from '../../types/permissions';
import { IMasterDataContext, MasterDataContext } from '../../context/MasterDataContext';

const containerStyle = { width: '100%', height: '100%' };

const PlanningToolManageDepartments: React.FC = () => {

    const { hasPermission } = usePermissions();
    const { updateDepartments } = useContext(MasterDataContext) as IMasterDataContext;

    // #region State
    const commonTableRef = useRef<TableRef>(null);
    const [inAdding, setInAdding] = useState<boolean>(false);
    // #endregion

    // #region Memo
    const columnDefs = useMemo(() => [
        {
            field: "name",
            headerName: "Name"
        },
        {
            field: "priority",
            headerName: "Priority",
            cellDataType: "text",
            sort: 'asc'
        },
        {
            field: "actions",
            headerName: "Actions",
            editable: false,
            filter: false,
            sortable: false,
            cellRenderer: (props: { data: any, node: RowNode }) => {
                return (
                    <>
                        {
                            <div className="flex">
                                {
                                    props.node.rowPinned === "top" &&
                                    <div
                                        onClick={onClickCancelNewRow}
                                        className={"flex text items-center cursor-pointer"}
                                    >
                                        <span className="mr-[8px]">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path fill="#3987E2" d="M12 2c5.53 0 10 4.47 10 10s-4.47 10-10 10S2 17.53 2 12S6.47 2 12 2m3.59 5L12 10.59L8.41 7L7 8.41L10.59 12L7 15.59L8.41 17L12 13.41L15.59 17L17 15.59L13.41 12L17 8.41z" /></svg>
                                        </span>
                                        <span className="hover:underline not-italic ">Remove</span>
                                    </div>
                                }
                            </div>
                        }
                    </>
                )
            },
        }
    ], []);
    // #endregion

    // #region UI Handlers
    const onClickSaveButton = () => {
        confirmSaveDepartments();
    };

    const onClickAddButton = () => {
        addNewDepartment();
    };

    const onClickDeleteButton = () => {
        confirmDeleteDepartment();
    };

    const onClickCancelNewRow = () => {
        cancelNewRow();
    };
    // #endregion

    // #region Util Functions
    const customValueSetter = (params: ValueSetterParams) => {
        let newValue = params.newValue;
        let field = params.colDef.field as string;

        // Validation
        if (newValue !== null && newValue !== undefined) {
            if (field === "priority") {
                if (isNaN(newValue)) {
                    toast.warning("The value cannot include letters");
                    return false;
                }
            }
        }

        params.data[field] = newValue;
        return true;
    };

    const getData = async () => {
        commonTableRef.current?.showLoadingOverlay();
        const response = await masterDataService.getDepartments();
        commonTableRef.current?.hideOverlay();

        if (response.success) {
            commonTableRef.current?.setRowData(response.data);
        } else {
            toast.error('Error retrieving departments');
        }
    };

    const addNewDepartment = () => {
        let newRow: any = {};
        commonTableRef.current?.addNewRowInsertion(newRow);
        setInAdding(true);
    };

    const cancelNewRow = () => {
        commonTableRef.current?.cancelNewRowInsertion();
        setInAdding(false);
    };

    const confirmSaveDepartments = async () => {
        commonTableRef.current?.setStopEditing();
        const wantToSave = await customConfirmAlert({
            message: "Are you sure to save departments?",
            title: "Save departments"
        });

        if (wantToSave) {
            await saveDepartments();
        }
    };

    const saveDepartments = async () => {
        let tableNodes: any = commonTableRef.current?.getTableNodes();
        let tableRows: IDepartment[] = tableNodes.map((x: any) => x.data as IDepartment);
        let newRowToInsert = commonTableRef.current?.getNewRowToInsert();
        tableRows = newRowToInsert !== undefined ? [newRowToInsert, ...tableRows] : tableRows;

        if (tableRows.length === 0) {
            toast.info("No departments to save");
            return;
        }

        // Check some value null
        if (tableRows.some((x: IDepartment) => !!!x.name || !!!x.priority)) {
            toast.warning("There are some null values");
            return;
        }

        const response = await masterDataService.updateDepartments(tableRows, "Saving departments", "Departments saved successfully");
        if (response.success) {
            await updateDepartments();
            commonTableRef.current?.cancelNewRowInsertion();
            commonTableRef.current?.setUnsavedCells([]);
            commonTableRef.current?.setRowsInError([]);
            setInAdding(false);
            setTimeout(function () { getData() }, 0)
        } else if (response.data.detail !== undefined) {
            commonTableRef.current?.setRowsInError(response.data.detailObject);
            setTimeout(function () { commonTableRef.current?.redrawTableRows() }, 0)
        }
    };

    const confirmDeleteDepartment = async () => {
        let selectedRows: IDepartment[] = commonTableRef.current?.getSelectedRows() as IDepartment[];
        let idsToRemove: number[] = selectedRows?.map(x => x.id);

        if (idsToRemove.length === 0) {
            toast.info("No departments selected");
            return;
        }

        const wantToDelete = await customConfirmAlert({
            title: "Delete departments",
            message: "Are you sure to delete the selected departments?"
        });

        if (wantToDelete) {
            await deleteDepartments(idsToRemove);
            setTimeout(function () { commonTableRef.current?.redrawTableRows() }, 0)
        }
    };

    const deleteDepartments = async (idsToRemove: number[]) => {
        let selectedRows: IDepartment[] = commonTableRef.current?.getSelectedRows() as IDepartment[];

        const response = await masterDataService.deleteDepartments(idsToRemove, "Deleting departments", "Departments deleted successfully");
        if (response.success) {
            await updateDepartments();
            commonTableRef.current?.deleteTableRows(selectedRows);
        }
    };
    // #endregion

    // #region CallBack
    const onGridReady = useCallback(() => {
        getData();
    }, []);
    // #endregion

    return (
        <div className='w-full mb-6 bg-white p-[32px]'>
            <div className="font-light text-lg leading-[25px]">Planning Tool</div>
            <div className="flex items-center pb-3 mb-6 border-b">
                <div className="md:mr-3 text-[32px] font-bold leading-[45px]">
                    Departments Configuration
                </div>
            </div>

            {
                hasPermission(PermissionAreas.ManageDepartment, PermissionOperations.Modify) &&
                <div className="mb-6 flex">
                    <Button
                        className="bg-blue text-white !px-[12px] !py-[8px]"
                        callback={onClickAddButton}
                        disabled={inAdding}
                    >
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10.3333 9.16602V9.66602H10.8333H15.3333V10.3327H10.8333H10.3333V10.8327V15.3327H9.66667V10.8327V10.3327H9.16667H4.66667V9.66602H9.16667H9.66667V9.16602V4.66602H10.3333V9.16602Z" fill="#3987E2" stroke="white" />
                        </svg>
                    </Button>

                    <Button
                        className="bg-blue text-white !px-[12px] !py-[8px] ml-[12px]"
                        callback={onClickDeleteButton}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path fill="currentColor" d="M19 4h-3.5l-1-1h-5l-1 1H5v2h14M6 19a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V7H6z" /></svg>
                    </Button>

                    <div className="ml-[12px]">
                        <Button
                            callback={onClickSaveButton}
                            className="bg-transparent text-black border-[1.4px] border-black"
                        >
                            Save configuration
                        </Button>
                    </div>
                </div>
            }

            <div style={containerStyle}>
                <CommonTable
                    ref={commonTableRef}
                    columnDefs={columnDefs}
                    valueSetter={customValueSetter}
                    onGridReady={onGridReady}
                    options={{
                        pageSize: 10,
                        showSelectionCheckbox: hasPermission(PermissionAreas.ManageDepartment, PermissionOperations.Modify),
                        editable: hasPermission(PermissionAreas.ManageDepartment, PermissionOperations.Modify)
                    }}
                />
            </div>
        </div>
    )
}

export default PlanningToolManageDepartments;