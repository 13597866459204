import React, { useCallback, useEffect, useRef, useState } from "react";
import _debounce from "lodash.debounce";
import { toast } from "react-toastify";
import { Items } from "react-virtuoso/dist/List";

type ResultType = {
    id: number;
    name: string;
}

type Props = {
    initialOptions: ResultType[];
    value: null | ResultType;
    label?: string;
    name: string;
    placeholder: string;
    disabled?: boolean;
    paginated?: boolean;
    paginationCount?: number;
    callbackOnSelect: (option: ResultType | null) => any;
    hasError?: boolean;
    dropDownPositionY?: 'top' | 'bottom';
    apiServiceSearch: (searchFilter: string, page?: number) => any;
    apiServiceAddRemoveToFavourites: (id: number, favorite: boolean) => any;
    callbackOnAddedToFavourites?: () => any;
    callbackOnRemovedFromFavourites?: () => any;
}

const SelectAsyncPreference: React.FC<Props> = ({
    callbackOnSelect,
    disabled = false,
    paginated = false,
    paginationCount = 25,
    initialOptions,
    label,
    name,
    placeholder,
    value,
    hasError = false,
    dropDownPositionY = "bottom",
    apiServiceSearch,
    apiServiceAddRemoveToFavourites,
    callbackOnAddedToFavourites,
    callbackOnRemovedFromFavourites
}) => {

    const dropDownResultsRef = useRef<null | HTMLDivElement>(null);
    const inputSearchRef = useRef<null | HTMLInputElement>(null);

    const [isVisibleDropDown, setIsVisibleDropDown] = useState<boolean>(false);
    const isVisibleDropDownRef = React.useRef(isVisibleDropDown);
    const setIsVisibleDropDownRef = (data: boolean) => {
        isVisibleDropDownRef.current = data;
        setIsVisibleDropDown(data);
    };

    const [loading, setLoading] = useState(false);
    const loadingRef = React.useRef(loading);
    const setLoadingRef = (data: boolean) => {
        loadingRef.current = data;
        setLoading(data);
    };

    const [currentPage, setCurrentPage] = useState(1);
    const currentPageRef = React.useRef(currentPage);
    const setCurrentPageRef = (data: number) => {
        currentPageRef.current = data;
        setCurrentPage(data);
    };

    const [lengthLastRequest, setLengthLastRequest] = useState<number | null>(null);
    const lengthLastRequestRef = React.useRef(lengthLastRequest);
    const setLengthLastRequestRef = (data: null | number) => {
        lengthLastRequestRef.current = data;
        setLengthLastRequest(data);
    };

    const [queryValue, setQueryValue] = useState<string>('');
    const queryValueRef = React.useRef(queryValue);
    const setQueryValueRef = (searchFilter: string) => {
        queryValueRef.current = searchFilter;
        setQueryValue(searchFilter);
    };

    const [options, setOptions] = useState<ResultType[]>([]);
    const optionsRef = React.useRef(options);
    const setOptionsRef = (data: ResultType[]) => {
        optionsRef.current = data;
        setOptions(data);
    };

    const [favourites, setFavourites] = useState<ResultType[]>([]);

    useEffect(() => {
        if (!!initialOptions && initialOptions.length > 0) {
            setFavourites(initialOptions);
        }
    }, [initialOptions]);

    useEffect(() => {
        if (value !== null && !!value.name) {
            setQueryValueRef(value.name);
        } else {
            setQueryValueRef('');
        }
    }, [value]);

    const handleSelectOption = (option: ResultType | null) => {
        if (!!option && !!option.name && !!option.id) {
            setQueryValueRef(option.name);
            callbackOnSelect(option);
            setIsVisibleDropDownRef(false);
            setOptionsRef([]);
        } else {
            callbackOnSelect(option);
        }
    }

    const handleDebounceFn = async (inputValue: string) => {
        if (inputValue !== "") {
            // Call API            
            if (!!apiServiceSearch && !!inputValue) {
                setOptionsRef([]);
                setLoadingRef(true);
                const response = await apiServiceSearch(inputValue);

                if (response.success) {
                    setOptionsRef(response.data);
                    setCurrentPageRef(2);
                    setLengthLastRequestRef(response.data.length);
                } else {
                    toast.error('Error retrieving results')
                }

                setLoadingRef(false);
            }
        }
    }

    const debounceFn = useCallback(_debounce(handleDebounceFn, 400), []);
    const handleChangeQuery = (e: any) => {
        if (!disabled) {
            if (e.target.value !== '') {
                setQueryValueRef(e.target.value);
                debounceFn(e.target.value);
            } else {
                setQueryValueRef(e.target.value);
                handleSelectOption(null);
                setOptionsRef([]);
            }
        }
    }

    const addToFavourites = async (e: any, option: ResultType) => {
        e.stopPropagation();

        // Call api addToFavourites
        if (!!apiServiceAddRemoveToFavourites) {
            const response = await apiServiceAddRemoveToFavourites(option.id, true);

            if (response.success) {
                const copyFavs = [...favourites];
                setFavourites([...copyFavs, option]);

                if (!!callbackOnAddedToFavourites) {
                    callbackOnAddedToFavourites();
                }
            } else {
                toast.error('Error adding to favorites')
            }
        }
    }

    const removeFromFavourites = async (e: any, option: ResultType) => {
        e.stopPropagation();

        if (!!apiServiceAddRemoveToFavourites) {
            const response = await apiServiceAddRemoveToFavourites(option.id, false);

            if (response.success) {
                const copyFavs = [...favourites];
                const index = copyFavs.findIndex(f => f.id === option.id);
                if (index !== -1 && index !== undefined && index !== null) {
                    copyFavs.splice(index, 1);
                    setFavourites(copyFavs);

                    if (!!callbackOnRemovedFromFavourites) {
                        callbackOnRemovedFromFavourites();
                    }
                }
            } else {
                toast.error('Error removing to favorites')
            }
        }
    }

    useEffect(() => {
        const listener = (e: any) => {
            if (!!dropDownResultsRef && !!dropDownResultsRef.current && !!inputSearchRef && !!inputSearchRef.current) {
                if (isVisibleDropDown && !dropDownResultsRef.current.contains(e.target) && !inputSearchRef.current.contains(e.target)) {
                    setIsVisibleDropDownRef(false);
                }
            }
        }

        document.addEventListener('click', listener);

        return () => {
            document.removeEventListener('click', listener);
        }
    }, [isVisibleDropDown]);

    useEffect(() => {
        if (paginated === true) {
            const listener = () => {
                if (!!dropDownResultsRef.current) {
                    const scrollTop = dropDownResultsRef.current?.scrollTop;
                    const offsetHeight = dropDownResultsRef.current?.offsetHeight;
                    const scrollHeight = dropDownResultsRef.current?.scrollHeight - 1;

                    if (queryValueRef.current !== '' && scrollTop + offsetHeight >= scrollHeight) {
                        getPaginatedItems();
                    }
                }
            }

            if (!!dropDownResultsRef.current && !loading && (options.length > 0 || favourites.length > 0)) {
                if (dropDownResultsRef && dropDownResultsRef.current !== null) {
                    dropDownResultsRef.current.addEventListener('scroll', listener);
                }
            }

            return () => {
                if (!!dropDownResultsRef.current) {
                    dropDownResultsRef.current.removeEventListener('scroll', listener)
                }
            }
        }
    }, [options, loading]);

    const canGoNext = () => {
        return (lengthLastRequestRef.current === null || (lengthLastRequestRef.current !== null && !(lengthLastRequestRef.current < paginationCount)))
    }

    const getPaginatedItems = async () => {
        if (!loadingRef.current && (canGoNext())) {
            setLoadingRef(true);
            const response = await apiServiceSearch(queryValueRef.current, currentPageRef.current);
            // const response = await notificationsService.getNotificationsBulk(refresh ? 1 : currentPageRef.current, paginationCount);
            if (response.success) {
                const results = response.data;
                setLengthLastRequestRef(results.length);
                setOptionsRef([...optionsRef.current.concat(results)]);
                setCurrentPageRef(currentPageRef.current + 1);
            } else {
                toast.error("Error fetching results")
            }

            setLoadingRef(false);
        }
    }

    const handleFocus = () => {
        setIsVisibleDropDown(true);

        if (!!queryValue && options.length === 0) {
            debounceFn(queryValue);
        }
    }

    return (
        <div>
            {
                !!label &&
                <label className="font-semibold text-sm text-blue uppercase leading-[17px] block mb-[5px]" htmlFor={name}>{label}</label>
            }

            <div className="relative">
                <input
                    ref={inputSearchRef}
                    className={"input-text outline-none leading-[18px] text-black text-[15px] border border-[#6F6F6F] rounded-[8px] p-[10px] w-full" + (hasError ? ' border-red' : '') + (!!disabled ? ' cursor-not-allowed bg-[#fafafa]' : ' bg-white')}
                    type={"text"}
                    placeholder={placeholder}
                    value={queryValue}
                    name={name}
                    id={name}
                    onChange={handleChangeQuery}
                    onFocus={handleFocus}
                    disabled={disabled}
                    autoComplete="off"
                />

                {
                    isVisibleDropDown === true &&
                    <div style={{ zIndex: 999 }} ref={dropDownResultsRef} className={"absolute left-0 max-h-[250px] overflow-y-auto hide-scrollbar shadow bg-white w-full rounded-[8px] " + (dropDownPositionY === "bottom" ? "top-[100%]" : "bottom-[100%]")}>
                        {
                            queryValue !== '' &&
                            <>
                                {
                                    options.length > 0 &&
                                    <>
                                        {
                                            options.map((option, index) => {
                                                return (
                                                    <div
                                                        className="hover:bg-lightGrey cursor-pointer p-[10px] flex justify-between items-center text-[13px] font-normal"
                                                        key={index}
                                                        onClick={() => handleSelectOption(option)}
                                                    >
                                                        <div className="grow">{option.name}</div>
                                                        {
                                                            !!favourites.find(f => f.id === option.id) ?
                                                                <div onClick={(e) => removeFromFavourites(e, option)}>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                                                                        <path fillRule="evenodd" d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z" clipRule="evenodd" />
                                                                    </svg>
                                                                </div>
                                                                :
                                                                <div onClick={(e) => addToFavourites(e, option)}>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M11.48 3.499a.562.562 0 0 1 1.04 0l2.125 5.111a.563.563 0 0 0 .475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 0 0-.182.557l1.285 5.385a.562.562 0 0 1-.84.61l-4.725-2.885a.562.562 0 0 0-.586 0L6.982 20.54a.562.562 0 0 1-.84-.61l1.285-5.386a.562.562 0 0 0-.182-.557l-4.204-3.602a.562.562 0 0 1 .321-.988l5.518-.442a.563.563 0 0 0 .475-.345L11.48 3.5Z" />
                                                                    </svg>
                                                                </div>
                                                        }
                                                    </div>
                                                )
                                            })
                                        }
                                    </>
                                }

                                {
                                    !loading && options.length === 0 &&
                                    <div
                                        className="hover:bg-lightGrey cursor-pointer p-[10px] flex justify-between text-[13px] font-normal items-center"
                                    >
                                        No result found.
                                    </div>
                                }
                            </>
                        }
                        {
                            queryValue === "" &&
                            <>
                                {
                                    favourites.length > 0 &&
                                    <>
                                        {
                                            favourites.map((option, index) => {
                                                return (
                                                    <div
                                                        className="hover:bg-lightGrey cursor-pointer p-[10px] flex justify-between items-center"
                                                        key={index}
                                                        onClick={() => handleSelectOption(option)}
                                                    >
                                                        <div className="grow text-[13px] font-normal">{option.name}</div>
                                                        <div className="group" onClick={(e) => removeFromFavourites(e, option)}>
                                                            <svg className="w-6 h-6 block group-hover:hidden" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                                                                <path fillRule="evenodd" d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z" clipRule="evenodd" />
                                                            </svg>

                                                            <svg className="w-6 h-6 hidden group-hover:block" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                                                                <path fillRule="evenodd" d="M16.5 4.478v.227a48.816 48.816 0 0 1 3.878.512.75.75 0 1 1-.256 1.478l-.209-.035-1.005 13.07a3 3 0 0 1-2.991 2.77H8.084a3 3 0 0 1-2.991-2.77L4.087 6.66l-.209.035a.75.75 0 0 1-.256-1.478A48.567 48.567 0 0 1 7.5 4.705v-.227c0-1.564 1.213-2.9 2.816-2.951a52.662 52.662 0 0 1 3.369 0c1.603.051 2.815 1.387 2.815 2.951Zm-6.136-1.452a51.196 51.196 0 0 1 3.273 0C14.39 3.05 15 3.684 15 4.478v.113a49.488 49.488 0 0 0-6 0v-.113c0-.794.609-1.428 1.364-1.452Zm-.355 5.945a.75.75 0 1 0-1.5.058l.347 9a.75.75 0 1 0 1.499-.058l-.346-9Zm5.48.058a.75.75 0 1 0-1.498-.058l-.347 9a.75.75 0 0 0 1.5.058l.345-9Z" clipRule="evenodd" />
                                                            </svg>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </>
                                }

                                {
                                    !loading && favourites.length === 0 &&
                                    <div
                                        className="hover:bg-lightGrey cursor-pointer p-[10px] flex justify-between text-[13px] font-normal items-center"
                                    >
                                        No favourite result found.
                                    </div>
                                }
                            </>
                        }

                        {
                            loading &&
                            <div
                                className="text-[13px] font-normal p-[10px] flex justify-between items-center"
                            >
                                Loading...
                            </div>
                        }
                    </div>
                }
            </div>
        </div>
    );
}

export default SelectAsyncPreference;