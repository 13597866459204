import React, { useEffect, useState, useMemo, useRef } from "react";
import { AgGridReact } from "@ag-grid-community/react";
import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-alpine.css';
import config from "../../../settings";
import { NavLink } from "react-router-dom";
import { useLocation } from "react-router-dom";
import PerfectScrollbar from "perfect-scrollbar";
import "bootstrap/dist/css/bootstrap.css";
import { Col } from "react-bootstrap";
import { RowGroupingModule } from "@ag-grid-enterprise/row-grouping";
import { MenuModule } from "@ag-grid-enterprise/menu";
import { SetFilterModule } from "@ag-grid-enterprise/set-filter";
import { FiltersToolPanelModule } from "@ag-grid-enterprise/filter-tool-panel";
import { ColumnsToolPanelModule } from "@ag-grid-enterprise/column-tool-panel";
import { StatusBarModule } from "@ag-grid-enterprise/status-bar";
import { RangeSelectionModule } from "@ag-grid-enterprise/range-selection";
import { ExcelExportModule } from "@ag-grid-enterprise/excel-export";
import { Typeahead } from "react-bootstrap-typeahead";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import * as alasql from "alasql";
import {
  EventMonitoringColumnsGrid,
  SOColumnsGrid,
  TOColumnsGrid,
  TripColumnsGrid,
  dateValueFormatter,
} from "../../../utils/GridUtils";
import { CustomPopover } from "../";
import Timer from "react-compound-timer";
import { toAbsoluteUrl } from "../../../_metronic/_helpers/AssetsHelpers";
import SVG from "react-inlinesvg";
import { AgGridService } from "../../../services/AgGridService";
import "./headerTable.css";
import { useNavigate } from "react-router-dom";
var server = {};

function FakeServer(allData, lightversion) {
  alasql.options.cache = false;
  var columnApi = null;
  var eventApi = null;
  var perfectScrollbarH = null;
  var perfectScrollbarV = null;
  var Title = null;
  var fieldIsNumber = {};
  var BlockQuery = false;
  var filteredData = [];

  return {
    getData: function (request) {
      var results = executeQuery(request);

      filteredData = results;

      return {
        success: true,
        rows: results,
        lastRow: getLastRowIndex(request, results),
      };
    },
    getValues: function (field) {
      var sql =
        "SELECT DISTINCT [" + field + "] FROM ? ORDER BY [" + field + "] ASC";
      var i = 0;

      while (fieldIsNumber[field] == undefined && i < allData.length) {
        if (typeof allData[i][field] == "number") {
          fieldIsNumber[field] = true;
        }
        i++;
      }

      return alasql(sql, [allData]).map(function (x) {
        return x[field];
      });
    },
    getFilteredData: function () {
      return filteredData;
    },
    getVersion: function () {
      return lightversion;
    },
    setColumnApi: function (columnapi) {
      columnApi = columnapi;
    },
    getColumnApi: function () {
      return columnApi;
    },
    setEventApi: function (eventapi) {
      eventApi = eventapi;
    },
    setTitle: function (title) {
      Title = title;
    },
    getEventApi: function () {
      return eventApi;
    },
    setPSVertical: function (perfectScrollbar) {
      perfectScrollbarV = perfectScrollbar;
    },
    getPSVertical: function () {
      return perfectScrollbarV;
    },
    setPSHorizontal: function (perfectScrollbar) {
      perfectScrollbarH = perfectScrollbar;
    },
    getPSHorizontal: function () {
      return perfectScrollbarH;
    },
    getTitle: function () {
      return Title;
    },
    setBlockQuery: function (block) {
      BlockQuery = block;
    },
    getBlockQuery: function () {
      return BlockQuery;
    },
  };

  function executeQuery(request) {
    var sql = buildSql(request);

    //console.log('[FakeServer] - about to execute query:', sql);

    return alasql(sql, [allData]);
  }

  function buildSql(request) {
    return (
      selectSql(request) +
      " FROM ?" +
      whereSql(request) +
      groupBySql(request) +
      orderBySql(request) +
      limitSql(request)
    );
  }

  function selectSql(request) {
    var rowGroupCols = request.rowGroupCols;
    var valueCols = request.valueCols;
    var groupKeys = request.groupKeys;

    if (isDoingGrouping(rowGroupCols, groupKeys)) {
      var rowGroupCol = rowGroupCols[groupKeys.length];
      var colsToSelect = [rowGroupCol.id];

      valueCols.forEach(function (valueCol) {
        colsToSelect.push(
          valueCol.aggFunc + "(" + valueCol.id + ") AS " + valueCol.id
        );
      });

      return "SELECT " + colsToSelect.join(", ");
    }

    return "SELECT *";
  }

  function whereSql(request) {
    var rowGroups = request.rowGroupCols;
    var groupKeys = request.groupKeys;
    var filterModel = request.filterModel;
    var whereParts = [];

    if (groupKeys) {
      groupKeys.forEach(function (key, i) {
        var value = typeof key === "string" ? "'" + key + "'" : key;

        whereParts.push(rowGroups[i].id + " = " + value);
      });
    }
    if (filterModel) {
      Object.keys(filterModel).forEach(function (columnKey) {
        var filter = filterModel[columnKey];
        if (filter.filterType === "set") {
          whereParts.push(
            "[" +
            columnKey +
            "] IN (" +
            myJoin(filter.values, fieldIsNumber[columnKey]) +
            ")"
          );
          return;
        }

        // console.log('unsupported filter type: ' + filter.filterType);
      });
    }

    if (whereParts.length > 0) {
      return " WHERE " + whereParts.join(" AND ");
    }

    return "";
  }

  function groupBySql(request) {
    var rowGroupCols = request.rowGroupCols;
    var groupKeys = request.groupKeys;

    if (isDoingGrouping(rowGroupCols, groupKeys)) {
      var rowGroupCol = rowGroupCols[groupKeys.length];

      return " GROUP BY " + rowGroupCol.id;
    }

    return "";
  }

  function orderBySql(request) {
    var sortModel = request.sortModel;

    if (sortModel.length === 0) return "";

    var sorts = sortModel.map(function (s) {
      return "[" + s.colId + "] " + s.sort.toUpperCase();
    });

    return " ORDER BY " + sorts.join(", ");
  }

  function limitSql(request) {
    var blockSize = request.endRow - request.startRow;

    return " LIMIT " + (blockSize + 1) + " OFFSET " + request.startRow;
  }

  function isDoingGrouping(rowGroupCols, groupKeys) {
    // we are not doing grouping if at the lowest level
    return rowGroupCols.length > groupKeys.length;
  }

  function getLastRowIndex(request, results) {
    if (!results || results.length === 0) {
      return null;
    }

    var currentLastRow = request.startRow + results.length;

    return currentLastRow <= request.endRow ? currentLastRow : -1;
  }
}
//eslint-disable-next-line
function ServerSideDatasource(server) {
  return {
    getRows: function (params) {
      //console.log('[Datasource] - rows requested by grid: ', params.request);
      var response = server.getData(params.request);

      setTimeout(function () {
        if (response.success) {
          params.successCallback(response.rows, response.lastRow);
          doAutoSize(server.getColumnApi(), server.getEventApi());
          updatePS(server.getVersion(), params.request.startRow, server);
        } else {
          params.failCallback();
        }
      }, 500);
    },
  };
}
// autosize sulla colonne con id (attualmente disabilitato) e autosize quando tabella più piccola del dovuto
function doAutoSize(columnApi, eventApi) {
  var totWidth = 0;
  var element = document.querySelector(".grid-wrapper");
  var maxWidth = element != null ? element.offsetWidth : 0;

  if (maxWidth != 0) {
    /*columnApi.getAllColumns().forEach(function (column) {
      var headerName = column.userProvidedColDef.headerName.toString().toLowerCase();
      if (headerName.includes(" id") || headerName.includes("id "))
        allColumnIds.push(column.colId);
    });
    columnApi.autoSizeColumns(allColumnIds, false);*/

    if (columnApi.getAllColumns()) {
      columnApi.getAllColumns().forEach(function (column) {
        if (!column.colDef.hide) totWidth += column.actualWidth;
      });

      //console.log(totWidth, maxWidth)
      if (totWidth < maxWidth) {
        eventApi.sizeColumnsToFit();
      }
    }
  }
}

function isNumeric(str) {
  if (typeof str != "string") return false;
  return !isNaN(str) && !isNaN(parseFloat(str));
}

function myJoin(values, fieldIsNumber) {
  var joined_string = "";

  for (var i = 0; i < values.length; i++) {
    if (i > 0) joined_string += ", ";

    if (fieldIsNumber) joined_string += values[i];
    else {
      values[i] = values[i].replaceAll("'", "''");
      values[i] = values[i].replaceAll('"', '""');
      values[i] = values[i].replaceAll("\\", "\\\\");
      joined_string += "'" + values[i] + "'";
    }
  }
  return joined_string;
}
//eslint-disable-next-line
const buildColumnTypes = (lightVersion) => {
  //eslint-disable-next-line
  var columnTypes = new Object();
  columnTypes.nonEditableColumn = { editable: false };

  if (!lightVersion) {
    columnTypes.numberColumn = {
      filter: "agNumberColumnFilter",
    };

    columnTypes.dateColumn = {
      filter: "agDateColumnFilter",
      filterParams: {
        comparator: function (filterLocalDateAtMidnight, cellValue) {
          var dateParts = cellValue.split("/");
          var day = Number(dateParts[0]);
          var month = Number(dateParts[1]) - 1;
          var year = Number(dateParts[2]);
          var cellDate = new Date(year, month, day);
          if (cellDate < filterLocalDateAtMidnight) {
            return -1;
          } else if (cellDate > filterLocalDateAtMidnight) {
            return 1;
          } else {
            return 0;
          }
        },
      },
    };
  }

  return columnTypes;
};

// gestisce il bottone ritorno indietro di tot pagine
function parsePath(path, title, navigate) {
  var pathElements = path.split("/");
  var formattedPath = "";
  //eslint-disable-next-line
  var numPagesBack = 0;
  for (var i = 0; i < pathElements.length; i++) {
    // non considero gli elementi vuoti (gli /) e l'ultimo, perchè è già title
    if (pathElements[i] != "" && i < pathElements.length - 1) {
      var tempPathElement = i != 1 ? pathElements[i] : "homepage";
      // tempPathElement deve essere passato ad una classe di servizio "Breadcrumb" che fa la conversione
      //eslint-disable-next-line
      formattedPath = (
        <span>
          {formattedPath}
          <button
            className="menu-link menu-toggle text-muted font-weight-bolder font-size-h5 text-left btn"
            style={{ paddingRight: "0.5rem" }}
            onClick={() => goBack(navigate, tempPathElement)}
          >
            {tempPathElement.charAt(0).toUpperCase() + tempPathElement.slice(1)}
            <span style={{ paddingLeft: "0.6rem" }}>{"/"}</span>
          </button>
        </span>
      );
    }
  }

  return (
    <div>
      {formattedPath}
      <button
        className="text-dark-75 font-weight-bolder font-size-h5 btn"
        style={{ verticalAlign: "middle", paddingLeft: "0" }}
      >
        {title}
      </button>
    </div>
  );
}

function goBack(navigate, path) {
  switch (path) {
    case "homepage":
      navigate("/dashboard");
      return 1;
    default:
      return -1;
  }
}

/*
function isFirstUpdatePS() {
  var psRails = document.querySelectorAll('.ps__rail-y');
  var numGrids = document.querySelectorAll('.ag-body-viewport').length;
  var numPsRails = 0;
  
  for (var i = 0; i < psRails.length; i++) {
    if (psRails[i].parentNode.className.includes("ag-body-viewport"))
      numPsRails += 1;
  }
 console.log("cia "+numGrids+ " "+numPsRails);
  return numPsRails != numGrids;
}*/

function hasRail(className, element) {
  for (var i = 0; i < element.childNodes.length; i++) {
    if (element.childNodes[i].className == className) return true;
  }

  return false;
}

function isCorrectGrid(value, element, title) {
  if (value == "ag-body-viewport") {
    //eslint-disable-next-line
    return (
      //eslint-disable-next-line
      element.parentElement.parentElement.parentElement.parentElement
        .parentElement.parentElement.parentElement.parentElement.parentElement
        .id ==
      "grid-wrapper-" + title
    );
  } else {
    //eslint-disable-next-line
    return (
      //eslint-disable-next-line
      element.parentElement.parentElement.parentElement.parentElement
        .parentElement.parentElement.parentElement.parentElement.parentElement
        .parentElement.id ==
      "grid-wrapper-" + title
    );
  }
}

function updatePS(lightVersion, startRow, server) {
  if (startRow == 0) {
    const agBodyViewport = document.querySelectorAll(".ag-body-viewport");
    const agBodyHorizontalViewport = document.querySelectorAll(
      ".ag-body-horizontal-scroll-viewport"
    );

    if (agBodyViewport) {
      var ps = null;
      for (var i = 0; i < agBodyViewport.length; i++) {
        if (
          isCorrectGrid(
            "ag-body-viewport",
            agBodyViewport[i],
            server.getTitle()
          ) &&
          !hasRail("ps__rail-y", agBodyViewport[i]) &&
          !hasRail("ps__rail-y ps--clicking", agBodyViewport[i])
        ) {
          ps = new PerfectScrollbar(agBodyViewport[i]);
          ps.settings.wheelSpeed = 0.5;
          ps.settings.maxScrollbarLength = 200;
          ps.settings.minScrollbarLength = 100;

          // if (!lightVersion) {
          ps.update();
          server.setPSVertical(ps);
          // }
          // else
          //   ps.destroy();
        }
      }
    }
    if (agBodyHorizontalViewport) {
      //eslint-disable-next-line
      var ps = null;
      //eslint-disable-next-line
      for (var i = 0; i < agBodyHorizontalViewport.length; i++) {
        if (
          isCorrectGrid(
            "ag-body-horizontal-scroll-viewport",
            agBodyHorizontalViewport[i],
            server.getTitle()
          ) &&
          !hasRail("ps__rail-x", agBodyHorizontalViewport[i])
        ) {
          ps = new PerfectScrollbar(agBodyHorizontalViewport[i]);
          ps.settings.wheelSpeed = 0.5;
          ps.settings.maxScrollbarLength = 200;
          ps.settings.minScrollbarLength = 100;
          //agBodyHorizontalViewport[i].addEventListener('ps-scroll-x', () => console.log('scrolled'))
          ps.update();
          server.setPSHorizontal(ps);
        }
      }
    }
  } else {
    if (server.getPSHorizontal() != null) server.getPSHorizontal().update();

    if (server.getPSVertical() != null) server.getPSVertical().update();
  }
}

function updatePSFilters() {
  const agFilterVirtualListViewport = document.querySelectorAll(
    ".ag-filter-virtual-list-viewport"
  );
  const agColumnSelectVirtualListViewport = document.querySelectorAll(
    ".ag-column-select-virtual-list-viewport"
  );

  if (agFilterVirtualListViewport) {
    var ps = null;
    for (var i = 0; i < agFilterVirtualListViewport.length; i++) {
      if (!hasRail("ps__rail-y", agFilterVirtualListViewport[i])) {
        ps = new PerfectScrollbar(agFilterVirtualListViewport[i]);
        ps.settings.wheelSpeed = 0.5;
        ps.settings.maxScrollbarLength = 100;
        ps.settings.minScrollbarLength = 50;
        ps.update();
      }
    }
  }
  if (agColumnSelectVirtualListViewport) {
    //eslint-disable-next-line
    var ps = null;
    //eslint-disable-next-line
    for (var i = 0; i < agColumnSelectVirtualListViewport.length; i++) {
      if (!hasRail("ps__rail-y", agColumnSelectVirtualListViewport[i])) {
        ps = new PerfectScrollbar(agColumnSelectVirtualListViewport[i]);
        ps.settings.wheelSpeed = 0.5;
        ps.settings.maxScrollbarLength = 100;
        ps.settings.minScrollbarLength = 50;
        ps.update();
      }
    }
  }
}

export default function GridCard({
  gridId,
  handleSaveGridConfig,
  groupColWidth,
  isNotDefaultConfig,
  isFetching,
  title,
  columns,
  data,
  sortModel,
  count,
  lightVersion,
  linkTo,
  openModalOnRowClick,
  isModal,
  handleShow,
  openPageOnRowClick,
  handleGoToPage,
  tooltipTitle,
  tooltipContent,
  tooltipFloat,
  tooltipPlacement,
  showTooltip,
  currentDensity,
  exportAsExcel,
  hideBreadcrumbs,
  receiveLiteVersionFromGridCard,
  liteVersion
}) {
  const navigate = useNavigate();
  const DEFAULT_DENSITY = "LARGE";
  const DENSITY_LIST = ["LARGE", "MEDIUM", "SMALL"];
  const BASE_HEIGHT_ROW = 40;
  const MAX_PAGE_SIZE = 100;
  //const [server, setServer] = useState(new FakeServer(data, lightVersion));
  const [agGridApi, setAgGridApi] = useState(null);
  //eslint-disable-next-line
  const [systemFilter, setSystemFilter] = useState(false);
  //eslint-disable-next-line
  const [id, setId] = useState(gridId != undefined ? gridId : "default");
  const [filterModel, setFilterModel] = useState({});
  const [resetFiltersEnabled, setResetFiltersEnabled] = useState(false);
  const [resetConfigEnabled, setResetConfigEnabled] = useState(
    isNotDefaultConfig
  );
  const [saveConfigEnabled, setSaveConfigEnabled] = useState(false);
  const [totColumns, setTotColumns] = useState();
  const [visibleColumns, setVisibleColumns] = useState();
  const [doStartTimer, setDoStartTimer] = useState(false);
  const [resetClicked, setResetClicked] = useState(false);
  const [ctrlPressed, setCtrlPressed] = useState(false);
  const [isMaximized, setIsMaximized] = useState(false);
  const [density, setDensity] = useState(
    currentDensity != undefined ? currentDensity : DEFAULT_DENSITY
  );
  const [cssValues, setCssValues] = useState();
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = (e) => {
    const newValue = e.target.checked;
    setIsChecked(newValue);
    receiveLiteVersionFromGridCard(newValue);
  };

  const sideBar = useMemo(() => {
    return {
      toolPanels: [
        {
          id: "filters",
          labelDefault: "Filters",
          labelKey: "filters",
          iconKey: "filter",
          toolPanel: "agFiltersToolPanel",
        },
      ],
      defaultToolPanel: "filters",
      hiddenByDefault: true,
    };
  }, []);

  const [filteredColumnsToDisplay, setfilteredColumnsToDisplay] = useState([]);
  var resettingColumns = false;
  //EVO 26
  //REF AG GRID
  const tableElement = useRef();
  // Ensuring the license key for AGGrid has been set
  useEffect(() => {
    new AgGridService();
  }, []);

  useEffect(() => {
    setResetConfigEnabled(isNotDefaultConfig);

    if (openModalOnRowClick || openPageOnRowClick) {
      document.addEventListener("keydown", disableRowClick);
      document.addEventListener("keyup", enableRowClick);
    }

    // var tempServer = new FakeServer(data, lightVersion);
    // setServer(tempServer);
    return () => {
      if (openModalOnRowClick || openPageOnRowClick) {
        document.removeEventListener("keydown", disableRowClick);
        document.removeEventListener("keyup", enableRowClick);
      }
    };
  }, [isNotDefaultConfig]);

  function getMainMenuItems(params) {
    var MenuItems = [];
    var itemsToExclude = [
      "separator",
      "pinSubMenu",
      "valueAggSubMenu",
      "resetColumns",
    ];

    if (lightVersion || params.column.colId == "ag-Grid-AutoColumn")
      itemsToExclude.push("rowGroup");

    params.defaultItems.forEach(function (item) {
      if (itemsToExclude.indexOf(item) < 0) {
        MenuItems.push(item);
      }
    });

    setTimeout(function () {
      updatePSFilters();

      var len = document.getElementsByClassName("ag-tab").length;
      for (var i = 0; i < len; i++) {
        if (
          document.getElementsByClassName("ag-tab")[i].ariaLabel == "columns" ||
          document.getElementsByClassName("ag-tab")[i].ariaLabel == "filter"
        )
          document
            .getElementsByClassName("ag-tab")
          [i].addEventListener("click", updatePSFilters);
      }
    }, 1000);

    return MenuItems;
  }

  function myFilterCellRenderer(params) {
    if (
      Number.isNaN(Date.parse(params.value)) ||
      isNumeric(params.value.toString()) ||
      !(params.value.includes(":") && params.value.includes("-"))
    ) {
      if (params.value == "false" || params.value == "true")
        return ((params.value == "true" ? "YES" : "NO"));
      else
        return params.value;
    } else
      return (dateValueFormatter(params));
  }

  const defaultColDef = useMemo(() => {
    return {
      //width: 190,
      lockVisible: false,
      lockPinned: true,
      enableRowGroup: true,
      resizable: true,
      sortable: true,
      tooltipValueGetter: tooltipValueGetter,
      filterParams: {
        suppressSelectAll: false,
        // values: (params) => { getValuesAsync(params, server[id]); },
        //suppressSorting: false,
        // refreshValuesOnOpen: false,
        cellRenderer: myFilterCellRenderer,
        resetButton: true,
      },
      menuTabs: ["generalMenuTab", "filterMenuTab", "columnsMenuTab"],
      filter: "agSetColumnFilter",
      // TODO: qui si abilita l'icona stilizzata
      icons: {
        menu: '<div class="custom-filter-icon"><div/>',
        //filter: '<div class="custom-filter-icon"><div/>'
      },
      wrapText: true,
      autoHeight: true,
      cellClass: "agCellAlignCenter",
    };
  }, []);

  //eslint-disable-next-line
  function buildStatusBar() {
    return {
      statusPanels: [
        // {
        //   statusPanel: 'agTotalAndFilteredRowCountComponent',
        //   align: 'left',
        // },
        // {
        //   statusPanel: 'agTotalRowCountComponent',
        //   align: 'center',
        // },
        // { statusPanel: 'agFilteredRowCountComponent' },
        // { statusPanel: 'agSelectedRowCountComponent' },
        { statusPanel: "agAggregationComponent" },
      ],
    };
  }

  function headerHeightGetter() {
    var columnHeaderTexts = [
      ...document.querySelectorAll(".ag-header-cell-text"),
    ];

    var clientHeights = columnHeaderTexts.map(
      (headerText) => headerText.clientHeight
    );

    var tallestHeaderTextHeight = Math.max(...clientHeights);

    return tallestHeaderTextHeight;
  }

  function headerHeightSetter(event) {
    server[id].getEventApi().resetRowHeights();
    var padding = 35;
    var height = headerHeightGetter() + padding;

    server[id].getEventApi().setGridOption('headerHeight', height);

    if (event.type != "firstDataRendered") {
      setSaveConfigurationColor("");
      setResetConfigurationColor("");
    } else {
      setSaveConfigurationColor("grey");
      // setResetConfigurationColor("grey");
    }
  }

  const getRowHeight = () => {
    if (!!density) {
      var addHeight = 0;
      if (density == DENSITY_LIST[1]) {
        addHeight = 12;
      } else if (density == DENSITY_LIST[0]) {
        addHeight = 23;
      }

      return BASE_HEIGHT_ROW + addHeight;
    } else {
      return BASE_HEIGHT_ROW + 0;
    }
  }

  function cleanValues(values) {
    var cleanedValues = [];

    for (var i = 0; i < values.length; i++) {
      if (values[i] != null && values[i] != undefined)
        cleanedValues.push(values[i]);
    }

    return cleanedValues;
  }
  // funzione generica per tirare fuori i valori nei filtri
  //eslint-disable-next-line
  function getValuesAsync(params) {
    //var newServer = new FakeServer(data, lightVersion);
    //eslint-disable-next-line
    var a = agGridApi;
    var values = server[id].getValues(params.colDef.field);
    values = cleanValues(values);

    // for (var i = 0; i < 3; i++) {
    //   if (document.getElementsByClassName("ag-tab")[i].ariaLabel == "columns" || document.getElementsByClassName("ag-tab")[i].ariaLabel == "filter")
    //     document.getElementsByClassName("ag-tab")[i].addEventListener("click", updatePSFilters);
    // }

    //console.log(values);
    //debugger;

    // var filterVals = [];
    // var visibleNodes = server.getEventApi().getRenderedNodes().map(el => filterVals.push(el.data[params.colDef.field].toString()));

    // var actualFilterModel = server.getEventApi().getFilterModel();
    // actualFilterModel[params.colDef.field] = {};
    // actualFilterModel[params.colDef.field].filterType = "set";
    // actualFilterModel[params.colDef.field].values = filterVals;
    // debugger;
    // server.setBlockQuery(true);
    // server.getEventApi().setFilterModel(actualFilterModel);
    //console.log(actualFilterModel)

    setTimeout(function () {
      params.success(values);

      //   var filterVals = [];
      // console.log("timeout get values");
      // server.getFilteredData().map(el => {
      //   if (el["objectId"]) return filterVals.push(el["objectId"].toString());
      // });
      //   var FilterComponent = server.getEventApi().getFilterInstance("objectId");
      //  FilterComponent.selectNothing();
      // filterVals.map(el => FilterComponent.selectValue(el));

      updatePSFilters();
    }, 500);
  }

  // qui va fatto il refresh dei filtri
  const onFilterChanged = () => {
    const filterModelColumns = Object.keys(filterModel).sort();
    const newFilterModel = server[id].getEventApi().getFilterModel();
    const newFilterModelColumns = Object.keys(newFilterModel).sort();

    if (
      JSON.stringify(filterModelColumns) !==
      JSON.stringify(newFilterModelColumns)
    ) {
      setFilterModel(newFilterModel);
      const gridColumns = server[id].getEventApi().getColumnState();
      const filteredColumns = gridColumns.filter((column) =>
        newFilterModelColumns.includes(column.colId)
      );

      let nameFileteredColumns = [];
      let columnsGrid = "";
      if (title === "T.O. Execution") {
        columnsGrid = TOColumnsGrid();
      } else if (title === "Trips") {
        columnsGrid = TripColumnsGrid();
      } else if (title === "Sales orders") {
        columnsGrid = SOColumnsGrid();
      } else {
        columnsGrid = EventMonitoringColumnsGrid(
          () => { },
          () => { }
        );
      }

      for (let i = 0; i < columnsGrid.length; i++) {
        for (let j = 0; j < filteredColumns.length; j++) {
          if (columnsGrid[i].colId === filteredColumns[j].colId) {
            nameFileteredColumns.push(columnsGrid[i].headerName);
            break;
          }
        }
      }

      setfilteredColumnsToDisplay(nameFileteredColumns);
      const gridFiltered = filteredColumns.some((column) => column);
      gridFiltered &&
        server[id]
          .getEventApi()
          .getToolPanelInstance("filters")
          .setFilterLayout(filteredColumns);
      !gridFiltered && server[id].getEventApi().closeToolPanel();
      server[id].getEventApi().setSideBarVisible(gridFiltered);
      setResetFiltersEnabled(gridFiltered);
    }
  };

  useEffect(() => {
    if (isFetching === false) {
      setfilteredColumnsToDisplay([]);
    }
  }, [isFetching])

  function tooltipValueGetter(params) {
    return params.valueFormatted != null ? params.valueFormatted : params.value;
  }

  function resetFilters() {
    server[id].getEventApi().setFilterModel(null);
    server[id].getEventApi().closeToolPanel();
    server[id].getEventApi().setSideBarVisible(false);
  }

  function resetGridConfiguration() {
    setResetClicked(true);
    handleSaveGridConfig("reset", server[id].getColumnApi(), server[id].getEventApi(), DEFAULT_DENSITY);
  }

  function saveGridConfiguration() {
    handleSaveGridConfig(
      server[id].getEventApi().getColumnState(),
      null,
      null,
      density
    );

    setSaveConfigurationColor("grey");
  }

  function updateVisibleColumns(cols) {
    var numVisible = 0;
    for (var i = 0; i < cols.length; i++) {
      if (!cols[i].hide && cols[i].colId != "ag-Grid-AutoColumn") numVisible++;
    }

    setVisibleColumns(numVisible);
  }

  const dataSanitizing = (items) => {
    if (items === null || items === undefined) return items;
    //eslint-disable-next-line
    var newItems = new Array();

    for (var i = 0; i < items.length; i++) {
      newItems.push(items[i]);
      for (var o in items[i]) {
        newItems[i][o] = items[i][o];

        if (items[i][o] === null || items[i][o] === "") {
          newItems[i][o] = " ";
        }
      }
    }

    return newItems;
  };

  const dataSanitized = useMemo(() => {
    if (data === null || data === undefined) return data;
    //eslint-disable-next-line
    var newItems = new Array();

    for (var i = 0; i < data.length; i++) {
      newItems.push(data[i]);
      for (var o in data[i]) {
        newItems[i][o] = data[i][o];

        if (data[i][o] === null || data[i][o] === "") {
          newItems[i][o] = " ";
        }
      }
    }

    return newItems;
  }, [data]);

  const onGridReady = (event) => {
    var sanitized = dataSanitizing(data);
    server[id] = new FakeServer(sanitized, lightVersion);

    setTotColumns(event.api.columnModel.columnDefs.length);
    updateVisibleColumns(event.api.columnModel.columnDefs);

    if (!isModal) {
      // doAutoSize (event.columnApi, event.api);

      if (!lightVersion) {
        document.getElementsByClassName(
          "ag-root-wrapper-body ag-layout-normal ag-focus-managed"
        )[0].style.height = "480px";
      }
    }

    var eventApi = event.api;
    setAgGridApi(event.api);

    const updateData = () => {
      server[id].setColumnApi(event.columnApi);
      server[id].setTitle(title);
      server[id].setEventApi(eventApi);
      updatePS(server[id].getVersion(), 0, server[id]);
      // TODO: da scommentare per server side model
      // var datasource = new ServerSideDatasource(server[id]);

      // eventApi.setServerSideDatasource(datasource);
    };

    // count undefined
    // if (count > 0) {
    updateData();
    // }
    // else {
    //   server[id].setColumnApi(event.columnApi);
    //   server[id].setEventApi(eventApi);
    //   server[id].setTitle(title);
    //   updatePS(lightVersion, 0, server[id]);
    // }

    var d = currentDensity != undefined ? currentDensity : DEFAULT_DENSITY;
    setDensity(d);
    if (sortModel) {
      event.api.applyColumnState({ state: sortModel });
    }
  };

  function setButtonsConfigurationColor(color) {
    setResetConfigurationColor(color);
    setSaveConfigurationColor(color);
  }

  function setResetConfigurationColor(color) {
    if (color == "grey") {
      setResetConfigEnabled(false);
    } else {
      if (!resettingColumns) {
        setResetConfigEnabled(true);
      } else {
        setResetConfigEnabled(false);
      }
    }
  }

  function setSaveConfigurationColor(color) {
    if (color == "grey") {
      setSaveConfigEnabled(false);
    } else {
      updateVisibleColumns(server[id].getEventApi().getColumnState());

      if (!resettingColumns) {
        setSaveConfigEnabled(true);
      } else {
        setSaveConfigEnabled(false);
      }
    }
  }

  function onPageSizeChanged() {
    var objName = "custom-page-size";
    if (isModal) objName += "-modal";

    var value = document.getElementById(objName).value;
    server[id].getEventApi().paginationSetPageSize(Number(value));

    updatePS(server[id].getVersion(), 1, server[id]);
  }

  function handlePaginationChanged() {
    if (server[id] != undefined)
      updatePS(server[id].getVersion(), 1, server[id]);
  }

  function handleSetColumnState(event) {
    if (server[id] != undefined) {
      // doAutoSize (server[id].getColumnApi(), server[id].getEventApi());
      if (
        event.type == "columnEverythingChanged" &&
        event.source == "api" &&
        resetClicked
      ) {
        resettingColumns = true;
        setSaveConfigurationColor("grey");

        setResetConfigurationColor("grey");
        setDoStartTimer(true);
        var tempDensity = currentDensity != undefined ? currentDensity : DEFAULT_DENSITY;
        setDensity(tempDensity);
      }
    }
  }

  function disableResettingColumns() {
    setDoStartTimer(false);
    resettingColumns = false;
  }

  function disableRowClick(e) {
    if (e.isTrusted && e.key == "Control") setCtrlPressed(true);
  }

  function enableRowClick(e) {
    if (e.isTrusted && e.key == "Control") setCtrlPressed(false);
  }

  function handleMaximize(maximize) {
    setIsMaximized(maximize);
    var elements = document.getElementById("grid-wrapper-" + title).children;
    var pagingPanels = document.getElementsByClassName(
      "ag-paging-panel ag-unselectable"
    );
    var titles = document.getElementsByClassName(
      "card-title d-flex align-items-center justify-content-between"
    );
    var pageSizeSelector = document.getElementsByClassName(
      "page-size-selector"
    );
    var agThemeAlpine = document.getElementsByClassName("ag-theme-alpine");
    var gridContainer = document.getElementsByClassName("grid-container");
    // aggiunte per dashboard
    var colXxl6 = document.getElementsByClassName("col-xxl-6");
    var colXxl12 = document.getElementsByClassName("col-xxl-12");
    // aggiunta per detailpage
    var col = document.getElementsByClassName("col");
    var body = document.getElementsByClassName("body");
    var detailsTabContainer = document.getElementsByClassName(
      "details-tab-container"
    );
    var tabsTabsPills = document.getElementsByClassName("tabs tabs-pills");
    var map = document.getElementsByClassName("map");
    var headerFixed = document.getElementsByClassName("header header-fixed");
    var searchBar = document.getElementsByClassName(
      "search-activator input-group"
    ); //  --> important
    var asideMenu = document.getElementsByClassName(
      "aside aside-left  aside-fixed d-flex flex-column flex-row-auto"
    );
    var subHeader = document.getElementsByClassName(
      "subheader py-2 py-lg-4 subheader-solid sub-show"
    );
    // aggiunta per modale
    var modalBody = document.getElementsByClassName("modal-body");
    var modalContent = document.getElementsByClassName("modal-content");
    var modalDialog = document.getElementsByClassName(
      "modal-dialog modal-dialog-centered"
    );

    var i = 0;
    var gridStyle = null;
    while (i < elements.length) {
      if (
        elements[i].className.includes(
          "card card-custom card-stretch card-stretch"
        ) &&
        elements[i].className.includes("grid-widget")
      ) {
        gridStyle = elements[i].style;
        i = elements.length;
      }

      i++;
    }

    if (maximize) {
      //eslint-disable-next-line
      oldCssValues = {
        pagingPanels: {
          height: [],
        },
        titles: {
          marginBottom: [],
        },
        pageSizeSelector: {
          bottom: [],
        },
        agThemeAlpine: {
          height: [],
        },
        gridContainer: {
          height: [],
        },
        pageYOffset: {
          y: 0,
        },
        col: {
          position: [],
        },
        body: {
          position: [],
        },
        detailsTabContainer: {
          position: [],
        },
        map: {
          zIndex: [],
        },
        tabsTabsPills: {
          zIndex: [],
        },
        headerFixed: {
          zIndex: [],
        },
        searchBar: {
          zIndex: [],
        },
        asideMenu: {
          zIndex: [],
        },
        subHeader: {
          zIndex: [],
        },
      };

      for (i = 0; i < pagingPanels.length; i++) {
        //eslint-disable-next-line
        oldCssValues.pagingPanels.height.push(pagingPanels[i].style.height);
        pagingPanels[i].style.height = "28px";
      }

      for (i = 0; i < titles.length; i++) {
        //eslint-disable-next-line
        oldCssValues.titles.marginBottom.push(titles[i].style.marginBottom);
        titles[i].style.marginBottom = "20px";
        titles[i].style.setProperty("margin-top", "15px", "important");
      }
      for (i = 0; i < pageSizeSelector.length; i++) {
        //eslint-disable-next-line
        oldCssValues.pageSizeSelector.bottom.push(
          pageSizeSelector[i].style.bottom
        );
        pageSizeSelector[i].style.bottom = "18px";
      }
      for (i = 0; i < agThemeAlpine.length; i++) {
        //eslint-disable-next-line
        oldCssValues.agThemeAlpine.height.push(agThemeAlpine[i].style.height);
        agThemeAlpine[i].style.height = "100%";
      }
      for (i = 0; i < gridContainer.length; i++) {
        //eslint-disable-next-line
        oldCssValues.gridContainer.height.push(gridContainer[i].style.height);
        gridContainer[i].style.height = "100%";
      }
      for (i = 0; i < colXxl6.length; i++) {
        colXxl6[i].style.position = "static";
      }
      for (i = 0; i < colXxl12.length; i++) {
        colXxl12[i].style.position = "static";
      }
      for (i = 0; i < col.length; i++) {
        //eslint-disable-next-line
        oldCssValues.col.position.push(col[i].style.position);
        col[i].style.position = "static";
      }
      for (i = 0; i < body.length; i++) {
        //eslint-disable-next-line
        oldCssValues.body.position.push(body[i].style.position);
        body[i].style.position = "static";
      }
      for (i = 0; i < detailsTabContainer.length; i++) {
        //eslint-disable-next-line
        oldCssValues.detailsTabContainer.position.push(
          detailsTabContainer[i].style.position
        );
        detailsTabContainer[i].style.position = "static";
      }
      for (i = 0; i < map.length; i++) {
        //eslint-disable-next-line
        oldCssValues.map.zIndex.push(map[i].style.zIndex);
        map[i].style.zIndex = "-1";
      }
      for (i = 0; i < tabsTabsPills.length; i++) {
        //eslint-disable-next-line
        oldCssValues.tabsTabsPills.zIndex.push(tabsTabsPills[i].style.zIndex);
        tabsTabsPills[i].style.zIndex = "-1";
      }
      for (i = 0; i < headerFixed.length; i++) {
        //eslint-disable-next-line
        oldCssValues.headerFixed.zIndex.push(headerFixed[i].style.zIndex);
        headerFixed[i].style.zIndex = "-1";
      }
      for (i = 0; i < searchBar.length; i++) {
        //eslint-disable-next-line
        oldCssValues.searchBar.zIndex.push(searchBar[i].style.zIndex);
        searchBar[i].style.setProperty("z-index", "0", "important");
      }
      for (i = 0; i < asideMenu.length; i++) {
        //eslint-disable-next-line
        oldCssValues.asideMenu.zIndex.push(asideMenu[i].style.zIndex);
        asideMenu[i].style.zIndex = "-1";
      }
      for (i = 0; i < subHeader.length; i++) {
        //eslint-disable-next-line
        oldCssValues.subHeader.zIndex.push(subHeader[i].style.zIndex);
        subHeader[i].style.zIndex = "-1";
      }
      for (i = 0; i < modalBody.length; i++) {
        modalBody[i].style.position = "static";
      }
      for (i = 0; i < modalContent.length; i++) {
        modalContent[i].style.position = "static";
      }
      for (i = 0; i < modalDialog.length; i++) {
        modalDialog[i].style.position = "static";
      }
      //eslint-disable-next-line
      oldCssValues.pageYOffset.y = window.pageYOffset;
      window.scroll(0, 0);
      document.body.style.height = "100%";
      document.body.style.setProperty("overflow", "hidden", "important");
      document.getElementsByTagName("html")[0].style.overflow = "hidden";

      gridStyle.position = "absolute";
      gridStyle.left = "16px";
      gridStyle.top = "40px";
      gridStyle.bottom = "16px";
      gridStyle.right = "16px";
      // z-index 1039 perchè la modale del reset ha 1040
      gridStyle.zIndex = "1039";
      gridStyle.setProperty("height", "93%", "important");
      //eslint-disable-next-line
      setCssValues(oldCssValues);
    } else {
      var oldCssValues = cssValues;
      for (i = 0; i < pagingPanels.length; i++) {
        pagingPanels[i].style.height = oldCssValues.pagingPanels.height[i];
      }

      for (i = 0; i < titles.length; i++) {
        titles[i].style.marginBottom = oldCssValues.titles.marginBottom[i];
        titles[i].style.setProperty("margin-top", "", "");
      }
      for (i = 0; i < pageSizeSelector.length; i++) {
        pageSizeSelector[i].style.bottom =
          oldCssValues.pageSizeSelector.bottom[i];
      }
      for (i = 0; i < agThemeAlpine.length; i++) {
        agThemeAlpine[i].style.height = oldCssValues.agThemeAlpine.height[i];
      }
      for (i = 0; i < gridContainer.length; i++) {
        gridContainer[i].style.height = oldCssValues.gridContainer.height[i];
      }
      for (i = 0; i < colXxl6.length; i++) {
        colXxl6[i].style.position = "";
      }
      for (i = 0; i < colXxl12.length; i++) {
        colXxl12[i].style.position = "";
      }
      for (i = 0; i < col.length; i++) {
        col[i].style.position = oldCssValues.col.position[i];
      }
      for (i = 0; i < body.length; i++) {
        body[i].style.position = oldCssValues.body.position[i];
      }
      for (i = 0; i < detailsTabContainer.length; i++) {
        detailsTabContainer[i].style.position =
          oldCssValues.detailsTabContainer.position[i];
      }
      for (i = 0; i < map.length; i++) {
        map[i].style.zIndex = oldCssValues.map.zIndex[i];
      }
      for (i = 0; i < tabsTabsPills.length; i++) {
        tabsTabsPills[i].style.zIndex = oldCssValues.tabsTabsPills.zIndex[i];
      }
      for (i = 0; i < headerFixed.length; i++) {
        headerFixed[i].style.zIndex = oldCssValues.headerFixed.zIndex[i];
      }
      for (i = 0; i < searchBar.length; i++) {
        searchBar[i].style.setProperty(
          "z-index",
          oldCssValues.searchBar.zIndex[i],
          "important"
        );
      }
      for (i = 0; i < asideMenu.length; i++) {
        asideMenu[i].style.zIndex = oldCssValues.asideMenu.zIndex[i];
      }
      for (i = 0; i < subHeader.length; i++) {
        subHeader[i].style.zIndex = oldCssValues.subHeader.zIndex[i];
      }
      for (i = 0; i < modalBody.length; i++) {
        modalBody[i].style.position = "";
      }
      for (i = 0; i < modalContent.length; i++) {
        modalContent[i].style.position = "";
      }
      for (i = 0; i < modalDialog.length; i++) {
        modalDialog[i].style.position = "";
      }
      document.getElementsByTagName("html")[0].style.overflow = "";

      document.body.style.height = "";
      document.body.style.overflow = "";
      window.scroll(0, oldCssValues.pageYOffset.y);
      gridStyle.position = "";
      gridStyle.left = "";
      gridStyle.top = "";
      gridStyle.bottom = "";
      gridStyle.right = "";
      gridStyle.zIndex = "";
      gridStyle.setProperty("height", "", "");
    }
  }

  function handleDensity(rowDensity) {
    setResetConfigurationColor();
    setSaveConfigurationColor();

    if (rowDensity == DENSITY_LIST[0]) {
      setDensity(DENSITY_LIST[0]);
    } else if (rowDensity == DENSITY_LIST[1]) {
      setDensity(DENSITY_LIST[1]);
    } else if (rowDensity == DENSITY_LIST[2]) {
      setDensity(DENSITY_LIST[2]);
    }
  }

  useEffect(() => {
    const eventApi = server[id]?.getEventApi();
    if (!!eventApi) {
      eventApi.forEachNode(function (rowNode) {
        if (rowNode.data) {
          rowNode.setRowHeight(getRowHeight());
        }
      });

      eventApi.onRowHeightChanged();
    }
  }, [density]);

  function exportExcel() {
    var params = {
      allColumns: true,
      fileName: title.replace(/ /g, ""),
      sheetName: "Sheet 1",
      skipGroups: true,
    };

    server[id].getEventApi().exportDataAsExcel(params);
  }

  //EVO 26
  //metodo onChange autocomplete
  //uso un ref sulla tabella AG GRID
  //una volta selezionato un valore dalla select mi sposto con la tabella - ensureColumnVisible
  //Infine con un query selector aggiungo la classe che evidenzierà la ricerca
  //All'inizio del metodo per 'resettare', tolgo a tutte la classe
  function onAutocompleteSearchColumnChange(event) {
    const resetClassMarked = document.querySelector(`.ag-header-cell.marked`);
    if (resetClassMarked) {
      resetClassMarked.classList.remove("marked");
    }
    if (event.length > 0) {
      const colId = event[0].colId;
      tableElement.current.api.ensureColumnVisible(colId);

      const addClassMarked = document.querySelector(
        `.ag-header-cell[col-id="${colId}"]`
      );
      if (addClassMarked) {
        addClassMarked.classList.add("marked");
      }
    }
  }

  const location = useLocation();

  return (
    <>
      {doStartTimer && (
        <Timer
          initialTime={1499}
          direction="backward"
          startImmediately={true}
          checkpoints={[
            {
              time: 0,
              callback: disableResettingColumns,
            },
          ]}
        ></Timer>
      )}
      <div
        className="grid-wrapper"
        id={"grid-wrapper-" + title}
        style={lightVersion ? { height: "450px" } : { paddingBottom: "3%" }}
      >
        {!lightVersion &&
          linkTo != "detail" &&
          !hideBreadcrumbs &&
          linkTo != "Event Monitoring" && (
            <div className="grid-breadcrumbs">{parsePath(location.pathname, title, navigate)}</div>
          )}
        {isMaximized && (
          <div className="backgroundFullScreen">
            <button
              className="notSelectedSvgGridCard"
              style={{
                position: "absolute",
                top: "0",
                right: "20px",
                zIndex: "1038",
              }}
              onClick={() => handleMaximize(false)}
            >
              <SVG
                src={toAbsoluteUrl("/media/svg/general/close_maximize.svg")}
              />
            </button>
          </div>
        )}
        <div
          className={
            isModal
              ? "grid-widget-modal card card-custom card-stretch card-stretch"
              : lightVersion
                ? "grid-widget card card-custom card-stretch card-stretch"
                : "grid-widget-view-all card card-custom card-stretch card-stretch"
          }
        >
          {isFetching && (
            <>
              <div className="loading-container"></div>
              <div className="loading-widget">
                <div className="loading-img"></div>
              </div>
            </>
          )}
          {!isFetching ? (
            <div className="card-body d-flex flex-column p-0">
              {!isModal || (isModal && isMaximized) ? (
                <>
                  <div
                    style={{ marginBottom: !lightVersion ? "20px" : "35px" }}
                    className="card-title d-flex align-items-center justify-content-between"
                  >
                    <div className="text-left">
                      {(!hideBreadcrumbs || isMaximized) && (
                        <span>{title}</span>
                      )}

                      {showTooltip && (
                        <span style={{ marginLeft: "15px" }}>
                          <CustomPopover
                            title={tooltipTitle}
                            content={tooltipContent}
                            float={tooltipFloat}
                            placement={tooltipPlacement}
                            marginTop={"-1px"}
                          />
                        </span>
                      )}

                      {exportAsExcel && (
                        <span
                          title="Download Excel"
                          style={{ cursor: "pointer", marginLeft: "15px" }}
                          onClick={exportExcel}
                        >
                          <SVG
                            title="Download Excel"
                            src={toAbsoluteUrl("/media/svg/grids/excel.svg")}
                          />
                        </span>
                      )}
                    </div>
                    {/* <label style={{ marginRight: '65rem' }} className="form-check-label">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={!liteVersion}
                        onChange={(e) => receiveLiteVersionFromGridCard(!e.target.checked)}
                      />
                      Full View
                    </label> */}
                    {(!lightVersion || isMaximized) && (
                      <div className="card-subtitle-right">
                        {!isMaximized && (
                          <button
                            className="notSelectedSvgGridCard"
                            onClick={() => handleMaximize(true)}
                          >
                            <SVG
                              src={toAbsoluteUrl(
                                "/media/svg/general/maximize.svg"
                              )}
                            />
                          </button>
                        )}

                        <button
                          id={DENSITY_LIST[0]}
                          className={
                            density == DENSITY_LIST[0]
                              ? "selectedSvgGridCard"
                              : "notSelectedSvgGridCard"
                          }
                          onClick={() => handleDensity(DENSITY_LIST[0])}
                        >
                          {density == DENSITY_LIST[0] && (
                            <SVG
                              src={toAbsoluteUrl(
                                "/media/svg/grids/hrow_big-black.svg"
                              )}
                            />
                          )}
                          {density != DENSITY_LIST[0] && (
                            <SVG
                              src={toAbsoluteUrl(
                                "/media/svg/grids/hrow_big-grey.svg"
                              )}
                              style={{ marginLeft: "-1px" }}
                            />
                          )}
                        </button>
                        <button
                          id={DENSITY_LIST[1]}
                          className={
                            density == DENSITY_LIST[1]
                              ? "selectedSvgGridCard"
                              : "notSelectedSvgGridCard"
                          }
                          onClick={() => handleDensity(DENSITY_LIST[1])}
                        >
                          {density == DENSITY_LIST[1] && (
                            <SVG
                              src={toAbsoluteUrl(
                                "/media/svg/grids/hrow_medium-black.svg"
                              )}
                            />
                          )}
                          {density != DENSITY_LIST[1] && (
                            <SVG
                              src={toAbsoluteUrl(
                                "/media/svg/grids/hrow_medium-grey.svg"
                              )}
                              style={{ marginLeft: "-2px" }}
                            />
                          )}
                        </button>
                        <button
                          id={DENSITY_LIST[2]}
                          className={
                            density == DENSITY_LIST[2]
                              ? "selectedSvgGridCard"
                              : "notSelectedSvgGridCard"
                          }
                          onClick={() => handleDensity(DENSITY_LIST[2])}
                        >
                          {density == DENSITY_LIST[2] && (
                            <SVG
                              src={toAbsoluteUrl(
                                "/media/svg/grids/hrow_small-black.svg"
                              )}
                            />
                          )}
                          {density != DENSITY_LIST[2] && (
                            <SVG
                              src={toAbsoluteUrl(
                                "/media/svg/grids/hrow_small-grey.svg"
                              )}
                              style={{ marginLeft: "-1px" }}
                            />
                          )}
                        </button>


                        {lightVersion && (
                          <span className="grid-count-info-no-margin-left">
                            {" "}
                            {config.limitLightGrids < count
                              ? config.limitLightGrids
                              : count}{" "}
                            of {count} elements
                          </span>
                        )}
                        {!lightVersion && (
                          <span className="grid-count-info-no-margin-left">
                            {count} elements
                          </span>
                        )}
                        <span className="grid-count-info-no-margin-left">
                          &nbsp;-&nbsp;
                          {visibleColumns + " of " + totColumns + " columns"}
                        </span>

                        <span className="config-btns">
                          <button
                            style={{
                              paddingRight: "0",
                              backgroundColor: "transparent",
                              borderStyle: "none",
                              fontFamily: "Swiss721BT-Normal",
                              fontSize: "14px",
                              lineHeight: "1.7",
                            }}
                            className={
                              "menu-link menu-toggle " +
                              (resetFiltersEnabled
                                ? "text-danger"
                                : "text-danger-disabled")
                            }
                            onClick={
                              resetFiltersEnabled ? resetFilters : () => { }
                            }
                          >
                            Reset All Filters
                          </button>
                        </span>
                        <span className="config-btns">
                          <button
                            style={{
                              backgroundColor: "transparent",
                              borderStyle: "none",
                              fontFamily: "Swiss721BT-Normal",
                              fontSize: "14px",
                              lineHeight: "1.7",
                            }}
                            className={
                              "menu-link menu-toggle " +
                              (resetConfigEnabled
                                ? "text-danger"
                                : "text-danger-disabled")
                            }
                            onClick={
                              resetConfigEnabled
                                ? resetGridConfiguration
                                : () => { }
                            }
                          >
                            Reset Config.
                          </button>
                        </span>
                        <span className="config-btns">
                          <button
                            style={{
                              backgroundColor: "transparent",
                              borderStyle: "none",
                              fontFamily: "Swiss721BT-Normal",
                              fontSize: "14px",
                              lineHeight: "1.7",
                            }}
                            className={
                              "menu-link menu-toggle " +
                              (saveConfigEnabled
                                ? "text-danger"
                                : "text-danger-disabled")
                            }
                            onClick={
                              saveConfigEnabled
                                ? saveGridConfiguration
                                : () => { }
                            }
                          >
                            Save Config.
                          </button>
                        </span>
                      </div>
                    )}

                    <div className="grid-view-all">
                      {lightVersion ? (
                        <>
                          <NavLink
                            className="menu-link menu-toggle text-danger"
                            to={linkTo}
                          >
                            View All
                          </NavLink>
                        </>
                      ) : (
                        <span></span>
                      )}
                    </div>
                  </div>
                  {lightVersion && !isMaximized && (
                    <div className="card-subtitle">
                      {lightVersion && (
                        <span className="grid-count-info-no-margin-left">
                          {" "}
                          {config.limitLightGrids < count
                            ? config.limitLightGrids
                            : count}{" "}
                          of {count} elements
                        </span>
                      )}
                      {!lightVersion && (
                        <span className="grid-count-info-no-margin-left">
                          {count} elements
                        </span>
                      )}
                      <span className="grid-count-info-no-margin-left">
                        &nbsp;-&nbsp;
                        {visibleColumns + " of " + totColumns + " columns"}
                      </span>

                      <span className="config-btns">
                        <button
                          style={{
                            paddingRight: "0",
                            backgroundColor: "transparent",
                            borderStyle: "none",
                            fontFamily: "Swiss721BT-Normal",
                            fontSize: "14px",
                            lineHeight: "1.7",
                          }}
                          className={
                            "menu-link menu-toggle " +
                            (resetFiltersEnabled
                              ? "text-danger"
                              : "text-danger-disabled")
                          }
                          onClick={
                            resetFiltersEnabled ? resetFilters : () => { }
                          }
                        >
                          Reset All Filters
                        </button>
                      </span>
                      <span className="config-btns">
                        <button
                          style={{
                            backgroundColor: "transparent",
                            borderStyle: "none",
                            fontFamily: "Swiss721BT-Normal",
                            fontSize: "14px",
                            lineHeight: "1.7",
                          }}
                          className={
                            "menu-link menu-toggle " +
                            (resetConfigEnabled
                              ? "text-danger"
                              : "text-danger-disabled")
                          }
                          onClick={
                            resetConfigEnabled
                              ? resetGridConfiguration
                              : () => { }
                          }
                        >
                          Reset Config.
                        </button>
                      </span>
                      <span className="config-btns">
                        <button
                          style={{
                            backgroundColor: "transparent",
                            borderStyle: "none",
                            fontFamily: "Swiss721BT-Normal",
                            fontSize: "14px",
                            lineHeight: "1.7",
                          }}
                          className={
                            "menu-link menu-toggle " +
                            (saveConfigEnabled
                              ? "text-danger"
                              : "text-danger-disabled")
                          }
                          onClick={
                            saveConfigEnabled ? saveGridConfiguration : () => { }
                          }
                        >
                          Save Config.
                        </button>
                      </span>
                    </div>
                  )}
                </>
              ) : (
                <div
                  className={!isModal ? "card-subtitle" : "card-subtitle-modal"}
                >
                  {lightVersion && (
                    <span className="grid-count-info-no-margin-left">
                      {" "}
                      {config.limitLightGrids < count
                        ? config.limitLightGrids
                        : count}{" "}
                      of {count} elements
                    </span>
                  )}
                  {!lightVersion && (
                    <span className="grid-count-info-no-margin-left">
                      {count} elements
                    </span>
                  )}
                  <span className="grid-count-info-no-margin-left">
                    &nbsp;-&nbsp;
                    {visibleColumns + " of " + totColumns + " columns"}
                  </span>

                  <span className="config-btns">
                    <button
                      style={{
                        paddingRight: "0",
                        backgroundColor: "transparent",
                        borderStyle: "none",
                        fontFamily: "Swiss721BT-Normal",
                        fontSize: "14px",
                        lineHeight: "1.7",
                      }}
                      className={
                        "menu-link menu-toggle " +
                        (resetFiltersEnabled
                          ? "text-danger"
                          : "text-danger-disabled")
                      }
                      onClick={resetFiltersEnabled ? resetFilters : () => { }}
                    >
                      Reset All Filters
                    </button>
                  </span>
                  <span className="config-btns">
                    <button
                      style={{
                        backgroundColor: "transparent",
                        borderStyle: "none",
                        fontFamily: "Swiss721BT-Normal",
                        fontSize: "14px",
                        lineHeight: "1.7",
                      }}
                      className={
                        "menu-link menu-toggle " +
                        (resetConfigEnabled
                          ? "text-danger"
                          : "text-danger-disabled")
                      }
                      onClick={
                        resetConfigEnabled ? resetGridConfiguration : () => { }
                      }
                    >
                      Reset Config.
                    </button>
                  </span>
                  <span className="config-btns">
                    <button
                      style={{
                        backgroundColor: "transparent",
                        borderStyle: "none",
                        fontFamily: "Swiss721BT-Normal",
                        fontSize: "14px",
                        lineHeight: "1.7",
                      }}
                      className={
                        "menu-link menu-toggle " +
                        (saveConfigEnabled
                          ? "text-danger"
                          : "text-danger-disabled")
                      }
                      onClick={
                        saveConfigEnabled ? saveGridConfiguration : () => { }
                      }
                    >
                      Save Config.
                    </button>
                  </span>
                  {!isMaximized && (
                    <span
                      className="config-btns-maximize-density"
                      style={{ marginTop: "-34px", marginLeft: "50px" }}
                    >
                      <button
                        className="notSelectedSvgGridCard"
                        onClick={() => handleMaximize(true)}
                      >
                        <SVG
                          src={toAbsoluteUrl("/media/svg/general/maximize.svg")}
                        />
                      </button>
                    </span>
                  )}
                  <span
                    className="config-btns-maximize-density"
                    style={
                      density == DENSITY_LIST[0]
                        ? { marginTop: "-29px" }
                        : { marginTop: "-35px" }
                    }
                  >
                    <button
                      id={"modal-" + DENSITY_LIST[0]}
                      className={
                        density == DENSITY_LIST[0]
                          ? "selectedSvgGridCard"
                          : "notSelectedSvgGridCard"
                      }
                      onClick={() => handleDensity(DENSITY_LIST[0])}
                    >
                      {density == DENSITY_LIST[0] && (
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/grids/hrow_big-black.svg"
                          )}
                        />
                      )}
                      {density != DENSITY_LIST[0] && (
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/grids/hrow_big-grey.svg"
                          )}
                          style={{ marginLeft: "-1px" }}
                        />
                      )}
                    </button>
                  </span>
                  <span
                    className="config-btns-maximize-density"
                    style={
                      density == DENSITY_LIST[1]
                        ? { marginTop: "-30px" }
                        : { marginTop: "-35px" }
                    }
                  >
                    <button
                      id={"modal-" + DENSITY_LIST[1]}
                      className={
                        density == DENSITY_LIST[1]
                          ? "selectedSvgGridCard"
                          : "notSelectedSvgGridCard"
                      }
                      onClick={() => handleDensity(DENSITY_LIST[1])}
                    >
                      {density == DENSITY_LIST[1] && (
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/grids/hrow_medium-black.svg"
                          )}
                        />
                      )}
                      {density != DENSITY_LIST[1] && (
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/grids/hrow_medium-grey.svg"
                          )}
                          style={{ marginLeft: "-2px" }}
                        />
                      )}
                    </button>
                  </span>
                  <span
                    className="config-btns-maximize-density"
                    style={
                      density == DENSITY_LIST[2]
                        ? { marginTop: "-30px" }
                        : { marginTop: "-35px" }
                    }
                  >
                    <button
                      id={"modal-" + DENSITY_LIST[2]}
                      className={
                        density == DENSITY_LIST[2]
                          ? "selectedSvgGridCard"
                          : "notSelectedSvgGridCard"
                      }
                      onClick={() => handleDensity(DENSITY_LIST[2])}
                    >
                      {density == DENSITY_LIST[2] && (
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/grids/hrow_small-black.svg"
                          )}
                        />
                      )}
                      {density != DENSITY_LIST[2] && (
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/grids/hrow_small-grey.svg"
                          )}
                          style={{ marginLeft: "-1px" }}
                        />
                      )}
                    </button>
                  </span>
                </div>
              )}
              {columns && columns.length > 0 && (
                <>
                  <Col className="mb-3 d-flex justify-content-end">
                    {filteredColumnsToDisplay.length > 0 && (
                      <div className="list-filtered-columns">
                        Filter on: &nbsp;
                        {filteredColumnsToDisplay.map((el, index) => (
                          <span key={index} className="span-filtered-columns">
                            {el}
                          </span>
                        ))}
                      </div>
                    )}

                    <Typeahead
                      labelKey="headerName"
                      onChange={onAutocompleteSearchColumnChange}
                      options={columns}
                      placeholder="Choose a column..."
                      id="autocomplete-select-columns"
                      className="mr-3"
                      style={{ width: "20%" }}
                    />
                  </Col>

                  <div
                    className={
                      (openModalOnRowClick || openPageOnRowClick) &&
                        !ctrlPressed
                        ? "grid-container rowClickable"
                        : "grid-container"
                    }
                    style={
                      isModal
                        ? { height: "338px" }
                        : lightVersion
                          ? { height: "100%" }
                          : { height: "610px" }
                    }
                  >
                    <div
                      className="ag-theme-alpine"
                      style={
                        isModal
                          ? { height: "338px" }
                          : lightVersion
                            ? { height: "100%" }
                            : { height: "610px" }
                      }
                    >
                      <AgGridReact
                        defaultColDef={defaultColDef}
                        autoGroupColumnDef={{ width: groupColWidth }}
                        onGridReady={onGridReady}
                        suppressCellSelection={true}
                        suppressRowHoverHighlight={true}
                        columnDefs={columns}
                        modules={[
                          ClientSideRowModelModule,
                          RowGroupingModule,
                          SetFilterModule,
                          MenuModule,
                          FiltersToolPanelModule,
                          ColumnsToolPanelModule,
                          StatusBarModule,
                          RangeSelectionModule,
                          ExcelExportModule,
                        ]}
                        rowModelType={"clientSide"}
                        // per client side
                        rowData={dataSanitized}
                        // per grouping
                        suppressMakeColumnVisibleAfterUnGroup={false}
                        rowGroupPanelShow={lightVersion ? "never" : "always"}
                        //columnTypes={buildColumnTypes(lightVersion)}
                        pagination={!lightVersion}
                        getMainMenuItems={getMainMenuItems}
                        onFilterChanged={onFilterChanged}
                        onRowClicked={
                          !ctrlPressed
                            ? openModalOnRowClick
                              ? handleShow
                              : openPageOnRowClick
                                ? handleGoToPage
                                : {}
                            : {}
                        }
                        //rowHeight={20}
                        enableCellTextSelection={true}
                        // questo impedisce l'hide delle colonne col drag fuori
                        suppressDragLeaveHidesColumns={false}
                        onFirstDataRendered={headerHeightSetter}
                        onColumnResized={headerHeightSetter}
                        onColumnMoved={setButtonsConfigurationColor}
                        onColumnVisible={setButtonsConfigurationColor}
                        onColumnRowGroupChanged={setButtonsConfigurationColor}
                        onColumnEverythingChanged={handleSetColumnState}
                        paginationPageSize={50}
                        onPaginationChanged={handlePaginationChanged}
                        suppressColumnVirtualisation={true}
                        sideBar={sideBar}
                        getRowHeight={getRowHeight}
                        // paginationPageSizeSelector={[10, 20, 30, 40, 50]}
                        // TODO: questo abilita punto e) mail 15/12/2020 17:45
                        //statusBar={buildStatusBar()}
                        //enableRangeSelection={true}
                        paginationPageSizeSelector={false}
                        ref={tableElement}
                      ></AgGridReact>
                    </div>

                    {!lightVersion && (
                      <div
                        className="page-size-selector"
                        style={isModal ? { bottom: "25px" } : {}}
                      >
                        Page Size:
                        <select
                          defaultValue={"50"}
                          onChange={onPageSizeChanged}
                          id={
                            !isModal
                              ? "custom-page-size"
                              : "custom-page-size-modal"
                          }
                        >
                          <option value="10">10</option>
                          <option value="25">25</option>
                          <option value="50">50</option>
                          <option value="100">{MAX_PAGE_SIZE}</option>
                        </select>
                      </div>
                    )}
                  </div>
                </>
              )}
            </div>
          ) : (
            <div></div>
          )}
        </div>
      </div>
    </>
  );
}