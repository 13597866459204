import { createSlice } from '@reduxjs/toolkit'

export const mapInitialState = {
  visibleElementsOnMap: []
}

const mapSlice = createSlice({
  name: 'map',
  initialState: mapInitialState,
  reducers: {
    setVisibleElementsOnMap(state, action) {
      state.visibleElementsOnMap = action.payload;
    }
  }
})

export const { 
  setVisibleElementsOnMap
 } = mapSlice.actions

export default mapSlice.reducer