import React from "react";
import { SODetailsContainer } from "../containers/sales-order-details/SODetailsContainer";
import { useParams } from "react-router-dom";

function SODetailsPage(props) {
  const params = useParams();
  return (<>
    <SODetailsContainer
      soId={params.id}/>
  </>);

}

export default SODetailsPage;