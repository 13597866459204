import React, { useState, useEffect } from "react";
import SVG from "react-inlinesvg";
import { expectedDate } from "../OrderProgressRequested";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";

export const VerticalView = ({ data, isToCustomer }) => {
    const [invParties, setInvParties] = useState([]);

    function checkForPhaseCustomer (data) {
        for (var i=0; i < data.length; i++) {
            if (data[i].phase == 'CUSTOMER'){
                return true
            }
        }
        return false
    }


    useEffect(() => {

        const sortedInvParties = data.flatMap(invParty => invParty.invParties.map(({ slotBooking, note, ...rest }) => rest));
        const sortedInvParties2 = sortedInvParties.sort((a, b) => Date.parse((a.expectedLateDate &&  a.expectedEarlyDate) ? a.expectedLateDate : a.expectedLateDate ? a.expectedLateDate : a.expectedEarlyDate) - Date.parse((b.expectedLateDate && b.expectedEarlyDate) ? b.expectedLateDate : b.expectedLateDate ? b.expectedLateDate : b.expectedEarlyDate )|| a.loadUnload - b.loadUnload);
        const uniqueInvParties = [...new Set(sortedInvParties2.map(invParty => JSON.stringify(invParty)))].map(invParty => JSON.parse(invParty));

        let filteredInvParties;
        const uniqueInvPartiesMap = {};
        const hasTrueIsShunter = data.some(({ isShunter }) => isShunter === true);
        if (hasTrueIsShunter) {
            // Se c'è almeno un invParty con isShunter true, mantieni tutti gli invParties
            filteredInvParties = sortedInvParties2;
            setInvParties(filteredInvParties);
        } else if (isToCustomer == true)
        {
            setInvParties(uniqueInvParties);
        } else {
            // Altrimenti, rimuovi i duplicati
            uniqueInvParties.forEach(invParty => {
                const key = `${invParty.tmsLocation}x`;
                uniqueInvPartiesMap[key] = invParty;
            });
            const filteredUniqueInvParties = Object.values(uniqueInvPartiesMap);
            setInvParties(filteredUniqueInvParties);
        }
    }, [data]);

    const timeline = () => {
        let timeline = <></>;

        const invPartyActivities = {
            LOAD: "sender",
            UNLOAD: "consignee"
        };

        for (let i = 0; i < invParties.length; i++) {
            const invParty = invParties[i];
            const activity = invPartyActivities[invParty.activity];
            const address = `${invParty.address} - ${invParty.zipCode} - ${invParty.city} ${invParty.provinceCode ? ` (${invParty.provinceCode})` : ''} - ${invParty.countryCode}`;
            const icon = <SVG className="activity" src={toAbsoluteUrl(`/media/svg/new/order-progress/${activity}-completed.svg`)} />;

            //icon
            timeline = (
                <>
                    {timeline}
                    {icon}
                </>
            );

            //vertical line
            if (i < invParties.length - 1)
                timeline = (
                    <>
                        {timeline}
                        <div className="timeline-requested-vertical-line"></div>
                    </>);

            //content
            timeline = (
                <>
                    {timeline}
                    <div className="timeline-requested-content">
                        <div className="activity">
                            <h4>{activity}</h4>
                        </div>
                        <div className="inv-party-name">
                            <h4>{invParty.invPartyName}</h4>
                        </div>
                        <div className="address">
                            <span>{address}</span>
                        </div>
                        <div className="other-info">
                            <div className="dock">
                                <h4>Dock</h4>
                                <span>{invParty.dock ?? <b>N/A</b>}</span>
                            </div>
                            <div className="expected">
                                <h4>{`Expected ${activity === "sender" ? "pickup" : "delivery"}`}</h4>
                                {expectedDate(invParty.expectedEarlyDate, invParty.expectedLateDate)}
                            </div>
                        </div>
                    </div>
                </>
            );
        }

        return <>{timeline}</>;
    }

    if (checkForPhaseCustomer(data) && !isToCustomer){
        console.log('OK!')
        return(
        <div className="timeline-requested-content">
        <div className="inv-party-name">
                <h2>Information Not Available</h2>
        </div>
        </div>
        )
    }
    else return (
        <>
            {
                invParties.length
                    ?
                    <div className="order-progress-card vertical-view">
                        <div className="order-progress-card-body">
                            <div className="timeline-requested">
                                {timeline()}
                            </div>
                        </div>
                    </div>
                    :
                    <></>
            }
        </>
    );
};