/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect, useLayoutEffect, useState } from "react";
import config from "../../../settings";
import { MapConsts } from "../../../consts/mapConsts";
import { FullScreenUtils } from "../../../utils/FullScreenUtils";
import { MapService } from "../../../services/mapService";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from '../../../_metronic/_helpers';

export function MapCard({ isFetching, mapType, positionObjects, onMapMoveCallback }) {
  // Create a reference to the HTML element we want to put the map on
  const mapRef = React.useRef(null);
  const [hMap, setHMap] = useState();
  const [ui, setUi] = useState();
  const [markers, setMarkers] = useState();
  const [clusterLayer, setClusterLayer] = useState();

  // Note: a bit hacky in order to solve the React state update error after unmmount, but it will get the job done
  var isMounted = true;

  /**
   * Create the map instance
   * While `useEffect` could also be used here, `useLayoutEffect` will render
   * the map sooner
   */

  useLayoutEffect(() => {
    // `mapRef.current` will be `undefined` when this hook first runs; edge case that
    if (!mapRef.current) return;
    const H = window.H;
    const platform = new H.service.Platform({
      apikey: config.HMap_AppKey
    });
    const defaultLayers = platform.createDefaultLayers();
    /*  NOTE (by Marino Didio): I had to replace defaultLayers.vector to defaultLayers.raster since the vector one
        would cause "Cannot access property _immediateRedraw, t._scene is null" when the component unmounts.
        I'm aware that this is a temporary fix, and it would need further investigation.
        FIXME 
    */
    const map = new H.Map(mapRef.current, defaultLayers.raster.normal.map, {
      center: { lat: 50, lng: 5 },
      zoom: 3,
      pixelRatio: window.devicePixelRatio || 1,
      padding: { top: 50, left: 50, bottom: 50, right: 50 }
    });

    MapService.setBaseTileLayer("00000000F", map, "reduced.day", config.HMap_AppKey);

    // add a resize listener to make sure that the map occupies the whole container
    window.addEventListener('resize', MapService.events.resize(map));

    // pointer move management
    map.addEventListener('pointermove', MapService.events.pointerMove(map, H), false);
    //eslint-disable-next-line 
    const behavior = new H.mapevents.Behavior(new H.mapevents.MapEvents(map));

    const ui = H.ui.UI.createDefault(map, defaultLayers);

    ui.removeControl('mapsettings');

    ui.addControl('custom_mapsettings', new H.ui.MapSettingsControl({
      baseLayers: [{
        label: 'Map view',
        layer: MapService.getBaseTileLayer("00000000F", "reduced.day", config.HMap_AppKey)
      }, {
        label: 'Satellite',
        layer: defaultLayers.raster.satellite.map
      }],
      layers: [{
        label: "Traffic conditions",
        layer: defaultLayers.vector.normal.traffic
      }, {
        label: "Show traffic incidents",
        layer: defaultLayers.vector.normal.trafficincidents
      }],
      alignment: H.ui.LayoutAlignment.BOTTOM_RIGHT
    }));

    setHMap(map);
    setUi(ui);

    // This will act as a cleanup to run once this hook runs again.
    // This includes when the component un-mounts
    return () => {
      map.dispose();
      MapService.events.detachAll(map);
    };
  }, [mapRef]); // This will run this hook every time this ref is updated

  useEffect(() => {
    isMounted = true;
    if (hMap) {
      resetObjectsAndLayersAndZoom();
      // Clustering
      startClustering();
    }
    return () => isMounted = false;
  }, [positionObjects]);

  function forceClosePopup() {
    var mapPopupContainer = document.getElementById("map-popup-container");

    if (mapPopupContainer && mapPopupContainer.parentElement.id != "map-widget") {
      ui.getBubbles().forEach(bub => ui.removeBubble(bub));

      var el = document.createElement('div');

      el.setAttribute('id', "map-popup-container");

      document.getElementById("map-widget").appendChild(el);
    }

  }

  function resetObjectsAndLayersAndZoom() {
    if (clusterLayer) {
      forceClosePopup();
      hMap.removeLayer(clusterLayer);
      hMap.removeObjects(hMap.getObjects());
      if (!positionObjects || (positionObjects && positionObjects.length == 0)) {
        hMap.setCenter({ lat: 50, lng: 5 });
        hMap.setZoom(3);
      }
    }
  }

  function startClustering() {
    if (positionObjects && positionObjects.length > 0) {
      const H = window.H;
      // First we need to create an array of DataPoint objects,
      // for the ClusterProvider
      var dataPoints = positionObjects.map(function (item) {
        return new H.clustering.DataPoint(item.lat, item.lng, 1, item);
      });

      // map centering
      centerMapByObjs(positionObjects);

      // Create a clustering provider with custom options for clusterizing the input
      var clusteredDataProvider = new H.clustering.Provider(dataPoints, {
        clusteringOptions: {
          // Maximum radius of the neighbourhood
          eps: 50,
          // minimum weight of points required to form a cluster
          minWeight: 2
        },
        theme: {
          getClusterPresentation: function (cluster) {
            // Use cluster weight to change the icon size
            var weight = cluster.getWeight(),
              // Calculate circle size
              radius = weight * 5,
              //eslint-disable-next-line 
              diameter = radius * 2;

            // Replace variables in the icon template
            var svgString = MapConsts.svgMarkups.markerCluster;
            svgString = svgString.replace(MapConsts.svgMarkups.clusterTxtPlaceholder, weight);

            // Create an icon
            // Note that we create a different icon depending from the weight of the cluster
            var clusterIcon = new H.map.Icon(svgString, {
              size: { w: 60, h: 60 },
              anchor: { x: 30, y: 30 }
            });

            // Create a marker for the cluster
            var clusterMarker = new H.map.Marker(cluster.getPosition(), {
              icon: clusterIcon,

              // Set min/max zoom with values from the cluster, otherwise
              // clusters will be shown at all zoom levels
              min: cluster.getMinZoom(),
              max: cluster.getMaxZoom()
            });

            // Bind cluster data to the marker
            clusterMarker.setData(cluster);

            return clusterMarker;
          },
          getNoisePresentation: function (noisePoint) {
            // Create a marker for noise points:
            var noiseMarker = new H.map.Marker(noisePoint.getPosition(), {
              icon: getIcon(noisePoint.getData().color),

              // Use min zoom from a noise point to show it correctly at certain zoom levels
              min: noisePoint.getMinZoom()
            });

            // Bind noise point data to the marker:
            noiseMarker.setData(noisePoint);
            return noiseMarker;
          }
        }
      });

      // click management
      // add a listener to the cluster which triggers on a tap event
      clusteredDataProvider.addEventListener('tap', MapService.events.clusterClick(clusteredDataProvider, hMap, H, mapType, ui), false);

      // Create a layer tha will consume objects from our clustering provider
      var clusteringLayer = new H.map.layer.ObjectLayer(clusteredDataProvider);
      setClusterLayer(clusteringLayer);

      // To make objects from clustering provder visible,
      // we need to add our layer to the map
      hMap.addLayer(clusteringLayer);

      // map move management
      hMap.addEventListener('mapviewchangeend', MapService.events.viewChanged(hMap, onMapMoveCallback));
    }
  }

  function getIcon(color) {
    const H = window.H;
    if (!markers || !markers[mapType + "_" + color]) {
      var markup = mapType === MapConsts.mapType.trip ? MapConsts.svgMarkups.markerTrip : MapConsts.svgMarkups.markerOrder;
      markup = markup.replace(MapConsts.svgMarkups.circleColorPlaceholder, color);
      let icon = new H.map.Icon(markup, { size: { w: 40, h: 40 }, anchor: { x: 20, y: 20 } });
      //eslint-disable-next-line 
      var newMarkers = new Array();
      if (markers)
        newMarkers = [...markers];
      newMarkers[mapType + "_" + color] = icon;

      // Note: a bit hacky in order to solve the React state update error after unmmount, but it will get the job done
      if (isMounted)
        setMarkers(newMarkers);

      return newMarkers[mapType + "_" + color];
    }
    else return markers[mapType + "_" + color];
  }

  function centerMapByObjs(objs) {
    const H = window.H;

    var obj = objs.map(p => new H.map.Marker({ lat: p.lat, lng: p.lng }, { data: p }));
    var group = new H.map.Group();

    // add markers to the group
    group.addObjects(obj);
    // set visibility to false so it is not visible and in this way can get the visible objects when map moves 
    // this is needed for intercept visible elements while moving around tha map (managed in mapService)
    group.setVisibility(false);
    hMap.addObject(group);

    // get geo bounding box for the group and set it to the map
    hMap.getViewModel().setLookAtData({
      bounds: group.getBoundingBox()
    });
  }

  return (
    <div id="map-widget" className="map-widget">

      {isFetching && (
        <>
          <div className="loading-container"></div>
          <div className="loading-widget"><div className="loading-img"></div></div>
        </>
      )}

      <div className="map-fullscreen-btn">
        <div onClick={() => FullScreenUtils.maximize("map-element", () => { hMap.getViewPort().resize(); }, () => { hMap.getViewPort().resize(); })}><SVG src={toAbsoluteUrl("/media/svg/general/maximize.svg")} /></div>
      </div>

      <div id="map-element" className="map-element" ref={mapRef} />

      <div id="map-popup-container"></div>

    </div>
  );
}