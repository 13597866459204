import React from "react";

const TableTooltipCustomerIds: React.FC<{ customerIds: string[] | null }> = ({ customerIds }) => {
    return (
        <div className="bg-white shadow-banner p-2 min-w-[80px] rounded-[4px]">
            {
                !!customerIds && customerIds.map((customerId, j) => {
                    return (
                        <div className="mb-1" style={{ lineHeight: '12px' }} key={j}>
                            {customerId}
                        </div>
                    )
                })
            }
        </div>
    )
}

export default TableTooltipCustomerIds;