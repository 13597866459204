import { ApiRulesCombinationTripsResponse, IRuleCombinationTrip } from "../../types/rulesCombinationsTrips";

export const computeRulesCombinationsTrips: (data: ApiRulesCombinationTripsResponse) => IRuleCombinationTrip[][] = (data) => {
    const result: IRuleCombinationTrip[][] = [];

    // sort tripRules
    const tripRules = data.tripRules;
    tripRules.sort((a, b) => {
        return parseInt(a.rule.slice(1)) - parseInt(b.rule.slice(1));
    })
    tripRules.forEach(rule => {
        const indexInResult = result.findIndex(r => {
            return !!r.find(subRule => subRule.rule === rule.rule);
        });

        const combination = data.tripCombinations.find(c => c.c === rule.c);
        if (!!combination) {
            if (indexInResult === undefined || indexInResult === null || indexInResult === -1) {
                result.push([{
                    ...combination,
                    rule: rule.rule
                }]);
            } else {
                result[indexInResult].push({
                    ...combination,
                    rule: rule.rule
                });
            }
        }
    });

    return result;
}