import React, { useContext, useMemo, useState } from "react";
import DatePicker from "react-datepicker";
import Select from "../../components/ui/select/Select";
import { IMasterDataContext, MasterDataContext } from "../../context/MasterDataContext";
import Button from "../../components/ui/button/Button";
import { exportPlanningService } from "../../api/exportPlanningService/exportPlanningService";
import { toast } from "react-toastify";
import dayjs from "dayjs";

const PlanningToolExportExcel: React.FC = () => {

    const { businessFlowTypes } = useContext(MasterDataContext) as IMasterDataContext;

    const businessFlowTypesOptions = useMemo(() => {
        return [...businessFlowTypes].map((bft) => {
            return {
                value: bft.id,
                label: bft.name
            }
        })
    }, [businessFlowTypes]);

    const [open, setOpen] = useState<boolean>(false);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [selectedBusinessFlowType, setSelectedBusinessFlowType] = useState<null | number>(null);

    const onFocus = () => {
        setOpen(true);
    };

    const onBlur = () => {
        setOpen(false);
    }

    const handleDownloadExcel = async () => {
        if (!!!selectedBusinessFlowType) {
            toast.info("Select Business Flow Type");
            return;
        }
        const response = await exportPlanningService.getExcelStream(selectedDate, selectedBusinessFlowType, "Creating Excel", "Excel successfully created", "Error while creating Excel");
        if (response.success) {
            const stream = response.data;

            const blob = new Blob([stream], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'     
            });

            var a = document.createElement('a');
            var url = window.URL.createObjectURL(blob);
            a.href = url;
            a.download = "Export Planning_" + dayjs(selectedDate).format('DD_MM_YYYY') + ".xlsx"; // DD/MM/YYYY_HH:MM
            a.click();
            window.URL.revokeObjectURL(url)
        }
    };

    return (
        <div className="w-full mb-6 bg-white p-[32px]">
            <div className="font-light text-lg leading-[25px]">Planning Tool</div>
            <div className="flex items-center mb-6">
                <div className="md:mr-3 text-[32px] font-bold leading-[45px]">
                    Export Planning
                </div>
            </div>

            <div className="text-[12px] leading-[16px] font-medium">
                <div className="w-full grid grid-cols-12 gap-4 mb-3">
                    <div className="col-span-4">
                        <Select
                            options={businessFlowTypesOptions}
                            label="Business Flow Type"
                            callbackOnChange={(e) => setSelectedBusinessFlowType(e.target.value)}
                            value={selectedBusinessFlowType}
                            defaultOptionEnabled={true}
                            placeholder={"Select Business Flow Type..."}
                        />
                    </div>

                    <div className="col-span-4 flex items-end">
                        <div>
                            <div className="filter-label">Date</div>

                            <label className="relative">
                                <DatePicker
                                    selected={selectedDate}
                                    onFocus={onFocus}
                                    onBlur={onBlur}
                                    onChange={(e: Date) => setSelectedDate(e)}
                                    dateFormat={"dd/MM/yyyy"}
                                    open={open}
                                    // popperModifiers={{
                                    //     // @ts-ignore
                                    //     flip: { enabled: false }
                                    // }}
                                    popperPlacement="top-start"
                                />
                            </label>
                        </div>
                    </div>
                </div>

                <div>
                    <Button
                        callback={handleDownloadExcel}
                    >Export</Button>
                </div>
            </div>
        </div>

    )
}

export default PlanningToolExportExcel;