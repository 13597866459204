import React, { useContext, useEffect, useState } from "react";
import { IPlanningToolContext, PlanningToolContext } from "../../context/PlanningToolContext";
import { dashboardService } from "../../api/dashboardService/dashboardService";
import { Table } from "react-bootstrap";
import dayjs from "dayjs";

type Props = {
    isOpen: boolean;
    callbackOpenCloseSidebar: () => void;
}

type MissingOrder = {
    id: number;
    customerOrderNumber: string;
    tradingPartner: {
        id: number;
        name: string
    };
    tradingPartnerId: 3;
    businessFlowType: {
        id: number;
        name: string;
    };
    businessFlowTypeId: number;
    declaredTime: string
    senderInvPartyName: string
    senderInvPartyAddress: string
    senderInvPartyCity: string
    senderInvPartyCountry: string
    senderInvPartyZipCode: string
    consigneeInvPartyName: string
    consigneeInvPartyAddress: string
    consigneeInvPartyCity: string
    consigneeInvPartyCountry: string
    consigneeInvPartyZipCode: string
    originInvPartyName: null | string;
    originInvPartyAddress: null | string;
    originInvPartyCity: null | string;
    originInvPartyCountry: null | string;
    originInvPartyZipCode: null | string;
    destinationInvPartyName: null | string;
    destinationInvPartyAddress: null | string;
    destinationInvPartyCity: null | string;
    destinationInvPartyCountry: null | string;
    destinationInvPartyZipCode: null | string;
    shippingUnitCount: number;
    weight: number;
    weightUOM: null | number;
    volume: number;
    volumeUOM: null | number;
    earlyPickupDate: string
    earlyPickupTime: string
    latePickupDate: string
    latePickupTime: string
    earlyDeliveryDate: string
    earlyDeliveryTime: null | string;
    lateDeliveryDate: string;
    lateDeliveryTime: null | string
}

const SidebarMissingOrders: React.FC<Props> = ({
    isOpen,
    callbackOpenCloseSidebar
}) => {

    const {
        activeTradingPartnerId,
        activeBftId,
        activeDashboardDate
    } = useContext(PlanningToolContext) as IPlanningToolContext;

    // To make the page not scrollabe when sidebar is open
    useEffect(() => {
        const root = document.getElementById('root');
        if (!!root) {
            if (isOpen === true) {
                root.style.height = '100vh';
                root.style.overflow = 'hidden';
            } else {
                root.removeAttribute("style");
            }
        }
    }, [isOpen]);

    useEffect(() => {
        getSetData();
    }, [activeTradingPartnerId, activeBftId]);

    const [data, setData] = useState<MissingOrder[]>([]);
    const getSetData = async () => {
        if (activeTradingPartnerId !== null && activeBftId !== null) {
            const response = await dashboardService.getPortalStatisticsMissingOrders(activeDashboardDate, activeBftId, activeTradingPartnerId);
            if (response.success) {
                const responseData: MissingOrder[] = response.data;
                setData(responseData);
            }
        }
    }

    return (
        <>
            <aside
                style={{
                    zIndex: isOpen ? 950 : 0
                }}
                className={"flex flex-col transition z-50 fixed right-0 top-0 h-[100dvh] w-screen md:w-[1000px] bg-white overflow-auto hide-scrollbar" + (isOpen === true ? " translate-x-0 sidebar-shadow" : " translate-x-[100%]")}
            >
                <div className="shrink-0 h-[88px] px-[30px] pt-[32px] pb-[24px] border-b flex justify-between items-center">
                    <div className="text-lg font-bold leading-[21.6px]">
                        Order details - {dayjs(activeDashboardDate).format('DD/MM/YYYY')}
                    </div>
                    <div onClick={() => {callbackOpenCloseSidebar(); setData([])}} className="cursor-pointer">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-8 h-8">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </div>
                </div>

                {/* MAIN CONTENT OF SIDEBAR */}
                <div className="grow overflow-auto px-[30px] py-8">
                    {/* @ts-ignore */}
                    <Table striped bordered responsive>
                        <thead>
                            <tr className="uppercase">
                                <th>Order N°</th>
                                <th>Sender</th>
                                <th>Consignee</th>
                                <th>Weight (Kg)</th>
                                <th>Volume (m³)</th>
                                <th>Pickup</th>
                                <th>Delivery</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                data.map((missingOrder, index) => {
                                    return (
                                        <tr className="text-[12px]" key={index}>
                                            <td>
                                                {missingOrder.customerOrderNumber}
                                            </td>
                                            <td>
                                                <div className="font-bold">
                                                    {missingOrder.senderInvPartyName}
                                                </div>
                                                <div>
                                                    {missingOrder.senderInvPartyZipCode} {missingOrder.senderInvPartyCity}
                                                </div>
                                            </td>
                                            <td>
                                                <div className="font-bold">
                                                    {missingOrder.consigneeInvPartyName}
                                                </div>
                                                <div>
                                                    {missingOrder.consigneeInvPartyZipCode} {missingOrder.consigneeInvPartyCity}
                                                </div>
                                            </td>
                                            <td>
                                                {missingOrder.weight}
                                            </td>
                                            <td>
                                                {missingOrder.volume}
                                            </td>
                                            <td>
                                                {missingOrder.earlyPickupDate !== null ? dayjs(missingOrder.earlyPickupDate).format("DD/MM/YYYY") : ''} {missingOrder.earlyPickupTime !== null ? dayjs(missingOrder.earlyPickupTime).format("HH:mm") : ''}
                                            </td>
                                            <td>
                                                {/* {dayjs(missingOrder.lateDeliveryTime).format("DD/MM/YYYY")} */}
                                                {missingOrder.earlyDeliveryDate !== null ? dayjs(missingOrder.earlyDeliveryDate).format("DD/MM/YYYY") : ''} {missingOrder.earlyDeliveryTime !== null ? dayjs(missingOrder.earlyDeliveryTime).format("HH:mm") : ''}
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </Table>
                </div>
            </aside>
        </>
    );
}

export default SidebarMissingOrders;