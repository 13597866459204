import React from "react";
import useImageFlag from "../../../utils/hooks/useImageFlag";
import { IOrder } from "../../../types/orders";

const OriginDestinationTableComponent: React.FC<{ data: IOrder, type: 'origin' | 'destination' }> = ({ data, type }) => {
    let realType: 'origin' | 'destination' | 'originPartner' | 'destinationPartner' = type;
    if (type === "origin" && !!data.originPartner) {
        realType = "originPartner";
    } else if (type === "destination" && !!data.destinationPartner) {
        realType = "destinationPartner";
    }

    const { image, loading } = useImageFlag(!!data[realType] ? data[realType]!.countryCode : '');
        
    return (
        <>
            {
                !!data[realType] &&
                <div className="text-[13px] leading-[17px] py-2">
                    <div className="flex items-center mb-1">                        
                        {
                            !loading &&
                            <div className="mr-1">
                                {/* @ts-ignore */}
                                <img src={image} alt={"flag-image " + data[realType].countryCode} />
                            </div>
                        }
                        <div className="flex">
                            <div className="font-bold">
                                {data[realType]!.name}
                            </div>
                            {(realType === "originPartner" || realType === "destinationPartner") &&
                                <div className="ml-2 text-blue">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" viewBox="0 0 24 24"><path fill="currentColor" d="M9.8 13.4h2.5c1.5 0 2.16-.28 2.8-.82c.64-.55.9-1.33.9-2.35c0-.97-.25-1.73-.9-2.35c-.65-.59-1.27-.88-2.8-.88H8v10h1.8zM19 3a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2zm-9.2 9V8.4h2.3c.66 0 1.17.25 1.5.6c.33.35.5.72.5 1.24c0 .56-.18.95-.5 1.26c-.32.31-.7.5-1.38.5z"/></svg>
                                </div>
                            }
                        </div>
                    </div>
                    <div className="text-darkGrey mb-[3px]">
                        {data[realType]!.street + ', ' + data[realType]!.zipCode + ' - ' + data[realType]!.city}
                    </div>                    
                </div>
            }
        </>
    )
}

export default OriginDestinationTableComponent;