import React, { useContext, useMemo, useState } from 'react';
import Button from '../ui/button/Button';
import Select from '../ui/select/Select';
import { IGroup } from '../../types/orders';
import { toast } from 'react-toastify';
import { customConfirmAlert } from '../../utils/functions/customConfirmAlert';
import { IMasterDataContext, MasterDataContext } from '../../context/MasterDataContext';
import ReactSelect from 'react-select';

type Props = {
    groups: undefined | IGroup[];
    selectedLegs: number;
    callbackSetGroup: (groupId: number) => void;
};

const TriageBanner: React.FC<Props> = ({
    groups,
    selectedLegs,
    callbackSetGroup
}) => {

    // #region State
    const [isExpandedTriageBanner, setIsExpandedTriageBanner] = useState<boolean>(true);
    const [selectedGroup, setSelectedGroup] = useState<any | null>(null);
    // #endregion

    // #region Context
    const { departments } = useContext(MasterDataContext) as IMasterDataContext;
    // #endregion

    // #region Memo
    const groupsOptions = useMemo(() => {
        if (groups === undefined) {
            return [];
        }

        if (departments.length === 0) {
            return [];
        }

        return departments.map(dep => {
            return {
                label: dep.name,
                options: groups.filter(x => x.departmentId === dep.id).map(group => {
                    return {
                        label: group.name,
                        value: group.id
                    };
                })
            };
        });
    }, [groups, departments]);
    // #endregion
    
    // #region UI Handlers
    const onExpandTriageBanner = () => {
        setIsExpandedTriageBanner(!isExpandedTriageBanner);  
    };

    const onClickSetGroup = async () => {
        if (!!!selectedGroup) {
            toast.info("Select a group");
            return;
        }
        
        const wantToContinue = await customConfirmAlert({
            message: "Are you sure to set group to selected legs?",
            title: "Group selection"
        });

        if (wantToContinue) {
            callbackSetGroup(selectedGroup.value);
        }
    };
    // #endregion

    return (
        <>
            <div style={{ zIndex: 40 }} className={"sticky bottom-[40px] transition w-full mt-[24px]" + (isExpandedTriageBanner === false ? ' translate-x-[calc(100%+70.5px)]' : '')}>
                <div className="relative bg-white shadow-banner rounded-[4px] pr-6 flex justify-between ">

                    {/* Badge Banner */}
                    <div onClick={onExpandTriageBanner} className="absolute top-0 left-0 -translate-x-full rounded-l-[4px] cursor-pointer shadow-card bg-white uppercase font-bold px-[16px] bg-blue h-full">
                        <div className={"absolute select-none top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 flex flex-col items-center" + (isExpandedTriageBanner === false ? ' text-blue' : ' text-black')}>
                            {
                                isExpandedTriageBanner === false &&
                                <>
                                    <div className="mb-1">{selectedLegs}</div>

                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M18.75 19.5l-7.5-7.5 7.5-7.5m-6 15L5.25 12l7.5-7.5" />
                                    </svg>
                                </>
                            }

                            {
                                isExpandedTriageBanner === true &&
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M11.25 4.5l7.5 7.5-7.5 7.5m-6-15l7.5 7.5-7.5 7.5" />
                                </svg>
                            }
                        </div>
                    </div>

                    <div className="flex items-center">
                        <div className="h-[100px] w-[24px] relative">
                            <div className="absolute left-0 top-0 bg-white h-full w-full aspect-square"></div>
                        </div>

                        <div className="flex items-center">
                            <div className="mr-8">
                                <div className="text-blue text-[12px] font-bold mb-2 uppercase">Selected legs</div>
                                <div className="text-[24px] font-bold">{selectedLegs}</div>
                            </div>
                        </div>
                    </div>

                    <div className="flex items-center">
                        <ReactSelect 
                            onChange={(e: any) => setSelectedGroup(e)}
                            className="w-[200px]"
                            options={groupsOptions}
                            value={selectedGroup}
                            placeholder={"Select group..."}
                            menuPlacement='auto'
                        />

                        <Button
                            callback={onClickSetGroup}
                            className="bg-darkGrey text-white ml-4"
                        >
                            <div className="mt-[1px]">Set group</div>
                        </Button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TriageBanner;