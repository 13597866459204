import React, { useEffect, useMemo, useRef } from "react";

type Props = {
    title: string;
    callbackCloseModal: () => any;
    children: JSX.Element;
    footer?: JSX.Element;
    customStyleContainer?: any;
    customStyleChildrenContainer?: any
}

const Modal: React.FC<Props> = ({
    title,
    callbackCloseModal,
    footer,
    children,
    customStyleContainer,
    customStyleChildrenContainer
}) => {

    // To make Modal above all other contents
    const canGo = useRef(true);
    useEffect(() => {
        if (canGo.current) {
            canGo.current = false;

            const root = document.getElementById('root');
            if (!!root) {
                root.style.height = '100vh';
                root.style.overflow = 'hidden';
            }

            const planningToolLayout = document.getElementById('planning-tool-layout');
            if (!!planningToolLayout) {
                planningToolLayout.style.zIndex = '999';
            }

            return () => {
                if (!!root) {
                    root.removeAttribute("style");
                }

                if (!!planningToolLayout) {
                    planningToolLayout.removeAttribute("style");
                }
            }
        }
    }, []);

    const hasOverflowOverride = useMemo<boolean>(() => {
        if (!!customStyleChildrenContainer) {
            return customStyleChildrenContainer['overflow'] !== undefined;
        }

        return false;
    }, [customStyleChildrenContainer])

    return (
        <div style={{ zIndex: 1000 }} className="anim-fade-in fixed top-0 left-0 h-screen w-screen bg-[#00000080] flex items-center justify-center">
            <div style={customStyleContainer} className={"relative bg-white max-h-[95%] w-[95%] lg:w-[50%] max-w-[700px] flex flex-col"}>
                <div className="shrink-0 flex justify-between border-b p-[30px]">
                    <div className="text-lg font-bold leading-[21.6px]">
                        {title}
                    </div>
                    <div onClick={callbackCloseModal} className="cursor-pointer">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-8 h-8">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </div>
                </div>

                {/* MAIN CONTENT */}
                <div style={{ ...customStyleChildrenContainer, zIndex: 999 }} className={"grow py-8 px-[30px] select-none relative " + (!!hasOverflowOverride ? '' : 'overflow-auto')}>
                    {children}
                </div>

                {
                    !!footer &&
                    <div className="select-none px-[30px] py-6 border-t">
                        {footer}
                    </div>
                }

            </div>
        </div>
    );
}

export default Modal;