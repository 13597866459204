import React from "react";
import {toAbsoluteUrl} from "../../../_metronic/_helpers";

export default function UnauthorizedPage({missingClaim, app}) {

    return (
        <>
            <div className="arcese-container container-fluid">
                <div className="row wrap-login">
                    <div className="col-md-5 d-none d-md-block">
                        <div>
                            <img className="logo" src={toAbsoluteUrl("/media/png/logo/arcese-square.png")} alt="Arcese logo"></img>
                        </div>
                    </div>

                    <div className="col-sm-12 d-md-none text-center">
                        <img className="logo-small" src={toAbsoluteUrl("/media/png/logo/arcese-square_small.png")} alt="Arcese logo"></img>
                    </div>

                    <div className="col-sm-12 col-md-7">
                        <div className="text-left login-title">
                            Utente non autorizzato
                        </div>
                        {missingClaim && (<h6>Parametro <strong>{missingClaim}</strong> mancante.</h6>)}
                        {!missingClaim && (<h6>L&#39;utente non &#232; autorizzato ad accedere al portale {app}.</h6>)}
                    </div>
                    <footer>
                        <div className="container">
                            <div className="row text-center">
                                <span className="col-4 nav-item"><a href="https://www.arcese.com">Arcese</a></span>
                                <span className="col-4 nav-item"><a href="https://www.arcese.com/privacy">Privacy</a></span>
                                <span className="col-4 nav-item"><a href="https://arcese.com/sedi-e-contatti/">Contatti</a></span>
                            </div>
                        </div>
                    </footer>
                </div>
            </div>
        </>

    );
}
