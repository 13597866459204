import React, { useEffect, useState } from "react";
import Button from "../ui/button/Button";
import { groupsService } from "../../api/groupsService/groupsService";
import { toast } from "react-toastify";
import { customConfirmAlert } from "../../utils/functions/customConfirmAlert";
import SignalRConnector from "../../utils/signalr-connection";
import { IInstantLogMessage, IPushMessage, PushMessagesTypes } from "../../types/pushMessages";
import dayjs from "dayjs";
import { tripsService } from "../../api/tripsService/tripsService";
// import { rollbackService } from "../../api/rollbackService/rollbackService";
// import Modal from "../../components/ui/modal/Modal";

type Props = {
    isOpen: boolean;
    callbackOpenCloseSidebar: () => void;
}

// type RollbackResult = {
//     removedOrders: string[];
//     removedTrips: string[];
// }

const SidebarDev: React.FC<Props> = ({
    isOpen,
    callbackOpenCloseSidebar
}) => {

    const { pushMessagesEvents } = SignalRConnector();

    // #region State
    const [newPushMessage, setNewPushMessage] = useState<IPushMessage | null>(null);
    const [instantLogs, setInstantLogs] = useState<IInstantLogMessage[]>([]);

    // const [openRollbackResultModal, setOpenRollbackResultModal] = useState<boolean>(false);
    // const [rollbackResultDescription, setRollbackResultDescription] = useState<RollbackResult | null>(null);
    
    // #endregion

    // #region UI Handlers
    const handleClose = () => {
        callbackOpenCloseSidebar();
    };

    const handleDeleteUserCharge = async () => {
        deleteUserCharge();
    }

    const handleSendDispatchableTrips = async () => {
        sendDispatchableTrips();
    };

    // const handleExecuteRollbackProcedure = async () => {
    //     executeRollbackProcedure();
    // };
    // #endregion

    // #region Callback
    const subscribeToPushMessagesEvents = () => {
        pushMessagesEvents((msg) => {
            setNewPushMessage(msg);
        });
    };

    const handleNewPushMessageReceived = (msg: IPushMessage) => {
        if (msg.type === PushMessagesTypes.INSTANT_LOG) {
            let newInstantLogMessage = (msg as IInstantLogMessage);
            setInstantLogs([newInstantLogMessage, ...instantLogs]);
        }
    };
    // #endregion

    // #region Util Functions
    const deleteUserCharge = async () => {
        const wantToContinue = await customConfirmAlert({
            title: "Delete My Groups",
            message: "Are you sure you want to delete all your group charges?"
        });

        if (!wantToContinue) {
            return;
        }

        const response = await groupsService.getGroups(true);
        if (response.success) {
            const groups = response.data.map((g: any) => g.id);
            await groupsService.deleteCharge(groups, "Deleting groups...", "Groups deleted successfully", "Error deleting groups");
        } else {
            toast.error("Error getting groups");
        }
    };

    const sendDispatchableTrips = async () => {
        const wantToContinue = await customConfirmAlert({
            title: "Send Trips",
            message: "Are you sure you want to send dispatchable trips?"
        });

        if (!wantToContinue) {
            return;
        }

        await tripsService.sendDispatchableTrips("Sending dispatchable trips...", "Dispatchable trips sent successfully", "Error sending dispatchable trips");
    };

    // const executeRollbackProcedure = async () => {
    //     const wantToContinue = await customConfirmAlert({
    //         title: "Rollback Procedure",
    //         message: "Are you sure you want to execute rollback procedure?"
    //     });

    //     if (!wantToContinue) {
    //         return;
    //     }

    //     var result = await rollbackService.executeProcedure("Executing Rollback...", "Rollback procedure executed successfully", 'Error executing rollback procedure');
    //     setRollbackResultDescription(result.data);
    //     setOpenRollbackResultModal(true);
    // };
    // #endregion

    // #region Use Effects
    useEffect(() => {
        subscribeToPushMessagesEvents();
    }, []);

    useEffect(() => {
        if (newPushMessage === null) {
            return;
        }

        handleNewPushMessageReceived(newPushMessage);
    }, [newPushMessage]);
    // #endregion

    return (
        <>
            {
                <>
                    <aside
                        className={"flex flex-col transition fixed right-0 top-[130px] h-[calc(100vh-130px)] w-screen md:w-[92%] bg-white hide-scrollbar z-[999]" + (isOpen === true ? ' translate-x-[65%] sidebar-shadow' : " translate-x-[100%]")}
                    >
                        {/* Badge Dev */}
                        {
                            <div onClick={handleClose} className="absolute top-36 left-0 -translate-x-full rounded-l-[4px] cursor-pointer shadow-card bg-white uppercase font-bold px-[16px] bg-blue h-[110px]">
                                <div className={"absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 -rotate-90 flex items-center" + (isOpen === false ? ' text-blue' : ' text-black')}>
                                    <svg className="rotate-90" xmlns="http://www.w3.org/2000/svg" width="1.6em" height="1.6em" viewBox="0 0 20 20">
                                        <path fill={isOpen ? "#333333" : 'red'} d="M5.719 14.75a.997.997 0 0 1-.664-.252L-.005 10l5.341-4.748a1 1 0 0 1 1.328 1.495L3.005 10l3.378 3.002a1 1 0 0 1-.664 1.748m8.945-.002L20.005 10l-5.06-4.498a.999.999 0 1 0-1.328 1.495L16.995 10l-3.659 3.252a1 1 0 0 0 1.328 1.496m-4.678 1.417l2-12a1 1 0 1 0-1.972-.329l-2 12a1 1 0 1 0 1.972.329"/>
                                    </svg>
                                    <div className="ml-1 text-red">Dev</div>
                                </div>
                            </div>
                        }

                        {/* MAIN CONTENT OF SIDEBAR */}
                        {/* <div className="py-6 flex relative"> */}
                            <div className="w-[35%] py-6 p-3">
                                <div className="mr-2 text-[13px] font-bold text-blue uppercase">Actions</div>
                                <div className="flex">
                                    <Button
                                        callback={handleDeleteUserCharge}
                                        className={"mb-4 mt-2 border border-darkBlue border-[1.4px] bg-white text-darkBlue text-sm font-semibold w-[250px]"}
                                    >
                                        Delete My Group Charges
                                    </Button>

                                    <Button
                                        callback={handleSendDispatchableTrips}
                                        className={"mb-4 mt-2 ml-2 border border-darkBlue border-[1.4px] bg-white text-darkBlue text-sm font-semibold w-[250px]"}
                                    >
                                        Send Dispatchable Trips and TO
                                    </Button>
                                </div>

                                {/* <div className="fled">
                                    <Button
                                        callback={handleExecuteRollbackProcedure}
                                        className={"mb-4 mt-2 ml-2 border border-darkBlue border-[1.4px] bg-white text-darkBlue text-sm font-semibold w-[250px]"}
                                    >
                                        Execute Rollback Procedure
                                    </Button>
                                </div> */}

                                <div className="mr-2 mt-4 text-[13px] font-bold text-blue uppercase">Instant Logs</div>
                                <div className="h-[200px] mt-2 overflow-auto">
                                    {instantLogs.map((log: IInstantLogMessage, index) => {
                                        return (
                                            <p key={index}>
                                                <span className="text-red font-bold">{log.user.name} {log.user.surname}</span> <span>({dayjs(log.sentTime).format("HH:mm")}): {log.message}</span>
                                            </p>
                                    )})}
                                </div>
                            </div>
                        {/* </div> */}
                    </aside>
                </>
            }
            
            {/* {
                    openRollbackResultModal &&
                    <Modal
                        callbackCloseModal={() => setOpenRollbackResultModal(false)}
                        title="Rollback Result"
                    >
                        <div>
                            <div>
                                <p className="font-bold">Removed Orders:</p>
                                {
                                    rollbackResultDescription?.removedOrders?.map((order : any, index: number) => {
                                        return (
                                            <p key={index} className="mb-0">{order}</p>
                                        )
                                    })
                                }
                            </div>
                            
                            <div className="mt-5">
                                <p className="font-bold">Removed Trips:</p>
                                {
                                    rollbackResultDescription?.removedTrips?.map((trip : any, index: number) => {
                                        return (
                                            <p key={index} className="mb-0">{trip}</p>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </Modal>
                } */}
        </>
    );
}

export default SidebarDev;