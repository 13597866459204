import { toAbsoluteUrl } from '../_metronic/_helpers';

export const FullScreenUtils = {

    waitForElm: (selector) => {
        return new Promise(resolve => {
            if (document.querySelector(selector)) {
                return resolve(document.querySelector(selector));
            }

            const observer = new MutationObserver(mutations => {
                if (document.querySelector(selector)) {
                    resolve(document.querySelector(selector));
                    observer.disconnect();
                }
            });

            observer.observe(document.body, {
                childList: true,
                subtree: true
            });
        });
    },

    maximize: (elementId, additionalOpenFn, additionalCloseFn, waitForCloseBtnExist) => {
        var element = document.getElementById(elementId);

        const fullScreenDashBoardPage = !waitForCloseBtnExist;

        if (!!element) {
            // saving current styles of element
            var oldStyles = {
                width: element.style.width,
                height: element.style.height,
                position: element.style.position,
                top: element.style.top,
                left: element.style.left,
                marginTop: element.style.marginTop,
                marginBottom: element.style.marginBottom,
                marginLeft: element.style.marginLeft,
                marginRight: element.style.marginRight,
                zIndex: element.style.zIndex,
                boxSizing: element.style.boxSizing,
                parentZIndex: element.parentElement.style.zIndex
            }

            // create back opaque element
            var backElement = document.createElement('div');
            backElement.id = "back-fs-element";
            backElement.style.cssText = 'position:fixed;width:100%;height:100%;opacity:0.8;z-index:100;background:#1e2427';
            document.body.appendChild(backElement);

            if (waitForCloseBtnExist) {
                FullScreenUtils.waitForElm('#close-mapfs-btn').then((el) => {

                    el.addEventListener("click", () => {
                        FullScreenUtils.closeMaximize(elementId, oldStyles, additionalCloseFn);
                    });
                });

            }
            else {

                // create close element
                var closeMaximizeElement = document.createElement('img');
                closeMaximizeElement.id = "close-fs-element";
                closeMaximizeElement.src = toAbsoluteUrl("/media/svg/general/close_maximize.svg");
                closeMaximizeElement.style.cssText = 'position:fixed;right: 20px; z-index: 999; cursor: pointer';
                closeMaximizeElement.onclick = () => { FullScreenUtils.closeMaximize(elementId, oldStyles, additionalCloseFn, fullScreenDashBoardPage) };
                document.body.appendChild(closeMaximizeElement);
            }

            // setting full screen for main element
            element.style.position = "fixed";
            element.style.top = "0px";
            element.style.left = "0px";
            element.style.right = "0px";
            element.style.bottom = "0px";
            element.style.marginTop = "40px";
            element.style.marginRight = "20px";
            element.style.marginLeft = "20px";
            element.style.marginBottom = "40px";
            element.style.width = "98%";
            element.style.height = "94%";
            element.style.zIndex = "9999999";
            element.style.boxSizing = "border-box";
            element.parentElement.style.zIndex = "9999999"; // to be sure that parent has high z-index

            if (!fullScreenDashBoardPage) document.querySelector(".details-tab-container").appendChild(element);

            if (!!additionalOpenFn) additionalOpenFn(); // in case of additional logic
        }
    },

    closeMaximize: (elementId, oldStyles, additionalFn, fullScreenDashBoardPage) => {
        // removing back and close elements
        const back = document.getElementById("back-fs-element");
        const close = document.getElementById("close-fs-element");

        if (close)
            document.body.removeChild(close);

        if (back)
            document.body.removeChild(back);

        // restore main element's style
        var element = document.getElementById(elementId);
        element.style.width = oldStyles.width;
        element.style.height = oldStyles.height;
        element.style.position = oldStyles.position;
        element.style.top = oldStyles.top;
        element.style.left = oldStyles.left;
        element.style.marginTop = oldStyles.marginTop;
        element.style.marginRight = oldStyles.marginRight;
        element.style.marginLeft = oldStyles.marginLeft;
        element.style.marginBottom = oldStyles.marginBottom;
        element.style.zIndex = oldStyles.zIndex;
        element.style.boxSizing = oldStyles.boxSizing;

        if (!fullScreenDashBoardPage) {
            element.parentElement.style.removeProperty('zIndex');
            document.querySelector(".details-tab-container").removeChild(element);
            document.getElementById("map-cnt").appendChild(element);
        }
        else {
            element.parentElement.style.zIndex = oldStyles.parentZIndex;
        }

        if (!!additionalFn) additionalFn(); // in case of additional logic
    }

}