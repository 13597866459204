import dayjs from "dayjs";
import { IOrder } from "../types/orders";

// Filtri CLIENT SIDE
export const gridNumberFilterParams = {
    filterOptions: ['equals', 'notEqual', 'greaterThan', 'greaterThanOrEqual', 'lessThan', 'lessThanOrEqual', 'inRange', 'blank', 'notBlank'],
    defaultOption: 'equals',
}

export const gridTextFilterParams = {
    filterOptions: ['contains', 'notContains', 'equals', 'notEqual', 'startsWith', 'endsWith', 'blank', 'notBlank'],
    defaultOption: 'contains',
}

type filterOptionsProps = {
    data: IOrder;
    filterText: string;
    column: {
        colId: 'origin' | 'destination';
    }
}

export const originDestinationGridTextFilterParams = {
    filterOptions: ['contains', 'startsWith'],
    defaultOption: 'contains',
    maxNumConditions: 6,
    textMatcher: (props: filterOptionsProps) => {
        const filterTextLower = props.filterText.toLowerCase();
        const originOrDestination = props.data[props.column.colId]
        return !!(
            originOrDestination!.name?.toLowerCase().indexOf(filterTextLower) >= 0 ||
            originOrDestination!.street?.toLowerCase().indexOf(filterTextLower) >= 0 ||
            originOrDestination!.zipCode?.toLowerCase().indexOf(filterTextLower) >= 0 ||
            originOrDestination!.countryCode?.toLowerCase().indexOf(filterTextLower) >= 0 ||
            originOrDestination!.city?.toLowerCase().indexOf(filterTextLower) >= 0
        );
    }
}

export const gridDateFilterParams = {
    filterOptions: ['equals', 'notEqual', 'lessThan', 'greaterThan', 'inRange', 'blank', 'notBlank'],
    defaultOption: 'equals',
    comparator: (filterLocalDateAtMidnight: any, cellValue: any) => {
        const dateAsString = cellValue;

        if (dateAsString == null) {
            return 0;
        }

        const dateDayjs = dayjs(dateAsString).format('DD/MM/YYYY');
        const dateParts = dateDayjs.split('/');
        const year = Number(dateParts[2]);
        const month = Number(dateParts[1]) - 1;
        const day = Number(dateParts[0]);

        const cellDate = new Date(year, month, day);

        // Now that both parameters are Date objects, we can compare
        if (cellDate < filterLocalDateAtMidnight) {
            return -1;
        } else if (cellDate > filterLocalDateAtMidnight) {
            return 1;
        }
        return 0;
    }
}

// Filtri SERVER SIDE
export const gridNumberFilterParamsSSR = {
    filterOptions: ['equals'],
    defaultOption: 'equals',
    maxNumConditions: 1,
}

export const gridTextFilterParamsSSR = {
    filterOptions: ['contains'],
    defaultOption: 'contains',
    maxNumConditions: 1,
}

export const gridDateFilterParamsSSR = {
    filterOptions: ['equals'],
    defaultOption: 'equals',
    maxNumConditions: 1,
}


export const originDestinationGridTextFilterParamsSSR = {
    filterOptions: ['contains', 'startsWith'],
    defaultOption: 'contains',
    maxNumConditions: 6,
}

