import React, { useEffect, useState, useRef, useMemo, useCallback, useContext, forwardRef } from "react";
import Select from "../../components/ui/select/Select";
import Button from "../../components/ui/button/Button";
import { ModuleRegistry, RowNode } from "@ag-grid-community/core";
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import dayjs from "dayjs";
import { IGroup, IOrder, Leg } from "../../types/orders";
import Planning from "../../components/planning/Planning";
import FilterRadio, { PlanningFilterEnum } from "../../components/filterRadio/FilterRadio";
import { ITripsContext, TripsContext } from "../../context/TripContext";
import TableTooltipPartner from "../../components/ui/tableTooltipPartner/TableTooltipPartner";
import { customConfirmAlert } from "../../utils/functions/customConfirmAlert";
import ModalShowGroupedOrders from "../../components/modals/modalShowGroupedOrders/ModalShowGroupedOrders";
import TableTooltipOrderNumbers from "../../components/ui/tableTooltipOrderNumbers/TableTooltipOrderNumbers";
import TableTooltipSuggestedPlanning from "../../components/ui/tableTooltipSuggestedPlanning/TableTooltipSuggestedPlanning";
import OriginDestinationTableComponent from "../../components/ui/originDestinationTableComponent/OriginDestinationTableComponent";
import { useLocation, useNavigate } from "react-router-dom";
import { planningToolRouteUrls } from "../../configs/planningToolRouteUrls";
import { DisableOrdersRequest, PaginationGetOrdersRequest, ordersService } from "../../api/ordersService/ordersService";
import { IMasterDataContext, MasterDataContext } from "../../context/MasterDataContext";
import SignalRConnector from "../../utils/signalr-connection"
import { INewOrdersMessage, PushMessagesTypes, NewOrdersAlertInfo, IPushMessage } from "../../types/pushMessages";
import { distinct, isDepositSelectable, isLegOpacited, isPlannedLeg, legCanBeDispatchable } from "../../utils/utilFunctions";
import GroupSelectProgress from "../../components/groupSelectProgress/GroupSelectProgress";
import { toast } from "react-toastify";
import { ChangeDepositsOrderActions, ChangeDepositsOrders, routingService } from "../../api/routingService/routingService";
import { IPartner } from "../../types/partner";
import ReactSelect from 'react-select'
import { LegPlanningTypesEnum, LegStatusEnum, LegTypesEnum } from "../../types/leg";
import { AddressTypesEnum } from "../../types/address";
import { AssignPartnerToLegRequestData, UpdateLegPlanningRequestData, legsService } from "../../api/legsService/legsService";
import ModalShowPlanningRules from "../../components/modals/modalShowPlanningRules/ModalShowPlanningRules";
import CommonContextMenu, { CommonContextMenuRef } from "../../components/commonContextMenu/CommonContextMenu";
import CommonTable, { TableRef } from "../../components/commonTable/CommonTable";
import TripBanner from "../../components/tripBanner/TripBanner";
import ModifyDepositsBanner from "../../components/modifyDepositsBanner/ModifyDepositsBanner";
import ModalAddToTrip from "../../components/modals/modalAddToTrip/ModalAddToTrip";
import { isArray } from "lodash";
import ModalCreateTrip from "../../components/modals/modalCreateTrip/ModalCreateTrip";
import { LegContextMenuActions } from "../../types/legContextMenu";
import { IUserContext, UserContext } from "../../context/UserContext";
import usePermissions from "../../utils/hooks/usePermissions";
import { PermissionAreas, PermissionOperations } from "../../types/permissions";
import { MultipleActionsEnum } from "../../types/planning";
import "./style.css";
import ModalDeliverToPartner from "../../components/modals/modalDeliverToPartner/ModalDeliverToPartner";
import { tripsService } from "../../api/tripsService/tripsService";
import { errorConstants } from "../../configs/errorConstants";
// import ModalOrdersInError from "../../components/modalOrdersInError/ModalOrdersInError";
import { gridDateFilterParamsSSR, gridTextFilterParamsSSR, originDestinationGridTextFilterParamsSSR } from "../../configs/constants";
import ModalShowShipmentUnits from "../../components/modals/modalShowShipmentUnits/ModalShowShipmentUnits";
import ModalOrderLegsErrors from "../../components/modals/modalOrderLegsErrors/ModalOrderLegsErrors";
import ReactDatePicker from "react-datepicker";
import { getFilterParamsOriginDestinationSSR } from "../../utils/functions/getFilterParamsOriginDestinationSSR";
import TableTooltipCustomerIds from "../../components/ui/tableTooltipCustomerIds/TableTooltipCustomerIds";
import { differenceInDays } from "date-fns";
import Switch from "../../components/ui/switch/Switch";

const columnsWithAggregation = ['volume', 'weight', 'taxWeight', 'linearMeters', 'shipmentUnitsNumber'];

// Register the required feature modules with the Grid
ModuleRegistry.registerModules([ClientSideRowModelModule]);

const actionOptions = [
    {
        label: "Separate orders",
        value: MultipleActionsEnum.SeparateOrders
    },
    {
        label: "Restore route",
        value: MultipleActionsEnum.RestoreOrdersRoute
    },
    {
        label: "Stand by (TO)",
        value: MultipleActionsEnum.SetOrdersAsStandBy
    },
    {
        label: "Assign collection to partner",
        value: MultipleActionsEnum.DeliverToPartnerCollection
    },
    {
        label: "Assign delivery to partner",
        value: MultipleActionsEnum.DeliverToPartnerDelivery
    },
    {
        label: "Set legs as dispatchable",
        value: MultipleActionsEnum.SetSelectedOrdersLegsAsDispatchable
    },
    {
        label: "Drop orders from planning",
        value: MultipleActionsEnum.HideOrdersFromPlanning
    },
    {
        label: "Include orders in planning",
        value: MultipleActionsEnum.IncludeOrdersInPlanning
    }
];

const selectionOptions = [
    {
        label: "Unselect all",
        value: 0
    },
    {
        label: "Legs",
        options: [
            {
                label: "Select first leg",
                value: 1
            },
            {
                label: "Select last leg",
                value: 2
            },
            {
                label: "Select collection legs",
                value: 3
            },
            {
                label: "Select delivery legs",
                value: 4
            },
            {
                label: "Select direct legs",
                value: 5
            },
            {
                label: "Select unplanned legs",
                value: 6
            }
        ]
    },
    {
        label: "Depots",
        options: [
            {
                label: "Select first depots",
                value: 7
            },
            {
                label: "Select last depots",
                value: 8
            },
            {
                label: "Select unplanned depots",
                value: 9
            }
        ]
    }
];

const planningStatusToNotFilterAllDays = [PlanningFilterEnum.ALL, PlanningFilterEnum.ERROR];

const containerStyle = { width: '100%', height: '100%' };

const PlanningToolPlanning: React.FC = () => {
    const navigate = useNavigate();
    const { hasPermission } = usePermissions();

    const { equipments, departments } = useContext(MasterDataContext) as IMasterDataContext;

    const {
        activeTripId,
        activeTrip,
        setActiveTripId,
        forceRefreshOrders,
        refreshOrders,
        updateTripWithCurrentParamsAndPagination,
        isOpenSidebarTrips,
        setIsOpenSidebarTrips,
        setIsVisibleSidebarTrips,
        refreshTripWithParams,
        updateTrips,
        setDepartmentId
    } = useContext(TripsContext) as ITripsContext;

    const {
        actualUser
    } = useContext(UserContext) as IUserContext;

    type LegOrDepotToSelect = {
        legId: number;
        type: "leg" | "deposit";
    };

    // Init
    const location = useLocation();
    const canGoCheckLocationState = useRef(true);
    const [selectedDepartmentId, setSelectedDepartmentId] = useState<number | null>(null);
    const [selectedDepartmentGroups, setSelectedDepartmentGroups] = useState<IGroup[]>([]);
    const [planningGroups, setPlanningGroups] = useState<IGroup[] | undefined>(undefined);
    const [ordersInUpdate, setOrdersInUpdate] = useState<number[]>([]);
    const [onlyDroppedOrders, setOnlyDroppedOrders] = useState<boolean>(false);
    const { pushMessagesEvents } = SignalRConnector();
    const [openDatePicker, setOpenDatePicker] = useState<boolean>(false);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [dateSelected, setDateSelected] = useState(true);
    const [isAllDays, setIsAllDays] = useState<boolean>(true);
    const [legsOrDepotsToSelect, setLegsOrDepotsToSelect] = useState<LegOrDepotToSelect | null>(null);

    // Common Table variables
    const commonTableRef = useRef<TableRef>(null);

    const equipmentOptions = useMemo(() => {
        return equipments.map((e) => {
            return {
                value: e.id,
                label: e.name
            }
        });
    }, [equipments]);

    const canEdit = useMemo(() => {
        if (!!!planningGroups) {
            return false;
        }

        if (!!!actualUser) {
            return false;
        }

        if (!hasPermission(PermissionAreas.Planning, PermissionOperations.Modify)) {
            return false;
        }

        if(!activeTrip) {
            if (planningGroups.some(x => x.chargeUser?.username !== actualUser.username)) {
                return false;
            }
        } else {
            if(activeTrip.owner?.username !== actualUser.username) {
                return false;
            } 
        }

        return true;
    }, [planningGroups, activeTrip]);

    const handleUndoSelectedLeg = (legId: number) => {
        let requestData: number[] = [legId];
        undoLegsAction(requestData);
    };

    const undoLegsAction = async (requestData: number[]) => {
        const response = await legsService.undoAction(requestData, "Undoing action...", "Action undone successfully", "Error undoing action");

        let nodesToUpdate = commonTableRef.current?.getTableNodes()?.filter((node: RowNode) => node.data.legs.some((leg: Leg) => requestData.includes(leg.id)));

        if (response.success) {
            setUpdateRoutingErrorOrderIds([]);

            if (!!nodesToUpdate) {
                updateOrdersData(nodesToUpdate);
            }
        }
    };

    const handleSetSelectedOrdersLegsAsDispatchable = async () => {
        const wantToContinue = await customConfirmAlert({
            title: "Set orders legs as dispatchable",
            message: "Are you sure to set selected orders legs as dispatchable?"
        });

        if (!wantToContinue) {
            return;
        }

        let selectedNodes = getTableSelectedNodes();
        let selectedNodesData = selectedNodes.map((node: any) => node.data);
        let legsToConfirm = selectedNodesData.map(x => x.legs).flat(1).filter(leg => legCanBeDispatchable(leg));

        if (legsToConfirm.length === 0) {
            toast.info("There are no legs to set as dispatchable");
            return;
        }

        let requestData = legsToConfirm.map(x => x.id);
        await setDispatchableLegs(requestData);
    };

    const handleSetLegAsDispatchable = (legId: number) => {
        let requestData: number[] = [legId];
        setDispatchableLegs(requestData);
    };

    const setDispatchableLegs = async (requestData: number[]) => {
        const response = await legsService.setDispatchable(requestData, "Setting legs as dispatchable...", "Legs set as dispatchable successfully", "Error setting legs as dispatchable");
        if (response.success) {
            // let requestDataIds = requestData.map(x => x.legId);
            // let tableNodes = commonTableRef.current?.getTableNodes();
            // let tableNodesToUpdate = tableNodes?.filter(node => node.data.legs.some((leg: Leg) => requestDataIds.includes(leg.id)));
            // updateOrdersData(tableNodesToUpdate as RowNode[]);
            refreshOrders();
            updateTripWithCurrentParamsAndPagination();
        }
    };

    const handleReplanLeg = async (legId: number) => {

        const wantToContinue = await customConfirmAlert({
            message: 'Are you sure you want to replan leg?',
            title: 'Replan leg',
        });

        if (!!wantToContinue) {
            let requestData: number[] = [legId];
            const response = await legsService.replanLeg(requestData, "Replanning leg...", "Leg is now replannable", "Error replanning leg");

            if (response.success) {
                refreshOrders();
                updateTripWithCurrentParamsAndPagination();
            }
        }
    }

    const legContextMenuItems = [
        {
            id: LegContextMenuActions.UNDO_ACTION,
            label: 'Undo action',
            icon: 'pi pi-undo',
            command: () => {
                let legId: number = legContextMenuRef.current?.getSelectedItem();
                handleUndoSelectedLeg(legId);
            }
        },
        {
            id: LegContextMenuActions.SET_AS_DISPATCHABLE,
            label: 'Set as dispatchable',
            icon: 'pi pi-check-circle',
            command: () => {
                let legId: number = legContextMenuRef.current?.getSelectedItem();
                handleSetLegAsDispatchable(legId);
            }
        },
        {
            id: LegContextMenuActions.REPLAN,
            label: 'Replan',
            icon: 'pi pi-sliders-h',
            command: () => {
                let legId: number = legContextMenuRef.current?.getSelectedItem();
                handleReplanLeg(legId);
            }
        }
    ];

    const legContextMenuRef = useRef<CommonContextMenuRef>(null);

    const [ordersNumber, setOrdersNumber] = useState<number | undefined>(undefined);
    const [selectedLegs, setSelectedLegs] = useState<number[]>([]);
    const [selectedDeposits, setSelectedDeposits] = useState<number[]>([]);
    const [selectedRows, setSelectedRows] = useState<number[]>([]);
    const [selectedDepositToModify, setSelectedDepositToModify] = useState<number | null>(null);

    // Get Orders
    useEffect(() => {
        if (!!planningGroups) {
            console.log("GRUPPI IN PIANIFICAZIONE: ", planningGroupsIds);

            // Registration to PushMessages Events
            subscribeToPushMessagesEvents();
        }

        if (hasPermission(PermissionAreas.Planning, PermissionOperations.View)) {
            setIsVisibleSidebarTrips(true);
        }
    }, [planningGroups]);

    const [newOrdersAlertInfo, setNewOrdersAlertInfo] = useState<NewOrdersAlertInfo | null>(null);
    const [newPushMessage, setNewPushMessage] = useState<IPushMessage | null>(null);
    const subscribeToPushMessagesEvents = () => {
        pushMessagesEvents((msg) => {
            setNewPushMessage(msg);
        });
    };

    const handleNewPushMessageReceived = (msg: IPushMessage) => {
        if (msg.type === PushMessagesTypes.NEW_ORDERS) {
            let newOrderMsg = (msg as INewOrdersMessage);
            let orderIds = distinct(newOrderMsg.infos.filter(info => planningGroupsIds.includes(info.groupId)).map(info => info.orderIds).flat(1)) as number[];
            if (newOrdersAlertInfo !== null && newOrdersAlertInfo.show === true) {
                let aggregatedOrderIds = distinct([...orderIds, ...newOrdersAlertInfo.orderIds]);
                orderIds = aggregatedOrderIds;
            }

            let newOrdersNumber = orderIds.length;
            let show = false;
            if (newOrdersNumber > 0) {
                show = true;
            }
            setNewOrdersAlertInfo(new NewOrdersAlertInfo(show, newOrderMsg.sentTime, newOrdersNumber, orderIds));
            if (show) {
                document.title = `(${newOrdersNumber}) UOM - Arcese`;
            }
        }
    };

    useEffect(() => {
        if (newPushMessage === null) {
            return;
        }

        handleNewPushMessageReceived(newPushMessage);
    }, [newPushMessage]);

    const planningGroupsIds = useMemo(() => {
        if (!!!planningGroups) {
            return [];
        }

        return planningGroups.map(x => x.id);
    }, [planningGroups]);

    const hideOrdersFromPlanning = async () => {
        const wantToContinue = await customConfirmAlert({
            title: "Drop orders",
            message: "Are you sure to drop selected orders?"
        });

        if (!wantToContinue) {
            return;
        }

        let selectedNodes = getTableSelectedNodes();
        let selectedNodesData: IOrder[] = selectedNodes.map((node: any) => node.data);
        let requestData = selectedNodesData.map(x => {
            let request: DisableOrdersRequest = {
                id: x.id,
                disable: true
            }

            return request;
        });

        const response = await ordersService.disableOrders(requestData, "Dropping orders...", "Orders dropped successfully", "Error dropping orders");
        if (response.success) {
            refreshOrders();
        }
    };

    const includeOrdersInPlanning = async () => {
        const wantToContinue = await customConfirmAlert({
            title: "Include orders",
            message: "Are you sure to include selected orders?"
        });

        if (!wantToContinue) {
            return;
        }

        let selectedNodes = getTableSelectedNodes();
        let selectedNodesData: IOrder[] = selectedNodes.map((node: any) => node.data);
        let requestData = selectedNodesData.map(x => {
            let request: DisableOrdersRequest = {
                id: x.id,
                disable: false
            }

            return request;
        });

        const response = await ordersService.disableOrders(requestData, "Including orders...", "Orders included successfully", "Error including orders");
        if (response.success) {
            refreshOrders();
        }
    }

    const separateMultipleOrders = async () => {
        let selectedNodes = getTableSelectedNodes();
        let selectedNodesData = selectedNodes.map((node: { data: IOrder }) => node.data);
        let aggregationIds = distinct(selectedNodesData.map(x => x.id));
        await separateOrders(aggregationIds);
    };

    const handleSeparateOrdersFromModal = async () => {
        if (activeAggregationId !== null) {
            separateOrders([activeAggregationId]);
        }
    }

    const separateOrders = async (aggregationIds: number[]) => {
        let tableRows: IOrder[] = commonTableRef.current?.getTableRows() as IOrder[];

        let aggregationIdsToSeparate: number[] = [];
        aggregationIds.filter(x => x !== null).forEach(id => {
            const tableRow = tableRows.find(y => y.id === id);
            if (!!tableRow && !!tableRow.orderNumbers && tableRow.orderNumbers.length > 0) {
                aggregationIdsToSeparate.push(id);
            }
        });

        if (aggregationIdsToSeparate.length === 0) {
            toast.info("No orders to separate");
            return;
        }

        if (await customConfirmAlert({
            title: "Separate orders",
            message: "Are you sure you want to proceed with separating grouped orders?"
        })) {
            const response = await ordersService.separateOrders(aggregationIdsToSeparate, "Separating orders...", "Orders separated successfully!", "Error while separating orders!");
            if (response.success === true) {
                refreshOrders();
                setActiveAggregationId(null);
            }
        }
    }

    const handleRestoreRoute = async (props: any) => {
        let orderId = props.data.id;
        await restoreRoute([orderId], [props.node]);
    };

    const restoreMultipleOrdersRoute = async () => {
        let selectedNodes = getTableSelectedNodes();
        let selectedNodesData = selectedNodes.map((node: { data: IOrder }) => node.data);

        let canGo = true;
        for (let j = 0; j < selectedNodesData.length; j++) {
            const order = selectedNodesData[j];

            for (let index = 0; index < order.legs.length; index++) {
                const plan = order.legs[index];
                if (
                    !isLegOpacited(plan, order, canEdit, activeTripId, false, planningGroupsIds).result &&
                    (
                        isPlannedLeg(plan).result ||
                        (plan.origin.type === "Partner" || plan.destination.type === "Partner")
                    )
                ) {
                    canGo = false;
                    break;
                }
            }

            if (!canGo) {
                break;
            }
        }

        if (canGo) {
            let selectedOrdersIds = selectedNodesData.filter(o => selectedRows.includes(o.id)).map(o => o.id).flat(1);
            await restoreRoute(selectedOrdersIds, selectedNodes);
        } else {
            toast.error("You can\'t restore the routing if it\'s already planned. Undo all actions and retry.")
        }
    };

    const restoreRoute = async (orderIds: number[], nodes: RowNode[]) => {
        const wantToRestore = await customConfirmAlert({ title: "Restore previous route", message: "Are you sure to restore the previuos route?" });
        if (wantToRestore) {
            const response = await routingService.resetDefault(orderIds, "Restoring previous route...", "Previous route restored successfully", "Error restoring previous route");
            if (response.success) {
                updateOrdersData(nodes);
            }
        }
    };

    const selectedLegsData = useMemo(() => {
        let result: Leg[] = [];

        let tableRows = commonTableRef.current?.getTableRows();
        if (tableRows === undefined) {
            return result;
        }

        let allLegs = tableRows.filter(x => x.legs !== 0).map(x => x.legs).flat(1);

        allLegs.forEach(l => {
            if (l !== 0 && selectedLegs.includes(l.id)) {
                result.push(l);
            }
        });

        return result;
    }, [selectedLegs]);

    const selectedDepositsData = useMemo(() => {
        let result: Leg[] = [];

        let tableRows = commonTableRef.current?.getTableRows();
        if (tableRows === undefined) {
            return result;
        }

        let allLegs = tableRows.filter(x => x.legs !== 0).map(x => x.legs).flat(1);

        allLegs.forEach(l => {
            if (l !== 0 && selectedDeposits.includes(l.id)) {
                result.push(l);
            }
        });

        return result;
    }, [selectedDeposits]);

    const orderOfSelectedLegs = useMemo(() => {
        let orders: IOrder[] = [];

        let tableRows: IOrder[] | undefined = commonTableRef.current?.getTableRows();
        if (tableRows === undefined) {
            return orders;
        }

        tableRows.forEach((order: IOrder) => {
            if (order.legs.some(leg => selectedLegs.includes(leg.id))) {
                orders.push(order);
            }
        });

        return orders;
    }, [selectedLegs]);

    const handleAddDepotToSelectedLegs = async () => {
        if (selectedDepositToModify === null) {
            toast.info("Select new depot");
            return;
        }

        let wantToContinue = await customConfirmAlert({
            title: "Add depot",
            message: "Are you sure to add depot to selected legs?"
        });

        if (!wantToContinue) {
            return;
        }

        let orderIds = selectedLegsData.map(x => x.orderId);

        let changeDepositOrdersRequest: ChangeDepositsOrders[] = [];

        let allTableNodes = commonTableRef.current?.getTableNodes();
        if (allTableNodes === undefined) {
            return;
        }

        let nodeToUpdate: RowNode[] = [];
        orderIds.forEach(orderId => {
            let orderActions: ChangeDepositsOrderActions[] = [];
            selectedLegsData.filter(x => x.orderId === orderId).forEach(leg => {
                orderActions.push({
                    newDepositId: selectedDepositToModify as number,
                    legId: leg.id
                });
            });

            changeDepositOrdersRequest.push({
                orderId: orderId,
                orderActions: orderActions
            });

            nodeToUpdate.push(!!allTableNodes && allTableNodes.find(x => x.data.id === orderId));
        });

        const response = await routingService.addDeposits(changeDepositOrdersRequest, "Add depots...", "Depots added successfully", "Error adding depots");
        if (response.success) {
            if (response.statusCode === 207) {
                if (changeDepositOrdersRequest.length > response.data.length) {
                    toast.update(response.toastId, { type: "warning", isLoading: false, render: `Depots added with errors`, autoClose: 3000 });
                } else {
                    toast.update(response.toastId, { type: "warning", isLoading: false, render: `Operation not allowed`, autoClose: 3000 });
                }
                let orderIdInError: any = [];
                response.data.map((x: any) => x.orderId).forEach((responseOrderId: any) => {
                    orderIdInError.push(commonTableRef.current?.getTableRows().find(x => x.id === responseOrderId).id);
                });
                setUpdateRoutingErrorOrderIds(orderIdInError);
            }

            updateOrdersData(nodeToUpdate);
            setSelectedLegs([]);
        }
    };

    const handleChangeSelectedDeposts = async () => {
        if (selectedDepositToModify === null) {
            toast.info("Select new depot");
            return;
        }

        let wantToContinue = await customConfirmAlert({
            title: "Change depots",
            message: "Are you sure to change selected depots?"
        });

        if (!wantToContinue) {
            return;
        }

        let orderIds: number[] = selectedDepositsData.map((x: any) => x.orderId);

        let changeDepositOrdersRequest: ChangeDepositsOrders[] = [];

        let allTableNodes = commonTableRef.current?.getTableNodes();
        if (allTableNodes === undefined) {
            return;
        }

        let nodeToUpdate: RowNode[] = [];
        orderIds.forEach(orderId => {
            let orderActions: ChangeDepositsOrderActions[] = [];
            selectedDepositsData.filter((x: any) => x.orderId === orderId).forEach((leg: Leg) => {
                orderActions.push({
                    depositAddressId: leg.origin.id,
                    newDepositId: selectedDepositToModify as number
                });
            });

            changeDepositOrdersRequest.push({
                orderId: orderId,
                orderActions: orderActions
            });

            nodeToUpdate.push(!!allTableNodes && allTableNodes.find(x => x.data.id === orderId));
        });

        const response = await routingService.changeDeposits(changeDepositOrdersRequest, "Change depots...", "Depots changed successfully", "Error changing depots");
        if (response.success) {
            if (response.statusCode === 207) {
                if (changeDepositOrdersRequest.length > response.data.length) {
                    toast.update(response.toastId, { type: "warning", isLoading: false, render: `Depots changed with errors`, autoClose: 3000 });
                } else {
                    toast.update(response.toastId, { type: "warning", isLoading: false, render: `Operation not allowed`, autoClose: 3000 });
                }
                let orderIdInError: any = [];
                response.data.map((x: any) => x.orderId).forEach((responseOrderId: any) => {
                    orderIdInError.push(commonTableRef.current?.getTableRows().find(x => x.id === responseOrderId).id);
                });
                setUpdateRoutingErrorOrderIds(orderIdInError);
            }

            updateOrdersData(nodeToUpdate);
            setSelectedDeposits([]);
        }
    };

    const handleRemoveSelectedDepots = async () => {
        let wantToContinue = await customConfirmAlert({
            title: "Remove depots",
            message: "Are you sure to remove selected depots?"
        });

        if (!wantToContinue) {
            return;
        }

        let orderIds: number[] = selectedDepositsData.map((x: any) => x.orderId);

        let changeDepositOrdersRequest: ChangeDepositsOrders[] = [];

        let allTableNodes = commonTableRef.current?.getTableNodes();
        if (allTableNodes === undefined) {
            return;
        }

        let nodeToUpdate: RowNode[] = [];
        orderIds.forEach(orderId => {
            let orderActions: ChangeDepositsOrderActions[] = [];
            selectedDepositsData.filter((x: any) => x.orderId === orderId).forEach((leg: Leg) => {
                orderActions.push({
                    depositAddressId: leg.origin.id
                });
            });

            changeDepositOrdersRequest.push({
                orderId: orderId,
                orderActions: orderActions
            });

            nodeToUpdate.push(!!allTableNodes && allTableNodes.find(x => x.data.id === orderId));
        });

        const response = await routingService.removeDeposits(changeDepositOrdersRequest, "Remove depots...", "Depots removed successfully", "Error removing depots");
        if (response.success) {
            if (response.statusCode === 207) {
                if (changeDepositOrdersRequest.length > response.data.length) {
                    toast.update(response.toastId, { type: "warning", isLoading: false, render: `Depots removed with errors`, autoClose: 3000 });
                } else {
                    toast.update(response.toastId, { type: "warning", isLoading: false, render: `Operation not allowed`, autoClose: 3000 });
                }
                let orderIdInError: any = [];
                response.data.map((x: any) => x.orderId).forEach((responseOrderId: any) => {
                    orderIdInError.push(commonTableRef.current?.getTableRows().find(x => x.id === responseOrderId).id);
                });
                setUpdateRoutingErrorOrderIds(orderIdInError);
            }

            updateOrdersData(nodeToUpdate);
            setSelectedDeposits([]);
        }
    };

    const handleRemoveSingleDeposit = async (orderId: number, leg: Leg) => {
        resetSelection();

        let changeDepositOrdersRequest: ChangeDepositsOrders[] = [];

        let tableNodes: RowNode[] | undefined = commonTableRef?.current?.getTableNodes();
        if (tableNodes === undefined) {
            return;
        }
        let nodeToUpdate = tableNodes?.find(x => x.data.id === orderId);

        if (!!nodeToUpdate) {
            let orderActions: ChangeDepositsOrderActions[] = [{
                depositAddressId: leg.origin.id
            }];

            changeDepositOrdersRequest.push({
                orderId: orderId,
                orderActions: orderActions
            });

            const response = await routingService.removeDeposits(changeDepositOrdersRequest, "Remove deposit...", "Deposit removed successfully", "Error removing deposit");
            if (response.success) {
                if (response.statusCode === 207) {
                    if (changeDepositOrdersRequest.length > response.data.length) {
                        toast.update(response.toastId, { type: "warning", isLoading: false, render: `Deposit removed with errors`, autoClose: 3000 });
                    } else {
                        toast.update(response.toastId, { type: "warning", isLoading: false, render: `Operation not allowed`, autoClose: 3000 });
                    }
                    let orderIdInError: any = [];
                    response.data.map((x: any) => x.orderId).forEach((responseOrderId: any) => {
                        orderIdInError.push(commonTableRef.current?.getTableRows().find(x => x.id === responseOrderId).id);
                    });
                    setUpdateRoutingErrorOrderIds(orderIdInError);
                }

                updateOrdersData([nodeToUpdate]);
                setSelectedDeposits([]);
            }
        }
    }

    const handleForwardtoNetworkSelectedLegs = async () => {
        let wantToContinue = await customConfirmAlert({
            title: "Forward to network",
            message: "Are you sure to forward selected legs to network?"
        });

        if (!wantToContinue) {
            return;
        }

        let updateLegPlanningRequestDatas: UpdateLegPlanningRequestData[] = [];
        selectedLegs.forEach((legId: number) => {
            updateLegPlanningRequestDatas.push({
                id: legId,
                planning: LegPlanningTypesEnum.Network
            });
        });

        updateLegPlanning(updateLegPlanningRequestDatas);
    };

    const handleStandBySelectedLegs = async () => {
        let wantToContinue = await customConfirmAlert({
            title: "Stand by legs",
            message: "Are you sure to stand by selected legs?"
        });

        if (!wantToContinue) {
            return;
        }

        let updateLegPlanningRequestDatas: UpdateLegPlanningRequestData[] = [];
        selectedLegs.forEach((legId: number) => {
            updateLegPlanningRequestDatas.push({
                id: legId,
                planning: LegPlanningTypesEnum.StandBy
            });
        });

        updateLegPlanning(updateLegPlanningRequestDatas);
    };

    const assignPartnerToLegs = async () => {
        let selectedNodes = getTableSelectedNodes();

        let selectedPartner = selectedPartnerToDeliver;
        if (!!!selectedPartner) {
            toast.info("Select a Partner");
            return;
        }

        let requestData: AssignPartnerToLegRequestData = { legs: [], partnerId: (selectedPartnerToDeliver?.id as number) };
        let nodesToUpdate: RowNode[] = [];

        selectedNodes.forEach(node => {
            let order = node.data;
            let legs = getSelectableLegs(order.legs);
            legs.forEach(leg => {
                if (legs.length === 1 || (leg.type.id === LegTypesEnum.Collection && legPlanningPartnerIsCollection) || (leg.type.id === LegTypesEnum.Delivery && !legPlanningPartnerIsCollection)) {
                    requestData.legs.push({
                        legId: leg.id,
                        isCollection: legPlanningPartnerIsCollection
                    });
                    nodesToUpdate.push(node);
                }
            });
        });

        if (requestData.legs.length === 0) {
            toast.warning("There are no legs assignable to the partner");
            return;
        }

        const response = await legsService.assignPartner(requestData, "Assigning partner to legs...", "Partner assigned successfully", "Error assigning partner to legs", "Partner assigned with errors");

        setOpenModalDeliverToPartner(false);
        restoreMultipleActionSelection();

        if (response.success) {
            updateOrdersData(nodesToUpdate);
        }

        if (!!response.data.detailObject && response.data.detailObject.length > 0) {
            setUpdateRoutingErrorOrderIds(response.data.detailObject);
        } else {
            setUpdateRoutingErrorOrderIds([]);
        }
    };

    const updateMultipleLegPlanning = async (newPlanningType: LegPlanningTypesEnum) => {
        setOpenModalDeliverToPartner(false);
        restoreMultipleActionSelection();

        let selectedNodes = getTableSelectedNodes();
        let selectedNodesData: IOrder[] = selectedNodes.map((node: any) => node.data);

        let requestData: UpdateLegPlanningRequestData[] = [];
        selectedNodesData.forEach(order => {
            let legs = getSelectableLegs(order.legs);

            legs.forEach(leg => {
                requestData.push({
                    id: leg.id,
                    planning: newPlanningType,
                    collection: legPlanningPartnerIsCollection,
                    partner: selectedPartnerToDeliver !== null ? selectedPartnerToDeliver.id : undefined
                });
            })
        });

        if (requestData.length > 0) {
            await updateLegPlanning(requestData);
        } else {
            toast.warning('Action not allowed on selected orders');
        }
    };

    const [activeModalOrderLegsErrorsOrderId, setActiveModalOrderLegsErrorsOrderId] = useState<number | null>(null);
    const [updateRoutingErrorOrderIds, setUpdateRoutingErrorOrderIds] = useState<number[]>([]);

    // const ordersInError = useMemo<IOrder[]>(() => {
    //     let tableRows: IOrder[] | undefined = commonTableRef.current?.getTableRows();
    //     if (tableRows === undefined) {
    //         return [];
    //     }

    //     return tableRows.filter(x => !!updateRoutingErrorOrderIds.includes(x.id));
    // }, [updateRoutingErrorOrderIds]);

    // const [openModalOrdersInError, setOpenModalOrdersInError] = useState(false);
    // useEffect(() => {
    //     if (updateRoutingErrorOrderIds.length > 0) {
    //         setOpenModalOrdersInError(true);
    //     }
    // }, [updateRoutingErrorOrderIds]);

    const [showOnlyErrors, setShowOnlyErrors] = useState(false);
    useEffect(() => {
        if (!!commonTableRef && !!commonTableRef.current) {
            refreshOrders();
        }
    }, [showOnlyErrors, updateRoutingErrorOrderIds]);

    const updateLegPlanning = async (requestData: UpdateLegPlanningRequestData[]) => {
        const response = await legsService.updateLegPlanning(requestData, "Updating legs...", "Legs updated successfully", "Error updating legs");
        if (response.success) {
            let legIds = requestData.map(x => x.id);

            let tableNodes = commonTableRef.current?.getTableNodes();
            if (tableNodes === undefined) {
                return;
            }

            let orderIdsInError: number[] = [];

            let nodesToUpdate: RowNode[] = [];
            tableNodes.forEach(node => {
                let data: IOrder = node.data;
                let orderLegs = data.legs;
                if (orderLegs.some(x => legIds.includes(x.id))) {
                    nodesToUpdate.push(node);
                    if (response.statusCode === 207) {
                        if (requestData.length > response.data.length) {
                            toast.update(response.toastId, { type: "warning", isLoading: false, render: `Legs updated with errors`, autoClose: 3000 });
                        } else {
                            toast.update(response.toastId, { type: "warning", isLoading: false, render: `Operation not allowed`, autoClose: 3000 });
                        }
                        let ordersInError = orderLegs.some(x => response.data.map((r: any) => r.legId).includes(x.id));
                        if (ordersInError) {
                            orderIdsInError.push(data.id);
                        }
                    }
                }
            });

            setUpdateRoutingErrorOrderIds(orderIdsInError);
            updateOrdersData(nodesToUpdate);
        }

        setSelectedLegs([]);
    };

    const updateOrdersData = async (nodes: RowNode[]) => {
        let idsToUpdate = nodes.map(node => node.data.id).flat(1);
        const response = await ordersService.getOrders(planningGroupsIds, idsToUpdate, activeTripId, onlyDroppedOrders, null);
        let ordersData: IOrder[] = response.data.orders;

        nodes.forEach(node => {
            let rowData: any = node.data;
            let ordersNewData = ordersData.find(x => x.id === rowData.id);

            if (ordersNewData !== undefined) {
                node.setData({ ...rowData, legs: ordersNewData.legs, origin: ordersNewData.origin, destination: ordersNewData.destination, originPartner: ordersNewData.originPartner, destinationPartner: ordersNewData.destinationPartner, dropped: ordersNewData.dropped });
            }
        });
    };

    const [selectedAction, setSelectedAction] = useState<'' | "1" | "2" | "3" | "4" | "5" | "6" | "7" | "8">('');

    const getTableSelectedNodes = () => {
        let tableRows: any[] = [];
        commonTableRef.current?.getTableNodes().forEach((node: RowNode) => {
            if (selectedRows.includes(node.data.id)) {
                tableRows.push(node);
            }
        });

        return tableRows;
    };

    const isDisabledLeg = (leg: Leg, groupsInCharge: number[]) => {
        if (!groupsInCharge.includes(leg.groupId)) {
            return true;
        }

        if (leg.planningType.id === LegPlanningTypesEnum.StandBy || leg.planningType.id === LegPlanningTypesEnum.Network) {
            return true;
        }

        if (leg.plannable === false || leg.planned === true) {
            return true;
        }

        if (leg.trip !== null) {
            return true;
        }

        let tableRows: IOrder[] | undefined = commonTableRef.current?.getTableRows();
        if (!!tableRows) {
            let order = tableRows?.find(x => x.id === leg.orderId);
            if (!!order && order.dropped) {
                return true;
            }
        }

        return false;
    };

    const checkAtLeastOneOrderHasAllLegsDisabled = (selectedNodesData: IOrder[], groupsInCharge: number[]) => {
        let atLeastOneOrderHasAllLegsDisabled = false;
        for (let index = 0; index < selectedNodesData.length; index++) {
            const order = selectedNodesData[index];

            let hasAllLegDisabled = true;
            for (let index = 0; index < order.legs.length; index++) {
                const leg = order.legs[index];

                if (!isDisabledLeg(leg, groupsInCharge)) {
                    hasAllLegDisabled = false;
                    break;
                }
            }

            if (hasAllLegDisabled === true) {
                atLeastOneOrderHasAllLegsDisabled = true;
                break;
            }
        }

        return atLeastOneOrderHasAllLegsDisabled;
    }

    const checkAtLeastOneOrderHasNotDisabledLegs = (selectedNodesData: IOrder[], groupsInCharge: number[]) => {
        let atLeastOneOrderHasNotDisabledLegs = false;
        for (let index = 0; index < selectedNodesData.length; index++) {
            const order = selectedNodesData[index];

            for (let index = 0; index < order.legs.length; index++) {
                const leg = order.legs[index];

                if (!isDisabledLeg(leg, groupsInCharge)) {
                    atLeastOneOrderHasNotDisabledLegs = true;
                    break;
                }
            }
        }

        return atLeastOneOrderHasNotDisabledLegs;
    }

    const disabledActionOptionsValues = useMemo<string[]>(() => {
        const selectedNodes = getTableSelectedNodes();
        const selectedNodesData: IOrder[] = selectedNodes.map((node: any) => node.data);

        const disabledOptions: string[] = [];

        if (!!selectedNodesData.find(n => n.dropped === true)) {
            disabledOptions.push(MultipleActionsEnum.SeparateOrders.toString());
            disabledOptions.push(MultipleActionsEnum.RestoreOrdersRoute.toString());
            disabledOptions.push(MultipleActionsEnum.SetOrdersAsStandBy.toString());
            disabledOptions.push(MultipleActionsEnum.DeliverToPartnerCollection.toString());
            disabledOptions.push(MultipleActionsEnum.DeliverToPartnerDelivery.toString());
            disabledOptions.push(MultipleActionsEnum.SetSelectedOrdersLegsAsDispatchable.toString());
            disabledOptions.push(MultipleActionsEnum.HideOrdersFromPlanning.toString());
        }

        if (!!selectedNodesData.find(n => n.dropped === false)) {
            disabledOptions.push(MultipleActionsEnum.IncludeOrdersInPlanning.toString());
        }

        if (checkAtLeastOneOrderHasAllLegsDisabled(selectedNodesData, planningGroupsIds)) {
            disabledOptions.push(MultipleActionsEnum.SeparateOrders.toString());
            disabledOptions.push(MultipleActionsEnum.RestoreOrdersRoute.toString());
            disabledOptions.push(MultipleActionsEnum.SetOrdersAsStandBy.toString());
            disabledOptions.push(MultipleActionsEnum.DeliverToPartnerCollection.toString());
            disabledOptions.push(MultipleActionsEnum.DeliverToPartnerDelivery.toString());
            disabledOptions.push(MultipleActionsEnum.HideOrdersFromPlanning.toString());
        }

        if (checkAtLeastOneOrderHasNotDisabledLegs(selectedNodesData, planningGroupsIds)) {
            disabledOptions.push(MultipleActionsEnum.SetSelectedOrdersLegsAsDispatchable.toString());
        }

        return disabledOptions;
    }, [selectedRows, selectedAction, ordersNumber, planningGroupsIds]);

    // Sums in footer row
    const calculatePinnedBottomData = (target: any) => {
        columnsWithAggregation.forEach(element => {
            let tableRows: IOrder[] | undefined = commonTableRef.current?.getTableRows();
            if (tableRows === undefined || tableRows.length === 0) {
                return target;
            }

            let agGridRef = commonTableRef.current?.getAgGridRef();
            if (!!!agGridRef) {
                return target;
            }

            agGridRef.api.forEachNode((rowNode: RowNode) => {
                if (!!rowNode && !!rowNode.data) {
                    if (!!rowNode.data[element] || rowNode.data[element] === '0' || rowNode.data[element] === 0) {
                        if (element !== 'linearMeters') {
                            let value = Number(target[element]);
                            value += Number(rowNode.data[element].toFixed(2));
                            target[element] = value.toString();
                        }
                    }
                }
            });

            if (element !== 'linearMeters') {
                target[element] = `${Number(target[element]).toFixed(2)}`;
            }
        });

        return target;
    }

    const generatePinnedBottomData = () => {
        let result: any = {};
        let gridColumns = commonTableRef.current?.getAllGridColumn();

        if (!!!gridColumns) {
            return result;
        }

        gridColumns.forEach((item: any) => {
            if (columnsWithAggregation.includes(item.colId)) {
                result[item.colId] = 0;
            } else {
                result[item.colId] = (item.colId === 'legs' || item.colId === 'checkbox') ? [] : null;
            }
        });

        return calculatePinnedBottomData(result);
    }

    const setPinnedBottomData = () => {
        let pinnedBottomData = generatePinnedBottomData();
        commonTableRef.current?.setPinnedBottomData([pinnedBottomData]);

        adjustTableScrollbar();
    };

    const gridContainerRef = useRef<HTMLDivElement>(null)

    const changeLinearMeters = (e: any, props: any, saveIconRef: any) => {
        props.node.setData({ ...props.data, linearMeters: e.target.value });
        setTimeout(function () { setPinnedBottomData() }, 0)
        setOrdersInUpdate([...ordersInUpdate.filter(x => x !== props.data.id), props.data.id]);
        document.getElementById(saveIconRef)?.classList?.add("text-red");
    }

    const handleChangeEquipment = (e: any, props: any, saveIconRef: any) => {
        let equipmentId = Number(e.target.value);
        let newEquipment = equipments.find(e => e.id === equipmentId);
        if (newEquipment === undefined) {
            return;
        }

        props.node.setData({ ...props.data, equipment: newEquipment });
        setOrdersInUpdate([...ordersInUpdate.filter(x => x !== props.data.id), props.data.id]);
        document.getElementById(saveIconRef)?.classList?.add("text-red");
    }

    const handleSaveLinearMetersEquipment = async (orderId: number, saveIconRef: any) => {
        let tableRows = commonTableRef.current?.getTableRows();
        if (!!!tableRows) {
            return;
        }

        let order: IOrder | undefined = tableRows.find(x => x.id === orderId);
        if (!!!order) {
            return;
        }

        if (order.linearMeters === null || order.linearMeters === undefined) {
            toast.info("Please insert linear meters value");
            return;
        }

        const response = await ordersService.updateOrder(orderId, { linearMeters: Number(order.linearMeters), customEquipmentId: order.equipment.id }, "Updating order data...", "Order data updated successfully", "Error updating order data");
        if (response.success) {
            setOrdersInUpdate([...ordersInUpdate.filter(x => x !== orderId)]);
            document.getElementById(saveIconRef)?.classList?.remove("text-red");
        }
    };

    const handleClickOrdersAlertRefreshData = () => {
        setNewOrdersAlertInfo(prevState => ({
            ...prevState,
            show: false
        }) as NewOrdersAlertInfo);
        document.title = "UOM - Arcese";

        refreshOrders();
    };

    const checkAll = (checked: boolean) => {
        if (checked) {
            let tableRows = commonTableRef.current?.getTableRows();
            let allTableRowsOrderIds: number[] | undefined = tableRows?.map(x => x.id);
            setSelectedRows(!!allTableRowsOrderIds ? allTableRowsOrderIds : []);
        } else {
            setSelectedRows([]);
        }
        setSelectedAction('');
    }

    const handleCheckRow = (checked: boolean, props: any) => {
        if (checked) {
            setSelectedRows([...selectedRows, props.data.id]);
        } else {
            setSelectedRows(selectedRows.filter((x: any) => x !== props.data.id));
        }
        setSelectedAction('');
    }

    enum MultipleSelection {
        FIRST_LEGS = 1,
        LAST_LEGS = 2,
        COLLECTION_LEGS = 3,
        DELIVERY_LEGS = 4,
        DIRECT_LEGS = 5,
        UNPLANNED_LEGS = 6,
        FIRST_DEPOTS = 7,
        LAST_DEPOTS = 8,
        UNPLANNED_DEPOTS = 9
    };

    const executeMultipleSelection = (selectionType: MultipleSelection) => {
        let allLegs = getAllLegs();
        let legsToSelect: number[] = [];
        let depositsToSelect: number[] = [];

        if (selectionType === MultipleSelection.FIRST_LEGS) {
            allLegs.forEach(leg => {
                if (leg.orderPosition === 0) {
                    if (isLegSelectable(leg)) {
                        legsToSelect.push(leg.id);
                    }
                }
            });
        } else if (selectionType === MultipleSelection.LAST_LEGS) {
            allLegs.forEach(leg => {
                let orderLegs = allLegs.filter(x => x.orderId === leg.orderId)
                let orderPositions = orderLegs?.map(l => l.orderPosition);
                if (orderPositions !== undefined) {
                    let maxOrderPosition = Math.max(...orderPositions);
                    if (leg.orderPosition === maxOrderPosition) {
                        if (isLegSelectable(leg)) {
                            legsToSelect.push(leg.id);
                        }
                    }
                }
            });
        } else if (selectionType === MultipleSelection.COLLECTION_LEGS) {
            allLegs.forEach(leg => {
                if (leg.type.id === LegTypesEnum.Collection) {
                    if (isLegSelectable(leg)) {
                        legsToSelect.push(leg.id);
                    }
                }
            });
        } else if (selectionType === MultipleSelection.DELIVERY_LEGS) {
            allLegs.forEach(leg => {
                if (leg.type.id === LegTypesEnum.Delivery) {
                    if (isLegSelectable(leg)) {
                        legsToSelect.push(leg.id);
                    }
                }
            });
        } else if (selectionType === MultipleSelection.DIRECT_LEGS) {
            allLegs.forEach(leg => {
                if (leg.type.id === LegTypesEnum.Direct) {
                    if (isLegSelectable(leg)) {
                        legsToSelect.push(leg.id);
                    }
                }
            });
        } else if (selectionType === MultipleSelection.UNPLANNED_LEGS) {
            allLegs.forEach(leg => {
                if (isLegSelectable(leg)) {
                    legsToSelect.push(leg.id);
                }
            });
        } else if (selectionType === MultipleSelection.FIRST_DEPOTS) {
            allLegs.forEach(leg => {
                let orderLegs = allLegs.filter(x => x.orderId === leg.orderId);
                let legWithDepot = orderLegs.filter(x => x.origin.type === AddressTypesEnum.Deposit);
                if (legWithDepot.length > 0 && legWithDepot.map(x => x.id).includes(leg.id)) {
                    let orderPositions = legWithDepot.map(x => x.orderPosition);
                    let minOrderPosition = Math.min(...orderPositions);
                    if (leg.orderPosition === minOrderPosition) {
                        let order: IOrder = commonTableRef.current?.getTableRows().find((x: IOrder) => x.legs.some(l => l.id === leg.id));
                        if (isDepositSelectable(leg, order, planningGroupsIds).result) {
                            depositsToSelect.push(leg.id);
                        }
                    }
                }
            });
        } else if (selectionType === MultipleSelection.LAST_DEPOTS) {
            allLegs.forEach(leg => {
                let orderLegs = allLegs.filter(x => x.orderId === leg.orderId);
                let legWithDepot = orderLegs.filter(x => x.origin.type === AddressTypesEnum.Deposit);
                if (legWithDepot.length > 0 && legWithDepot.map(x => x.id).includes(leg.id)) {
                    let orderPositions = legWithDepot.map(x => x.orderPosition);
                    let minOrderPosition = Math.max(...orderPositions);
                    if (leg.orderPosition === minOrderPosition) {
                        let order: IOrder = commonTableRef.current?.getTableRows().find((x: IOrder) => x.legs.some(l => l.id === leg.id));
                        if (isDepositSelectable(leg, order, planningGroupsIds).result) {
                            depositsToSelect.push(leg.id);
                        }
                    }
                }
            });
        } else if (selectionType === MultipleSelection.UNPLANNED_DEPOTS) {
            let legWithDepot = allLegs.filter(x => x.origin.type === AddressTypesEnum.Deposit);
            legWithDepot.forEach(leg => {
                let order: IOrder = commonTableRef.current?.getTableRows().find((x: IOrder) => x.legs.some(l => l.id === leg.id));
                if (isDepositSelectable(leg, order, planningGroupsIds).result) {
                    depositsToSelect.push(leg.id);
                }
            });
        }

        selectLegsOrDeposits(legsToSelect, depositsToSelect);
    };

    const getAllLegs = () => {
        let allLegs: Leg[] = [];
        let tableRows: IOrder[] | undefined = commonTableRef.current?.getTableRows();
        if (tableRows === undefined) {
            return allLegs;
        }
        let allLegsTemp = tableRows.map(x => x.legs).flat(1);
        allLegsTemp.forEach(leg => {
            allLegs.push(leg);
        });

        return allLegs;
    };

    const isLegSelectable = (leg: Leg) => {
        return !isDisabledLeg(leg, planningGroupsIds);
    };

    const getSelectableLegs = (legs: Leg[]) => {
        let result: Leg[] = [];
        legs.forEach(leg => {
            if (isLegSelectable(leg)) {
                result.push(leg);
            }
        });

        return result;
    };

    const selectLegsOrDeposits = async (legsToSelect: number[], depositsToSelect: number[]) => {
        if (legsToSelect.length > 0) {
            if (selectedDeposits.length > 0) {
                let wantToContinue = await customConfirmAlert({
                    title: "Confirm action",
                    message: "There are selected deposits, do you want to select legs?"
                })

                if (!wantToContinue) {
                    return;
                }
            }

            setSelectedLegs(legsToSelect);
            setSelectedDeposits([]);
        }

        if (depositsToSelect.length > 0) {
            if (selectedLegs.length > 0) {
                let wantToContinue = await customConfirmAlert({
                    title: "Confirm action",
                    message: "There are selected legs, do you want to select deposits?"
                })

                if (!wantToContinue) {
                    return;
                }
            }

            setSelectedDeposits(depositsToSelect);
            setSelectedLegs([]);
        }

        if (legsToSelect.length === 0 && depositsToSelect.length === 0) {
            setSelectedDeposits([]);
            setSelectedLegs([]);
        }
    };

    useEffect(() => {
        adjustTableScrollbar();

        if (isOpenSidebarTrips) {
            resetSelection()
            checkAll(false);
        }
    }, [isOpenSidebarTrips]);

    const handleSelectLegOrDeposit = async (type: "leg" | "deposit", legId: number) => {
        let confirmMessage = "";
        if (type === "leg" && selectedDeposits.length > 0) {
            confirmMessage = "There are selected deposits, do you want to select legs?";
        } else if (type === "deposit" && selectedLegs.length > 0) {
            confirmMessage = "There are selected legs, do you want to select deposits?";

        }

        let wantToContinue = true;
        if (confirmMessage !== "") {
            wantToContinue = await customConfirmAlert({
                title: "Confirm action",
                message: confirmMessage
            });
        }

        if (wantToContinue) {
            if (type === "leg") {
                if (selectedLegs.includes(legId)) {
                    setSelectedLegs([...selectedLegs.filter(x => x !== legId)]);
                } else {
                    setSelectedLegs([...selectedLegs, legId]);
                    setSelectedDeposits([]);
                }
            } else if (type === "deposit") {
                if (selectedDeposits.includes(legId)) {
                    setSelectedDeposits([...selectedDeposits.filter(x => x !== legId)])
                } else {
                    setSelectedDeposits([...selectedDeposits, legId]);
                    setSelectedLegs([]);
                }
            }
        }
    };

    const [activeAggregationId, setActiveAggregationId] = useState<null | number>(null);
    const dataForGroupedRows = useMemo(() => {
        if (activeAggregationId !== null) {
            let tableRows: IOrder[] | undefined = commonTableRef.current?.getTableRows();
            const tableRow: IOrder | undefined = tableRows?.find(x => x.id === activeAggregationId);
            return !!tableRow ? tableRow : null;
        } else {
            return null;
        }
    }, [activeAggregationId]);

    const [planningRulesOptions, setPlanningRulesOptions] = useState<{ legNumber: number, businessFlowTypeId: number } | null>(null);
    const handleTogglePlanningRulesModal = (legsNumber: number = 0, businessFlowTypeId: number = 0) => {
        if (legsNumber !== 0) {
            setPlanningRulesOptions({ legNumber: legsNumber, businessFlowTypeId: businessFlowTypeId });
        } else {
            setPlanningRulesOptions(null);
        }
    };

    const removeLegFromTrip = async (leg: Leg) => {
        if (leg.trip === null) {
            return;
        }

        let wantToRemove = await customConfirmAlert({ title: "Remove Leg", message: "Are you sure to remove leg from Trip?" });
        if (wantToRemove) {
            resetSelection();

            const toastId = toast.loading("Removing leg...");

            var response = await tripsService.removeTripLegs(leg.trip!.id, { legsIds: [leg.id], force: false });

            let isLegDeleted = false;
            let isLastLeg = false;

            if (response.success) {
                toast.update(toastId, { type: "success", isLoading: false, render: `Leg removed successfully!`, autoClose: 3000 });
                isLegDeleted = true;
            } else if (response.data.code === errorConstants.CONFIRMED_TRIP) {
                toast.update(toastId, { type: "warning", isLoading: false, render: `Trip is confirmed. Operation not allowed`, autoClose: 3000 });
            } else if (response.data.code === errorConstants.LAST_LEG_EXCEPTION) {
                isLastLeg = true;
                const wantToContinue = await customConfirmAlert({ title: "Remove empty Trip", message: "This is the last leg of the trip. Do you want to remove the empty trip?" });
                if (wantToContinue) {
                    response = await tripsService.removeTripLegs(leg.trip!.id, { legsIds: [leg.id], force: true });
                    if (response.success) {
                        toast.update(toastId, { type: "success", isLoading: false, render: `Leg removed successfully!`, autoClose: 3000 });
                        isLegDeleted = true;
                    }
                } else {
                    toast.dismiss(toastId);
                }
            } else {
                toast.update(toastId, { type: "error", isLoading: false, render: `Error removing leg.`, autoClose: 3000 });
            }

            if (isLegDeleted) {
                if (activeTripId !== null) {
                    if (isLastLeg) {
                        setActiveTripId(null);
                    } else {
                        refreshOrders();
                    }
                } else {
                    let nodesToUpdate: RowNode[] | undefined = commonTableRef.current?.getTableNodes()?.filter(x => x.data.id === leg.orderId);
                    if (!!nodesToUpdate) {
                        updateOrdersData(nodesToUpdate);
                    }
                }

                updateTripWithCurrentParamsAndPagination();
            }
        }
    };

    const handleUpdateOrderLegs = (orderId: number) => {
        let tableNodes: RowNode[] | undefined = commonTableRef.current?.getTableNodes();
        if (tableNodes === undefined) {
            return;
        }
        let nodeToUpdate = tableNodes?.find(x => x.data.id === orderId);
        if (nodeToUpdate !== undefined) {
            updateOrdersData([nodeToUpdate]);
        }
    };

    const [planningFilterState, setPlanningFilterState] = useState<PlanningFilterEnum>(3);
    const initPlanningLegsFilter = () => {
        // Init Planning (legs) filter to "Not planned"
        let agGridRef = commonTableRef.current?.getAgGridRef();
        if (!!agGridRef) {
            agGridRef.api.setFilterModel({
                'legs': {
                    filter: FilterRadio
                }
            });
            agGridRef.api.onFilterChanged();
        }
    }

    const columnDefs: any = useMemo(() => [
        {
            field: 'checkbox',
            maxWidth: 30,
            filter: false,
            hide: !canEdit,
            cellClass: 'checkbox-col',
            headerComponent: () => {
                const isCheckedAll = () => {
                    let agGridRef = commonTableRef.current?.getAgGridRef();
                    if (!!agGridRef) {
                        return selectedRows.length === ordersNumber && selectedRows.length > 0;
                    }
                }

                return (
                    <div className="relative z-10 -translate-x-1/2 text-[12px] flex h-full items-center justify-center">
                        <input disabled={isOpenSidebarTrips} onChange={(e) => checkAll(e.target.checked)} checked={isCheckedAll()} type="checkbox" name="" id="" />
                    </div>
                )
            },
            cellRenderer: (props: { data: IOrder, value: any }) => {
                return (
                    <>
                        {
                            !isArray(props.value) &&
                            <div className="relative z-10 text-[12px] flex h-full items-center justify-center">
                                {/* @ts-ignore */}
                                <input
                                    disabled={isOpenSidebarTrips}
                                    checked={selectedRows.includes(props.data.id)}
                                    type="checkbox"
                                    name=""
                                    id=""
                                    onChange={(e: any) => handleCheckRow(e.target.checked, props)}
                                />
                            </div>
                        }
                    </>
                )
            }
        },
        {
            field: 'orderNumbers',
            sortable: false,
            filter: 'agTextColumnFilter',
            filterParams: gridTextFilterParamsSSR,
            floatingFilter: true,
            headerName: "ORDER N°",
            minWidth: 200,
            cellClass: "agCellAlignCenter",
            tooltipComponent: (e: { data: IOrder }) => <TableTooltipOrderNumbers order={e.data} />,
            tooltipField: "orderNumbers",
            cellRenderer: ({ data }: { data: IOrder }) => {
                let isNewOrder = !!data.orderNumbers && data.orderNumbers.length > 0 && newOrdersAlertInfo?.orderIds?.includes(data.id);
                return (
                    <>
                        <div className="text-[12px] flex h-full w-full items-center">
                            <div className="flex items-center mt-1 overflow-hidden text-ellipsis text-nowrap">
                                {
                                    data.dropped === true &&
                                    <div title="dropped" className="mr-3">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M3.98 8.223A10.477 10.477 0 0 0 1.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.451 10.451 0 0 1 12 4.5c4.756 0 8.773 3.162 10.065 7.498a10.522 10.522 0 0 1-4.293 5.774M6.228 6.228 3 3m3.228 3.228 3.65 3.65m7.894 7.894L21 21m-3.228-3.228-3.65-3.65m0 0a3 3 0 1 0-4.243-4.243m4.242 4.242L9.88 9.88" />
                                        </svg>
                                    </div>
                                }

                                <div className="overflow-hidden text-ellipsis text-nowrap">
                                    {
                                        data.orderNumbers?.map((n, j) => {
                                            if (j < 3) {
                                                return (
                                                    <div className="overflow-hidden text-ellipsis text-nowrap leading-[12px]" key={j}>
                                                        {n}
                                                    </div>
                                                )
                                            }
                                        })
                                    }

                                    {
                                        !!data.orderNumbers && data.orderNumbers.length > 3 &&
                                        <div style={{ lineHeight: '12px' }} className="text-blue cursor-pointer">
                                            e altri {data.orderNumbers.length - 3}
                                        </div>
                                    }
                                </div>
                            </div>

                            {
                                data.isGroup === true &&
                                <div className="ml-2 shrink-0">
                                    <div onClick={() => setActiveAggregationId(data.id)} className="h-[16px] w-[16px] bg-blue flex items-center justify-center cursor-pointer rounded-full">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="#FFFFFF" className="w-5 h-5">
                                            <path d="M10.75 6.75a.75.75 0 00-1.5 0v2.5h-2.5a.75.75 0 000 1.5h2.5v2.5a.75.75 0 001.5 0v-2.5h2.5a.75.75 0 000-1.5h-2.5v-2.5z" />
                                        </svg>
                                    </div>
                                </div>
                            }

                            {
                                isNewOrder &&
                                <div className="shrink-0 ml-2 border-orange border-[3px] rounded-[5px] flex items-center justify-center px-3 py-2">
                                    <p className="font-bold !mb-0 h-full leading-2 text-xs">NEW</p>
                                </div>
                            }
                        </div>
                    </>
                )
            }
        },
        {
            field: 'origin',
            cellClass: "agCellAlignCenter",
            sortable: true,
            filter: 'agTextColumnFilter',
            filterParams: originDestinationGridTextFilterParamsSSR,
            floatingFilter: true,
            tooltipField: 'originPartner',
            tooltipComponent: (e: any) => !!e.data.originPartner ? <TableTooltipPartner destination={e.data.origin} type="origin" /> : <div></div>,
            minWidth: 330,
            wrapText: true,
            headerName: "ORIGIN",
            cellRenderer: ({ data }: { data: IOrder }) => <OriginDestinationTableComponent type="origin" data={data} />
        },
        {
            field: 'destination',
            cellClass: "agCellAlignCenter",
            sortable: true,
            filter: 'agTextColumnFilter',
            filterParams: originDestinationGridTextFilterParamsSSR,
            floatingFilter: true,
            tooltipField: 'destinationPartner',
            tooltipComponent: (e: any) => !!e.data.destinationPartner ? <TableTooltipPartner destination={e.data.destination} type="destination" /> : <div></div>,
            minWidth: 330,
            wrapText: true,
            headerName: "DESTINATION",
            cellRenderer: ({ data }: { data: IOrder }) => <OriginDestinationTableComponent type="destination" data={data} />
        },
        {
            field: 'consigneeDock',
            sortable: true,
            filter: 'agTextColumnFilter',
            filterParams: gridTextFilterParamsSSR,
            floatingFilter: true,
            headerName: "DOCK",
            minWidth: 110,
            cellRenderer: ({ data }: { data: IOrder }) => {
                return (
                    <div className="text-[12px] flex h-full items-center">
                        {!!data.consigneeDock ? data.consigneeDock : '-'}
                    </div>
                )
            },
        },
        {
            field: 'legs',
            minWidth: 700,
            sortable: false,
            filter: FilterRadio,
            floatingFilter: true,
            headerClass: 'planning-column-header',
            headerName: "PLANNING",
            cellRenderer: (props: { data: IOrder }) => {
                const orderData: IOrder | undefined = !!commonTableRef.current?.getTableRows() ? commonTableRef.current.getTableRows().find(o => props.data.id === o.id) : undefined;
                return (
                    <>
                        {
                            !!orderData &&
                            <div className="text-[12px] flex h-full items-center">
                                <Planning
                                    isTriagePage={false}
                                    callbackOnReady={() => { }}
                                    order={orderData}
                                    callbackRestoreRoute={() => handleRestoreRoute(props)}
                                    callbackSelectedLegOrDeposit={(type, legId) => setLegsOrDepotsToSelect({legId: legId, type: type})}
                                    groupsInCharge={planningGroupsIds}
                                    callbackOnContextMenu={handleContextMenuPlanning}
                                    legContextMenuRef={legContextMenuRef}
                                    selectedLegs={props.data.selectedLegs}
                                    selectedDeposits={props.data.selectedDepots}
                                    callbackUpdateOrderLegs={handleUpdateOrderLegs}
                                    canEdit={canEdit}
                                    callbackRemoveDeposit={handleRemoveSingleDeposit}
                                    callbackRemoveLegFromTrip={removeLegFromTrip}
                                />

                                {
                                    <div onClick={() => setActiveTooltipSuggestedPlanningOrderId(props.data.id)} className="ml-2 cursor-pointer">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                                            <path d="M10 8a3 3 0 100-6 3 3 0 000 6zM3.465 14.493a1.23 1.23 0 00.41 1.412A9.957 9.957 0 0010 18c2.31 0 4.438-.784 6.131-2.1.43-.333.604-.903.408-1.41a7.002 7.002 0 00-13.074.003z" />
                                        </svg>
                                    </div>
                                }

                                {
                                    !updateRoutingErrorOrderIds.includes(props.data?.id) &&
                                    <div className="ml-2 cursor-pointer" onClick={() => handleTogglePlanningRulesModal(props.data.legs.length, props.data.businessFlowType?.id)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-[20px] h-[20px]">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z" />
                                        </svg>
                                    </div>
                                }

                                {
                                    updateRoutingErrorOrderIds.includes(props.data?.id) &&
                                    <div className="ml-2 cursor-pointer">
                                        <svg xmlns="http://www.w3.org/2000/svg" onClick={() => handleTogglePlanningRulesModal(props.data.legs.length, props.data.businessFlowType?.id)} fill="none" viewBox="0 0 24 24" strokeWidth={1.5} className="stroke-red w-[20px] h-[20px]">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M18.364 18.364A9 9 0 0 0 5.636 5.636m12.728 12.728A9 9 0 0 1 5.636 5.636m12.728 12.728L5.636 5.636" />
                                        </svg>
                                    </div>
                                }

                                {
                                    orderData.legs.some(leg => leg.status?.id === LegStatusEnum.ERROR) &&
                                    <div onClick={() => setActiveModalOrderLegsErrorsOrderId(orderData?.id)} className="ml-2 cursor-pointer">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 16 16"><g fill="#cf0a2c"><path d="M15.964.686a.5.5 0 0 0-.65-.65L.767 5.855a.75.75 0 0 0-.124 1.329l4.995 3.178l1.531 2.406a.5.5 0 0 0 .844-.536L6.637 10.07l7.494-7.494l-1.895 4.738a.5.5 0 1 0 .928.372zm-2.54 1.183L5.93 9.363L1.591 6.602z" /><path d="M12.5 16a3.5 3.5 0 1 0 0-7a3.5 3.5 0 0 0 0 7m.5-5v1.5a.5.5 0 0 1-1 0V11a.5.5 0 0 1 1 0m0 3a.5.5 0 1 1-1 0a.5.5 0 0 1 1 0" /></g></svg>
                                    </div>
                                }
                            </div>
                        }
                        {
                            props.data.legs.length === 0 &&
                            <div className="text-[12px] flex h-full items-center justify-end font-bold">
                                SUM
                            </div>
                        }
                    </>
                )
            }
        },
        {
            field: 'volume',
            sortable: true,
            // filter: 'agNumberColumnFilter',
            // filterParams: gridNumberFilterParams,
            floatingFilter: false,
            headerName: "VOLUME",
            minWidth: 110,
            cellRenderer: ({ data }: { data: IOrder }) => {
                return (
                    <div className="text-[12px] flex h-full items-center">
                        {data.volume}
                    </div>
                )
            },
        },
        {
            field: 'weight',
            sortable: true,
            // filter: 'agNumberColumnFilter',
            // filterParams: gridNumberFilterParams,
            floatingFilter: false,
            headerName: "WEIGHT",
            minWidth: 110,
            cellRenderer: ({ data }: { data: IOrder }) => {
                return (
                    <div className="text-[12px] flex h-full items-center">
                        {data.weight}
                    </div>
                )
            }
        },
        {
            field: 'taxWeight',
            sortable: true,
            // filter: 'agNumberColumnFilter',
            // filterParams: gridNumberFilterParams,
            floatingFilter: false,
            headerName: "TAX-WEIGHT",
            minWidth: 110,
            cellRenderer: ({ data }: { data: IOrder }) => {
                return (
                    <div className="text-[12px] flex h-full items-center">
                        {data.taxWeight}
                    </div>
                )
            }
        },
        {
            field: 'linearMeters',
            sortable: true,
            // filter: 'agNumberColumnFilter',
            // filterParams: gridNumberFilterParams,
            floatingFilter: false,
            headerName: "LINEAR METERS",
            minWidth: 180,
            cellRenderer: (props: { data: IOrder, node: any }) => {
                const initialVal = (props.data.linearMeters !== null && props.data.linearMeters !== undefined) ? props.data.linearMeters : "";
                let value = initialVal;
                const handleChange = (e: any, saveIconRef: any) => {
                    if (!!e && ((!isNaN(parseFloat(e.target.value)) && parseFloat(e.target.value) <= 40) || e.target.value === "")) {
                        if (!!!props.data.legs.find((l: any) => l.trip !== null)) {
                            value = e.target.value;
                            changeLinearMeters(e, props, saveIconRef);
                        }
                    }
                }

                let sumLinearMeters = 0;
                if ((props.data.legs.length === 0) && !!commonTableRef.current) {
                    const allRows: IOrder[] = commonTableRef.current.getTableRows();
                    if (allRows && allRows.length > 0) {
                        for (let index = 0; index < allRows.length; index++) {
                            const element = allRows[index];

                            if (!!element.linearMeters) {
                                sumLinearMeters += Number(element.linearMeters);
                            }
                        }
                    }
                }

                let saveIconRef = `saveIcon-${props.data.id}`;

                const isDroppedOrder = !!props.data.dropped;
                const hasAtLeastOneLegPlannedOrStandByOrNetworkOrPartner = !!props.data.legs?.find((l: Leg) => (l.trip !== null || l.planningType.id === LegPlanningTypesEnum.StandBy || l.planningType.id === LegPlanningTypesEnum.Network || (l.origin.type === "Partner" || l.destination.type === "Partner")));
                const hasAtLeastOneLegSelected = !!props.data.legs.some(l => selectedLegs.includes(l.id));

                const canSave = !isDroppedOrder && !(hasAtLeastOneLegPlannedOrStandByOrNetworkOrPartner || !canEdit || hasAtLeastOneLegSelected);
                return (
                    <div className="text-[12px] flex h-full items-center">
                        {
                            (props.data.legs.length > 0) &&
                            <div className="flex items-center">
                                <input
                                    onChange={(e) => handleChange(e, saveIconRef)}
                                    className="disabled:opacity-50 disabled:cursor-not-allowed text-center border border-[1.4px] border-darkGrey rounded-[4px] w-[50px] h-[30px] p-2 "
                                    type="number"
                                    name="linearMeters"
                                    value={value}
                                    disabled={(hasAtLeastOneLegSelected || hasAtLeastOneLegPlannedOrStandByOrNetworkOrPartner || !canEdit || isDroppedOrder)}
                                    max={40}
                                />

                                <div className="ml-2 flex items-center h-full">
                                    <Select
                                        className="h-[30px] p-0 "
                                        callbackOnChange={(e) => handleChangeEquipment(e, props, saveIconRef)}
                                        value={props.data.equipment.id}
                                        options={equipmentOptions}
                                        disabled={hasAtLeastOneLegSelected || (hasAtLeastOneLegPlannedOrStandByOrNetworkOrPartner || !canEdit || isDroppedOrder)}
                                    />
                                </div>

                                {canSave &&
                                    <div className="ml-2 mt-3">
                                        <button className={canSave ? "cursor-pointer" : "cursor-default"}>
                                            <svg
                                                id={saveIconRef}
                                                onClick={() => {
                                                    if (canSave) {
                                                        handleSaveLinearMetersEquipment(props.data.id, saveIconRef);
                                                    }
                                                }}
                                                xmlns="http://www.w3.org/2000/svg" width="1.6em" height="1.6em" viewBox="0 0 24 24"><path fill="none" stroke="currentColor" strokeDasharray="24" strokeDashoffset="24" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 11L11 17L21 7"><animate fill="freeze" attributeName="stroke-dashoffset" dur="0.4s" values="24;0" /></path>
                                            </svg>
                                        </button>
                                    </div>
                                }
                            </div>
                        }
                        {
                            (props.data.legs.length === 0) &&
                            <>
                                {sumLinearMeters}
                            </>
                        }
                    </div>
                )
            }
        },
        {
            field: 'shipmentUnitsNumber',
            sortable: true,
            cellStyle: { overflow: 'visible' },
            minWidth: 110,
            // filter: 'agNumberColumnFilter',
            // filterParams: gridNumberFilterParams,
            floatingFilter: false,
            headerName: "SHIPPING UNIT",
            cellRenderer: ({ data }: { data: IOrder }) => {
                return (
                    <div
                        onClick={() => {
                            setActiveOrderShipmentUnits({
                                orderId: data.id,
                                orderNumber: !!data.orderNumbers ? data.orderNumbers[0] : ''
                            });
                        }}
                        className="overflow-visible h-full flex items-center justify-start z-50 relative"
                    >
                        <div className="relative z-50">
                            <div className={"text-[12px] cursor-pointer" + (data.legs.length > 0 ? " text-underlined" : "")}>
                                {data.shipmentUnitsNumber}
                            </div>
                        </div>
                    </div>
                );
            }
        },
        {
            field: 'earlyPickup',
            sortable: true,
            filter: 'agDateColumnFilter',
            floatingFilter: true,
            filterParams: gridDateFilterParamsSSR,
            minWidth: 180,
            headerName: "EARLY PICKUP",
            cellRenderer: ({ data }: { data: IOrder }) => {
                return (
                    <>
                        {!!data.earlyPickup &&
                            <div className="text-[12px] flex h-full items-center">
                                {dayjs(data.earlyPickup).format('DD/MM/YYYY - HH:mm')}
                            </div>
                        }

                        {!!!data.earlyPickup &&
                            <div className="text-[12px] flex h-full items-center">-</div>
                        }
                    </>
                )
            }
        },
        {
            field: 'latePickup',
            sortable: true,
            filter: 'agDateColumnFilter',
            floatingFilter: true,
            filterParams: gridDateFilterParamsSSR,
            minWidth: 180,
            headerName: "LATE PICKUP",
            cellRenderer: ({ data }: { data: IOrder }) => {
                return (
                    <>
                        {!!data.latePickup &&
                            <div className="text-[12px] flex h-full items-center">
                                {dayjs(data.latePickup).format('DD/MM/YYYY - HH:mm')}
                            </div>
                        }

                        {!!!data.latePickup &&
                            <div className="text-[12px] flex h-full items-center">-</div>
                        }
                    </>
                )
            }
        },
        {
            field: 'earlyDelivery',
            sortable: true,
            filter: 'agDateColumnFilter',
            floatingFilter: true,
            filterParams: gridDateFilterParamsSSR,
            minWidth: 180,
            headerName: "EARLY DELIVERY",
            cellRenderer: ({ data }: { data: IOrder }) => {
                return (
                    <>
                        {!!data.earlyDelivery &&
                            <div className="text-[12px] flex h-full items-center">
                                {dayjs(data.earlyDelivery).format('DD/MM/YYYY - HH:mm')}
                            </div>
                        }

                        {!!!data.earlyDelivery &&
                            <div className="text-[12px] flex h-full items-center">-</div>
                        }
                    </>
                )
            }
        },
        {
            field: 'lateDelivery',
            sortable: true,
            filter: 'agDateColumnFilter',
            floatingFilter: true,
            filterParams: gridDateFilterParamsSSR,
            minWidth: 180,
            headerName: "LATE DELIVERY",
            cellRenderer: ({ data }: { data: IOrder }) => {
                return (
                    <>
                        {!!data.lateDelivery &&
                            <div className="text-[12px] flex h-full items-center">
                                {dayjs(data.lateDelivery).format('DD/MM/YYYY - HH:mm')}
                            </div>
                        }

                        {!!!data.lateDelivery &&
                            <div className="text-[12px] flex h-full items-center">-</div>
                        }
                    </>
                )
            }
        },
        {
            field: 'customerCollectionID',
            sortable: true,
            filter: 'agTextColumnFilter',
            filterParams: gridTextFilterParamsSSR,
            floatingFilter: true,
            headerName: "CUSTOMER COLLECTION ID",
            tooltipField: "customerCollectionID",
            tooltipComponent: (e: { data: IOrder }) => !!e.data.customerCollectionID && e.data.customerCollectionID.length > 0 ? <TableTooltipCustomerIds customerIds={e.data.customerCollectionID} /> : <></>,
            cellRenderer: (props: { data: IOrder }) => {
                return (
                    <div className="text-[12px] flex h-full items-center justify-between">
                        <div className="flex items-center mt-1">
                            {
                                (!!props.data.customerCollectionID && props.data.customerCollectionID.length > 0) ?
                                    (
                                        <div>
                                            {
                                                props.data.customerCollectionID?.map((n, j) => {
                                                    if (j < 3) {
                                                        return (
                                                            <div style={{ lineHeight: '12px' }} key={j}>
                                                                {n}
                                                            </div>
                                                        )
                                                    }
                                                })
                                            }

                                            {
                                                !!props.data.customerCollectionID && props.data.customerCollectionID.length > 3 &&
                                                <div style={{ lineHeight: '12px' }} className="text-blue cursor-pointer">
                                                    and {props.data.customerCollectionID.length - 3} more
                                                </div>
                                            }
                                        </div>
                                    )
                                    :
                                    (
                                        <div>-</div>
                                    )
                            }
                        </div>
                    </div>
                )
            }
        },
        {
            field: 'customerLinehaulID',
            sortable: true,
            filter: 'agTextColumnFilter',
            filterParams: gridTextFilterParamsSSR,
            floatingFilter: true,
            headerName: "CUSTOMER LINEHAUL ID",
            tooltipComponent: (e: { data: IOrder }) => !!e.data.customerLinehaulID && e.data.customerLinehaulID.length > 0 ? <TableTooltipCustomerIds customerIds={e.data.customerLinehaulID} /> : <></>,
            tooltipField: "customerLinehaulID",
            cellRenderer: (props: { data: IOrder }) => {
                return (
                    <div className="text-[12px] flex h-full items-center justify-between">
                        <div className="flex items-center mt-1">
                            {
                                (!!props.data.customerLinehaulID && props.data.customerLinehaulID.length > 0) ?
                                    (
                                        <div>
                                            {
                                                props.data.customerLinehaulID?.map((n, j) => {
                                                    if (j < 3) {
                                                        return (
                                                            <div style={{ lineHeight: '12px' }} key={j}>
                                                                {n}
                                                            </div>
                                                        )
                                                    }
                                                })
                                            }

                                            {
                                                !!props.data.customerLinehaulID && props.data.customerLinehaulID.length > 3 &&
                                                <div style={{ lineHeight: '12px' }} className="text-blue cursor-pointer">
                                                    and {props.data.customerLinehaulID.length - 3} more
                                                </div>
                                            }
                                        </div>
                                    )
                                    :
                                    (
                                        <div>-</div>
                                    )
                            }
                        </div>
                    </div>
                )
            }
        },
    ], [ordersNumber, selectedRows, updateRoutingErrorOrderIds, isOpenSidebarTrips]);

    const [openModalDeliverToPartner, setOpenModalDeliverToPartner] = useState(false);
    const [selectedPartnerToDeliver, setSelectedPartnerToDeliver] = useState<null | IPartner>(null);

    const [legPlanningPartnerIsCollection, setLegPlanningPartnerIsCollection] = useState<boolean>(false);

    const restoreMultipleActionSelection = () => {
        setSelectedAction('');
        setSelectedRows([]);
    }

    const applyAction = async () => {
        if (selectedAction !== "") {
            let selectedNodes = getTableSelectedNodes();
            if (selectedNodes.length === 0) {
                toast.info("Select one or more orders");
                return;
            }

            switch (selectedAction) {
                case MultipleActionsEnum.SeparateOrders.toString():
                    await separateMultipleOrders();
                    restoreMultipleActionSelection();
                    break;
                case MultipleActionsEnum.RestoreOrdersRoute.toString():
                    await restoreMultipleOrdersRoute();
                    restoreMultipleActionSelection();
                    break;
                case MultipleActionsEnum.SetOrdersAsStandBy.toString():
                    await updateMultipleLegPlanning(LegPlanningTypesEnum.StandBy);
                    restoreMultipleActionSelection();
                    break;
                case MultipleActionsEnum.DeliverToPartnerCollection.toString():
                    setLegPlanningPartnerIsCollection(true);
                    setOpenModalDeliverToPartner(true);
                    break;
                case MultipleActionsEnum.DeliverToPartnerDelivery.toString():
                    setLegPlanningPartnerIsCollection(false);
                    setOpenModalDeliverToPartner(true);
                    break;
                case MultipleActionsEnum.SetSelectedOrdersLegsAsDispatchable.toString():
                    await handleSetSelectedOrdersLegsAsDispatchable();
                    restoreMultipleActionSelection();
                    break;
                case MultipleActionsEnum.HideOrdersFromPlanning.toString():
                    await hideOrdersFromPlanning();
                    restoreMultipleActionSelection();
                    break;
                case MultipleActionsEnum.IncludeOrdersInPlanning.toString():
                    await includeOrdersInPlanning();
                    restoreMultipleActionSelection();
                    break;
            }
        }
    }

    const handleChangePlanningGroups = (groups: IGroup[]) => {
        setPlanningGroups(groups);
    }

    const handleExitFromPlanning = async () => {
        const wantToLeave = await customConfirmAlert({ title: "Exit", message: "Are you sure you want to leave?" });
        if (wantToLeave) {
            navigate(planningToolRouteUrls.groupsCharge.extended);
        }
    };

    const multipleSelectionDropdownRef = useRef<any>(null);

    const handleMultipleSelection = (value: number) => {
        switch (value) {
            case 0:
                resetSelection()
                break;
            case 1:
                executeMultipleSelection(MultipleSelection.FIRST_LEGS);
                break;
            case 2:
                executeMultipleSelection(MultipleSelection.LAST_LEGS);
                break;
            case 3:
                executeMultipleSelection(MultipleSelection.COLLECTION_LEGS);
                break;
            case 4:
                executeMultipleSelection(MultipleSelection.DELIVERY_LEGS);
                break;
            case 5:
                executeMultipleSelection(MultipleSelection.DIRECT_LEGS);
                break;
            case 6:
                executeMultipleSelection(MultipleSelection.UNPLANNED_LEGS);
                break;
            case 7:
                executeMultipleSelection(MultipleSelection.FIRST_DEPOTS);
                break;
            case 8:
                executeMultipleSelection(MultipleSelection.LAST_DEPOTS);
                break;
            case 9:
                executeMultipleSelection(MultipleSelection.UNPLANNED_DEPOTS);
                break;
        }
    };


    // @ts-ignore
    const contextMenuRef = useRef<ContextMenuRef | null>(null);
    // const [contextMenu, setContextMenu] = React.useState<{
    //     mouseX: number;
    //     mouseY: number;
    // } | null>(null);

    const [activeContextMenuPlan, setActiveContextMenuPlan] = useState<null | Leg>(null);

    const handleContextMenuPlanning = (event: React.MouseEvent, plan: Leg) => {
        // setContextMenu(
        //     contextMenu === null
        //         ? {
        //             mouseX: event.clientX + 2,
        //             mouseY: event.clientY - 6,
        //         }
        //         : null,
        // );

        // setActiveContextMenuPlan(plan);

        if (!!contextMenuRef && !!contextMenuRef.current) {
            setActiveContextMenuPlan(plan);
            contextMenuRef.current.show(event);
        }
    }

    /** START HANDLING NEW TRIP CREATION AND ADD TO TRIP */
    const [openModalAddToTrip, setOpenModalAddToTrip] = useState<boolean>(false);
    const [isOpenTripBanner, setIsOpenTripBanner] = useState(true);
    const [isOpenModifyDepositsBanner, setIsOpenModifyDepositsBanner] = useState(true);
    const [openModalNewTrip, setOpenModalNewTrip] = useState(false);

    const handleCallbackCreateTrip = () => {
        setSelectedLegs([]);
        updateTrips("my");
    }

    const handleCallbackAddToTrip = () => {
        setSelectedLegs([]);
        refreshTripWithParams();
    }
    /** END HANDLING NEW TRIP CREATION */

    const adjustTableScrollbar = () => {
        const widthOfTable = gridContainerRef?.current?.offsetWidth;
        const scrollBar = document.querySelector('.ag-body-horizontal-scroll');
        if (!!scrollBar && !!widthOfTable) {
            // @ts-ignore
            scrollBar.style.width = widthOfTable?.toString() + 'px';
        }
    }

    const onGridReady = useCallback(() => {
        adjustTableScrollbar();
        initPlanningLegsFilter();

        if (!!canGoCheckLocationState && canGoCheckLocationState.current === true) {
            canGoCheckLocationState.current = false;

            if (!!location && location.state !== null && !!location.state.groups && location.state.groups.length > 0) {
                setSelectedDepartmentGroups(location.state.groups);
                setPlanningGroups(location.state.groups);
                setSelectedDepartmentId(location.state.groups[0]?.departmentId);
                
            } else {
                navigate(planningToolRouteUrls.groupsCharge.extended);
            }
        }
    }, []);

    useEffect(() => {
        setActiveTripId(undefined);

        if (!!location && location.state !== null && !!location.state.groups && location.state.groups.length > 0) {
            setDepartmentId(location.state.groups[0]?.departmentId);
        }
    }, []);

    const isSelectedPlannedLeg = useMemo(() => {
        let tableRows: IOrder[] | undefined = commonTableRef.current?.getTableRows() as IOrder[] | undefined;
        if (tableRows === undefined) {
            return false;
        }
        let legs: Leg[] = tableRows?.map(x => x.legs).flat(1).filter(x => selectedLegs.includes(x.id));
        if (legs.some(x => x.planned)) {
            return true;
        }

        return false;
    }, [selectedLegs]);

    // Suggested Planning
    const [activeTooltipSuggestedPlanningOrderId, setActiveTooltipSuggestedPlanningOrderId] = useState<number | null>(null);

    // Modal Shipment units
    const [activeOrderShipmentUnits, setActiveOrderShipmentUnits] = useState<null | {
        orderId: number;
        orderNumber: string;
    }>(null);

    const principalDivClassName = useMemo(() => {
        return isOpenSidebarTrips ? "relative w-[65%] mr-2 mb-6 bg-white p-[32px]" : "relative mr-2 mb-6 bg-white p-[32px]";
    }, [isOpenSidebarTrips]);

    const resetSelection = () => {
        // Reset selection!
        setSelectedLegs([]);
        setSelectedDeposits([]);
    }

    // #region SSR Retrieve Data
    const getData = async (pageSize: number, pageIndex: number, orderBy?: string, orderDirection?: string, filterModel?: any, originalFilterModel?: any) => {
        resetSelection();

        const isFilterModelPopulated = !!filterModel && Object.keys(filterModel).length > 0;

        const options: PaginationGetOrdersRequest = {
            pageSize: pageSize,
            pageIndex: pageIndex,
            PlanningStatus: !!isFilterModelPopulated ? filterModel.legs : 3,
            OrderBy: orderBy,
            OrderDirection: orderDirection,
            Number: !!isFilterModelPopulated ? filterModel.orderNumbers : null,
            // Origin: !!isFilterModelPopulated ? filterModel['origin.name'] : null,
            // Destination: !!isFilterModelPopulated ? filterModel['destination.name'] : null,
            Origin: !!originalFilterModel['origin'] ? JSON.stringify(getFilterParamsOriginDestinationSSR('origin', originalFilterModel)) : null,
            Destination: !!originalFilterModel['destination'] ? JSON.stringify(getFilterParamsOriginDestinationSSR('destination', originalFilterModel)) : null,
            ConsigneeDock: !!isFilterModelPopulated ? filterModel.consigneeDock : null,
            // Volume: !!isFilterModelPopulated ? filterModel.volume : null,
            // Weight: !!isFilterModelPopulated ? filterModel.weight : null,
            // TaxWeight: !!isFilterModelPopulated ? filterModel.taxWeight : null,
            // LinearMeters: !!isFilterModelPopulated ? filterModel.linearMeters : null,
            // ShippingUnits: !!isFilterModelPopulated ? filterModel.shipmentUnits?.length : null,
            Volume: null,
            Weight: null,
            TaxWeight: null,
            LinearMeters: null,
            ShippingUnits: null,
            EarlyPickup: !!isFilterModelPopulated ? filterModel.earlyPickup : null,
            LatePickup: !!isFilterModelPopulated ? filterModel.latePickup : null,
            EarlyDelivery: !!isFilterModelPopulated ? filterModel.earlyDelivery : null,
            LateDelivery: !!isFilterModelPopulated ? filterModel.lateDelivery : null,
            CustomerCollectionId: !!isFilterModelPopulated ? filterModel.customerCollectionID : null,
            CustomerLinehaulId: !!isFilterModelPopulated ? filterModel.customerLinehaulID : null,
        };

        let groupsIds = !!activeTrip ? [] : planningGroupsIds;
        const dateTimeStart = (!isAllDays && !!!activeTripId) ? startDate : undefined;
        const dateTimeEnd = (!isAllDays && !!!activeTripId) ? endDate : undefined;

        const response = await ordersService.getOrders(groupsIds, null, activeTripId, onlyDroppedOrders, options, dateTimeStart, dateTimeEnd);
        if (response.success) {
            return response.data;
        } else {
            toast.error("Error while retrieving orders");
        }
    }

    const callbackOnGetRows = () => {
        setOrdersNumber(commonTableRef.current?.getTableNodes().length);
        setPinnedBottomData();
        setSelectedRows([]);
    }

    const CustomInput = forwardRef(() => (
        <Button
            callback={() => {
                if (!(isOpenSidebarTrips || !(!isAllDays && !!!activeTripId))) {
                    setOpenDatePicker(true);
                }
            }}
            className={"bg-none border-none"}
            style={isOpenSidebarTrips || !(!isAllDays && !!!activeTripId) ? { opacity: "0.5"} : {}}
        >
            {dayjs(startDate).format('DD/MM/YYYY')} - {!!endDate ? dayjs(endDate).format('DD/MM/YYYY') : "Select"}
        </Button>
    ));

    useEffect(() => {
        if (planningStatusToNotFilterAllDays.includes(planningFilterState)) {
            setIsAllDays(false);
        }

        if (planningFilterState === PlanningFilterEnum.NOT_PLANNED) {
            setIsAllDays(true);
        }
    }, [planningFilterState]);

    useEffect(() => {
        refreshOrders();
    }, [isAllDays]);

    useEffect(() => {
        let tableNodes = commonTableRef.current?.getTableNodes();

        tableNodes?.forEach((node: RowNode) => {
            let data = node.data as IOrder;
            let originalNodeLegsSelected = data.selectedLegs ?? [];

            let nodeLegsSelected = data.legs.filter(leg => selectedLegs.includes(leg.id)).map(leg => leg.id);

            if (originalNodeLegsSelected.length !== nodeLegsSelected.length) {
                data.selectedLegs = nodeLegsSelected;
                node.setData(data);
                
            }
        });
    }, [selectedLegs]);

    useEffect(() => {
        let tableNodes = commonTableRef.current?.getTableNodes();

        tableNodes?.forEach((node: RowNode) => {
            let data = node.data as IOrder;
            let originalNodeLegsSelected = data.selectedDepots ?? [];

            let nodeLegsSelected = data.legs.filter(leg => selectedDeposits.includes(leg.id)).map(leg => leg.id);

            if (originalNodeLegsSelected.length !== nodeLegsSelected.length) {
                data.selectedDepots = nodeLegsSelected;
                node.setData(data);
                
            }
        });
    }, [selectedDeposits]);

    useEffect(() => {
        if (!!!legsOrDepotsToSelect) {
            return;
        }
        
        handleSelectLegOrDeposit(legsOrDepotsToSelect.type, legsOrDepotsToSelect.legId);
    }, [legsOrDepotsToSelect]);

    return (
        <div style={{ zIndex: 999 }} id="planning-tool-planning-page" className={principalDivClassName}>

            {/* CONTEXT MENU */}
            <CommonContextMenu
                ref={legContextMenuRef}
                items={legContextMenuItems}
            />

            {/* Planning */}
            {/* TABLE VIEW */}
            <div className="font-light text-lg leading-[25px]">Planning Tool</div>
            <div className="flex items-center justify-between pb-3 border-b mb-6">
                <div className="flex items-center">
                    <div className="md:mr-3 text-[32px] font-bold leading-[45px]">
                        {
                            !!activeTripId &&
                            <div className="flex">
                                <svg className="cursor-pointer text-blue w-[45px]" onClick={() => setActiveTripId(null)} xmlns="http://www.w3.org/2000/svg" width="1.6em" height="1.6em" viewBox="0 0 24 24"><path fill="currentColor" d="M19 11H7.14l3.63-4.36a1 1 0 1 0-1.54-1.28l-5 6a1.19 1.19 0 0 0-.09.15c0 .05 0 .08-.07.13A1 1 0 0 0 4 12a1 1 0 0 0 .07.36c0 .05 0 .08.07.13a1.19 1.19 0 0 0 .09.15l5 6A1 1 0 0 0 10 19a1 1 0 0 0 .64-.23a1 1 0 0 0 .13-1.41L7.14 13H19a1 1 0 0 0 0-2" /></svg>
                                <div className="ml-2">Trip ID {activeTrip?.dailyId} - {dayjs(activeTrip?.creationTime).format("DD/MM/YYYY")}</div>
                            </div>
                        }

                        {
                            !!!activeTripId &&
                            <>
                                {
                                    canEdit &&
                                    departments.find(d => d.id === selectedDepartmentId)?.name
                                }
                                {
                                    !canEdit &&
                                    <>
                                        {
                                            !!planningGroups &&
                                            `${departments.find(d => d.id === selectedDepartmentId)?.name} - ${planningGroups[0].name}`
                                        }
                                    </>
                                }
                            </>
                        }
                    </div>

                    {/* GROUPS SELECT & PROGRESS */}
                    {
                        ordersNumber !== undefined && canEdit && !!!activeTripId &&
                        <div>
                            <GroupSelectProgress
                                departmentGroups={selectedDepartmentGroups}
                                planningGroups={planningGroups}
                                callbackOnChange={handleChangePlanningGroups}
                            />
                        </div>
                    }
                </div>

                <div className="flex items-center">
                    <Button
                        className="ml-3 bg-transparent text-black border-[1.4px] border-black"
                        callback={handleExitFromPlanning}
                    >
                        <>
                            {canEdit &&
                                <>Exit from Planning</>
                            }
                            {!canEdit &&
                                <>Exit from View</>
                            }
                        </>
                    </Button>
                </div>
            </div>

            <div className="flex justify-between items-center mb-6">
                <div>
                    {
                        canEdit &&

                        // APPLY ACTION
                        <div className="flex items-center">
                            <label htmlFor="action" className="uppercase leading-[16px]">Action</label>
                            <div className="ml-3">
                                <Select
                                    name="action"
                                    value={selectedAction}
                                    callbackOnChange={(e) => setSelectedAction(e.target.value)}
                                    options={actionOptions}
                                    placeholder="Select..."
                                    disabledValues={disabledActionOptionsValues}
                                    defaultOptionEnabled={true}
                                />
                            </div>

                            <div className="ml-3">
                                <Button
                                    callback={applyAction}
                                >
                                    Apply
                                </Button>
                            </div>

                            <div className="ml-3">
                                <ReactSelect
                                    ref={multipleSelectionDropdownRef}
                                    className="w-[200px]"
                                    options={selectionOptions}
                                    placeholder="Multiple selection"
                                    value={null}
                                    onChange={(e: any) => handleMultipleSelection(e.value)}
                                    isDisabled={isOpenSidebarTrips}
                                />
                            </div>

                            {/* Include dropped */}
                            <div className="ml-3 w-max flex items-center p-2 border-[1.4px] border-blue rounded-[4px] h-[37.2px]">
                                <label className="mb-0 text-blue select-none text-[13px] leading-[16px] font-semibold uppercase" htmlFor="only_dropped_orders">Only dropped orders</label>
                                <div className="ml-[6px] flex items-center">
                                    <input
                                        type="checkbox"
                                        name="only_dropped_orders"
                                        id="only_dropped_orders"
                                        checked={onlyDroppedOrders}
                                        onChange={(e) => setOnlyDroppedOrders(e.target.checked)}
                                    />
                                </div>
                            </div>

                            {/* Filter Errors */}
                            {
                                hasPermission(PermissionAreas.DeveloperOperations, PermissionOperations.TestDebugActions) && updateRoutingErrorOrderIds.length > 0 &&
                                <div className="ml-3 w-max flex items-center p-2 border-[1.4px] border-red rounded-[4px] h-[37.2px]">
                                    <label className="mb-0 text-red select-none text-[13px] leading-[16px] font-semibold uppercase" htmlFor="show_only_errors">Show only errors</label>
                                    <div className="ml-[6px] flex items-center">
                                        <input
                                            type="checkbox"
                                            name="show_only_errors"
                                            id="show_only_errors"
                                            checked={showOnlyErrors}
                                            onChange={(e) => setShowOnlyErrors(e.target.checked)}
                                        />
                                    </div>
                                </div>
                            }

                            {
                                newOrdersAlertInfo !== null && newOrdersAlertInfo.show &&
                                <div className="ml-4 flex">
                                    <div className="border-orange pt-[12px] pl-[15px] pr-[15px] pb-[12px] border-[3px] rounded-[10px] flex">
                                        <span className="">
                                            {dayjs(newOrdersAlertInfo.time).format("HH:mm")}
                                        </span>
                                        <p className="ml-2 !mb-0"><span className="font-bold">{newOrdersAlertInfo.number} new orders</span> added to your groups.</p>
                                        <span className="ml-1 text-blue font-bold text-underlined underline-offset-2 cursor-pointer" onClick={handleClickOrdersAlertRefreshData}>
                                            Refresh data
                                        </span>
                                        <span className="ml-1">to take charge </span>
                                    </div>
                                </div>
                            }
                        </div>
                    }
                </div>

                <div className="flex">
                    <div className={planningStatusToNotFilterAllDays.includes(planningFilterState) || !!activeTripId ? "opacity-50 flex" : "flex"}>
                        <div className="mr-3 mt-3">
                            <Switch
                                callbackOnChange={() => {setIsAllDays(!isAllDays)}}
                                checked={isAllDays}
                                checkedElement={""}
                                uncheckedElement={""}
                                pinBackgroundColor={isAllDays ? "blue" : "darkGrey"}
                                width={"35px"}
                                disabled={planningStatusToNotFilterAllDays.includes(planningFilterState) || !!activeTripId}
                            />
                        </div>

                        <div className="flex items-center mt-3 mr-5">
                            <div className="mr-2 font-bold uppercase text-[13px]">ALL DAYS</div>
                        </div>
                    </div>
                    
                    <label className="relative !mb-0">
                        <ReactDatePicker
                            selected={startDate}
                            startDate={startDate}
                            endDate={endDate}
                            customInput={<CustomInput />}
                            onChange={(dates: any) => {
                                const [start, end] = dates;

                                if (start && end) {
                                    const dayDifference = differenceInDays(end, start);

                                    if (dayDifference > 31) {
                                        return;
                                    }
                                }

                                setStartDate(start);
                                setEndDate(end);
                                if (!!end) {
                                    setTimeout(() => {
                                        setOpenDatePicker(false);
                                    }, 100);
                                    setDateSelected(!dateSelected);
                                }
                            }}
                            open={openDatePicker}
                            onClickOutside={() => setOpenDatePicker(false)}
                            popperPlacement="bottom"
                            maxDate={new Date()}
                            
                            disabled={isOpenSidebarTrips || !(!isAllDays && !!!activeTripId)}
                            selectsRange
                        />
                    </label>
                </div>
            </div>

            {
                hasPermission(PermissionAreas.Planning, PermissionOperations.View) &&
                <div
                    className="planning-tool-planning-table-wrapper"
                    ref={gridContainerRef}
                    style={containerStyle}
                >
                    <CommonTable
                        ref={commonTableRef}
                        columnDefs={columnDefs}
                        onGridReady={onGridReady}
                        options={{
                            editable: false,
                            pageSize: 100,
                            suppressCellFocus: true,
                            isServerSide: true,
                            context: {
                                planningFilterState,
                                setPlanningFilterState
                            }
                        }}
                        fetchDataFunction={getData}
                        callbackOnGetRows={callbackOnGetRows}
                        dependenciesForUpdatingTable={[activeTripId, planningGroups, forceRefreshOrders, onlyDroppedOrders, dateSelected, planningFilterState]}
                        getRowId={(data: { data: IOrder }) => {
                            return data.data.id;
                        }}
                        rowHeight={70}
                    />
                </div>
            }

            {/* TRIP BANNER */}
            {
                selectedLegs.length > 0 &&
                <TripBanner
                    isOpen={isOpenTripBanner}
                    callbackOpenCloseTripBanner={() => setIsOpenTripBanner(!isOpenTripBanner)}
                    selectedLegs={selectedLegs}
                    allOrders={commonTableRef.current?.getTableRows() as IOrder[]}
                    isPlannedLeg={isSelectedPlannedLeg}
                    setSelectedDepositToModify={setSelectedDepositToModify}
                    callBackCreateTrip={() => setOpenModalNewTrip(true)}
                    callBackAddToTrip={() => setOpenModalAddToTrip(true)}
                    callBackStandBy={handleStandBySelectedLegs}
                    callBackFwdToNetwork={handleForwardtoNetworkSelectedLegs}
                    callbackAddDepot={handleAddDepotToSelectedLegs}
                />
            }

            {
                selectedDeposits.length > 0 &&
                <ModifyDepositsBanner
                    isOpen={isOpenModifyDepositsBanner}
                    callbackOpenCloseModifyDepositsBanner={() => setIsOpenModifyDepositsBanner(!isOpenModifyDepositsBanner)}
                    allOrders={commonTableRef.current?.getTableRows() as IOrder[]}
                    selectedDeposits={selectedDeposits}
                    setSelectedDepositToModify={setSelectedDepositToModify}
                    callbackRemoveDepots={handleRemoveSelectedDepots}
                    callbackChangeDepots={handleChangeSelectedDeposts}
                />
            }

            {
                openModalNewTrip &&
                <ModalCreateTrip
                    callbackClose={() => setOpenModalNewTrip(false)}
                    selectedLegs={selectedLegs}
                    callbackOnTripCreated={handleCallbackCreateTrip}
                    callbackSetOrderIdsInError={(orderIds: number[]) => setUpdateRoutingErrorOrderIds(orderIds)}
                />
            }

            {/* MODAL ADD TO TRIP */}
            {
                openModalAddToTrip &&
                <ModalAddToTrip
                    callbackCloseModal={() => setOpenModalAddToTrip(false)}
                    selectedLegs={selectedLegs}
                    callbackOnLegAdded={handleCallbackAddToTrip}
                    callbackSetOrderIdsInError={(orderIds: number[]) => setUpdateRoutingErrorOrderIds(orderIds)}
                />
            }

            {/* Modal Deliver to Partner */}
            {
                openModalDeliverToPartner &&
                <ModalDeliverToPartner
                    callbackOnChangePartner={(e: any) => setSelectedPartnerToDeliver(e)}
                    callbackOnClose={() => { setOpenModalDeliverToPartner(false); restoreMultipleActionSelection(); setSelectedPartnerToDeliver(null); }}
                    callbackOnConfirm={() => { assignPartnerToLegs(); setSelectedPartnerToDeliver(null); }}
                    selectedPartnerToDeliver={selectedPartnerToDeliver}
                />
            }

            {/* Modal for tooltip of GROUPED ORDERS */}
            {
                activeAggregationId !== null &&
                <ModalShowGroupedOrders
                    callbackCloseModal={() => setActiveAggregationId(null)}
                    order={dataForGroupedRows}
                    callbackSeparateOrders={handleSeparateOrdersFromModal}
                    canEdit={canEdit}
                />
            }

            {/* Modal to show Planning Rules */}
            {
                planningRulesOptions !== null &&
                <ModalShowPlanningRules
                    callbackCloseModal={() => handleTogglePlanningRulesModal()}
                    legNumber={planningRulesOptions.legNumber}
                    businessFlowTypeId={planningRulesOptions.businessFlowTypeId}
                />
            }

            {/* {
                openModalOrdersInError &&
                <ModalOrdersInError
                    callbackOnClose={() => setOpenModalOrdersInError(false)}
                    ordersInError={ordersInError}
                />
            } */}

            {
                activeModalOrderLegsErrorsOrderId !== null &&
                <ModalOrderLegsErrors
                    callbackCloseModal={() => setActiveModalOrderLegsErrorsOrderId(null)}
                    orderId={activeModalOrderLegsErrorsOrderId}
                />
            }

            {
                activeTooltipSuggestedPlanningOrderId !== null &&
                <TableTooltipSuggestedPlanning
                    suggestedPlanningOrderId={activeTooltipSuggestedPlanningOrderId}
                    callbackClose={() => setActiveTooltipSuggestedPlanningOrderId(null)}
                />
            }

            {
                activeOrderShipmentUnits !== null &&
                <ModalShowShipmentUnits
                    callbackCloseModal={() => setActiveOrderShipmentUnits(null)}
                    orderId={activeOrderShipmentUnits.orderId}
                    orderNumber={activeOrderShipmentUnits.orderNumber}
                />
            }
        </div >
    )
}

export default PlanningToolPlanning;