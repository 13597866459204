import { planningToolApiUrls } from "../../configs/planningToolApiUrls";
import basicRequest from "../basicRequest";

export const triageService = {
    getTriageOrders: async () => {
        const request = new basicRequest();
        return await request.get(planningToolApiUrls.triage);
    },
    setTriageOrdersGroup: async (groupId: number, legIds: number[], loadingMessage: string | null = null, successMessage: string | null = null, errorMessage: string | null = null) => {
        const request = new basicRequest();
        return await request.post(`${planningToolApiUrls.triage}`, {
            groupId: groupId,
            legIds: legIds
        }, {}, loadingMessage, successMessage, errorMessage);
    }
}