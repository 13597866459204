import dayjs from "dayjs";
import { planningToolApiUrls } from "../../configs/planningToolApiUrls";
import basicRequest from "../basicRequest";

export const exportPlanningService = {
    getExcelStream: async (date: Date, bftId: number, loadingMessage: string | null = null, successMessage: string | null = null, errorMessage: string | null = null) => {
        const request = new basicRequest();
        return await request.get(planningToolApiUrls.exportPlanning, {
            date: dayjs(date).format("YYYY-MM-DD"),
            bft: bftId
        }, loadingMessage, successMessage, errorMessage, undefined, { responseType: "arraybuffer" });
    }
}