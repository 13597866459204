import React, { ForwardedRef, forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { ContextMenu } from 'primereact/contextmenu';
import { MenuItem } from 'primereact/menuitem';
import './style.css';

type Props = {
    items: MenuItem[];
};

export interface CommonContextMenuRef {
    getSelectedItem: () => any;
    handleRightClick: (event: React.MouseEvent, selectedItem: any, disabledOptions?: string[]) => void;
};

const CommonContextMenu = forwardRef<CommonContextMenuRef, Props>((props: Props, ref: ForwardedRef<CommonContextMenuRef>) => {
    const [selectedItem, setSelectedItem] = useState<any>(undefined);
    const contextMenuRef = useRef<ContextMenu>(null);
    const [items, setItems] = useState<MenuItem[] | undefined>(undefined);
    const [event, setEvent] = useState<React.MouseEvent | undefined>(undefined);

    const getSelectedItem = () => {
        return selectedItem;
    };

    const handleRightClick = (event: React.MouseEvent, selectedItem: any, disabledOptions: string[] = []) => {
        if (contextMenuRef.current) {
            setEvent(event);
            setSelectedItem(selectedItem);
            let menuItems = props.items;
            menuItems.forEach((item) => {
                if (!!item.id && disabledOptions.includes(item.id)) {
                    item.disabled = true;
                    item.className = "opacity-50";
                } else {
                    item.disabled = false;
                    item.className = "opacity-100"
                }
            });
            setItems(menuItems);
        }
    };

    useEffect(() => {
        if (!!selectedItem && !!items && !!event && contextMenuRef.current) {
            contextMenuRef.current.show(event);
        }
    }, [selectedItem, items, event]);
    
    useImperativeHandle(ref, () => ({
        getSelectedItem,
        handleRightClick
    }));

    return (
        <ContextMenu
            style={{ width: "190px" }}
            ref={contextMenuRef}
            model={items}
            //breakpoint="1567px"
            onHide={() => setSelectedItem(undefined)}
        />
    );
});

export default CommonContextMenu;