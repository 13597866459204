import React, { useContext } from "react";
import SelectAsyncPreference from "../../ui/selectAsyncPreference/SelectAsyncPreference";
import Modal from "../../ui/modal/Modal";
import Button from "../../ui/button/Button";
import { IPartner } from "../../../types/partner";
import { masterDataService } from "../../../api/masterDataService/masterDataService";
import { IMasterDataContext, MasterDataContext } from "../../../context/MasterDataContext";

type Props = {
    callbackOnClose: () => any;
    callbackOnConfirm: () => any;
    callbackOnChangePartner: (e: any) => any;
    selectedPartnerToDeliver: IPartner | null;
}

const ModalDeliverToPartner: React.FC<Props> = ({
    callbackOnClose,
    callbackOnChangePartner,
    callbackOnConfirm,
    selectedPartnerToDeliver,
}) => {

    const { partners, updatePartners } = useContext(MasterDataContext) as IMasterDataContext;

    const handleCallbackConfirm = () => {
        if (!!selectedPartnerToDeliver) {
            callbackOnConfirm();
        }
    }

    return (
        <Modal
            callbackCloseModal={callbackOnClose}
            title="Deliver to partner"
            footer={
                <div className="flex items-center justify-end">
                    <Button
                        callback={handleCallbackConfirm}
                    >
                        Confirm
                    </Button>
                </div>
            }
            customStyleChildrenContainer={{
                overflow: "visible"
            }}
        >
            <>
                <div className="mb-4">
                    The following leg(s) will be delivered to a selected partner. Choose the partner from the dropdown menu and click confirm.
                </div>

                <div className="relative" style={{ zIndex: 1000 }}>
                    <SelectAsyncPreference
                        label="Partner"
                        initialOptions={partners}
                        value={selectedPartnerToDeliver}
                        name="partner"
                        placeholder="Select partner..."
                        callbackOnSelect={(e: any) => { callbackOnChangePartner(e) }}
                        dropDownPositionY="bottom"
                        apiServiceSearch={masterDataService.searchPartners}
                        apiServiceAddRemoveToFavourites={masterDataService.addRemovePartnerToFavourites}
                        paginated={true}
                        paginationCount={25}
                        callbackOnAddedToFavourites={updatePartners}
                        callbackOnRemovedFromFavourites={updatePartners}
                    />
                </div>
            </>
        </Modal>
    );
}

export default ModalDeliverToPartner;