import { planningToolApiUrls } from "../../configs/planningToolApiUrls";
import basicRequest from "../basicRequest";

export const usersService = {
    getUsers: async () => {
        const request = new basicRequest();
        return await request.get(planningToolApiUrls.users);
    },
    getUserCharges: async (username: string) => {
        const request = new basicRequest();
        return await request.get(`${planningToolApiUrls.users}/charges`, {
            user: username
        });
    }
}