import React, { useContext, useEffect, useState } from "react";
import { AdvancedFiltersType } from "../../api/tripsService/tripsService";
import InputText from "../ui/inputText/InputText";
import Button from "../ui/button/Button";
import Select from "../ui/select/Select";
import { IMasterDataContext, MasterDataContext } from "../../context/MasterDataContext";

type Props = {
    advancedFilters: null | AdvancedFiltersType;
    callbackSelect: (advancedFilters: AdvancedFiltersType) => any;
};

const AdvancedFilter: React.FC<Props> = ({ advancedFilters, callbackSelect }) => {

    const { tripStatusOptions } = useContext(MasterDataContext) as IMasterDataContext;

    const [filters, setFilters] = useState<null | AdvancedFiltersType>(null);

    const [activeOrderCreationDate, setActiveOrderCreationDate] = useState<'pickup' | 'delivery'>('pickup');
    const [activeInvPartyType, setActiveInvPartyType] = useState<'origin' | 'destination'>('origin');

    useEffect(() => {
        setFilters(advancedFilters);
    }, [advancedFilters]);

    const handleChangeSelect = (e: any) => {
        setFilters({
            ...filters,
            tripStatus: !!e.target.value ? e.target.value : undefined
        })
    }

    const handleChangeTextInput = (e: any, filterName: string, isNumeric = false) => {
        const obj: any = { ...filters };
        obj[filterName] = e !== '' ? (isNumeric ? parseFloat(e) : e) : undefined;
        setFilters(obj);
    }

    const handleChangeActiveOrderCreationDate = (e: any) => {
        const obj = { ...filters };
        if (e.target.value === 'pickup') {
            obj.pickupDate = obj.deliveryDate;
            obj.deliveryDate = undefined;
        } else if (e.target.value === 'delivery') {
            obj.deliveryDate = obj.pickupDate;
            obj.pickupDate = undefined;
        }

        setFilters(obj);
        setActiveOrderCreationDate(e.target.value);
    }

    const handleChangeActiveInvPartyType = (e: any) => {
        const obj = { ...filters };
        if (e.target.value === 'origin') {
            obj.sender = obj.consignee;
            obj.senderCity = obj.consigneeCity;
            obj.consignee = undefined;
            obj.consigneeCity = undefined;
        } else if (e.target.value === 'destination') {
            obj.consignee = obj.sender;
            obj.consigneeCity = obj.senderCity;
            obj.sender = undefined;
            obj.senderCity = undefined;
        }

        setFilters(obj);
        setActiveInvPartyType(e.target.value);
    }

    const [errorTripCreationDate, setErrorTripCreationDate] = useState<boolean>(false);
    const handleSubmitFilters = () => {
        if (!!!(filters?.tripDate)) {
            setErrorTripCreationDate(true);
        } else {
            callbackSelect(filters);
        }
    }

    return (
        <div className="border border-blue rounded-[8px] bg-lightGrey2 p-[12px]">

            <div>
                <div className="mb-6">
                    <div className="uppercase font-bold text-[13px] text-blue mb-6">Trip</div>
                    <div className="grid grid-cols-12 gap-3 mb-6">
                        <div className="col-span-12">                           
                            <Select
                                label="Trip status"
                                options={tripStatusOptions.map(sO => {
                                    return {
                                        label: sO.name,
                                        value: sO.id
                                    }
                                })}
                                callbackOnChange={handleChangeSelect}
                                placeholder={'--'}
                                value={!!filters?.tripStatus ? filters.tripStatus : ''}
                                defaultOptionEnabled={true}
                            />
                        </div>
                        {/* <div className="col-span-6">
                            <InputText
                                callbackOnChange={(e: any) => handleChangeTextInput(e, 'tripDate')}
                                name="trip_creation"
                                placeholder="gg/mm/aaaa"
                                label="Trip creation"
                                value={!!filters?.tripDate ? filters.tripDate : ''}
                                type="date"
                                hasError={errorTripCreationDate}
                                callbackOnFocus={() => setErrorTripCreationDate(false)}
                            />
                        </div> */}
                    </div>

                    <div>
                        <InputText
                            callbackOnChange={(e: any) => handleChangeTextInput(e, 'description')}
                            name="trip_description"
                            placeholder="--"
                            label="Trip description"
                            value={!!filters?.description ? filters.description : ''}
                        />
                    </div>
                </div>

                <div>
                    <div className="uppercase font-bold text-[13px] text-blue mb-6">Order</div>
                    <div>
                        <div className="flex items-end mb-6">
                            <div className="w-1/2 mr-3">
                                <div>
                                    <InputText
                                        callbackOnChange={(e: any) => handleChangeTextInput(e, activeOrderCreationDate === 'delivery' ? 'deliveryDate' : 'pickupDate')}
                                        name="order_creation"
                                        placeholder="gg/mm/aaaa"
                                        label="Order creation"
                                        value={activeOrderCreationDate === 'delivery' ? (!!filters?.deliveryDate ? filters.deliveryDate : '') : (!!filters?.pickupDate ? filters.pickupDate : '')}
                                        type="date"
                                    />
                                </div>
                            </div>
                            <div className="grow py-[10px] flex items-center">
                                <div className="flex items-center">
                                    <div className="mr-3">
                                        <input onChange={handleChangeActiveOrderCreationDate} checked={activeOrderCreationDate === 'pickup'} className="mr-[2px]" type="radio" id="pickup" name="order_creation_date" value="pickup" />
                                        <label className="mb-0" htmlFor="pickup">Pickup</label>
                                    </div>
                                    <div>
                                        <input onChange={handleChangeActiveOrderCreationDate} checked={activeOrderCreationDate === 'delivery'} className="mr-[2px]" type="radio" id="delivery" name="order_creation_date" value="delivery" />
                                        <label className="mb-0" htmlFor="delivery">Delivery</label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="mb-6 grid grid-cols-10 gap-3">
                            <div className="col-span-3">
                                <InputText
                                    callbackOnChange={(e: any) => handleChangeTextInput(e, activeInvPartyType === 'destination' ? 'consignee' : 'sender')}
                                    name="involved_party"
                                    placeholder="--"
                                    label="Inv. Party Name"
                                    value={activeInvPartyType === 'origin' ? (!!filters?.sender ? filters.sender : '') : (!!filters?.consignee ? filters.consignee : '')}
                                />
                            </div>
                            <div className="col-span-3">
                                <InputText
                                    callbackOnChange={(e: any) => handleChangeTextInput(e, activeInvPartyType === 'destination' ? 'consigneeCity' : 'senderCity')}
                                    name="city"
                                    placeholder="--"
                                    label="City"
                                    value={activeInvPartyType === 'origin' ? (!!filters?.senderCity ? filters.senderCity : '') : (!!filters?.consigneeCity ? filters.consigneeCity : '')}
                                />
                            </div>
                            <div className="col-span-4 flex items-end">
                                <div className="py-[10px] flex items-center">
                                    <div className="flex items-center">
                                        <div className="mr-3">
                                            <input onChange={handleChangeActiveInvPartyType} checked={activeInvPartyType === 'origin'} className="mr-[2px]" type="radio" id="origin" name="inv_party" value="origin" />
                                            <label className="mb-0" htmlFor="origin">Origin</label>
                                        </div>
                                        <div>
                                            <input onChange={handleChangeActiveInvPartyType} checked={activeInvPartyType === 'destination'} className="mr-[2px]" type="radio" id="destination" name="inv_party" value="destination" />
                                            <label className="mb-0" htmlFor="destination">Destination</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="mb-6 flex items-end">
                            <div className="grow">
                                <InputText
                                    callbackOnChange={(e) => handleChangeTextInput(e, 'minShippingUnits', true)}
                                    name="shipping_units_count"
                                    placeholder="--"
                                    label="Shipping units count"
                                    value={!!filters?.minShippingUnits ? filters?.minShippingUnits.toString() : ''}
                                />
                            </div>
                            <div className="p-[10px]">to</div>
                            <div className="grow">
                                <InputText
                                    callbackOnChange={(e) => handleChangeTextInput(e, 'maxShippingUnits', true)}
                                    name="shipping_units_count_to"
                                    placeholder="--"
                                    value={!!filters?.maxShippingUnits ? filters?.maxShippingUnits.toString() : ''}
                                />
                            </div>
                        </div>

                        <div className="mb-6">
                            <InputText
                                callbackOnChange={(e) => handleChangeTextInput(e, 'generalId')}
                                name="generalId"
                                placeholder="--"
                                value={!!filters?.generalId ? filters?.generalId : ''}
                                label="ID"
                            />
                        </div>

                        <div className="pt-6 border-t">
                            <Button
                                callback={handleSubmitFilters}
                            >
                                Apply
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdvancedFilter;