import { createSlice } from '@reduxjs/toolkit'

export const gridConfigurationInitialState = {
  gridConfigurationList: []
}

const gridConfigurationSlice = createSlice({
  name: 'gridConfiguration',
  initialState: gridConfigurationInitialState,
  reducers: {
    addConfiguration(state, action) {
      var updated = false;
      var i = 0;
      while (i < state.gridConfigurationList.length && !updated) {
        if (state.gridConfigurationList[i].gridId == action.payload.gridId) {
          state.gridConfigurationList[i].gridConfig = action.payload.gridConfig;
          updated = true;
        }
        i++;
      }

      if (!updated)
        state.gridConfigurationList.push(action.payload);
    }
  }
})

export const { 
  addConfiguration
 } = gridConfigurationSlice.actions

export default gridConfigurationSlice.reducer