import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { GetUser } from "../../../utils/AuthUtils";
import { addConfiguration } from "../../../redux/features/grid-configuration/gridConfigurationSlice";
import { connect } from "react-redux";
import { gridConfigurationAPI } from "../../../api/grid-configuration/gridConfigurationAPI";
import GridCard from "../../components/grid-card/GridCard";
import { pagesLinks } from "../../../utils/PageUtils";

export const RelatedCustomerOrdersGridContainer = ({
  lightVersion,
  data,
  title,
  linkTo,
  isModal,
  gridId,
  gridConfigurationList,
  addConfiguration,
  hideBreadcrumbs,
  isFetching
}) => {
  const [columns, setColumns] = useState();
  const [showModalReset, setShowModalReset] = useState(false);
  const [columnapi, setColumnapi] = useState();
  const [eventapi, setEventapi] = useState();
  const [groupColWidth, setGroupColWidth] = useState(220);
  const [currentDensity, setCurrentDensity] = useState();

  function handleGoToPage(event) {
    // console.log(event.data)
    // if (event.data != undefined)
    //     history.push(pagesLinks.TODetailPage+"/"+event.data.id);
    if (event.data != undefined) {
      const win = window.open(
        pagesLinks.newOrderCustomerDetailsPage + "/" + event.data.transportOrderID,
        "_blank"
      );
      // FIXME: this causes an error if the browser prevents pops up from being opened
      win.focus();
    }
  }

  const buildDefaultColumnsGrid = () => {
    return [
      {
        headerName: "Transport Order Number",
        field: "transportOrderNumber",
        colId: "transportOrderNumber",
        width: "250px",
      },
      {
        headerName: "Is Manual",
        field: "isManual",
        colId: "isManual",
        width: "120px",
        cellDataType: "string" 
      },
      {
        headerName: "Customer",
        field: "customer",
        colId: "customer",
        width: "250px",
      },
      {
        headerName: "Business Flow Type",
        field: "businessFlowType",
        colId: "businessFlowType",
        width: "253px",
      },
      {
        headerName: "Early Pickup Date",
        field: "earlyPickupDate",
        colId: "earlyPickupDate",
        width: "164px",
      },
      {
        headerName: "Late Pickup Date",
        field: "latePickupDate",
        colId: "latePickupDate",
        width: "164px",
      },
      {
        headerName: "Early Delivery Date",
        field: "earlyDeliveryDate",
        colId: "earlyDeliveryDate",
        width: "164px",
      },
      {
        headerName: "Late Delivery Date",
        field: "lateDeliveryDate",
        colId: "lateDeliveryDate",
        width: "164px",
      },
      {
        headerName: "Total Weight (KG)",
        field: "totalWeight",
        colId: "totalWeight",
        width: "142px",
      },
      {
        headerName: "Total Linear Dimension (M)",
        field: "totalLinearDimension",
        colId: "totalLinearDimension",
        width: "218px",
      },
      {
        headerName: "SU Count",
        field: "shipUnitCount",
        colId: "shipUnitCount",
        width: "138px",
      },
      {
        headerName: "Total Volume (M3)",
        field: "totalVolume",
        colId: "totalVolume",
        width: "142px",
      },
      {
        headerName: "Origin (Name)",
        field: "originName",
        colId: "originName",
        width: "200px",
      },
      {
        headerName: "Origin (City)",
        field: "originCity",
        colId: "originCity",
        width: "200px",
      },
      {
        headerName: "Origin (Country)",
        field: "originCountry",
        colId: "originCountry",
        width: "200px",
      },
      {
        headerName: "Destination (Name)",
        field: "destinationName",
        colId: "destinationName",
        width: "200px",
      },
      {
        headerName: "Destination (City)",
        field: "destinationCity",
        colId: "destinationCity",
        width: "200px",
      },
      {
        headerName: "Destination (Country)",
        field: "destinationCountry",
        colId: "destinationCountry",
        width: "200px",
      },
    ];
  };

  function myFilterList(myList, colId) {
    var newList = [];

    for (var i = 0; i < myList.length; i++) {
      if (myList[i].colId != colId) newList.push(myList[i]);
    }

    return newList;
  }

  function buildColumnsGridFromConfig(configuration) {
    var defaultCols = buildDefaultColumnsGrid();
    var newConfiguration = [];

    for (var i = 0; i < configuration.length; i++) {
      var j = 0;
      while (j < defaultCols.length) {
        if (configuration[i].colId == defaultCols[j].colId) {
          defaultCols[j].width = configuration[i].width;
          defaultCols[j].hide = configuration[i].hide;
          defaultCols[j].rowGroup = configuration[i].rowGroupIndex != null;
          defaultCols[j].rowGroupIndex = configuration[i].rowGroupIndex;
          newConfiguration.push(defaultCols[j]);
          j = defaultCols.length;
          defaultCols = myFilterList(defaultCols, configuration[i].colId);
        }
        j++;
      }
    }

    if (defaultCols.length > 0) {
       //eslint-disable-next-line
      for (var i = 0; i < defaultCols.length; i++) {
        newConfiguration.push(defaultCols[i]);
      }
    }

    if (configuration[0].colId == "ag-Grid-AutoColumn")
      setGroupColWidth(configuration[0].width);

    if (
      configuration[configuration.length - 1].colId ==
      "ag-Grid-GlobalProperties"
    )
      setCurrentDensity(configuration[configuration.length - 1].rowDensity);

    return newConfiguration;
  }

  function handleCloseModalCancel() {
    setShowModalReset(false);
  }

  function handleCloseModalReset() {
    let defaultCols = buildDefaultColumnsGrid();

    for (var i = 0; i < defaultCols.length; i++) {
      defaultCols[i].hide = false;
      defaultCols[i].rowGroup = false;
      defaultCols[i].rowGroupIndex = null;
    }

    eventapi.applyColumnState({state: defaultCols, applyOrder: true});

    var gridConfigObj = getGridConfigInStore(gridId);

    if (gridConfigObj != null && gridConfigObj.gridConfig != null) {
      // remove da store e db
      var params = {
        gridId: gridId,
        insertUser: GetUser().email,
      };

      gridConfigurationAPI.removeGridConfiguration(params, (res) => {
        addConfiguration({
          gridId: params.gridId,
          gridConfig: null,
        });
      });
    }

    setShowModalReset(false);
  }

  function handleSaveGridConfig(columnState, columnApi, eventApi, density) {
    if (columnState == "reset") {
      setShowModalReset(true);
      setColumnapi(columnApi);
      setEventapi(eventApi);
      setCurrentDensity(density);
    } else {
      var configuration = [];
      var tempGroupColWidth = 220;
      for (var i = 0; i < columnState.length; i++) {
        if (columnState[i].colId != "ag-Grid-AutoColumn") {
          configuration.push({
            colId: columnState[i].colId,
            width: columnState[i].width,
            hide: columnState[i].hide,
            rowGroupIndex: columnState[i].rowGroupIndex,
          });
        } else {
          tempGroupColWidth = columnState[i].width;
        }
      }
      configuration.unshift({
        colId: "ag-Grid-AutoColumn",
        width: tempGroupColWidth,
      });
      configuration.push({
        colId: "ag-Grid-GlobalProperties",
        rowDensity: density,
      });

      setCurrentDensity(density);

      var params = {
        gridId: gridId,
        configuration: JSON.stringify(configuration),
        insertUser: GetUser().email,
      };

      gridConfigurationAPI.addOrUpdateGridConfiguration(params, (res) => {
        addConfiguration({
          gridId: params.gridId,
          gridConfig: configuration,
        });
      });
    }
  }

  function getGridConfigInStore(gridId) {
    for (var i = 0; i < gridConfigurationList.length; i++) {
      if (gridId == gridConfigurationList[i].gridId)
        return gridConfigurationList[i];
    }

    return null;
  }

  function getIsNotDefaultConfig(gridId) {
    var gridConfigObj = getGridConfigInStore(gridId);

    return gridConfigObj != null && gridConfigObj.gridConfig != null;
  }

  useEffect(() => {
    let isMounted = true;

    var gridConfigObj = getGridConfigInStore(gridId);
    if (gridConfigObj != null) {
      let config = gridConfigObj.gridConfig;
      if (config == null) {
        setColumns(buildDefaultColumnsGrid());
      } else {
        setColumns(buildColumnsGridFromConfig(config));
      }
    } else {
      gridConfigurationAPI.getGridConfiguration(
        {
          userMail: GetUser().email,
          gridId: gridId,
        },
        (res) => {
          if (isMounted) {
            if (res.gridId != null) {
              let parsedConfiguration = JSON.parse(res.configuration);
              setColumns(buildColumnsGridFromConfig(parsedConfiguration));
              addConfiguration({
                gridId: gridId,
                gridConfig: parsedConfiguration,
              });
            } else {
              setColumns(buildDefaultColumnsGrid());
              addConfiguration({
                gridId: gridId,
                gridConfig: null,
              });
            }
          }
        }
      );
    }

    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <>
      <Modal
        show={showModalReset}
        backdrop="static"
        keyboard={false}
        animation={false}
        centered
        className="modal-bookmarks"
      >
        <Modal.Header style={{ padding: "0" }}>
          <div className="modal-title-bookmarks">
            Are you sure to restore the default grid configuration?
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex pt-4" style={{ float: "right" }}>
            <Button
              className="bookmarks-cancel-button"
              onClick={handleCloseModalCancel}
            >
              Cancel
            </Button>
            <Button
              className="bookmarks-confirm-button"
              onClick={handleCloseModalReset}
            >
              Confirm
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      <GridCard
        gridId={gridId}
        handleSaveGridConfig={handleSaveGridConfig}
        groupColWidth={groupColWidth}
        isNotDefaultConfig={getIsNotDefaultConfig(gridId)}
        isFetching={isFetching}
        title={title}
        columns={columns}
        data={data}
        count={data.length}
        lightVersion={lightVersion}
        linkTo={linkTo}
        isModal={isModal}
        currentDensity={currentDensity}
        hideBreadcrumbs={hideBreadcrumbs}
        openPageOnRowClick={true}
        handleGoToPage={handleGoToPage}
      />
    </>
  );
};

const mapStateToProps = function(state) {
  return {
    gridConfigurationList: state.gridConfiguration.gridConfigurationList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addConfiguration: (gridConfiguration) => {
      dispatch(addConfiguration(gridConfiguration));
    },
  };
};

export const RelatedCustomerOrdersGrid = connect(
  mapStateToProps,
  mapDispatchToProps
)(RelatedCustomerOrdersGridContainer);
