import { Bft } from "../../types/dashboard";

export const getHighestValue: (bft: Bft) => ("PTOrderCount" | "RPAOrderCount" | "bothOrderCount") = (bft: Bft) => {

    let highestValueProperty: "PTOrderCount" | "RPAOrderCount" | "bothOrderCount" = "PTOrderCount";
    if (!!bft) {
        const array = Object.entries({
            PTOrderCount: bft.PTOrderCount,
            bothOrderCount: bft.bothOrderCount,
            RPAOrderCount: bft.RPAOrderCount
        });

        for (let index = 0; index < array.length; index++) {
            const element: any[] = array[index];

            if (!!element && element[1] > bft[highestValueProperty]) {
                highestValueProperty = element[0];
            }
        }
    }

    return highestValueProperty;
};

export const getRemainingProperties = (property: "PTOrderCount" | "RPAOrderCount" | "bothOrderCount") => {
    const properties: ("PTOrderCount" | "RPAOrderCount" | "bothOrderCount")[] = ["PTOrderCount", "RPAOrderCount", "bothOrderCount"];
    properties.splice(properties.indexOf(property), 1);
    return properties;
}

export const getObjectBftPercents = (bft: Bft | null) => {
    if (bft !== null) {
        const highestInboundReturn = getHighestValue(bft);
        const objPercentInboundReturn = {
            [highestInboundReturn]: bft[highestInboundReturn] !== 0 ? 100 : 0,
        }

        const remainingProps = getRemainingProperties(highestInboundReturn);
        for (let index = 0; index < remainingProps.length; index++) {
            const propName = remainingProps[index];
            if (bft[highestInboundReturn] !== 0) {
                objPercentInboundReturn[propName] = bft[propName] * 100 / bft[highestInboundReturn]
            } else {
                objPercentInboundReturn[propName] = 0;
            }
        }

        return objPercentInboundReturn;
    }

    return null;
}

export const getBftOrdersReceivedPercent = (bft: Bft | null) => {
    if (bft !== null) {
        let value = 0;
        if (bft["RPAOrderCount"] !== 0) {
            value = bft["bothOrderCount"] * 100 / bft["RPAOrderCount"];
        }

        return value;
    }

    return null;
}