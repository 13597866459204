import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import {
    TOsGrid
} from "..";
import {
    SODetail
} from "../../components";
import { detailAPI } from "../../../api/detail/detailAPI";
import { Tab, Row, Tabs } from 'react-bootstrap';
import { setSearched } from '../../../redux/features/search/searchSlice';
import { selectLastTOUpdate } from "../../../redux/features/navigation/navigationSlice";
import { useLocation } from 'react-router-dom';
import { formatDateToMin } from '../../../utils/DateUtils';
import { RelatedCustomerOrdersGrid } from '../relatedCustomerOrders-grid/RelatedCustomerOrdersGrid';
import { RelatedOrdersGrid } from '../relatedOrders-grid/RelatedOrdersGrid';
import { GetUser } from "../../../utils/AuthUtils";

export const SODetailsContainerFn = ({ isMainTab, setId, soId, setSearched }) => {
    const [data, setData] = useState();
    const [isFetching, setIsFetching] = useState(true);
    const lastUpdate = useSelector(selectLastTOUpdate);
    const location = useLocation();
    const [tabToRender, setTabToRender] = useState("");
    const [relatedExecutionOrders, setRelatedExecutionOrders] = useState([]);
    const [relatedCustomerOrders, setRelatedCustomerOrders] = useState([]);
    const [executionIsFetching, setExecutionIsFetching] = useState(false);
    const [customerIsFetching, setCustomerIsFetching] = useState(false);
    const customer_view = GetUser().customer_view === "TRUE"
    
    function getCorrectTO(dataGetter, title, phase, gridId, disableOpenPageOnRowClick) {
        return (<TOsGrid
            filters={{
                id: soId,
                phase: phase
            }}
            lightVersion={false}
            dataUpdated={() => { }}
            immediate={true}
            requestUpdateTime={lastUpdate}
            dataGetter={dataGetter}
            title={title}
            linkTo={"detail"}
            hiddenCols={phase == "CUSTOMER" ? ['tmsOrderId', 'collectionId'] : []}
            gridId={gridId}
            disableOpenPageOnRowClick={disableOpenPageOnRowClick} />);
    }

    useEffect(() => {        
        setTabToRender(location.state?.extotab ? "executionTO" : "customerTO")
    }, [location])

    useEffect(() => {
        let isMounted = true;
        
        setIsFetching(true);

        detailAPI.getSalesOrderDetailsById(soId,
            res => {
                if (isMounted) {
                    setData(res);
                    setSearched(res.externalSO, "SO");
    
                    setIsFetching(false);
                }
                
            });
        
        setExecutionIsFetching(true);
        detailAPI.getTransportOrderExecutionBySalesOrderId(soId, (relatedOrders) => {
            if (isMounted) {
            setRelatedExecutionOrders(
                relatedOrders.items.map((el) => {
                el.isClickable = true;
                return el;
                })
            );
            setExecutionIsFetching(false);
            console.log('EXE', executionIsFetching)
            }
        });
        setCustomerIsFetching(true);
        if(customer_view){
            detailAPI.getTransportOrderCustomerBySalesOrderId(soId, (relatedOrders) => {
                
                if (isMounted) {
                relatedOrders.items.forEach(element => {
                element.earlyPickupDate = formatDateToMin(element.earlyPickupDate);
                element.latePickupDate = formatDateToMin(element.latePickupDate);
                element.earlyDeliveryDate = formatDateToMin(element.earlyDeliveryDate);
                element.lateDeliveryDate = formatDateToMin(element.lateDeliveryDate);
                
                if (element.isManual == true){
                    element.isManual = 'YES'
                } else if (element.isManual == false){
                    element.isManual = 'NO'
                }
                });
                
                
                setRelatedCustomerOrders(
                    relatedOrders.items.map((el) => {
                    el.isClickable = true;
                    return el;
                    })
                );
        
                setCustomerIsFetching(false);
                console.log('CUSTOMER', customerIsFetching)
                }
            });

        }  

        return () => {
            isMounted = false;
            };
    }, [lastUpdate]);

    const handleChangeTab = (key) => {
        setTabToRender(key);
    } 

    /* return (<div className="position-relative">
        <SODetail isFetching={isFetching} soDetailObj={data} />

        {!isFetching && <Row>
            <div className="detail-tab-container so" style={{marginBottom: "32px"}}>
                <Tabs
                    defaultActiveKey="customerTO"
                    activeKey={tabToRender}
                    onSelect={(key) => handleChangeTab(key)} id="SOgridstabs" unmountOnExit={true} transition={false}>
                    <Tab eventKey="customerTO" tabClassName='prova' title="Customer TO" className="detail-tab">             
                        {getCorrectTO(detailAPI.getTransportOrderBySalesOrderIdAndPhase, "Customer TO", "CUSTOMER", "CustomerTO-SODetail", true)}
                    </Tab>
                    <Tab eventKey="executionTO" title="Execution TO" className="detail-tab" onClick={() => handleChangeTab}>
                        {getCorrectTO(detailAPI.getTransportOrderBySalesOrderIdAndPhase, "Execution TO", "EXECUTION", "ExecutionTO-SODetail", false)}
                    </Tab>
                </Tabs>  
            </div>
        </Row>}
    </div>);
}; */
    return (<div className="position-relative">
        <SODetail isFetching={isFetching} soDetailObj={data} />
        {!isFetching && <Row>
            <div className="detail-tab-container so" style={{marginBottom: "32px"}}>
            {customer_view ? (
                <Tabs
                    defaultActiveKey="customerTO"
                    activeKey={tabToRender}
                    onSelect={(key) => handleChangeTab(key)} id="SOgridstabs" unmountOnExit={true} transition={false}>
                    <Tab eventKey="customerTO" tabClassName='prova' title="Customer TO" className="detail-tab">       
                    <RelatedCustomerOrdersGrid
                          lightVersion={false}
                          data={relatedCustomerOrders}
                          title={'Customer TO'}
                          gridId={"RelatedCustomerOrders"}
                          hideBreadcrumbs={false}
                          isFetching={customerIsFetching}
                          linkTo={"detail"}
                        />
                    </Tab>
                    <Tab eventKey="executionTO" title="Execution TO" className="detail-tab" onClick={() => handleChangeTab}>
                    <RelatedOrdersGrid
                          lightVersion={false}
                          data={relatedExecutionOrders}
                          title={'Execution TO'}
                          gridId={"RelatedExecutionOrders"}
                          hideBreadcrumbs={false}
                          isFetching={executionIsFetching}
                          linkTo={"detail"}
                          isSO={true}
                        />    
                    </Tab>
                </Tabs>  
            ) : (
                    <RelatedOrdersGrid
                    lightVersion={false}
                    data={relatedExecutionOrders}
                    title={'Execution TO'}
                    gridId={"RelatedExecutionOrders"}
                    hideBreadcrumbs={false}
                    isFetching={executionIsFetching}
                    linkTo={"detail"}
                    isSO={true}
                    />    
            )}
            </div>
        </Row>}
    </div>);
    };

const mapDispatchToProps = dispatch => {
    return {
        setSearched: (searchedCode, searchedType) => {
            dispatch(setSearched({ searchedCode, searchedType }));
        }
    };
};

export const SODetailsContainer = connect(null, mapDispatchToProps)(SODetailsContainerFn);