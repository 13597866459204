import React from "react";

const IconCamionM = () => {
    return (
        <svg width="85" height="55" viewBox="0 0 85 55" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_12832_25072)">
                <path d="M16.854 46.7568C15.7276 46.7568 14.8105 47.6738 14.8105 48.8003C14.8105 49.9267 15.7276 50.8437 16.854 50.8437C17.9805 50.8437 18.8975 49.9267 18.8975 48.8003C18.8975 47.6738 17.9805 46.7568 16.854 46.7568Z" fill="#231F20" />
                <path d="M23.1586 48.8024C23.1586 45.3193 20.3358 42.4966 16.8527 42.4966C13.3696 42.4966 10.5469 45.3193 10.5469 48.8024C10.5469 52.2855 13.3715 55.1083 16.8527 55.1083C20.334 55.1083 23.1586 52.2855 23.1586 48.8024ZM13.0036 48.8024C13.0036 46.7118 14.7262 45.0174 16.8508 45.0174C18.9754 45.0174 20.7 46.7118 20.7 48.8024C20.7 50.8931 18.9773 52.5874 16.8508 52.5874C14.7244 52.5874 13.0036 50.8931 13.0036 48.8024Z" fill="#231F20" />
                <path d="M62.1699 48.8022C62.1699 49.9287 63.0869 50.8457 64.2134 50.8457C65.3398 50.8457 66.2568 49.9287 66.2568 48.8022C66.2568 47.6758 65.3398 46.7588 64.2134 46.7588C63.0869 46.7588 62.1699 47.6758 62.1699 48.8022Z" fill="#231F20" />
                <path d="M57.9082 48.8024C57.9082 52.2855 60.7328 55.1083 64.2141 55.1083C67.6953 55.1083 70.5199 52.2855 70.5199 48.8024C70.5199 45.3193 67.6972 42.4966 64.2141 42.4966C60.7309 42.4966 57.9082 45.3193 57.9082 48.8024ZM68.0632 48.8024C68.0632 50.8931 66.3405 52.5874 64.2141 52.5874C62.0876 52.5874 60.3668 50.8931 60.3668 48.8024C60.3668 46.7118 62.0895 45.0174 64.2141 45.0174C66.3386 45.0174 68.0632 46.7118 68.0632 48.8024Z" fill="#231F20" />
                <path d="M77.9868 46.7568C76.8604 46.7568 75.9434 47.6738 75.9434 48.8003C75.9434 49.9267 76.8604 50.8437 77.9868 50.8437C79.1133 50.8437 80.0303 49.9267 80.0303 48.8003C80.0303 47.6738 79.1133 46.7568 77.9868 46.7568Z" fill="#231F20" />
                <path d="M77.9875 42.4966C74.5063 42.4966 71.6816 45.3193 71.6816 48.8024C71.6816 52.2855 74.5063 55.1083 77.9875 55.1083C81.4687 55.1083 84.2933 52.2855 84.2933 48.8024C84.2933 45.3193 81.4706 42.4966 77.9875 42.4966ZM77.9875 52.5874C75.8629 52.5874 74.1402 50.8931 74.1402 48.8024C74.1402 46.7118 75.8629 45.0174 77.9875 45.0174C80.1121 45.0174 81.8367 46.7118 81.8367 48.8024C81.8367 50.8931 80.114 52.5874 77.9875 52.5874Z" fill="#231F20" />
                <path d="M82.0164 40.9981C83.6606 40.9981 85 39.5983 85 37.8797V3.11834C85 1.3998 83.6625 0 82.0164 0H30.9836C29.3394 0 28 1.39788 28 3.11834V37.8817C28 39.6002 29.3375 41 30.9836 41H82.0146L82.0164 40.9981Z" fill="url(#paint0_linear_12832_25072)" />
                <path d="M12.4023 15.2539C12.4023 15.3464 12.591 15.4049 12.9722 15.4049H23.4574C24.1838 15.4049 24.7782 14.8105 24.7782 14.0841V11.3463C24.7782 10.5406 24.2216 10.1141 23.5442 10.4009L12.8873 14.886C12.5457 15.0294 12.3854 15.1615 12.4042 15.2558L12.4023 15.2539Z" fill="#231F20" />
                <path d="M57.2683 44.7776C57.2777 44.2587 56.9173 43.836 56.3965 43.836H25.6315C25.1107 43.8398 24.6881 43.2681 24.6881 42.6549V18.0938C24.6881 17.4805 24.2654 16.9824 23.7446 16.9824H10.4424C9.92159 16.9824 9.27252 17.4032 8.99515 17.9221L8.70835 18.4542C8.90647 18.7032 8.97628 18.9768 8.86496 19.1825L3.92519 28.3375C3.81576 28.5394 3.55914 28.6299 3.25159 28.6073L1.8874 31.147C1.61003 31.6659 1.38361 32.5848 1.38361 33.198V38.1396C1.6723 38.2434 1.8723 38.4529 1.8723 38.6982V41.3077C1.8723 41.5511 1.6723 41.7605 1.38361 41.8643V42.5171C1.38361 43.4681 0.621322 43.7323 0.260934 44.3795C-0.207004 45.2229 0.0477202 47.321 0.274142 47.8116C0.479808 48.2588 0.67604 48.7871 2.54779 48.7871L8.42721 48.8003C8.94798 48.8003 9.3763 47.6399 9.59328 47.0739C10.4726 44.7795 12.1368 41.5246 16.852 41.5246C22.0861 41.5246 25.3315 46.4286 23.8692 51.7457C23.4484 53.2741 24.2937 52.374 24.7503 49.857C24.8579 49.257 25.1409 48.8003 25.6617 48.8003H56.323C56.8437 48.8003 57.2664 48.3777 57.2664 47.8569C57.2664 47.8569 57.2456 45.8606 57.2664 44.7776H57.2683ZM21.8899 35.0565C21.8899 35.4245 21.5804 35.7226 21.1974 35.7226H19.2332C18.8502 35.7226 18.5407 35.4245 18.5407 35.0565V34.3792C18.5407 34.0112 18.8502 33.7131 19.2332 33.7131H21.1974C21.5804 33.7131 21.8899 34.0112 21.8899 34.3792V35.0565ZM23.3371 28.7696C23.3371 29.5111 22.8748 30.113 22.305 30.113L13.4311 30.1017C12.8613 30.1017 12.3368 30.4489 12.0556 31.0451L9.56875 35.8056C9.15365 36.4358 8.55174 36.432 7.98191 36.432H6.0762C5.50637 36.432 5.09693 35.9905 5.09693 35.2471V30.1375C5.09693 29.3941 5.17617 28.8016 5.47807 28.1771L10.0103 19.6863C10.3122 19.058 11.0197 18.5485 11.5877 18.5485H22.305C22.8748 18.5485 23.3371 19.1504 23.3371 19.8919V28.7658V28.7696Z" fill="#231F20" />
                <path d="M37.878 35H34.938V24.934H38.872L40.272 29.96C40.3 30.03 40.328 30.184 40.384 30.422C40.44 30.674 40.51 31.01 40.608 31.43C40.678 31.094 40.734 30.8 40.79 30.548C40.846 30.296 40.902 30.114 40.93 29.974L42.358 24.934H46.292V35H43.366L43.394 30.044V29.176C43.394 28.896 43.408 28.588 43.408 28.28C43.296 28.812 43.17 29.372 43.016 29.988L42.988 30.072L41.742 35H39.488L38.228 30.184C38.172 29.974 38.116 29.722 38.06 29.442C38.004 29.162 37.92 28.77 37.822 28.294C37.836 28.714 37.85 29.092 37.864 29.4C37.864 29.708 37.878 29.96 37.878 30.142V35Z" fill="black" />
            </g>
            <defs>
                <linearGradient id="paint0_linear_12832_25072" x1="28.1379" y1="41" x2="85.0806" y2="41" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FECA00" />
                    <stop offset="0.90687" stopColor="#FECA00" />
                    <stop offset="0.908622" stopColor="#6F6F6F" />
                    <stop offset="1" stopColor="#6F6F6F" />
                </linearGradient>
                <clipPath id="clip0_12832_25072">
                    <rect width="85" height="55" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}

export default IconCamionM;
