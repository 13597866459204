import loadable from "@loadable/component";

// Layout
export const Layout = loadable(() => import("./layout/Layout"), {
	resolveComponent: (components) => components.Layout,
});

// Layout Init
export const LayoutInit = loadable(() => import("./layout-init/LayoutInit"), {
	resolveComponent: (components) => components.LayoutInit,
});

// Header Mobile
export const HeaderMobile = loadable(() => import("./header-mobile/HeaderMobile"), {
	resolveComponent: (components) => components.HeaderMobile,
});

// Aside
export const Aside = loadable(() => import("./aside/Aside"), {
	resolveComponent: (components) => components.Aside,
});

// Aside Menu
export { AsideMenu } from "./aside-menu/AsideMenu";

// Footer
export const Footer = loadable(() => import("./footer/Footer"), {
	resolveComponent: (components) => components.Footer,
});

// Header
export const Header = loadable(() => import("./header/Header"), {
	resolveComponent: (components) => components.Header,
});

// User
export const User = loadable(() => import("./user/User"), {
	resolveComponent: (components) => components.User,
});
export const UserToggler = loadable(() => import("./user/UserToggler"), {
	resolveComponent: (components) => components.UserToggler,
});

// Scroll Top
export const ScrollTop = loadable(() => import("./scroll-top/ScrollTop"), {
	resolveComponent: (components) => components.ScrollTop,
});

// Sticky Toolbar
export const StickyToolbar = loadable(() => import("./sticky-toolbar/StickyToolbar"), {
	resolveComponent: (components) => components.StickyToolbar,
});

// Subheader
export const SubHeader = loadable(() => import("./subheader/SubHeader"), {
	resolveComponent: (components) => components.SubHeader,
});
export const SubHeaderSimple = loadable(() => import("./subheader/SubHeaderSimple"), {
	resolveComponent: (components) => components.SubHeaderSimple,
});
export const SubHeaderReportPage = loadable(() => import("./subheader/SubHeaderReportPage"), {
	resolveComponent: (components) => components.SubHeaderReportPage,
});

// Brand
export const Brand = loadable(() => import("./brand/Brand"), {
	resolveComponent: (components) => components.Brand,
});

// Search
export const SearchActivator = loadable(() => import("./search/SearchActivator"), {
	resolveComponent: (components) => components.SearchActivator,
});
export const SearchInput = loadable(() => import("./search/SearchInput"), {
	resolveComponent: (components) => components.SearchInput,
});
export const SearchResults = loadable(() => import("./search/SearchResults"), {
	resolveComponent: (components) => components.SearchResults,
});
export const SearchResult = loadable(() => import("./search/SearchResult"), {
	resolveComponent: (components) => components.SearchResult,
});
export const TOSearchItemPreview = loadable(() => import("./search/TOSearchItemPreview"), {
	resolveComponent: (components) => components.TOSearchItemPreview,
});
export const TOCustomerSearchItemPreview = loadable(() => import("./search/TOCustomerSearchItemPreview.js"), {
	resolveComponent: (components) => components.TOCustomerSearchItemPreview,
});
export const SOSearchItemPreview = loadable(() => import("./search/SOSearchItemPreview"), {
	resolveComponent: (components) => components.SOSearchItemPreview,
});
export const TRIPSearchItemPreview = loadable(() => import("./search/TRIPSearchItemPreview"), {
	resolveComponent: (components) => components.TRIPSearchItemPreview,
});

// Details
export const SODetail = loadable(() => import("./so-detail/SODetail"), {
	resolveComponent: (components) => components.SODetail,
});
export const TODetail = loadable(() => import("./to-detail/TODetail"), {
	resolveComponent: (components) => components.TODetail,
});
export const InvPartyDetail = loadable(() => import("./invparty-detail/InvPartyDetail"), {
	resolveComponent: (components) => components.InvPartyDetail,
});
export const SingleInvParty = loadable(() => import("./single-invparty/SingleInvParty"), {
	resolveComponent: (components) => components.SingleInvParty,
});
export const DetailLeg = loadable(() => import("./detail-leg/DetailLeg"), {
	resolveComponent: (components) => components.DetailLeg,
});
export const SingleLeg = loadable(() => import("./single-leg/SingleLeg"), {
	resolveComponent: (components) => components.SingleLeg,
});
export const SingleTrip = loadable(() => import("./single-trip/SingleTrip"), {
	resolveComponent: (components) => components.SingleTrip,
});
export const SingleTripDetail = loadable(() => import("./single-trip-detail/SingleTripDetail"), {
	resolveComponent: (components) => components.SingleTripDetail,
});
export const TimelineTOLeg = loadable(() => import("./timeline-toleg/TimelineTOLeg"), {
	resolveComponent: (components) => components.TimelineTOLeg,
});
export const OrderStatusHistory = loadable(() => import("./order-status-history/OrderStatusHistory"), {
	resolveComponent: (components) => components.OrderStatusHistory,
});
export const OrderEvents = loadable(() => import("./order-events/OrderEvents"), {
	resolveComponent: (components) => components.OrderEvents,
});
export const OrderProgress = loadable(() => import("./order-progress/OrderProgress"), {
	resolveComponent: (components) => components.OrderProgress,
});
export const TripProgress = loadable(() => import("./trip-progress/TripProgress"), {
	resolveComponent: (components) => components.TripProgress,
});
export const TimelineTrip = loadable(() => import("./timeline-trip/TimelineTrip"), {
	resolveComponent: (components) => components.TimelineTrip,
});
export const TimelineMilestonesLeg = loadable(() => import("./timeline-toleg/TimelineMilestonesLeg"), {
	resolveComponent: (components) => components.TimelineMilestonesLeg,
});
export const SingleDocumentDetail = loadable(() => import("./single-document-detail/SingleDocumentDetail"), {
	resolveComponent: (components) => components.SingleDocumentDetail,
});
export const ComparisonDetail = loadable(() => import("./comparison-detail/ComparisonDetail"), {
	resolveComponent: (components) => components.ComparisonDetail,
});
export const SingleComparisonDetail = loadable(() => import("./single-comparison-detail/SingleComparisonDetail"), {
	resolveComponent: (components) => components.SingleComparisonDetail,
});

// Advanced Search
export const AdvFiltersContainer = loadable(() => import("./adv-search/AdvFiltersContainer"), {
	resolveComponent: (components) => components.AdvFiltersContainer,
});

// Time Span
export const TimeSpan = loadable(() => import("./time-span/TimeSpan"), {
	resolveComponent: (components) => components.TimeSpan,
});

// Custom Dropdown
export const CustomDropdown = loadable(() => import("./custom-dropdown/CustomDropdown"), {
	resolveComponent: (components) => components.CustomDropdown,
});
export const CustomAutocompleteKeyword = loadable(() => import("./custom-autocomplete-keyword/CustomAutocompleteKeyword"), {
	resolveComponent: (components) => components.CustomAutocompleteKeyword,
});

// Bookmarks
export const BookmarksPage = loadable(() => import("./bookmarks-page/BookmarksPage"), {
	resolveComponent: (components) => components.BookmarksPage,
});
export const SingleBookmark = loadable(() => import("./single-bookmark/SingleBookmark"), {
	resolveComponent: (components) => components.SingleBookmark,
});
export const BookmarksDescriptionEditor = loadable(() => import("./bookmarks-description-editor/BookmarksDescriptionEditor"), {
	resolveComponent: (components) => components.BookmarksDescriptionEditor,
});

// Settings
export const SettingsPage = loadable(() => import("./settings-page/SettingsPage"), {
	resolveComponent: (components) => components.SettingsPage,
});
export const SingleSettingCustomer = loadable(() => import("./single-setting-customer/SingleSettingCustomer"), {
	resolveComponent: (components) => components.SingleSettingCustomer,
});
export const SingleSettingTmsCustomer = loadable(() => import("./single-setting-tmscustomer/SingleSettingTmsCustomer"), {
	resolveComponent: (components) => components.SingleSettingTmsCustomer,
});
export const SingleSettingView = loadable(() => import("./single-setting-view/SingleSettingView"), {
	resolveComponent: (components) => components.SingleSettingView,
});
export const ReportsEditor = loadable(() => import("./reports-editor/ReportsEditor"), {
	resolveComponent: (components) => components.ReportsEditor,
});

// Popover
export const CustomPopover = loadable(() => import("./popover/CustomPopover"), {
	resolveComponent: (components) => components.CustomPopover,
});

// Checkboxed Tree View
export const CheckboxedTreeView = loadable(() => import("./tree-view/CheckboxedTreeView"), {
	resolveComponent: (components) => components.CheckboxedTreeView,
});

// Custom Modal
export const GridConfigurationModal = loadable(() => import("./custom-modals/GridConfigurationModal"), {
	resolveComponent: (components) => components.GridConfigurationModal,
});

// Map
// NOTE: this one needs a regular import to correctly load HereAPI chunks
export { MapCard } from "./map-card/MapCard"
export const TripMapPopup = loadable(() => import("./map-popup/TripMapPopup"), {
	resolveComponent: (components) => components.TripMapPopup,
});
export const OrderMapPopup = loadable(() => import("./map-popup/OrderMapPopup"), {
	resolveComponent: (components) => components.OrderMapPopup,
});

// MapSide
export const ArceseBUFilter = loadable(() => import("./map-side-filters/ArceseBUFilter"), {
	resolveComponent: (components) => components.ArceseBUFilter,
});
export const ArrivalDateFilter = loadable(() => import("./map-side-filters/ArrivalDateFilter"), {
	resolveComponent: (components) => components.ArrivalDateFilter,
});
export const DepartureDateFilter = loadable(() => import("./map-side-filters/DepartureDateFilter"), {
	resolveComponent: (components) => components.DepartureDateFilter,
});
export const DriverFilter = loadable(() => import("./map-side-filters/DriverFilter"), {
	resolveComponent: (components) => components.DriverFilter,
});
export const LicensePlateFilter = loadable(() => import("./map-side-filters/LicensePlateFilter"), {
	resolveComponent: (components) => components.LicensePlateFilter,
});
export const StopFilter = loadable(() => import("./map-side-filters/StopFilter"), {
	resolveComponent: (components) => components.StopFilter,
});
export const TripCreationDateFilter = loadable(() => import("./map-side-filters/TripCreationDateFilter"), {
	resolveComponent: (components) => components.TripCreationDateFilter,
});
export const CustomerMainOrderReferenceFilter = loadable(() => import("./map-side-filters/CustomerMainOrderReferenceFilter"), {
	resolveComponent: (components) => components.CustomerMainOrderReferenceFilter,
});
export const CollectionIdFilter = loadable(() => import("./map-side-filters/CollectionIdFilter"), {
	resolveComponent: (components) => components.CollectionIdFilter,
});
export const LastMilestoneFilter = loadable(() => import("./map-side-filters/LastMilestoneFilter"), {
	resolveComponent: (components) => components.LastMilestoneFilter,
});
export const GoodsTypeFilter = loadable(() => import("./map-side-filters/GoodsTypeFilter"), {
	resolveComponent: (components) => components.GoodsTypeFilter,
});
export const LastLegTypeFilter = loadable(() => import("./map-side-filters/LastLegTypeFilter"), {
	resolveComponent: (components) => components.LastLegTypeFilter,
});
export const LaneFilter = loadable(() => import("./map-side-filters/LaneFilter"), {
	resolveComponent: (components) => components.LaneFilter,
});
export const TMSCompetenceDateFilter = loadable(() => import("./map-side-filters/TMSCompetenceDateFilter"), {
	resolveComponent: (components) => components.TMSCompetenceDateFilter,
});
export const SenderOriginDestinationConsigneeFilter = loadable(() => import("./map-side-filters/SenderOriginDestinationConsigneeFilter"), {
	resolveComponent: (components) => components.SenderOriginDestinationConsigneeFilter,
});
export const RequestedDeliveryDate = loadable(() => import("./map-side-filters/RequestedDeliveryDate"), {
	resolveComponent: (components) => components.RequestedDeliveryDate,
});