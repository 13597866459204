import React, { useContext, useEffect, useMemo, useState } from "react";
import Modal from "../../ui/modal/Modal";
import Button from "../../ui/button/Button";
import { IUserContext, UserContext } from "../../../context/UserContext";
import { IMasterDataContext, MasterDataContext } from "../../../context/MasterDataContext";
import { IGroup } from "../../../types/orders";
import { groupsService } from "../../../api/groupsService/groupsService";
import Spinner from "../../ui/spinner/Spinner";
import Select from "../../ui/select/Select";
import { IComb } from "../../../pages/planningToolGroupsCharge/PlanningToolGroupsCharge";


type Props = {
    callbackCloseModal: () => any;
}

const ModalShowGroupsStatus: React.FC<Props> = ({
    callbackCloseModal,
}) => {

    const { actualUser } = useContext(UserContext) as IUserContext;
    const { departments } = useContext(MasterDataContext) as IMasterDataContext;

    const departmentOptions = useMemo(() => {
        return [...departments].map((d => {
            return {
                value: d.id,
                label: d.name
            }
        }));
    }, [departments]);

    const [loading, setLoading] = useState<boolean>(false);
    /* ---- START GROUP AND DEPARTMENT SELECTION ---- */
    const [preliminarGroupSelection, setPreliminarGroupSelection] = useState<"my_groups" | "other_groups" | null>("other_groups");
    const [selectedDepartment, setSelectedDepartment] = useState<number | null>(null);
    const [otherGroupsResult, setOtherGroupsResult] = useState<IGroup[]>([]);
    const [myGroupsResult, setMyGroupsResult] = useState<IComb[]>([]);
    /* ---- END GROUP AND DEPARTMENT SELECTION ---- */

    useEffect(() => {
        if (!!departments && departments.length > 0) {
            setSelectedDepartment(departments[0].id);
        }
    }, [departments]);

    useEffect(() => {
        if (preliminarGroupSelection === "other_groups" && selectedDepartment !== null) {
            getOtherGroups();
        }

        if (preliminarGroupSelection === "my_groups") {
            getMyGroups();
        }
    }, [selectedDepartment, preliminarGroupSelection]);

    const getOtherGroups = async () => {
        if (selectedDepartment !== null) {
            setLoading(true);
            const response = await groupsService.getGroups(null, selectedDepartment);
            if (response.success) {
                setOtherGroupsResult(response.data);
            }
            setLoading(false);
        }
    }

    const getMyGroups = async () => {
        if (preliminarGroupSelection !== null) {
            setLoading(true);
            const response = await groupsService.getGroups(true, selectedDepartment);
            if (response.success) {
                // Find all combinations of departmentId
                const combs: IComb[] = [];

                response.data.forEach((group: IGroup, index: number) => {
                    const indexComb = combs.findIndex(comb => {
                        return comb.departmentId === group.departmentId;
                    });

                    if (indexComb !== -1 && indexComb !== null && indexComb !== undefined) {
                        combs[indexComb].groups.push(group);
                    } else {
                        combs.push({
                            departmentId: group.departmentId,
                            groups: [group]
                        });
                    }
                });

                setMyGroupsResult(combs);
            }
            setLoading(false);
        }
    }

    const MyGroupCurrentTable: React.FC<{ myGroupsResults: IComb[], departmentId: number | null }> = ({
        myGroupsResults,
        departmentId
    }) => {
        const comb = myGroupsResults.find((c) => c.departmentId == departmentId);
        return (
            <div>
                <table className="text-left w-full">
                    <thead className="bg-[#F9F7F6] text-black font-semibold text-[12px] uppercase border-t border-[#6F6F6F] border-b">
                        <tr>
                            <th className="p-3">Group</th>
                            <th className="p-3">Status</th>
                            <th className="p-3">User</th>
                            <th className="p-3">Remaining</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            !!comb && comb.groups.length > 0 &&
                            <>
                                {
                                    comb?.groups.map((group, index) => {
                                        return (
                                            <tr key={index} className="border-b border-[#F2F2F2] uppercase text-[12px] font-semibold text-[#6F6F6F]">
                                                {/* Group */}
                                                <td style={{ minWidth: "170px" }} className="p-3">{group.name}</td>
                                                {/* Status */}
                                                <td style={{ minWidth: "170px" }} className="p-3">{group.status}</td>
                                                {/* User */}
                                                <td style={{ minWidth: "170px" }} className="p-3">
                                                    Me
                                                </td>
                                                {/* Remaining */}
                                                <td style={{ minWidth: "170px" }} className="p-3">{group.volumeToBePlanned.toFixed(2)} m³</td>
                                            </tr>
                                        )
                                    })
                                }
                            </>
                        }

                        {
                            (!!!comb || comb.groups.length === 0) &&
                            <div>There are no groups to show.</div>
                        }
                    </tbody>
                </table>
            </div>
        );
    }

    const OtherGroupsCurrentTable: React.FC<{ otherGroupsResult: IGroup[] }> = ({
        otherGroupsResult,
    }) => {
        return (
            <>
                <table className="text-left w-full">
                    <thead className="bg-[#F9F7F6] text-black font-semibold text-[12px] uppercase border-t border-[#6F6F6F] border-b">
                        <tr>
                            <th className="p-3">Group</th>
                            <th className="p-3">Status</th>
                            <th className="p-3">User</th>
                            <th className="p-3">Remaining</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            otherGroupsResult.map((group, index) => {
                                return (
                                    <tr key={index} className="border-b border-[#F2F2F2] uppercase text-[12px] font-semibold text-[#6F6F6F]">
                                        {/* Group */}
                                        <td className="p-3">{group.name}</td>
                                        {/* Status */}
                                        <td className="p-3">{group.status}</td>
                                        {/* User */}
                                        <td className="p-3">
                                            {
                                                group.chargeUser !== null &&
                                                <>
                                                    {
                                                        group.chargeUser.username === actualUser?.username ? 'Me' : (group.chargeUser.name + ' ' + group.chargeUser.surname)
                                                    }
                                                </>
                                            }
                                            {
                                                group.chargeUser === null &&
                                                '-'
                                            }
                                        </td>
                                        {/* Remaining */}
                                        <td className="p-3">{group.volumeToBePlanned.toFixed(2)} m³</td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </>
        );
    }

    return (
        <Modal
            callbackCloseModal={callbackCloseModal}
            title="Groups status"
            footer={

                <div className="flex items-center justify-end">
                    <Button
                        callback={callbackCloseModal}
                    >
                        Close
                    </Button>
                </div>
            }
            customStyleChildrenContainer={{
                overflow: "visible"
            }}
            customStyleContainer={{
                maxHeight: '75%',
                width: '80%',
                maxWidth: '1920px'
            }}
        >
            <>
                {
                    departments.length === 0 &&
                    <div>Loading data...</div>
                }

                {
                    departments.length > 0 &&
                    <div>
                        <div className="flex items-center justify-between pb-[24px]">
                            <div className="flex items-center">
                                {
                                    departmentOptions.map((option, index) => {
                                        return (
                                            <div
                                                key={index}
                                                onClick={() => setSelectedDepartment(option.value)}
                                                className={"uppercase cursor-pointer border border-darkBlue border-[1.4px] px-[18px] py-[9px] rounded-[4px]" + (selectedDepartment === option.value ? (" bg-blue text-white text-sm font-semibold") : (" bg-white text-darkBlue text-sm font-semibold"))}
                                            >
                                                {option.label}
                                            </div>
                                        );
                                    })
                                }
                            </div>

                            {
                                <div className="flex items-center">
                                    <label
                                        htmlFor="filter"
                                        className="mt-1 uppercase font-semibold text-[13px] text-black mr-4 leading-[16px]"
                                    >
                                        Filter
                                    </label>
                                    <Select
                                        name="filter"
                                        options={[
                                            {
                                                label: "All groups",
                                                value: "other_groups"
                                            },
                                            {
                                                label: "My groups",
                                                value: "my_groups"
                                            }
                                        ]}
                                        callbackOnChange={(e) => setPreliminarGroupSelection(e.target.value)}
                                        value={preliminarGroupSelection}
                                    />
                                </div>
                            }
                        </div>

                        <div className="w-full overflow-auto h-full">
                            {/* My Groups Table */}
                            {
                                preliminarGroupSelection === "my_groups" &&
                                <>
                                    {
                                        !loading &&
                                        <>
                                            {
                                                myGroupsResult.length > 0 &&
                                                <MyGroupCurrentTable
                                                    myGroupsResults={myGroupsResult}
                                                    departmentId={selectedDepartment}
                                                />
                                            }
                                            {
                                                myGroupsResult.length === 0 &&
                                                <div>There are no groups matching the search parameters.</div>
                                            }
                                        </>
                                    }

                                    {
                                        loading &&
                                        <Spinner />
                                    }
                                </>
                            }

                            {/* Other Groups Table */}
                            {
                                (preliminarGroupSelection === "other_groups" && selectedDepartment !== null) &&
                                <>
                                    {
                                        !loading &&
                                        <div>
                                            {
                                                otherGroupsResult.length > 0 &&
                                                <OtherGroupsCurrentTable
                                                    otherGroupsResult={otherGroupsResult}
                                                />
                                            }

                                            {
                                                otherGroupsResult.length === 0 &&
                                                <div>There are no groups matching the search parameters.</div>
                                            }
                                        </div>
                                    }

                                    {
                                        loading &&
                                        <Spinner />
                                    }
                                </>
                            }
                        </div>
                    </div>
                }
            </>
        </Modal>
    )
}

export default ModalShowGroupsStatus;