import React, { useEffect, useRef, useState } from "react";
import Modal from "../../ui/modal/Modal";
import { ShippingUnitDetails } from "../../../types/orders";
import { ordersService } from "../../../api/ordersService/ordersService";
import { Table } from "react-bootstrap";

type Props = {
    callbackCloseModal: () => any;
    orderId: number | null;
    orderNumber: string;
    isGroupedOrdersModal?: boolean;
}

const ModalShowShipmentUnits: React.FC<Props> = ({
    callbackCloseModal,
    orderId,
    orderNumber,
    isGroupedOrdersModal = false
}) => {

    const [data, setData] = useState<null | ShippingUnitDetails[]>([]);
    const [loading, setLoading] = useState(true);

    const canGo = useRef(true);
    useEffect(() => {
        if (canGo.current === true && orderId !== null) {
            canGo.current = false;
            handleGetSetShippingUnitDetails();
        }
    }, []);

    const handleGetSetShippingUnitDetails = async () => {
        if (orderId !== null) {
            setLoading(true);
            const response = await ordersService.getShipmentUnits(orderId, isGroupedOrdersModal);

            if (response.success) {
                setData(response.data);
            }
            setLoading(false);
        }
    }

    return (
        <Modal
            callbackCloseModal={callbackCloseModal}
            title={"Shipment Units - Order " + (orderNumber)}
            customStyleChildrenContainer={{
                overflow: "auto",
            }}
            customStyleContainer={{
                maxHeight: '75%',
                width: '80%',
                maxWidth: '1920px'
            }}
        >
            <>
                {
                    !!loading &&
                    <div>Loading...</div>
                }

                {
                    !loading &&
                    <>
                        {
                            !!data && data.length > 0 &&
                            <div className="overflow-hidden">
                                {/* @ts-ignore */}
                                <Table>

                                    <thead className="bg-grey">
                                        <tr>
                                            <th className="p-2">Quantity</th>
                                            <th className="p-2">Package</th>
                                            <th className="p-2">Stackability</th>
                                            <th className="p-2">Width</th>
                                            <th className="p-2">Length</th>
                                            <th className="p-2">Height</th>
                                        </tr>
                                    </thead>
                                    <tbody className="bg-white">
                                        {
                                            !!data && data.map((d, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td className="p-2">{d.quantity}</td>
                                                        <td className="p-2">{d.package}</td>
                                                        <td className="p-2">{d.stackability}</td>
                                                        <td className="p-2">{d.width}</td>
                                                        <td className="p-2">{d.length}</td>
                                                        <td className="p-2">{d.height}</td>
                                                    </tr>
                                                );
                                            })
                                        }
                                    </tbody>
                                </Table>
                            </div>
                        }
                        {
                            !!data && data.length === 0 &&
                            <div>There are no Shipment Units to show.</div>
                        }
                    </>
                }
            </>
        </Modal>
    );
}

export default ModalShowShipmentUnits;