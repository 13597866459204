import React from "react";
import { Outlet } from "react-router-dom";
import "../assets/styles/tailwind-input.css";
import "../assets/styles/index.css";

import "../../../node_modules/react-toastify/dist/ReactToastify.css";
import 'primeicons/primeicons.css';

import "../assets/styles/ag-grid/ag-grid.scss";
import "../assets/styles/ag-grid/ag-theme-alpine.scss";

// Contexts Providers
import { TripsProvider } from "../context/TripContext";
import { MasterDataProvider } from "../context/MasterDataContext";
import { UserProvider } from "../context/UserContext";
import { ToastContainer } from "react-toastify";
import { PlanningToolProvider } from "../context/PlanningToolContext";

const PlanningToolRoot: React.FC = () => {
	return (
		<div id="planning-tool">
			<PlanningToolProvider>
				<UserProvider>					
					<MasterDataProvider>
						<TripsProvider>
							<Outlet />

							<ToastContainer
								style={{ marginTop: "70px" }}
								position="top-right"
								autoClose={8000}
								closeButton={true}
								closeOnClick={true}
							/>

						</TripsProvider>
					</MasterDataProvider>
				</UserProvider>
			</PlanningToolProvider>
		</div>
	)
}

export default PlanningToolRoot;
