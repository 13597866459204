import {GetUser} from "./AuthUtils";

export function SetUserContext() {
    var appInsights = window.appInsights;
    appInsights.clearAuthenticatedUserContext();
    
    var user = GetUser();
    if (user !== null) {
        appInsights.setAuthenticatedUserContext(GetUser().email, null, true);            
    }
}