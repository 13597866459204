import React from "react";
import Modal from "../../ui/modal/Modal";
import Button from "../../ui/button/Button";
import TableRulesCombinationsTrips from "../../tableRulesCombinationsTrips/TableRulesCombinationsTrips";

type Props = {
    callbackOnClose: () => any;
}

const ModalTripRules: React.FC<Props> = ({
    callbackOnClose
}) => {
    return (
        <Modal
            callbackCloseModal={callbackOnClose}
            title="Trip rules"
            footer={
                <div className="flex items-center justify-end">
                    <Button
                        callback={callbackOnClose}
                    >
                        Close
                    </Button>
                </div>
            }
            customStyleChildrenContainer={{
                overflow: "auto"
            }}
            customStyleContainer={{
                maxHeight: '75%',
                width: '80%',
                maxWidth: '1920px'
            }}
        >
            <>
                <TableRulesCombinationsTrips />
            </>
        </Modal>
    );
}

export default ModalTripRules;