import dayjs from "dayjs";
import { planningToolApiUrls } from "../../configs/planningToolApiUrls";
import basicRequest from "../basicRequest";

export const dashboardService = {
    getPortalStatistics: async (datetime: (Date | undefined) = undefined) => {
        const request = new basicRequest();
        return await request.get(planningToolApiUrls.portalStatistics, {
            datetime: dayjs(datetime).format('YYYY-MM-DDTHH:mm:ss')
        });
    },
    getPortalStatisticsMissingOrders: async (datetime: (Date | undefined) = undefined, bft: number, tpr: number) => {
        const request = new basicRequest();
        return await request.get(planningToolApiUrls.portalStatistics + '/missingOrders', {
            datetime: dayjs(datetime).format('YYYY-MM-DDTHH:mm:ss'),
            bft: bft,
            tpr: tpr
        });
    },
}