import {LicenseManager} from "@ag-grid-enterprise/core";

export class AgGridService {
    constructor() {
        if (!AgGridService._instance) {
            AgGridService._instance = this;

            //LicenseManager.setLicenseKey("CompanyName=ARCESE TRASPORTI S.P.A.,LicensedGroup=Multi,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=0,AssetReference=AG-011823,ExpiryDate=20_November_2021_[v2]_MTYzNzM2NjQwMDAwMA==571e739532a88a573e7303c78f5543f6");
            LicenseManager.setLicenseKey("Using_this_AG_Grid_Enterprise_key_( AG-048104 )_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_( legal@ag-grid.com )___For_help_with_changing_this_key_please_contact_( info@ag-grid.com )___( ARCESE TRASPORTI SPA )_is_granted_a_( Multiple Applications )_Developer_License_for_( 1 ))_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_need_to_be_licensed_in_addition_to_the_ones_working_with_AG_Grid_Enterprise___This_key_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_AG_Grid_Enterprise_versions_released_before_( 19 November 2024 )____[v2]_MTczMTk3NDQwMDAwMA==db1351e3f4f155e80ac349af254d87ce");
        }
        return AgGridService._instance;
    }
}