import * as signalR from "@microsoft/signalr";
import settings from "../settings";
import { IPushMessage } from "../types/pushMessages";

const URL = settings.signalRHubUrl;

class SignalRConnector {
    private connection: signalR.HubConnection;

    public pushMessagesEvents: (
        onMessageReceived: (message: IPushMessage) => void
    ) => void;

    static instance: SignalRConnector;

    constructor() {
        this.connection = new signalR.HubConnectionBuilder()
            .withUrl(URL, {
                accessTokenFactory: () => {
                    return getAccessToken();
                },
                skipNegotiation: true,
                transport: signalR.HttpTransportType.WebSockets
            })
            .withServerTimeout(30000) // 30 sec
            .withKeepAliveInterval(15000) // 15 sec
            .withAutomaticReconnect()
            .build();

        this.connection.start().catch(err => console.log("Unable to connect to SignalR Hub"));
        this.pushMessagesEvents = (onMessageReceived) => {
            this.connection.on("BroadcastMessage", (message) => {
                onMessageReceived(message);
            });
        };
    }

    public static getInstance(): SignalRConnector {
        if (!SignalRConnector.instance) {
            SignalRConnector.instance = new SignalRConnector();
        }
        return SignalRConnector.instance;
    }
}

export default SignalRConnector.getInstance;

function getAccessToken(): string {
    var accessToken = window.sessionStorage.getItem('accessToken');
    return accessToken != null ? accessToken : "";
}