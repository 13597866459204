import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import CommonTable, { TableRef } from "../../components/commonTable/CommonTable";
import { triageService } from "../../api/triageService/triageService";
import { IGroup, IOrder } from "../../types/orders";
import TableTooltipPartner from "../../components/ui/tableTooltipPartner/TableTooltipPartner";
import OriginDestinationTableComponent from "../../components/ui/originDestinationTableComponent/OriginDestinationTableComponent";
import dayjs from "dayjs";
import Planning from "../../components/planning/Planning";
import TriageBanner from "../../components/triageBanner/TriageBanner";
import { groupsService } from "../../api/groupsService/groupsService";
import usePermissions from "../../utils/hooks/usePermissions";
import { PermissionAreas, PermissionOperations } from "../../types/permissions";
import { gridDateFilterParams, gridNumberFilterParams, gridTextFilterParams, originDestinationGridTextFilterParams } from "../../configs/constants";
import TableTooltipOrderNumbers from "../../components/ui/tableTooltipOrderNumbers/TableTooltipOrderNumbers";
import { toast } from "react-toastify";
import ModalShowShipmentUnits from "../../components/modals/modalShowShipmentUnits/ModalShowShipmentUnits";
import ModalErrorMissingFields from "../../components/modals/modalErrorMissingFields/ModalErrorMissingFields";
import TableTooltipCustomerIds from "../../components/ui/tableTooltipCustomerIds/TableTooltipCustomerIds";

const containerStyle = { width: '100%', height: '100%' };

const PlanningToolTriage: React.FC = () => {

    const { hasPermission } = usePermissions();

    // #region State
    const [selectedLegs, setSelectedLegs] = useState<number[]>([]);
    const [allGroups, setAllGroups] = useState<IGroup[] | undefined>(undefined);

    const [activeOrderShipmentUnits, setActiveOrderShipmentUnits] = useState<null | {
        orderId: number;
        orderNumber: string;
    }>(null);

    const [activeErrorMissingFields, setActiveErrorMissingFields] = useState<null | string[]>(null);

    const gridContainerRef = useRef<HTMLDivElement>(null)
    const commonTableRef = useRef<TableRef>(null);
    // #endregion

    // #region Memo    
    const columnDefs: any = useMemo(() => [
        {
            field: 'orderNumbers',
            sortable: false,
            filter: 'agTextColumnFilter',
            filterParams: gridTextFilterParams,
            floatingFilter: true,
            headerName: "ORDER N°",
            tooltipComponent: (e: any) => <TableTooltipOrderNumbers order={e.data} />,
            tooltipField: "orderNumbers",
            minWidth: 180,
            cellRenderer: ({ data }: { data: IOrder }) => {
                return (
                    <div className="text-[12px] flex h-full items-center justify-between">
                        <div>
                            {
                                !!data.orderNumbers && data.orderNumbers.map((n, j) => {
                                    if (j < 3) {
                                        return (
                                            <div style={{ lineHeight: '12px' }} key={j}>
                                                {n}
                                            </div>
                                        )
                                    }
                                })
                            }

                            {
                                !!data.orderNumbers && data.orderNumbers.length > 3 &&
                                <div style={{ lineHeight: '12px' }} className="text-blue cursor-pointer">
                                    e altri {data.orderNumbers.length - 3}
                                </div>
                            }
                        </div>

                        {
                            !!data.missingFields && data.missingFields !== null && data.missingFields.length > 0 &&
                            <div className="ml-2 cursor-pointer" title="Missing fields">
                                <svg xmlns="http://www.w3.org/2000/svg" onClick={() => setActiveErrorMissingFields(data.missingFields)} fill="none" viewBox="0 0 24 24" strokeWidth={1.5} className="stroke-red w-[20px] h-[20px]">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M18.364 18.364A9 9 0 0 0 5.636 5.636m12.728 12.728A9 9 0 0 1 5.636 5.636m12.728 12.728L5.636 5.636" />
                                </svg>
                            </div>
                        }
                    </div>
                );
            }
        },
        {
            field: 'origin',
            filter: 'agTextColumnFilter',
            sortable: true,
            filterParams: originDestinationGridTextFilterParams,
            floatingFilter: true,
            tooltipField: 'originPartner',
            tooltipComponent: (e: any) => !!e.data.originPartner ? <TableTooltipPartner destination={e.data.origin} type="origin" /> : <div></div>,
            minWidth: 330,
            autoHeight: true,
            wrapText: true,
            headerName: "ORIGIN",
            cellRenderer: ({ data }: { data: IOrder }) => <OriginDestinationTableComponent type="origin" data={data} />
        },
        {
            field: 'destination',
            filter: 'agTextColumnFilter',
            sortable: true,
            filterParams: originDestinationGridTextFilterParams,
            floatingFilter: true,
            tooltipField: 'destinationPartner',
            tooltipComponent: (e: any) => !!e.data.destinationPartner ? <TableTooltipPartner destination={e.data.destination} type="destination" /> : <div></div>,
            minWidth: 330,
            autoHeight: true,
            wrapText: true,
            headerName: "DESTINATION",
            cellRenderer: ({ data }: { data: IOrder }) => <OriginDestinationTableComponent type="destination" data={data} />
        },
        {
            field: 'consigneeDock',
            sortable: true,           
            filter: 'agTextColumnFilter',
            filterParams: gridTextFilterParams,
            floatingFilter: true,
            headerName: "DOCK",
            minWidth: 110,
            cellRenderer: ({ data }: { data: IOrder }) => {
                return (
                    <div className="text-[12px] flex h-full items-center">
                        {!!data.consigneeDock ? data.consigneeDock : '-'}
                    </div>
                )
            },
        },
        {
            field: 'legs',
            minWidth: 600,
            filter: false,
            sortable: false,
            headerName: "PLANNING",
            cellRenderer: (props: { data: IOrder }) => {
                const orderData: IOrder | undefined = !!commonTableRef.current?.getTableRows() ? commonTableRef.current.getTableRows().find(o => props.data.id === o.id) : undefined;
                return (
                    <>
                        {
                            !!orderData &&
                            <div className="text-[12px] flex h-full items-center">
                                <Planning
                                    isTriagePage={true}
                                    callbackOnReady={() => { }}
                                    order={orderData}
                                    callbackRestoreRoute={null}
                                    callbackSelectedLegOrDeposit={(type, legId) => onSelectLegOrDeposit(type, legId)}
                                    groupsInCharge={null}
                                    callbackOnContextMenu={() => { }}
                                    legContextMenuRef={null}
                                    selectedLegs={selectedLegs}
                                    selectedDeposits={[]}
                                    callbackUpdateOrderLegs={() => { }}
                                    canEdit={true}
                                    callbackRemoveDeposit={() => { }}
                                    callbackRemoveLegFromTrip={() => { }}
                                />
                            </div>
                        }
                    </>
                )
            }
        },
        {
            field: 'volume',
            sortable: true,
            filter: 'agNumberColumnFilter',
            filterParams: gridNumberFilterParams,
            floatingFilter: true,
            headerName: "VOLUME",
            cellRenderer: ({ data }: { data: IOrder }) => {
                return (
                    <div className="text-[12px] flex h-full items-center">
                        {data.volume}
                    </div>
                )
            },
        },
        {
            field: 'weight',
            sortable: true,
            filter: 'agNumberColumnFilter',
            filterParams: gridNumberFilterParams,
            floatingFilter: true,
            headerName: "WEIGHT",
            cellRenderer: ({ data }: { data: IOrder }) => {
                return (
                    <div className="text-[12px] flex h-full items-center">
                        {data.weight}
                    </div>
                )
            }
        },
        {
            field: 'taxWeight',
            sortable: true,
            filter: 'agNumberColumnFilter',
            filterParams: gridNumberFilterParams,
            floatingFilter: true,
            headerName: "TAX-WEIGHT",
            cellRenderer: ({ data }: { data: IOrder }) => {
                return (
                    <div className="text-[12px] flex h-full items-center">
                        {data.taxWeight}
                    </div>
                )
            }
        },
        {
            field: 'linearMeters',
            sortable: true,
            filter: 'agNumberColumnFilter',
            filterParams: gridNumberFilterParams,
            floatingFilter: true,
            headerName: "LINEAR METERS",
            cellRenderer: (props: { data: IOrder }) => {
                return (
                    <div className="text-[12px] flex h-full items-center">
                        {
                            <div className="flex items-center">
                                {props.data.linearMeters !== null ? props.data.linearMeters : '-'}
                            </div>
                        }
                    </div>
                )
            }
        },
        {
            field: 'shipmentUnitsNumber',
            sortable: true,
            cellStyle: { overflow: 'visible' },
            maxWidth: 150,
            filter: 'agNumberColumnFilter',
            filterParams: gridNumberFilterParams,
            floatingFilter: true,
            headerName: "SHIPPING UNIT",
            cellRenderer: ({ data }: { data: IOrder }) => {
                return (
                    <>
                        <div
                            onClick={() => {
                                setActiveOrderShipmentUnits({
                                    orderId: data.id,
                                    orderNumber: !!data.orderNumbers ? data.orderNumbers[0] : ''
                                });
                            }}
                            className="overflow-visible h-full flex items-center justify-start z-50 relative"
                        >
                            <div className="relative z-50">
                                <div className={"text-[12px] text-underlined cursor-pointer"}>
                                    {data.shipmentUnitsNumber}
                                </div>
                            </div>
                        </div>
                    </>
                );
            }
        },
        {
            field: 'earlyPickup',
            sortable: true,
            filter: 'agDateColumnFilter',
            floatingFilter: true,
            filterParams: gridDateFilterParams,
            minWidth: 180,
            headerName: "EARLY PICKUP",
            cellRenderer: ({ data }: { data: IOrder }) => {
                return (
                    <>
                        {
                            !!data.earlyPickup &&
                            <div className="text-[12px] flex h-full items-center">
                                {dayjs(data.earlyPickup).format('DD/MM/YYYY - HH:mm')}
                            </div>
                        }
                    </>
                )
            }
        },
        {
            field: 'latePickup',
            sortable: true,
            filter: 'agDateColumnFilter',
            floatingFilter: true,
            filterParams: gridDateFilterParams,
            minWidth: 180,
            headerName: "LATE PICKUP",
            cellRenderer: ({ data }: { data: IOrder }) => {
                return (
                    <>
                        {
                            !!data.latePickup &&
                            <div className="text-[12px] flex h-full items-center">
                                {dayjs(data.latePickup).format('DD/MM/YYYY - HH:mm')}
                            </div>
                        }
                    </>
                )
            }
        },
        {
            field: 'earlyDelivery',
            sortable: true,
            filter: 'agDateColumnFilter',
            floatingFilter: true,
            filterParams: gridDateFilterParams,
            minWidth: 180,
            headerName: "EARLY DELIVERY",
            cellRenderer: ({ data }: { data: IOrder }) => {
                return (
                    <>
                        {
                            !!data.earlyDelivery &&
                            <div className="text-[12px] flex h-full items-center">
                                {dayjs(data.earlyDelivery).format('DD/MM/YYYY - HH:mm')}
                            </div>
                        }
                    </>
                )
            }
        },
        {
            field: 'lateDelivery',
            sortable: true,
            filter: 'agDateColumnFilter',
            floatingFilter: true,
            filterParams: gridDateFilterParams,
            minWidth: 180,
            headerName: "LATE DELIVERY",
            cellRenderer: ({ data }: { data: IOrder }) => {
                return (
                    <>
                        {
                            !!data.lateDelivery &&
                            <div className="text-[12px] flex h-full items-center">
                                {dayjs(data.lateDelivery).format('DD/MM/YYYY - HH:mm')}
                            </div>
                        }
                    </>
                )
            }
        },
        {
            field: 'customerCollectionID',
            sortable: true,
            filter: 'agTextColumnFilter',
            filterParams: gridTextFilterParams,
            floatingFilter: true,
            headerName: "CUSTOMER COLLECTION ID",
            tooltipField: "customerCollectionID",
            tooltipComponent: (e: { data: IOrder }) => !!e.data.customerCollectionID && e.data.customerCollectionID.length > 0 ? <TableTooltipCustomerIds customerIds={e.data.customerCollectionID} /> : <></>,
            cellRenderer: (props: { data: IOrder }) => {
                return (                    
                    <div className="text-[12px] flex h-full items-center justify-between">
                        <div className="flex items-center mt-1">
                            {
                                (!!props.data.customerCollectionID && props.data.customerCollectionID.length > 0) ?
                                    (
                                        <div>
                                            {
                                                props.data.customerCollectionID?.map((n, j) => {
                                                    if (j < 3) {
                                                        return (
                                                            <div style={{ lineHeight: '12px' }} key={j}>
                                                                {n}
                                                            </div>
                                                        )
                                                    }
                                                })
                                            }

                                            {
                                                !!props.data.customerCollectionID && props.data.customerCollectionID.length > 3 &&
                                                <div style={{ lineHeight: '12px' }} className="text-blue cursor-pointer">
                                                    and {props.data.customerCollectionID.length - 3} more
                                                </div>
                                            }
                                        </div>
                                    )
                                    :
                                    (
                                        <div>-</div>
                                    )
                            }
                        </div>
                    </div>
                )
            }
        },
        {
            field: 'customerLinehaulID',
            sortable: true,
            filter: 'agTextColumnFilter',
            filterParams: gridTextFilterParams,
            floatingFilter: true,
            headerName: "CUSTOMER LINEHAUL ID",
            tooltipField: "customerLinehaulID",
            tooltipComponent: (e: { data: IOrder }) => !!e.data.customerLinehaulID && e.data.customerLinehaulID.length > 0 ? <TableTooltipCustomerIds customerIds={e.data.customerLinehaulID} /> : <></>,
            cellRenderer: (props: { data: IOrder }) => {
                return (                    
                    <div className="text-[12px] flex h-full items-center justify-between">
                        <div className="flex items-center mt-1">
                            {
                                (!!props.data.customerLinehaulID && props.data.customerLinehaulID.length > 0) ?
                                    (
                                        <div>
                                            {
                                                props.data.customerLinehaulID?.map((n, j) => {
                                                    if (j < 3) {
                                                        return (
                                                            <div style={{ lineHeight: '12px' }} key={j}>
                                                                {n}
                                                            </div>
                                                        )
                                                    }
                                                })
                                            }

                                            {
                                                !!props.data.customerLinehaulID && props.data.customerLinehaulID.length > 3 &&
                                                <div style={{ lineHeight: '12px' }} className="text-blue cursor-pointer">
                                                    and {props.data.customerLinehaulID.length - 3} more
                                                </div>
                                            }
                                        </div>
                                    )
                                    :
                                    (
                                        <div>-</div>
                                    )
                            }
                        </div>
                    </div>
                )
            }
        },
    ], [selectedLegs]);
    // #endregion

    // #region UI Handlers
    const onSelectLegOrDeposit = (type: "leg" | "deposit", legId: number) => {
        if (type === "leg" && hasPermission(PermissionAreas.Triage, PermissionOperations.Modify)) {
            if (selectedLegs.includes(legId)) {
                setSelectedLegs([...selectedLegs.filter(x => x !== legId)]);
            } else {
                setSelectedLegs([...selectedLegs, legId]);
            }
        }
    };
    // #endregion

    // #region Util Functions
    const getTriageData = async () => {
        commonTableRef.current?.showLoadingOverlay();
        const response = await triageService.getTriageOrders();
        commonTableRef.current?.hideOverlay();

        if (response.success) {
            commonTableRef.current?.setRowData(response.data);
        } else {
            toast.error('Error retrieving orders data');
        }
    };

    const getGroups = async () => {
        const response = await groupsService.getGroups();
        if (response.success) {
            setAllGroups(response.data);
        }
    };

    const setTriageOrdersGroup = async (groupId: number) => {
        const response = await triageService.setTriageOrdersGroup(groupId, selectedLegs, "Setting group...", "Group set successfully", "Error setting group");
        if (response.success) {
            getTriageData();
            setSelectedLegs([]);
        }
    };
    // #endregion

    // #region CallBack
    const onGridReady = useCallback(() => {
        adjustTableScrollbar();
        getTriageData();
    }, []);
    // #endregion

    // #region Use Effects
    useEffect(() => {
        getGroups();
    }, []);
    // #endregion

    const adjustTableScrollbar = () => {
        const widthOfTable = gridContainerRef?.current?.offsetWidth;
        const scrollBar = document.querySelector('.ag-body-horizontal-scroll');
        if (!!scrollBar && !!widthOfTable) {
            // @ts-ignore
            scrollBar.style.width = widthOfTable?.toString() + 'px';
        }
    }

    return (
        <div id="planning-tool-triag-page" className="w-full mb-6 bg-white p-[32px]">
            <div className="font-light text-lg leading-[25px]">Planning Tool</div>
            <div className="flex items-center pb-3 border-b mb-6">
                <div className="md:mr-3 text-[32px] font-bold leading-[45px]">
                    Triage
                </div>
            </div>

            <div
                className="planning-tool-triage-table-wrapper"
                style={containerStyle}
                ref={gridContainerRef}
            >
                <CommonTable
                    ref={commonTableRef}
                    columnDefs={columnDefs}
                    onGridReady={onGridReady}
                    options={{
                        editable: false,
                        pageSize: 10,
                        suppressCellFocus: true
                    }}
                />
            </div>

            {
                selectedLegs.length > 0 &&
                <TriageBanner
                    groups={allGroups}
                    selectedLegs={selectedLegs.length}
                    callbackSetGroup={setTriageOrdersGroup}
                />
            }

            {
                activeOrderShipmentUnits !== null &&
                <ModalShowShipmentUnits
                    callbackCloseModal={() => setActiveOrderShipmentUnits(null)}
                    orderId={activeOrderShipmentUnits.orderId}
                    orderNumber={activeOrderShipmentUnits.orderNumber}
                />
            }

            {
                !!activeErrorMissingFields && activeErrorMissingFields !== null && activeErrorMissingFields.length > 0 &&
                <ModalErrorMissingFields
                    callbackCloseModal={() => setActiveErrorMissingFields(null)}
                    missingFields={activeErrorMissingFields}
                />
            }
        </div>
    )
};

export default PlanningToolTriage;