import React, { useContext, useEffect, useMemo, useState } from "react";
import Modal from "../../ui/modal/Modal";
import Button from "../../ui/button/Button";
import { ITripsContext, TripsContext } from "../../../context/TripContext";
import { IMasterDataContext, MasterDataContext } from "../../../context/MasterDataContext";
import { tripsService } from "../../../api/tripsService/tripsService";
import Select from "../../ui/select/Select";
import TableRulesCombinationsTrips from "../../tableRulesCombinationsTrips/TableRulesCombinationsTrips";
import { errorConstants } from "../../../configs/errorConstants";

type Props = {
    callbackClose: () => any;
    selectedLegs: number[];
    callbackOnTripCreated: () => any;
    callbackSetOrderIdsInError: (orderIds: number[]) => any;
}

const ModalCreateTrip: React.FC<Props> = ({
    callbackClose,
    selectedLegs,
    callbackOnTripCreated,
    callbackSetOrderIdsInError
}) => {

    const {
        setAdvancedFiltersRef,
        advancedFiltersRef,
        setActiveTripId,
        setIsOpenSidebarTrips
    } = useContext(TripsContext) as ITripsContext;
    const { equipments } = useContext(MasterDataContext) as IMasterDataContext;

    // EquipmentOptions for Select
    const equipmentsOptions = useMemo(() => {
        return equipments.map(e => {
            return {
                value: e.id,
                label: e.name
            }
        })
    }, [equipments]);

    const [loadingTripCreation, setLoadingTripCreation] = useState<boolean>(false);
    const [tripDescription, setTripDescription] = useState('');
    const [tripEquipment, setTripEquipment] = useState<number | null>(null);

    useEffect(() => setTripEquipment(!!equipments[1] ? equipments[1].id : null), [equipments])

    const createTrip = async () => {
        if (!!tripEquipment) {
            setLoadingTripCreation(true);

            const legIds: number[] = selectedLegs;

            const response = await tripsService.createTrip({
                header: {
                    description: tripDescription,
                    equipmentId: parseInt(tripEquipment.toString()),
                    carrierId: null,
                    truckPlateNumber: null,
                    truckPlateTrailer: null
                },
                legs: legIds,
            }, "Creating Trip...", "Trip created successfully", "Error creating trip");

            if (response.success) {
                let createdTripId = response.data;
                setLoadingTripCreation(false);
                callbackClose();
                setActiveTripId(createdTripId);
                setAdvancedFiltersRef({ ...advancedFiltersRef.current, tripDate: (new Date()).toISOString().split('T')[0] });
                setIsOpenSidebarTrips(true);
                callbackOnTripCreated();
            } else {
                setLoadingTripCreation(false);
                if (response.data.code === errorConstants.tripRuleForbidden) {
                    setShowedTab("rules_visualization");
                }

                if (response.data.code === errorConstants.ON_TRIP_LEG_COMBINATION_EXCEPTION) {
                    callbackSetOrderIdsInError(response.data.detailObject);
                }
            }
        }
    }

    const [showedTab, setShowedTab] = useState<"trip_selection" | "rules_visualization">('trip_selection');


    return (
        <Modal
            callbackCloseModal={callbackClose}
            title="Create trip"
            footer={
                showedTab === "trip_selection" ?
                    <div className="flex items-center justify-end">
                        <Button
                            callback={createTrip}
                            loading={loadingTripCreation}
                        >
                            Confirm creation
                        </Button>
                    </div>
                    : <div></div>
            }
        >
            <>
                {
                    showedTab === "trip_selection" &&
                    <>
                        <div className="flex items-center mb-6">
                            <div className="w-1/2">
                                <Select
                                    options={equipmentsOptions}
                                    label="Equipment"
                                    value={tripEquipment}
                                    name="equipment"
                                    callbackOnChange={(e) => setTripEquipment(e.target.value)}
                                />
                            </div>
                        </div>

                        <div>
                            <label className="mb-3 block text-[15px]" htmlFor="tripDescription">Add a description (optional)</label>
                            <textarea
                                onChange={(e) => setTripDescription(e.target.value)}
                                value={tripDescription}
                                name="tripDescription"
                                id="tripDescription"
                                className="w-full text-[15px] border border-black rounded-[4px] min-h-[80px] p-2"
                            >
                            </textarea>
                        </div>
                    </>
                }

                {
                    showedTab === "rules_visualization" &&
                    <div>
                        <div className="flex items-center mb-4">
                            <div onClick={() => {
                                setShowedTab("trip_selection");
                            }} className="cursor-pointer">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-8 h-8">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </div>

                            <div className="ml-4 text-[18px] font-bold">Trip Combination Rules</div>
                        </div>
                        <TableRulesCombinationsTrips />
                    </div>
                }
            </>
        </Modal>
    );
}

export default ModalCreateTrip;