import React from "react";
import './Spinner.css';

interface Props {
    height?: number;
    width?: number;
    color?: string;
}

const Spinner: React.FC<Props> = ({
    height = 19,
    width = 19,
    color = '#3699FF'
}) => {
    return (
        <div
            className="spinner-border"
            style={{
                width: width + "px",
                height: height + "px",
                color: color,
                borderLeftColor: color,
                borderTopColor: color,
                borderBottomColor: color,
            }}
            role="status"
        >
            <span className="sr-only">Loading...</span>
        </div>
    )
}

export default Spinner;