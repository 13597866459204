import moment from "moment";

export function formatDate(date) {
    if (date && date != null)
        return moment(new Date(date)).format("DD/MM/yyyy");

    return "";
}

export function formatDateToMin(date) {
    if (date)
        return moment(new Date(date)).format("DD/MM/yyyy HH:mm");

    return "";
}

export function isCurrentDateTimeSpan(from, to) {
    var nowFrom = new Date(new Date().setHours(0, 0, 0, 0));
    var nowTo = new Date(new Date().setHours(23, 59, 59, 999));

    return from.getTime() === nowFrom.getTime() && to.getTime() === nowTo.getTime();
}

export function getCurrentDateTimeSpan() {
    var from = new Date(new Date().setHours(0, 0, 0, 0));
    var to = new Date(new Date().setHours(23, 59, 59, 999));

    return {
        from,
        to
    };
}

export function getCurrentHourTimeSpan() {
    var from = new Date(new Date().setHours(new Date().getHours(), 0, 0, 0));
    var to = new Date(new Date().setHours(new Date().getHours(), 59, 59, 999));
    
    return {
        from,
        to
    };
}

export function getTimeFromDate(date) {
    if (date && date != null)
        return moment(date).format("HH:mm:ss");

    return "";
}

export function toUTC(date) {
    return moment.utc(date).format('YYYY-MM-DDTHH:mm:ss');
}

export function formatDateNoTZ(date) {
    if (date && date != null)
        return moment(removeTZ(date)).format("DD/MM/yyyy");

    return "";
}

export function formatDateToMinNoTZ(date) {
    if (date)
        return moment(removeTZ(date)).format("DD/MM/yyyy HH:mm");

    return "";
}

export function getTimeFromDateNoTZ(date) {
    if (date && date != null)
        return moment(removeTZ(date)).format("HH:mm");

    return "";
}

export function removeTZ(dateStr) {
    if (dateStr && dateStr.length > 0 && dateStr.indexOf("+") >= 0)
        dateStr = dateStr.replace(dateStr.substring(dateStr.indexOf("+"), dateStr.length), "");

    return dateStr;
}

export function toDateObject(date) {
    return date instanceof Date ? date : new Date(date);
}

export function calculateTimeDifferenceAndFormat(firstDate, secondDate) {
    firstDate = new moment(toDateObject(firstDate));
    secondDate = new moment(toDateObject(secondDate));
    return moment.duration(secondDate.diff(firstDate));
}

export function isValidDate(date) {
    return (date instanceof Date && !isNaN(date.valueOf()));
}