import basicRequest from "../basicRequest";
import { planningToolApiUrls } from "../../configs/planningToolApiUrls";
import { DefaultRoutingConfiguration } from "../../types/routingConfiguration";

export class GetRoutingOption {
    public customerCode?: string | null;
    public businessFlow?: string[] | null;
    public originCountry?: string | null;
    public originZipCodeFrom?: string | null;
    public originZipCodeTo?: string | null;
    public destinationCountry?: string | null;
    public destinationZipCodeFrom?: string | null;
    public destinationZipCodeTo?: string | null;
    public orderBy?: string | null;
    public orderDirection?: string | null = "asc";
    public pageSize?: number | null;
    public pageIndex?: number | null;
}

export const routingConfigurationService = {
    getRoutingConfiguration: async (options: GetRoutingOption = new GetRoutingOption()) => {
        const request = new basicRequest();
        return await request.get(planningToolApiUrls.routingConditions, options);
    },
    deleteRoutingConfigurations: async (body: DefaultRoutingConfiguration[], loadingMessage: string | null = null, successMessage: string | null = null, errorMessage: string | null = null) => {
        const request = new basicRequest();
        return await request.post(`${planningToolApiUrls.routingConditions}/multidelete`, body, undefined, loadingMessage, successMessage, errorMessage);
    },
    updateRoutingConfigurations: async (body: DefaultRoutingConfiguration[], loadingMessage: string | null = null, successMessage: string | null = null, errorMessage: string | null = null) => {
        const request = new basicRequest();
        return await request.post(`${planningToolApiUrls.routingConditions}`, body, undefined, loadingMessage, successMessage, errorMessage);
    },
}